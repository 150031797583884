import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const SummaryTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.8),
  fontSize: 16,
  lineHeight: '24px',
  fontWeight: 500,
  '@media (max-width: 1780px)': {
    marginBottom: theme.spacing(1.3),
  },
}));

export const SummaryCount = styled(Typography)(() => ({
  fontWeight: 'bold',
  fontSize: 40,
  lineHeight: '24px',
}));

export const SummaryLabel = styled(Box)(({ theme }) => ({
  fontSize: '0.875rem',
  paddingLeft: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 1780px)': {
    display: 'none',
  },
}));

export const SummarySectionBox = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'isActive',
})<{ isActive: boolean }>(({ theme, isActive }) => ({
  borderRight: '1px solid rgba(0, 51, 102, 0.2)',
  flexGrow: 1,
  padding: theme.spacing(0, 2),
  cursor: 'pointer',
  '&:first-of-type': {
    paddingLeft: 0,
  },
  '&:last-of-type': {
    borderRight: 'none',
  },
  opacity: isActive ? 1 : 0.3,
  '@media (max-width: 1790px)': {
    padding: theme.spacing(0, 1),
  },
}));

export const SummaryTagAlert = styled('span')(({ color, theme }) => ({
  color: color ?? '#D5001A',
  flexWrap: 'nowrap',
  fontWeight: 'bold',
  fontSize: '0.875rem',
  paddingLeft: theme.spacing(1),
}));
