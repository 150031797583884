import { useEffect } from 'react';
import { useDomainAuth } from './useDomainAuth';

const LogoutWatcher = () => {
  const { signOut } = useDomainAuth();

  useEffect(() => {
    const broadcastChannel = new BroadcastChannel('auth-channel');

    broadcastChannel.addEventListener('message', (event) => {
      if (event.data === 'logout') {
        signOut(true);
      }
    });

    return () => {
      broadcastChannel.close();
    };
  }, [signOut]);

  return null;
};

export default LogoutWatcher;
