import AppColors from 'AppColors';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { DataGridReturn } from './useDataGrid';
import { StyledTableCell } from './styled';
import { useDataHeader } from './useDataHeader';

interface Props<DataType> {
  hook: DataGridReturn<DataType>;
  hasSelectors?: boolean;
  stickyHeader?: boolean;
  stickyOffset?: number | string;
  isEditMode?: boolean;
}

export default function DataHead<DataType>({
  hook,
  hasSelectors,
  stickyHeader,
  stickyOffset,
  isEditMode,
}: Props<DataType>) {
  const { selectedCount, dataCount, selectAll } = useDataHeader(hook);
  const { columns } = hook;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    selectAll(event.target.checked);
  };

  return (
    <TableHead
      sx={{
        position: stickyHeader ? 'sticky' : 'static',
        top: stickyOffset,
      }}
    >
      <TableRow>
        {hasSelectors && (
          <TableCell
            padding="checkbox"
            sx={{
              borderBottom: `1px solid ${AppColors.AAA_LIGHT_NAVY}`,
            }}
          >
            {isEditMode && (
              <Checkbox
                color="primary"
                indeterminate={selectedCount > 0 && selectedCount < dataCount}
                checked={dataCount > 0 && selectedCount === dataCount}
                onChange={handleSelectAllClick}
              />
            )}
          </TableCell>
        )}
        {columns.map((column, index) => (
          <StyledTableCell
            key={index}
            colSpan={column.colSpan}
            sx={{
              minWidth: column.minWidth,
              maxWidth: column.maxWidth,
              width: column.width,
              borderBottom: `1px solid ${AppColors.AAA_LIGHT_NAVY}`,
            }}
          >
            <Typography fontSize={14} fontWeight={700} width="100%">
              {column.label}
            </Typography>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
