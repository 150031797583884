import { Button } from '@mui/material';
import AppColors from 'AppColors';
import { useCaseRejectContext } from 'features/Cases/contexts/CaseReject';
const ClaimRejectActionComponent = ({ row }: { row: { uuid: string } }) => {
  const { initOverride } = useCaseRejectContext();

  const handleClick = () => {
    initOverride(row.uuid);
  };
  return (
    <Button
      onClick={handleClick}
      variant="outlined"
      sx={{
        width: '55px',
        height: '24px',
        textTransform: 'capitalize',
        color: AppColors.AAA_WHITE,
        backgroundColor: AppColors.AAA_BLUE,
        '&:disabled': {
          opacity: 0.5,
          color: AppColors.AAA_WHITE,
        },
        '&:hover': {
          backgroundColor: AppColors.AAA_BLUE,
        },
      }}
    >
      Override
    </Button>
  );
};

export default ClaimRejectActionComponent;
