import { useCallback } from 'react';
import { useDataSubscribe } from './useDataSubscribe';
import { DataGridReturn } from './useDataGrid';

export function useDataHeader<DataType>(gridHook: DataGridReturn<DataType>) {
  const { observer, reload } = gridHook;

  const selectedCount = useDataSubscribe(observer.selectedCount);
  const dataCount = useDataSubscribe(observer.dataCount);

  const selectAll = useCallback(
    (checked: boolean) => {
      observer.selectAll(checked);
      reload();
    },
    [observer, reload],
  );

  return { selectedCount, dataCount, selectAll };
}
