export const {
  ENV_AWS_REGION,
  ENV_AWS_USER_POOL_ID,
  ENV_AWS_USER_POOL_WEBCLIENTID,
  ENV_AWS_REGION_2,
  ENV_AWS_USER_POOL_ID_2,
  ENV_AWS_USER_POOL_WEBCLIENTID_2,
  ENV_AWS_IDENTITY_PROVIDER,
  ENV_AWS_OAUTH_DOMAIN,
  ENV_AWS_OAUTH_CLIENTID,
  ENV_BACKOFFICE_BASE_API_URL,
} = import.meta.env;
