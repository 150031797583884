import { fetchTotalErrors } from 'api/dashboard/casesErrorsSummaryApi';
import { useMappedReactQuery } from 'hooks/useReactQueryHelpers';
import { useMemo } from 'react';

export const useTotalErrorsQuery = () =>
  useMappedReactQuery('QUERY_TOTAL_ERRORS', fetchTotalErrors, {});

export const useErrorsQuery = () => {
  const { data: totalErrors, isLoading } = useTotalErrorsQuery();

  const errorCounts = useMemo(
    () => ({
      total: totalErrors || { count: 0, appliedFilter: '' },
    }),
    [totalErrors],
  );

  return { errorCounts, isLoading };
};
