import { Routes, Route } from 'react-router-dom';
import { PrivatePathRouteProps, privateRoutes } from 'routes';
import { RoleBasedComponent } from 'RoleBasedComponent';

const renderRoute = ({ element, notAllowedRoles, ...routeProps }: PrivatePathRouteProps) => {
  if (routeProps.path === '/no-permissions') {
    return element;
  }

  return <RoleBasedComponent notAllowedRoles={notAllowedRoles} element={element} />;
};

export const AppPrivateRoutes = () => (
  <Routes>
    {privateRoutes.map((route) => (
      <Route {...route} key={route.path} element={renderRoute(route)} />
    ))}
  </Routes>
);
