import RecordDetailGroup from 'components/RecordDetailGroup';
import { ServiceItemsData } from '../types';
import ServiceItemDetailGroup from './ServiceItemDetailGroup';

interface Props {
  data?: ServiceItemsData;
  isLoading?: boolean;
  toIndividualScreen?: (uuid: string) => void;
  isCreationMode?: boolean;
}

const ServiceItemDetails = ({ isLoading, data, toIndividualScreen, isCreationMode }: Props) => {
  return (
    <RecordDetailGroup
      loadingLabel="Loading Service Item Information"
      isLoading={isLoading}
      showExpandAll={!isCreationMode}
      defaultExpanded={true}
    >
      <ServiceItemDetailGroup
        data={data}
        toIndividualScreen={toIndividualScreen}
        isCreationMode={isCreationMode}
      />
    </RecordDetailGroup>
  );
};

export default ServiceItemDetails;
