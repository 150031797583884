import FormDivider from 'components/Form/FormDivider';
import { BalanceSheetDetail } from './components';
import { BalanceSheetTable } from './components/BalanceSheetTable';
import { Box } from '@mui/material';
import { CaseFinancialData } from './types';
import { useCaseFinancialsConfig } from './config';

type Props = {
  data: CaseFinancialData;
};

export default function BalanceSheet({ data }: Props) {
  const { caseFinancialViewTable } = useCaseFinancialsConfig();
  const canReadChargedToCreditCard = caseFinancialViewTable?.fields?.['chargedToCreditCard']?.read;

  if (!canReadChargedToCreditCard) return null;

  return (
    <>
      <FormDivider label="Case Billing & Payment Details" sx={{ mb: 3 }} />
      <Box mx={-4} my={3}>
        <BalanceSheetDetail data={data} />
        <BalanceSheetTable data={data.caseFinancialItems} />
      </Box>
    </>
  );
}
