import { ClientQuery } from 'features/Setup/Clients/ClientProvider';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { ProgramData } from '../ProgramQuery';

export function useGetInitialData() {
  const { clientUUID } = useParams();
  const { data: clientData, isSuccess, isLoading } = ClientQuery.useFetchByUUID(clientUUID || '');

  const data: Partial<ProgramData> = useMemo(
    () => ({
      uuid: '',
      rapClientId: clientData?.rapClientId,
      clientName: clientData?.name || '',
      contactCenterPartner: clientData?.contactCenterPartner,
      customerCallNumber: clientData?.customerCallNumber,
      clubCallInNumber: clientData?.clubCallInNumber,
      aaaAssignedDid: clientData?.aaaAssignedDid,
      poEffectiveDateTime: '',
      poExpirationDateTime: '',
      clientUuid: clientData?.uuid,
    }),
    [clientData],
  );
  return { data, isSuccess, isLoading };
}
