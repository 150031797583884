import { CRUDComponent } from 'components/CRUDNavigator';
import EntityCreationFlow, {
  EntityCreationFlowProvider,
} from '../../../components/EntityCreationFlow';
import { ClientCreationFormSteps } from './ClientCreation/config';

const ClientPageCreator: CRUDComponent = ({ navigators }) => {
  return (
    <EntityCreationFlowProvider>
      <EntityCreationFlow steps={ClientCreationFormSteps} navigators={navigators} />
    </EntityCreationFlowProvider>
  );
};

export default ClientPageCreator;
