import { Typography, TypographyProps } from '@mui/material';
import styled from '@mui/system/styled';

const HeaderTypography = styled(Typography)<TypographyProps>(() => ({
  marginBottom: '1rem',
  fontWeight: 600,
  fontSize: '2.125rem',
  lineHeight: '2.25rem',
  letterSpacing: '0.15px',
  color: '#003366',
}));

const BodyTypography = styled(Typography)<TypographyProps>(() => ({
  width: 397,
  marginBottom: '1.5rem',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  letterSpacing: '0.5px',
  color: '#003366',
}));

export default function LoginHeader() {
  return (
    <>
      <HeaderTypography variant="h5">Login</HeaderTypography>
      <BodyTypography variant="body2" paragraph>
        {'Log in with your organizational account'}
        <br />
        {'to access the RAP Billing & Payment Portal'}
      </BodyTypography>
    </>
  );
}
