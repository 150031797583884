import { useMemo, useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import SubMenuButton from 'components/SubMenuButton';
import { SubMenuItem } from 'components/SubMenuButton/SubMenuButton';
import { NotificationType } from 'components/Notifications';
import DialogConfirmation from 'components/DialogConfirmation';
import { DynamicField, DynamicFieldType, DynamicForm, DynamicValue } from 'components/Dynamic';
import { CaseData } from './interfaces/CaseData';
import { buildErrorCodesArray, CaseBulkActionsEnum, getAvailableActions } from './helpers';
import { useExecuteBulkAction } from './queries';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';
import { yupResolver } from '@hookform/resolvers/yup';
import { RbpCaseStatus } from 'core/enums/RbpCaseStatus';
import { useClaimOverrideConfig } from './CaseDetails/CaseFinancials/useClaims';

type Props = {
  selectedCases: string[];
  setNotifications: (value: NotificationType) => void;
  setSelectedCases: React.Dispatch<React.SetStateAction<string[]>>;
  data: CaseData[];
  onBulkActionSubmit: (caseIds: string[]) => void;
};

const ignoreErrorsValidationSchema = Yup.object().shape({
  errorCodes: Yup.array()
    .of(Yup.string())
    .required('Error codes')
    .min(1, 'Select one error code at least'),
  reason: Yup.string().required().label('Reason'),
});

export function CasePageListBulkActions({
  selectedCases,
  data,
  setNotifications,
  setSelectedCases,
  onBulkActionSubmit,
}: Props) {
  const { mutateAsync: executeBulkAction, isError } = useExecuteBulkAction();
  const [showErrorCodeDialog, setShowErrorCodeDialog] = useState(false);
  const [showOverrideDialog, setShowOverrideDialog] = useState(false);
  const errorCasesList = buildErrorCodesArray(selectedCases, data);
  const { enableCloseAction, enableCancelAction, enableUnCancelAction, enableIgnoreAction } =
    useMemo(() => getAvailableActions(selectedCases, data), [selectedCases, data]);
  const selectedCasesData = data.filter((data) => selectedCases.includes(data.uuid));
  const selectedCasesIdsNotClientRuleNok = selectedCasesData
    .filter((c) => c.rbpCaseStatus !== RbpCaseStatus.CLIENT_RULE_NOK)
    .map((data) => data.rapCaseId)
    .join(', ');
  const { claimOverrideFields, claimOverrideValidationSchema } = useClaimOverrideConfig();

  const ignoreErrorsForm = useForm({
    defaultValues: {
      errorCodes: [],
      reason: '',
    },
    resolver: yupResolver(ignoreErrorsValidationSchema),
    mode: 'onChange',
  });

  const overrideForm = useForm({
    resolver: yupResolver(claimOverrideValidationSchema),
    mode: 'onChange',
  });

  const ignoreErrorsFields: DynamicField<DynamicValue>[] = [
    {
      name: 'errorCodes',
      label: 'Error Codes',
      colspan: 12,
      type: DynamicFieldType.SELECT,
      select: {
        options: errorCasesList,
        multiple: true,
        type: 'chip',
      },
    },
    {
      name: 'reason',
      label: 'Reason',
      multiline: true,
      colspan: 12,
      type: DynamicFieldType.TEXT,
      required: true,
      rows: 3,
    },
  ];
  const { permissions: clsPermissions } = useRapCoreFeaturePermissions('forceCls');

  const handleCancelCasesBulkAction = async () => {
    try {
      await executeBulkAction({
        rbpCaseUuids: selectedCases,
        action: CaseBulkActionsEnum.CANCEL,
      });
      setNotifications({
        type: 'success',
        name: 'Success',
        message: 'Cancelling selected cases initiated successfully',
      });
      setSelectedCases([]);
      onBulkActionSubmit(selectedCases);
    } catch (error: any) {
      console.error(error.response.data.message);
      setNotifications({
        type: 'error',
        name: 'Error',
        message: 'Cancelling selected cases initiation failed',
      });
    }
  };

  const handleUncancelCasesBulkAction = async () => {
    try {
      await executeBulkAction({
        rbpCaseUuids: selectedCases,
        action: CaseBulkActionsEnum.UN_CANCEL,
      });
      setNotifications({
        type: 'success',
        name: 'Success',
        message: 'Uncancelling selected cases initiated successfully',
      });
      setSelectedCases([]);
      onBulkActionSubmit(selectedCases);
    } catch (error: any) {
      console.error(error.response.data.message);
      setNotifications({
        type: 'error',
        name: 'Error',
        message: 'Uncancelling selected cases initiation failed',
      });
    }
  };

  const handleIgnoreErrorsBulkAction = async () => {
    try {
      await ignoreErrorsForm.trigger();

      if (ignoreErrorsForm.formState.isValid) {
        await executeBulkAction({
          rbpCaseUuids: selectedCases,
          action: CaseBulkActionsEnum.IGNORE_ERROR,
          parameters: {
            errorCodes: ignoreErrorsForm.getValues().errorCodes.join(', '),
            reason: ignoreErrorsForm.getValues().reason,
          },
        });
        setNotifications({
          type: 'success',
          name: 'Success',
          message: 'Ignoring errors of selected cases initiated successfully',
        });
        setSelectedCases([]);
        onBulkActionSubmit(selectedCases);
      }
    } catch (error: any) {
      console.error(error.response.data.message);
      setNotifications({
        type: 'error',
        name: 'Error',
        message: 'Ignoring errors of selected cases initiation failed',
      });
    }
  };

  const handleCloseCasesBulkAction = async () => {
    try {
      await executeBulkAction({
        rbpCaseUuids: selectedCases,
        action: CaseBulkActionsEnum.CLOSE,
      });
      setNotifications({
        type: 'success',
        name: 'Success',
        message: 'Closing selected cases initiated successfully',
      });
      setSelectedCases([]);
      onBulkActionSubmit(selectedCases);
    } catch (error: any) {
      console.error(error.response.data.message);
      setNotifications({
        type: 'error',
        name: 'Error',
        message: 'Closing selected cases initiation failed',
      });
    }
  };

  const handleOverrideCasesRejectsBulkAction = async () => {
    try {
      const rbpCaseUuidsToOverride = selectedCases.filter(
        (c) =>
          selectedCasesData.find((d) => d.uuid === c)?.rbpCaseStatus ===
          RbpCaseStatus.CLIENT_RULE_NOK,
      );
      await overrideForm.trigger();

      if (overrideForm.formState.isValid) {
        await executeBulkAction({
          rbpCaseUuids: rbpCaseUuidsToOverride,
          action: CaseBulkActionsEnum.CLAIM_OVERRIDE,
          parameters: {
            claimStatus: overrideForm.getValues().claimStatus,
            ignoreReason: overrideForm.getValues().ignoreReason,
          },
        });
        setNotifications({
          type: 'success',
          name: 'Success',
          message: 'Overriding of selected cases initiated successfully',
        });
        setSelectedCases([]);
        onBulkActionSubmit(rbpCaseUuidsToOverride);
      }
    } catch (error: any) {
      console.error(error.response.data.message);
      setNotifications({
        type: 'error',
        name: 'Error',
        message: 'Overriding of selected cases initiation failed',
      });
    }
  };

  const subMenuActions: SubMenuItem[] = [
    {
      label: 'Cancel Cases',
      title: 'Cancel Cases',
      content: `Are you sure you want to cancel ${selectedCases.length} case${
        selectedCases.length > 1 ? 's' : ''
      }?`,
      labelConfirm: 'Cancel cases',
      labelCancel: 'Close',
      onClick: handleCancelCasesBulkAction,
      disabled: !selectedCases || selectedCases.length < 1 || !enableCancelAction,
    },
    {
      label: 'Uncancel Cases',
      title: 'Uncancel Cases',
      content: `Are you sure you want to uncancel ${selectedCases.length} case${
        selectedCases.length > 1 ? 's' : ''
      }?`,
      labelConfirm: 'Uncancel cases',
      labelCancel: 'Close',
      onClick: handleUncancelCasesBulkAction,
      disabled: !selectedCases || selectedCases.length < 1 || !enableUnCancelAction,
    },
    {
      label: 'Close Cases',
      title: 'Close Cases',
      content: `Are you sure you want to Close ${selectedCases.length} case${
        selectedCases.length > 1 ? 's' : ''
      }?`,
      labelConfirm: 'Close cases',
      labelCancel: 'Cancel',
      onClick: handleCloseCasesBulkAction,
      disabled:
        !selectedCases || selectedCases.length < 1 || !clsPermissions?.update || !enableCloseAction,
    },
    {
      label: 'Ignore Errors',
      onClick: () => setShowErrorCodeDialog(true),
      hasCustomAction: true,
      disabled:
        !selectedCases ||
        selectedCases.length < 1 ||
        errorCasesList.length < 1 ||
        !enableIgnoreAction,
    },
    {
      label: 'Override Rejects',
      onClick: () => setShowOverrideDialog(true),
      hasCustomAction: true,
      disabled: !selectedCases || selectedCases.length < 1 || !clsPermissions?.update,
    },
  ];

  const isNotValid = !ignoreErrorsForm.formState.isValid;

  return (
    <Box component={Paper} borderRadius={0}>
      <Box display="flex" justifyContent="flex-end" padding={2}>
        {selectedCases.length > 0 && (
          <Typography mr={2} pt={1}>
            {selectedCases.length} case{selectedCases.length > 1 ? 's' : ''} selected
          </Typography>
        )}
        <SubMenuButton label="Actions" menu={subMenuActions} />
      </Box>
      <DialogConfirmation
        maxWidth="xs"
        open={showErrorCodeDialog}
        onClose={() => {
          ignoreErrorsForm.reset();
          setShowErrorCodeDialog(false);
        }}
        title="Ignore Errors"
        onConfirm={handleIgnoreErrorsBulkAction}
        disableConfirmLoader={isNotValid}
        isError={isError || isNotValid}
        sx={{
          '.MuiDialogContent-root': {
            paddingTop: 0,
          },
        }}
      >
        <Typography variant="subtitle1" pb={2}>
          Are you sure you want to ignore errors in {selectedCases.length} number of cases?
        </Typography>
        <DynamicForm isEditMode hookForm={ignoreErrorsForm} fields={ignoreErrorsFields} />
      </DialogConfirmation>
      <DialogConfirmation
        maxWidth="xs"
        open={showOverrideDialog}
        onClose={() => {
          overrideForm.reset();
          setShowOverrideDialog(false);
        }}
        title="Override Rejects"
        onConfirm={handleOverrideCasesRejectsBulkAction}
        disableConfirmLoader={isNotValid}
        isError={isError || isNotValid}
        sx={{
          '.MuiDialogContent-root': {
            paddingTop: 0,
          },
        }}
      >
        <Typography variant="subtitle1" pb={2}>
          Are you sure you want to Override Rejects of{' '}
          {
            selectedCasesData.filter((c) => c.rbpCaseStatus === RbpCaseStatus.CLIENT_RULE_NOK)
              .length
          }{' '}
          case{selectedCases.length > 1 ? 's' : ''}?
          {selectedCasesIdsNotClientRuleNok.length
            ? `*Cases ${selectedCasesIdsNotClientRuleNok} will be skipped because their status are not CLIENT RULE NOK.`
            : ''}
        </Typography>
        <DynamicForm isEditMode hookForm={overrideForm} fields={claimOverrideFields} />
      </DialogConfirmation>
    </Box>
  );
}
