/* eslint-disable @typescript-eslint/no-explicit-any */
import { DynamicExpandableFormActions } from 'components/Dynamic/DynamicExpandableFormActions';
import { DynamicExpandablePanel, useExpandablePanelGroup } from 'components/Dynamic';
import { PageableProviderContextType } from '../types';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { OptionalObjectSchema } from 'yup/lib/object';
import { yupResolver } from '@hookform/resolvers/yup';

interface Props<DtoType, DataType = DtoType> {
  context: PageableProviderContextType<DtoType, DataType>;
  isCreationMode?: boolean;
  icon?: ReactNode;
  title: string;

  /**
   * Callback to get UUID from data
   */
  getUUID?: (data: DataType) => string;

  /**
   * Callback executed after successfully creation.
   */
  onAfterCreate?: (uuid: string) => void;

  /**
   * Callback that returns a React Node of the form inputs
   */
  formContent?: (hookForm: UseFormReturn<any>, isEditMode: boolean) => ReactNode;

  /**
   * Initial data for the form inputs in case of creation mode = true
   */
  initialFormData?: DataType;

  /**
   * Provides yup validation schema for the form
   */
  validationSchema?: OptionalObjectSchema<any>;

  /**
   * open section with edition mode enabled
   */
  defaultEditMode?: boolean;

  /**
   * if enabled, the edit button is not visible
   */
  readOnly?: boolean;
}

export function CRUDPageableSection<DtoType, DataType = DtoType>({
  context,
  isCreationMode,
  getUUID,
  onAfterCreate,
  formContent,
  icon,
  title,
  initialFormData,
  validationSchema,
  defaultEditMode,
  readOnly,
}: Props<DtoType, DataType>) {
  const {
    queries,
    getById: { data },
    setIsEditingSection,
    isEditingSection,
    setNotifications,
  } = context;
  const defaultValues: any = useMemo(() => {
    return isCreationMode ? initialFormData || { uuid: '' } : data || {};
  }, [data, initialFormData, isCreationMode]);

  const hookForm = useForm({
    defaultValues,
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
  });
  const [expanded, setExpanded] = useState<boolean>(false);
  const [isEditMode, setEditMode] = useState<boolean>(defaultEditMode || false);
  const { groupExpanded } = useExpandablePanelGroup();
  const uuid: string = getUUID ? getUUID(defaultValues) : defaultValues.uuid;

  const { mutateAsync: mutateUpdateAsync } = queries.useUpdateByUUID(uuid);
  const { mutateAsync: mutateCreateAsync } = queries.useCreate();

  useEffect(() => {
    setExpanded(groupExpanded);
  }, [groupExpanded]);

  useEffect(() => {
    hookForm.reset(defaultValues);
  }, [defaultValues, hookForm]);

  const handleDiscard = useCallback(() => {
    hookForm.reset();
    setEditMode(false);
    setIsEditingSection(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsEditingSection]);

  const handleSave = useCallback(async () => {
    const handleUpdate = async (data: DataType) => {
      await mutateUpdateAsync(data);
      setEditMode(false);
      setIsEditingSection(false);
    };

    const handleCreate = async (data: DataType) => {
      const response = await mutateCreateAsync(data);
      const createdUUID = getUUID ? getUUID(response as any) : (response as any).uuid;
      setEditMode(false);
      setIsEditingSection(false);
      onAfterCreate?.(createdUUID);
    };

    const onSubmit = async (data: any) => {
      try {
        if (data.uuid && !isCreationMode) {
          await handleUpdate(data);
        } else {
          await handleCreate(data);
        }
      } catch (error: any) {
        const { response } = error;
        setNotifications({
          name: 'Error',
          message: response.data.details,
          type: 'error',
        });
      }
    };

    const submitForm = hookForm.handleSubmit(onSubmit);
    submitForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreationMode]);

  const handleEdit = useCallback(() => {
    setEditMode(true);
    setIsEditingSection(true);
  }, [setIsEditingSection]);

  return (
    <DynamicExpandablePanel
      data-testid={title}
      title={title}
      icon={icon}
      onExpanded={() => setExpanded(!expanded)}
      expanded={expanded}
      actions={{
        isEditMode,
        onEdit: !readOnly ? handleEdit : undefined,
      }}
      isEditingSection={isEditingSection}
    >
      {formContent?.(hookForm, isEditMode)}

      <DynamicExpandableFormActions
        isEditMode={isEditMode}
        save={{
          onSubmit: handleSave,
        }}
        discard={{
          onSubmit: handleDiscard,
        }}
      />
    </DynamicExpandablePanel>
  );
}
