import {
  BillingRequestStatus,
  BillingRequestStatusSchema,
} from 'api/invoices/interfaces/BillingRequestStatus';
import { fetchBillingRequestTotalByStatus } from 'api/invoices/manageBillingRequestApi';
import {
  BillingRequestSearchInitialState,
  BillingRequestStatusMap,
  BillRequestSummary,
  DefaultBillingRequestStatusSearch,
} from '../types';

export async function getBillingRequestSummary(): Promise<BillRequestSummary> {
  const responses = await Promise.all([
    ...DefaultBillingRequestStatusSearch.map((key) =>
      fetchBillingRequestTotalByStatus(BillingRequestStatusMap[key]),
    ),
  ]);

  const summary = responses.map((response, index) => {
    const statuses = BillingRequestStatusMap[DefaultBillingRequestStatusSearch[index]];

    const lateRequestsCount =
      response?.content?.filter(
        (item) => item.status === BillingRequestStatus.LATE_REVIEW_SUBMISSION,
      ).length || 0;

    const sentRequestCount =
      response?.content?.filter((item) => item.status === BillingRequestStatus.SENT).length || 0;

    const statusSchema =
      lateRequestsCount > 0
        ? BillingRequestStatusSchema[BillingRequestStatus.LATE_REVIEW_SUBMISSION]
        : BillingRequestStatusSchema[statuses[0]];

    let tag = undefined;
    if (lateRequestsCount > 0) {
      tag = `(${lateRequestsCount} Late)`;
    }
    if (sentRequestCount > 0) {
      tag = `(${sentRequestCount} Sent)`;
    }

    return {
      category: statusSchema.label,
      amount: response.totalElements,
      color: statusSchema.color,
      tag,
      name: statusSchema.key,
      value: statuses.join(','),
    };
  });

  const totalCard = {
    category: 'Total',
    amount: responses.reduce((total, item) => total + (item?.totalElements || 0), 0),
    color: '#003366',
    tag: undefined,
    name: 'Total',
    value: BillingRequestSearchInitialState.status,
  };

  summary.push(totalCard);

  return {
    summary,
    total: totalCard.amount,
  };
}
