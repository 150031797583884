import { CRUDNavigator } from 'components/CRUDNavigator';
import PaymentForecastsDetail from './PaymentForecastsDetail';
import PaymentRequestList from './PaymentForecastsList';
import PaymentForecastsClubDetail from './PaymentForecastsClubDetail';

export default function PaymentForecastsPage() {
  return (
    <CRUDNavigator
      components={{
        Main: PaymentRequestList,
        Individual: PaymentForecastsDetail,
        Detail: PaymentForecastsClubDetail,
      }}
    />
  );
}
