export const errorCodeLabels: Record<string, string> = {
  '1': 'Tow Miles <= 1',
  '2': 'Tow Miles > 12',
  '3': 'Tow Miles > 15',
  '4': 'Tow Miles > 20',
  '5': 'Tow Miles > 35',
  '6': 'Tow Miles > 50',
  '7': 'Tow Miles > 75',
  '8': 'Tow Miles > 100',
  '9': 'Failed to price',
  '10': '7A/7B not covered services',
  '11': 'Closed 7A/7B without cost',
  '12': 'Closed as No Services Provided',
  '13': 'ATA Greater than 400 min',
  '14': 'Cash call not allowed',
  '15': 'Client Bill Amount = $0.00',
  '16': 'Client Bill Amount > $ 999.99',
  '17': 'Closed med/hvy duty',
  '18': 'Closed w/2 tow events',
  '19': 'Closed with multiple svcs',
  '20': 'Prepaid call without auth code',
  '21': 'Prepaid Missing Credit Card #',
  '22': 'Prepaid verify pay resp & card info',
  '23': 'Dispatched after Sponsor Cancel',
  '24': 'Duplicate Case Exists',
  '25': 'Duplicate credit card auth code',
  '26': 'wrong Pmt. Type',
  '27': 'T8-Ext < 50.00 or > 100.00 (Tow ext - value GT + LT)',
  '28': 'Prepaid Call on Hold',
  '29': 'Club Pay Amount = $0.00',
  '30': 'Club Pay Amount > $999.99',
  '31': 'Revenue Share Rate Missing',
  '32': 'Check Revenue Share Dollar Amount',
  '33': 'Duplicate API',
  '34': 'Invalid HMA_GMA API ID',
  '35': 'Invalid API format begins with 0000',
  '36': 'Program Make/Model Mismatch',
  '37': 'HCA Missing PLAN Code Type Indicator',
  '38': 'Sonsio-closed for non-tire service',
  '39': 'Sonsio Bill Amount > $250.00',
  '40': 'Sonsio call without account number',
  '41': 'Ent. Fleet battery replacement',
  '42': 'Sprint battery replacement',
  '43': 'Missing Agreement #',
  '44': 'Odometer Exceeds Max',
  '45': 'Toyota T0 or T9 Service',
  '46': 'Start Date Missing',
  '47': 'End Date Missing',
  '48': 'Sales Type Missing',
  '49': 'reference missing #',
  '50': 'GOA <5 min or >150% of quoted ETA',
  '51': 'GOA requires GOA explanation',
  '52': 'Missing/Invalid Format RAP ID',
  '53': 'Invalid Service Call Date',
  '54': 'VIN length != 17',
  '55': 'Med/Hvy Duty non Tow',
  '56': 'Misc svc',
  '57': 'Missing Breakdown Location',
  '58': 'Missing Dispatch Timestamp',
  '59': 'Missing Odometer',
  '61': 'Missing Vehicle Information',
  '62': 'Tow Exchange closed w/single tow',
  '63': 'Client Cancel=RE Time',
  '64': 'T8-extrication not requested service',
  '65': 'Tow Dest Missing - when Tow Provided',
  '66': 'VIN missing',
  '67': 'Svc dispatched after cancelled',
  '68': 'Time to Scene less than 1 min',
};
