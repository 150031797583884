import { useNavigate, useParams } from 'react-router-dom';
import VendorDetail from './VendorDetail';
import { CRUDComponent } from 'components/CRUDNavigator';
import { CRUDPageableDetail } from 'components/CRUDPageable';
import { useVendorContext } from './VendorProvider';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';
import { VendorData } from './types';

const breadcrumbs = [
  {
    url: '..',
    label: 'Vendor List',
  },
  {
    url: '',
    label: 'Vendor Details',
  },
];

const VendorPageView: CRUDComponent = (props) => {
  const { uuid = '' } = useParams();
  const navigate = useNavigate();
  const { permissions } = useRapCoreFeaturePermissions('vendors');
  const context = useVendorContext();

  const { queries, setNotifications } = context;
  const { mutateAsync } = queries.useDeleteByUUID();

  const handleDeleteAction = async (data: VendorData) => {
    try {
      await mutateAsync(data.uuid);
      navigate('/setup/vendors', {
        state: {
          notifications: {
            type: 'success',
            name: 'Success',
            message: 'Vendor Successfully Deleted',
          },
        },
      });
    } catch (error: any) {
      setNotifications({
        type: 'error',
        name: 'Error',
        message: error.response.data.message,
      });
    }
  };

  return (
    <CRUDPageableDetail
      breadcrumbs={breadcrumbs}
      context={context}
      uuid={uuid}
      componentProps={props}
      details={({ data, isRunning }) => <VendorDetail isLoading={isRunning} data={data} />}
      handleLabel={(data) => data.name}
      visible={permissions.delete}
      subMenuActions={(data) => [
        {
          label: 'Delete Vendor',
          onClick: () => handleDeleteAction(data),
          title: `Delete ${data.name}?`,
          content: `Are you sure you want to delete ${data.name}?`,
          labelConfirm: 'Delete',
          labelCancel: 'Cancel',
        },
      ]}
    />
  );
};

export default VendorPageView;
