import { StepComponent } from 'components/FormStepper/types';
import {
  BillingConfigurationProgramData,
  useCreateProgramBillingConfig,
  useGetDefaultProgramBillingInfoData,
} from '../ProgramQuery';
import { useEntityCreationFlowContext, useEntityCreationStep } from 'components/EntityCreationFlow';
import { ProgramBillingInfoForm } from '../ProgramDetails/ProgramBillingInfoForm';
import { ProgramBillingInfoValidationSchema } from '../ProgramDetails/fields';
import { BillingConfigurationProgramDto } from 'api/setup/manageProgramsApi';

const ProgramBillingInfoStep = ({
  stepKey,
  submitted,
  completed,
  resetSubmitted,
  setCompleted,
}: StepComponent) => {
  const { data: clientData } = useEntityCreationFlowContext();
  const initialData = useGetDefaultProgramBillingInfoData();
  const BillingConfigStep = useEntityCreationStep<
    BillingConfigurationProgramData,
    BillingConfigurationProgramDto
  >({
    initialData: initialData,
    validationSchema: ProgramBillingInfoValidationSchema,
    forwardData: false,
    form: ProgramBillingInfoForm,
    // eslint-disable-next-line react-hooks/rules-of-hooks
    query: () => useCreateProgramBillingConfig(clientData?.uuid as string),
  });

  return (
    <BillingConfigStep
      stepKey={stepKey}
      submitted={submitted}
      completed={completed}
      resetSubmitted={resetSubmitted}
      setCompleted={setCompleted}
    />
  );
};

export default ProgramBillingInfoStep;
