import { EnumStrType } from './EnumStrType';

interface ObjectBase {
  key: string;
}

export type SchemaList<DataType extends ObjectBase> = Array<DataType>;

export type SchemaRecord<DataType extends ObjectBase, EnumType extends EnumStrType> = Record<
  keyof EnumType,
  DataType
>;

export function convertListToSchema<DataType extends ObjectBase, EnumType extends EnumStrType>(
  list: SchemaList<DataType>,
): SchemaRecord<DataType, EnumType> {
  return list.reduce((schema, item) => ({ ...schema, [item.key]: item }), {}) as SchemaRecord<
    DataType,
    EnumType
  >;
}
