import { UseFormReturn } from 'react-hook-form';
import DataTable, { DataTableProps, DataTableColumn } from 'components/DataTable';
import { ControlledTextField } from 'components/ControlledForm/ControlledTextField';
import { ControlledSelect } from 'components/ControlledForm/ControlledSelect';
import { DynamicFieldType } from 'components/Dynamic';
import FormInputTooltip, { TooltipCategories } from 'components/Form/FormInputTooltip';
import { ControlledCurrencyInput } from '../ControlledCurrencyInput';

export interface ControlledFormTableColumn<DataType> extends DataTableColumn<DataType> {
  fieldType?: string;
  isReadOnly?: boolean;
  options?: { label: string; value: string | number | boolean }[];
  startAdornment?: string;
  tooltip?: boolean;
  headerTooltip?: TooltipCategories;
}

export interface ControlledFormTableProps<DataType> extends Omit<DataTableProps<DataType>, 'rows'> {
  isEditMode: boolean;
  fieldArrayName: string;
  columns: ControlledFormTableColumn<DataType>[];
  hookForm: UseFormReturn<any>;
  fields: DataType[];
}

function ControlledFormTable<RowDataType extends Record<string, any>>({
  isEditMode,
  fieldArrayName,
  columns,
  hookForm,
  fields,
  ...rest
}: ControlledFormTableProps<RowDataType>) {
  function getDataTableColumns<DataType>(dataTableColumns: ControlledFormTableColumn<DataType>[]) {
    return dataTableColumns.map((column) => {
      const { fieldType, ...options } = column;
      return {
        ...options,
        ...(fieldType && {
          accessor: (_: any, rowIndex: number) => getAccessor(column, rowIndex),
        }),
      };
    });
  }

  function getAccessor<DataType>(column: ControlledFormTableColumn<DataType>, rowIndex: number) {
    const { control, formState } = hookForm;
    const { label, startAdornment } = column;
    const name = `${fieldArrayName}.${rowIndex}.${String(column.id)}`;
    const error = !!formState.errors[fieldArrayName]?.[rowIndex]?.[column.id]?.message;
    const errorMessage =
      formState.errors[fieldArrayName]?.[rowIndex]?.[column.id]?.message?.toString();
    const readOnly = !isEditMode || column.isReadOnly;
    const tooltipCategory = column.tooltip && (fields[rowIndex].tooltip as TooltipCategories);

    if (column.fieldType) {
      switch (column.fieldType) {
        case DynamicFieldType.TEXT:
          return (
            <ControlledTextField
              control={control}
              id={name}
              name={name}
              readOnly={readOnly}
              fullWidth
              variant="standard"
              inlineEditing
              placeholder={label}
              error={error}
              helperText={errorMessage}
              startAdornment={startAdornment}
              isEditMode={isEditMode}
              Tooltip={
                tooltipCategory &&
                isEditMode && (
                  <FormInputTooltip
                    category={TooltipCategories.t6FieldOnPricingSetup}
                    position="start"
                  />
                )
              }
            />
          );
        case DynamicFieldType.SELECT:
          return (
            <ControlledSelect
              control={control}
              id={name}
              name={name}
              readOnly={readOnly}
              options={column.options}
              inlineEditing
              variant="standard"
              error={error}
              helperText={errorMessage}
            />
          );
        case DynamicFieldType.CURRENCY:
          return (
            <ControlledCurrencyInput
              control={control}
              id={name}
              name={name}
              placeholder={label}
              readOnly={readOnly}
              error={error}
              helperText={errorMessage}
              isTableForm
            />
          );
        default:
          return null;
      }
    }
    return null;
  }

  const dataTableColumn = getDataTableColumns(columns);

  return <DataTable columns={dataTableColumn} rows={fields} {...rest} />;
}

export default ControlledFormTable;
