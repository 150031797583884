import { Card } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { SummaryCardContent, SummaryLabel, SummaryCount } from './styles';

type Props = {
  icon: JSX.Element;
  label: string;
  value: string | number;
  className?: string;
  sx?: SxProps<Theme>;
};

export default function SummaryCard({ icon, label, value, sx = {}, ...restProps }: Props) {
  return (
    <Card
      sx={{ minWidth: '300px', height: '120px', padding: '0 1rem', ...sx }}
      {...restProps}
      data-testid={`DashboardSummary-${label.split(' ').join('')}-Card`}
    >
      <SummaryCardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ height: '40px', position: 'relative' }}>
            <SummaryLabel>{label}</SummaryLabel>
          </Box>
          <SummaryCount>{value}</SummaryCount>
        </Box>
        <Box>{icon}</Box>
      </SummaryCardContent>
    </Card>
  );
}
