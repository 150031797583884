import { useState } from 'react';
import { FormControl, Typography, Button, Box, TextField } from '@mui/material';

interface LoginFormProps {
  onSubmit?: (email: string, password: string) => void;
  errorMessage?: string;
  isLoading?: boolean;
}

const LoginForm = ({ onSubmit, isLoading, errorMessage }: LoginFormProps) => {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = (ev: React.FormEvent) => {
    ev.preventDefault();
    if (password && email && onSubmit) {
      onSubmit(email, password);
    }
  };

  return (
    <form onSubmit={handleSubmit} data-testid="LoginForm">
      <FormControl variant="outlined" sx={{ mb: 3, width: 444 }}>
        <TextField
          data-testid="LoginFormUserEmail"
          id="useremail"
          type="text"
          value={email}
          placeholder="User ID"
          onChange={(ev) => setEmail(ev.target.value)}
        />
      </FormControl>

      <FormControl variant="outlined" sx={{ mb: 3.75, width: 444 }}>
        <TextField
          data-testid="LoginFormUserPass"
          id="userpass"
          type="password"
          value={password}
          placeholder="Password"
          onChange={(ev) => setPassword(ev.target.value)}
        />
      </FormControl>

      <Button
        data-testid="LoginSubmit"
        variant="contained"
        disabled={isLoading}
        sx={{ width: 444, letterSpacing: '1.25px' }}
        type="submit"
      >
        {isLoading ? 'Loading...' : 'Log In'}
      </Button>

      {errorMessage && (
        <Box
          sx={{
            mt: 1,
            textAlign: 'center',
            color: 'error.main',
          }}
        >
          <Typography variant="body2">{errorMessage}</Typography>
        </Box>
      )}
    </form>
  );
};

export default LoginForm;
