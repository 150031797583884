import Box, { BoxProps } from '@mui/material/Box';
import clsx from 'clsx';
import styles from '../CaseFinancials.module.css';

export function ColumnHeader({
  label,
  variant = 'receivable',
  ...rest
}: BoxProps & {
  label: string;
  variant?: 'payable' | 'receivable';
}) {
  return (
    <Box display="flex" alignItems="center" {...rest}>
      <span
        className={clsx(styles.headerPill, {
          [styles.receivable]: variant === 'receivable',
          [styles.payable]: variant === 'payable',
        })}
      />
      {label}
    </Box>
  );
}
