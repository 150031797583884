import { FormControl, TextField } from '@mui/material';
import { Header, Text } from 'layouts/Authentication';

type Props = {
  onChange: (value: string) => void;
};

const EmailInput = ({ onChange }: Props) => {
  return (
    <>
      <Header>Forgot Password?</Header>
      <Text>
        {'Enter your username and you will'}
        <br />
        {'receive a code to reset your password'}
      </Text>
      <FormControl variant="outlined" sx={{ width: 444 }}>
        <TextField
          name="email"
          type="email"
          placeholder="E-mail"
          onChange={(e) => onChange(e.target.value)}
        />
      </FormControl>
    </>
  );
};

export default EmailInput;
