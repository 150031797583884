import { useEffect } from 'react';
import { DynamicForm } from 'components/Dynamic';
import FormDivider from 'components/Form/FormDivider';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  accountingNumbersFields,
  allowManageCreditCardFields,
  useBillingInformationFields,
} from './fields';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hookForm: UseFormReturn<any>;
  isEditMode?: boolean;
}

const ClientBillingInfoForm = ({ hookForm, isEditMode }: Props) => {
  const { control, setValue } = hookForm;
  const watchPoRequired = useWatch({ control, name: 'poRequired' });

  const billingInformationFields = useBillingInformationFields({
    isPoRequired: watchPoRequired,
  });

  useEffect(() => {
    if (!watchPoRequired) {
      setValue('poNumber', '');
      setValue('poEffectiveDates', []);
    }
  }, [watchPoRequired, setValue]);

  return (
    <>
      <FormDivider label="Information" sx={{ mb: 3 }} />
      <DynamicForm
        hookForm={hookForm}
        isEditMode={isEditMode}
        fields={billingInformationFields}
        rowSpacing={3}
      />

      <FormDivider label="Account Numbers" sx={{ mt: 2, mb: 3 }} />
      <DynamicForm hookForm={hookForm} isEditMode={isEditMode} fields={accountingNumbersFields} />

      <FormDivider label="Allow/Manage Credit Card Case Activity" sx={{ mt: 2, mb: 3 }} />
      <DynamicForm
        hookForm={hookForm}
        isEditMode={isEditMode}
        fields={allowManageCreditCardFields}
        rowSpacing={3}
      />
    </>
  );
};

export default ClientBillingInfoForm;
