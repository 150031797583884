import { Header, Text } from 'layouts/Authentication';

const ResetConfirmation = () => {
  return (
    <>
      <Header>Password Reset</Header>

      <Text sx={{ marginBottom: '0px' }}>
        <>Your password has been successfully reset. </>
        <br />
        <>Click below to go to the login page</>
      </Text>
    </>
  );
};

export default ResetConfirmation;
