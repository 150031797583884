import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  TableRow,
  Box,
  Button,
  Stack,
} from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { ControlledTextField } from 'components/ControlledForm/ControlledTextField';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  StyledTableCell,
  LoadingIcon,
} from './AddonsTable.styled';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';
import { SyntheticEvent, useState } from 'react';
import { useUpdateCaseStatus } from 'features/Cases/queries';
import { RbpCaseStatus } from 'core/enums/RbpCaseStatus';
import { canRefreshGeneralInfoSection } from 'features/Cases/helpers';
import { CaseGeneralInfoData } from './interface/CaseGeneralInfoData';

export interface Props {
  data: { [key: string]: string };
  control: any;
  isEditMode: boolean;
  refetchAddons: () => void;
  isLoadingAddons: boolean;
  generalInfoData?: CaseGeneralInfoData;
}

const convertToTitleCase = (text: string) => {
  /**
   * owningArea => Owning Area
   * vehiclePONumber => Vehicle PO Number , etc..
   */

  // Hard coded condition requested on https://nationalaaa.atlassian.net/browse/RBP-2161
  if (text === 'accountNumber') {
    return 'Account Number/Client API ID/Reference #/Rental Agreement #';
  }
  // Hard coded condition requested on https://nationalaaa.atlassian.net/browse/RBP-4052
  if (text === 'vehicleSaleDate') {
    return 'Vehicle Sale Date/Warranty Start Date';
  }

  let result = text.replace(/([A-Z])/g, ' $1');
  result = result.replace(/\b([A-Z])\s+([A-Z])\b/g, '$1$2');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const AddonsTable: React.FC<Props> = ({
  data,
  control,
  isEditMode,
  refetchAddons,
  isLoadingAddons,
  generalInfoData,
}) => {
  const { mutateAsync: updateCaseStatus } = useUpdateCaseStatus(generalInfoData!.rbpCaseUuid);

  const { permissions: addOnsTablePermissions } = useRapCoreFeaturePermissions('addOnsTable');
  const [isLoadingSection, setIsloadingSection] = useState<boolean>(false);

  const elements = Object.entries(data);

  const refreshSectionHandler = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    setIsloadingSection(true);

    updateCaseStatus(RbpCaseStatus.FIXED)
      .then(() => refetchAddons())
      .finally(() => setIsloadingSection(false));
  };

  const [, clientId] = generalInfoData?.clientName ? generalInfoData.clientName.split(' - ') : '';
  const showRefreshButton = canRefreshGeneralInfoSection(generalInfoData!.rbpCaseStatus, clientId);

  const isLoading = isLoadingAddons || isLoadingSection;

  return (
    <Box sx={{ mx: -4, pt: 7, pb: 2 }}>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          sx={{
            height: 56,
          }}
        >
          <Typography
            fontSize={14}
            fontWeight={700}
            width="100%"
            display="flex"
            alignItems="center"
          >
            Client Specific Fields
          </Typography>
          {showRefreshButton && (
            <Button
              startIcon={<CachedIcon />}
              variant="contained"
              sx={{ width: 240 }}
              onClick={refreshSectionHandler}
              disabled={isLoading}
            >
              Refresh Section
            </Button>
          )}
        </AccordionSummary>
        {isLoading ? (
          <Stack flexGrow={1} alignItems="center" justifyContent="center" padding={5}>
            <LoadingIcon
              color="disabled"
              sx={{
                fontSize: 102,
              }}
            />
          </Stack>
        ) : (
          <AccordionDetails sx={{ m: 0, p: 0 }}>
            <TableContainer>
              <Table sx={{ tableLayout: 'auto' }}>
                <TableBody>
                  {elements.map(([key]) => {
                    const readOnly = !isEditMode || !addOnsTablePermissions?.update;
                    if (key === 'disputeQueueCode') return null;
                    return (
                      <TableRow key={key}>
                        <TableCell
                          sx={{
                            px: 4,
                            width: '1%',
                            maxWidth: '100%',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          <Typography fontSize={14} fontWeight={700} width="100%" component="span">
                            {convertToTitleCase(key)}
                          </Typography>
                        </TableCell>
                        <StyledTableCell focusHighlight>
                          <ControlledTextField
                            control={control}
                            id={key}
                            name={key}
                            readOnly={readOnly}
                            fullWidth
                            variant="standard"
                            inlineEditing
                            placeholder={key}
                          />
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        )}
      </Accordion>
    </Box>
  );
};

export default AddonsTable;
