import { ServiceItemsData } from '../types';
import InfoIcon from '@mui/icons-material/Info';
import { GeneralInfoValidationSchema } from './ServiceItemDetailsConfig';
import { useServiceItemsContext } from '../ServiceItemsProvider';
import { CRUDPageableSection } from 'components/CRUDPageable';
import ServiceItemForm from './ServiceItemForm';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';

type Props = {
  isCreationMode?: boolean;
  onAfterCreate?: (uuid: string) => void;
  initialData?: ServiceItemsData;
};

const ServiceItemGeneralInfo = ({ isCreationMode, onAfterCreate, initialData }: Props) => {
  const context = useServiceItemsContext();
  const { permissions } = useRapCoreFeaturePermissions('servicesProvided');

  return (
    <CRUDPageableSection
      context={context}
      title="General Info"
      icon={<InfoIcon />}
      isCreationMode={isCreationMode}
      onAfterCreate={onAfterCreate}
      initialFormData={initialData}
      defaultEditMode={isCreationMode}
      validationSchema={GeneralInfoValidationSchema}
      formContent={(hookForm, isEditMode) => (
        <ServiceItemForm hookForm={hookForm} isEditMode={isEditMode} />
      )}
      readOnly={!permissions.update}
    />
  );
};

export default ServiceItemGeneralInfo;
