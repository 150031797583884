/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Paper } from '@mui/material';
import { StepComponent } from 'components/FormStepper/types';
import {
  useEntityCreationFlowContext,
  useEntityCreationHookForm,
} from 'components/EntityCreationFlow';
import { useEffect, useMemo, useState } from 'react';
import { UseMutationResult } from 'react-query';
import { OptionalObjectSchema, TypeOfShape } from 'yup/lib/object';
import { AnyObject } from 'yup/lib/types';
import { EntityCreationForm } from '../types';
import { LoaderCard } from 'components/Cards';
import { NotificationSnackbar, NotificationType } from 'components/Notifications';

interface Props<DataType, DtoType = DataType> {
  initialData: Partial<DataType>;
  validationSchema?: OptionalObjectSchema<any, AnyObject, TypeOfShape<any>> | undefined;
  forwardData?: boolean;
  form: React.FC<EntityCreationForm>;
  query: () => UseMutationResult<DtoType, unknown, DataType>;
}

export default function useEntityCreationStep<DataType, DtoType = DataType>({
  initialData,
  validationSchema,
  forwardData = true,
  form: FormComponent,
  query,
}: Props<DataType, DtoType>): React.FC<StepComponent> {
  const EntityCreationStep = ({
    submitted,
    completed,
    stepKey,
    resetSubmitted,
    setCompleted,
  }: StepComponent) => {
    const [notifications, setNotifications] = useState<NotificationType>();
    const { setData } = useEntityCreationFlowContext();
    const hookForm = useEntityCreationHookForm<DataType>({
      stepKey,
      data: initialData,
      validationSchema,
    });
    const { mutateAsync, isLoading } = query();

    useEffect(() => {
      if (submitted) {
        const submitForm = hookForm.handleSubmit(
          async (data: DataType) => {
            try {
              const response = (await mutateAsync(data)) as any;
              if (forwardData) setData({ ...data, ...response });
              setCompleted(true);
            } catch (error: any) {
              const { response } = error;
              resetSubmitted();
              setNotifications({
                message: response?.data?.message || error.message,
                name: 'An unexpected error has occurred',
                type: 'error',
              });
            }
          },
          () => resetSubmitted(),
        );
        submitForm();
      }
    }, [submitted, hookForm, mutateAsync, setCompleted, setData, resetSubmitted]);

    return (
      <>
        {isLoading && <LoaderCard label={'Loading...'} sx={{ p: 2 }} />}
        {!isLoading && (
          <Box
            component={Paper}
            sx={{
              borderRadius: '4px 4px 0 0',
              padding: 2,
            }}
          >
            <FormComponent hookForm={hookForm} isEditMode={!completed} isCreationMode />
          </Box>
        )}
        <NotificationSnackbar
          clearNotification={() => setNotifications(undefined)}
          notification={notifications}
        />
      </>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => EntityCreationStep, []);
}
