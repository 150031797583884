import { StepComponent } from 'components/FormStepper/types';
import {
  BillingConfigurationClientData,
  useCreateClientBillingConfig,
  useGetDefaultClientBillingInfoData,
} from '../ClientQuery';
import ClientBillingInfoForm from '../ClientDetail/ClientBillingInfoForm';
import { ClientBillingInfoValidationSchema } from '../ClientDetail/fields';
import { useEntityCreationFlowContext, useEntityCreationStep } from 'components/EntityCreationFlow';
import { BillingConfigurationClientDto } from 'api/setup/manageClientApi';

const ClientBillingInfoStep = ({
  stepKey,
  submitted,
  completed,
  resetSubmitted,
  setCompleted,
}: StepComponent) => {
  const { data: clientData } = useEntityCreationFlowContext();
  const initialData = useGetDefaultClientBillingInfoData();
  const BillingConfigStep = useEntityCreationStep<
    BillingConfigurationClientData,
    BillingConfigurationClientDto
  >({
    initialData: initialData,
    validationSchema: ClientBillingInfoValidationSchema,
    forwardData: false,
    form: ClientBillingInfoForm,
    // eslint-disable-next-line react-hooks/rules-of-hooks
    query: () => useCreateClientBillingConfig(clientData?.uuid as string),
  });

  return (
    <BillingConfigStep
      stepKey={stepKey}
      submitted={submitted}
      completed={completed}
      resetSubmitted={resetSubmitted}
      setCompleted={setCompleted}
    />
  );
};

export default ClientBillingInfoStep;
