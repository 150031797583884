import { TableRow, TableCell } from '@mui/material';
import { CaseFinancialItemSummary } from 'api/cases/interfaces/CaseFinancialDto';
import { convertToCurrency } from 'utils/formatter';

interface Props {
  finantialItem: CaseFinancialItemSummary;
}

function AttachedFinancialItemRow({ finantialItem }: Props) {
  return (
    <TableRow
      sx={{
        background: 'white',
      }}
    >
      <TableCell>{finantialItem.itemCode}</TableCell>
      <TableCell>{finantialItem.itemName}</TableCell>
      <TableCell>{convertToCurrency(finantialItem.totalAmount)}</TableCell>
    </TableRow>
  );
}

export default AttachedFinancialItemRow;
