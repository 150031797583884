import { Stack } from '@mui/material';
import CasesSummaryCards from './SummaryCards/CasesSummaryCards';
import CreditCardsSummaryCards from './SummaryCards/CreditCardsSummaryCards';

const DashboardPage = () => {
  return (
    <Stack gap={3} sx={{ padding: '8px' }}>
      <CasesSummaryCards />
      <CreditCardsSummaryCards />
    </Stack>
  );
};

export default DashboardPage;
