import InfoIcon from '@mui/icons-material/Info';
import { VendorData } from '../types';
import { useVendorContext } from '../VendorProvider';
import { contactInfoFields, generalInfoFields, generalInfoSchema } from './fields';
import {
  DynamicExpandableFormActions,
  DynamicExpandablePanel,
  DynamicForm,
  useExpandablePanelGroup,
} from 'components/Dynamic';
import { Typography } from '@mui/material';
import { LoaderCard } from 'components/Cards';
import FormDivider from 'components/Form/FormDivider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useCreatePaymentRequestInfo,
  useUpdatePaymentRequestInfo,
  PaymentRequestInformationData,
  useGetPaymentRequestInfo,
} from '../VendorQuery';
import { isEqual } from 'lodash';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';

interface Props {
  isCreationMode?: boolean;
  onAfterCreate?: (uuid: string) => void;
  initialData?: VendorData;
}

export default function VendorGeneralInfo({ isCreationMode, onAfterCreate, initialData }: Props) {
  const {
    queries: { useUpdateByUUID },
    isEditingSection,
    setIsEditingSection,
  } = useVendorContext();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [isEditMode, setEditMode] = useState<boolean>(isCreationMode || false);
  const { groupExpanded } = useExpandablePanelGroup();
  const { permissions } = useRapCoreFeaturePermissions('vendors');

  useEffect(() => {
    setExpanded(groupExpanded);
  }, [groupExpanded]);

  const { data: paymentRequestData, isLoading: isGetPaymentRequestInfoLoading } =
    useGetPaymentRequestInfo(initialData?.uuid as string);

  const {
    mutateAsync: vendorUpdateMutateAsync,
    isLoading: isVendorUpdateLoading,
    isError: isVendorUpdateError,
    reset: resetVendorFields,
  } = useUpdateByUUID(initialData?.uuid as string);

  const {
    mutateAsync: createPaymentRequestInfoMutateAsync,
    isLoading: isCreatePaymentRequestInfoLoading,
    isError: isCreatePaymentRequestInfoError,
  } = useCreatePaymentRequestInfo(initialData?.uuid as string);

  const {
    mutateAsync: updatePaymentRequestInfoMutateAsync,
    isLoading: isUpdatePaymentRequestInfoLoading,
    isError: isUpdatePaymentRequestInfoError,
  } = useUpdatePaymentRequestInfo(initialData?.uuid as string);

  const handleEdit = useCallback(() => {
    setEditMode(true);
  }, []);

  const handleDiscard = () => {
    generalInfoHookForm.reset();
    resetVendorFields();
    setEditMode(false);
    setIsEditingSection(false);
  };

  const handleSave = async () => {
    const submit = generalInfoHookForm.handleSubmit(async (data) => {
      const { paymentReportRecipientEmails = [], ...newVendorData } = data;

      // only create-update paymentReportRecipientEmails when there are changes in the field
      if (
        !isEqual(paymentReportRecipientEmails, paymentRequestData?.paymentReportRecipientEmails)
      ) {
        if (!paymentRequestData) {
          await createPaymentRequestInfoMutateAsync({
            paymentReportRecipientEmails,
          } as PaymentRequestInformationData);
        } else {
          await updatePaymentRequestInfoMutateAsync({
            ...paymentRequestData,
            paymentReportRecipientEmails,
          });
        }
      }

      const { paymentReportRecipientEmails: _, ...oldVendorData } = defaultData;

      // only update when there are changes in the form
      if (!isEqual(newVendorData, oldVendorData)) {
        await vendorUpdateMutateAsync(newVendorData);
      }

      setEditMode(false);
      setIsEditingSection(false);
    });
    await submit();
  };

  const isLoading = useMemo(
    () =>
      isCreatePaymentRequestInfoLoading ||
      isUpdatePaymentRequestInfoLoading ||
      isVendorUpdateLoading ||
      isGetPaymentRequestInfoLoading,
    [
      isCreatePaymentRequestInfoLoading,
      isUpdatePaymentRequestInfoLoading,
      isVendorUpdateLoading,
      isGetPaymentRequestInfoLoading,
    ],
  );

  const isError = useMemo(
    () => isVendorUpdateError || isCreatePaymentRequestInfoError || isUpdatePaymentRequestInfoError,
    [isVendorUpdateError, isCreatePaymentRequestInfoError, isUpdatePaymentRequestInfoError],
  );

  const defaultData = useMemo(() => {
    return {
      ...initialData,
      paymentReportRecipientEmails: paymentRequestData?.paymentReportRecipientEmails || [],
    };
  }, [initialData, paymentRequestData]);

  const generalInfoHookForm = useForm<VendorData>({
    resolver: yupResolver(generalInfoSchema),
    defaultValues: defaultData,
  });

  useEffect(() => {
    // Update the form values when paymentRequestData changes
    generalInfoHookForm.reset(defaultData);
  }, [defaultData, generalInfoHookForm, generalInfoHookForm.reset]);

  return (
    <DynamicExpandablePanel
      title="General Info"
      icon={<InfoIcon />}
      onExpanded={() => setExpanded(!expanded)}
      expanded={expanded}
      actions={{
        isEditMode,
        onEdit: permissions.update ? handleEdit : undefined,
      }}
      isEditingSection={isEditingSection}
    >
      {isLoading && <LoaderCard label="Loading General Info" />}
      {defaultData && !isLoading && (
        <>
          <FormDivider label="Information" sx={{ mb: 3 }} />
          <DynamicForm
            hookForm={generalInfoHookForm}
            isEditMode={isEditMode}
            fields={generalInfoFields}
          />
          <FormDivider label="Vendor Contact Information" sx={{ mt: 2, mb: 3 }} />
          <DynamicForm
            hookForm={generalInfoHookForm}
            isEditMode={isEditMode}
            fields={contactInfoFields}
          />
          <DynamicExpandableFormActions
            isEditMode={isEditMode}
            save={{
              onSubmit: handleSave,
            }}
            discard={{
              onSubmit: handleDiscard,
            }}
          />
        </>
      )}
      {isError && <Typography>An Error Has Occurred While Updating Vendor General Info</Typography>}
    </DynamicExpandablePanel>
  );
}
