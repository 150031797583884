import { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useFieldArray, useForm, UseFormReturn } from 'react-hook-form';
import { nanoid } from 'nanoid';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Button, FormHelperText, Typography, styled } from '@mui/material';
import { DynamicExpandableFormActions } from 'components/Dynamic/DynamicExpandableFormActions';
import DynamicFormDeleteAction from 'components/Dynamic/DynamicFormDeleteAction/DynamicFormDeleteAction';
import { ControlledFormTable } from 'components/ControlledForm/ControlledFormTable';
import { NotificationSnackbar, NotificationType } from 'components/Notifications';
import { towsValidationResolver } from '../PricingSetupConfig';
import { Context } from '../components/PricingSetupProvider';
import { DutyTypes, PricingRecordDto } from 'api/setup/manageServicesSetupApi';
import { CreateTowSetupRequestDto, TowSetupDto, TowTypes } from 'api/setup/manageTowSetupApi';
import {
  useCreateTowsSetupBatch,
  useUpdateTowsSetupBatch,
  useCreateTowSetup,
  useDeleteTowSetup,
  TowSetupData,
  TowData,
  QUERY_TOWS_SETUP_ALL,
  QUERY_GET_PRICING_INFO,
} from '../PricingSetupQuery';
import { getUpdateTowsSetupData } from '../helper';
import { changeTime } from 'utils/convertDate';
import { DynamicFieldType } from 'components/Dynamic';

const ButtonWrapper = styled('div')(({ error }: { error: boolean }) => {
  return {
    button: {
      borderColor: error ? 'red' : 'inherit',
    },
  };
});

interface Props {
  data?: TowSetupDto[];
  dutyType: DutyTypes;
  isEditMode: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  isCreationMode: boolean;
  toIndividualScreen: (uuid: string) => void;
  tabIndex: number;
  generalInfoHookForm: UseFormReturn<Partial<PricingRecordDto>>;
  onSave: () => void;
  hasT6Service: boolean;
}

const PricingTowMileageRatesEditor = ({
  data,
  dutyType,
  isEditMode,
  setEditMode,
  toIndividualScreen,
  tabIndex,
  generalInfoHookForm,
  isCreationMode,
  onSave,
  hasT6Service,
}: Props) => {
  const [t6ServiceError, setT6ServiceError] = useState<boolean>(false);
  const [selectedTowsRate, setSelectedTowsRate] = useState<string[]>([]);
  const [deletedTowsRate, setDeletedTowsRate] = useState<string[]>([]);
  const [notifications, setNotifications] = useState<NotificationType>();
  const [resetTrigger, setResetTrigger] = useState<boolean>(false);
  const { mutateAsync: mutateCreateTowsSetupBatch } = useCreateTowsSetupBatch();
  const { mutateAsync: mutateUpdateTowsSetupBatch } = useUpdateTowsSetupBatch();
  const { mutateAsync: mutateCreateTowSetup } = useCreateTowSetup();
  const { mutateAsync: mutateDeleteTowSetup } = useDeleteTowSetup();
  const { uuid = '' } = useParams();
  const { entityType, handleIsTowMileageDirty, setIsEditingSection } = useContext(Context);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const hookForm = useForm<TowSetupData>({ resolver: towsValidationResolver });
  const {
    handleSubmit: handleGeneralInfoSubmit,
    reset: generalInfoReset,
    formState: { isDirty: isGeneralInfoDirty, dirtyFields: generalInfoDirtyFields },
  } = generalInfoHookForm;

  const { control, reset, getValues } = hookForm;
  const { isDirty: isTowMileageDirty } = hookForm.formState;

  const { fields, append, remove } = useFieldArray<TowSetupData>({
    control: control,
    name: 'tows',
  });

  useEffect(() => {
    handleIsTowMileageDirty?.(tabIndex, isTowMileageDirty);
  }, [isTowMileageDirty, handleIsTowMileageDirty, tabIndex]);

  useEffect(() => {
    const getDefaultValues = () => {
      if (data && data.length) {
        return getUpdateTowsSetupData(data);
      } else {
        return { tows: [] };
      }
    };
    const defaultValues = getDefaultValues();
    reset(defaultValues);
  }, [uuid, data, entityType, reset]);

  const handleResetSelect = () => {
    setResetTrigger((current) => !current);
  };

  const onAddTow = () => {
    setT6ServiceError(false);
    append({
      uuid: `fakeUuid-${nanoid()}`,
      entityType: entityType,
      entityUuid: uuid,
      startMileage: '',
      endMileage: '',
      type: TowTypes.STANDARD,
      pricePerUnit: '',
      priceCap: '',
    });
  };

  const onDeleteTow = () => {
    const itemsToRemove = selectedTowsRate.filter((x) => !deletedTowsRate?.includes(x));
    const indexes = itemsToRemove.map((towDestination) => {
      return fields.findIndex((field) => field.uuid === towDestination);
    });

    remove(indexes);
    setDeletedTowsRate?.((current) => [...current, ...itemsToRemove]);
    setSelectedTowsRate([]);
    handleResetSelect();
    hookForm.clearErrors();
    // User must enter tows in case T6 Service was configured
    if (hasT6Service && !getValues()?.tows?.length) {
      setT6ServiceError(true);
    }
  };

  const onSubmitAll = (formData: TowSetupData) => {
    handleGeneralInfoSubmit((generalInfoData) => {
      // User must enter tows in case T6 Service was configured
      if (hasT6Service && !formData.tows.length) {
        setT6ServiceError(true);
      }

      onSubmitTowMileage(generalInfoData, formData);
    })();
  };

  const onSubmitTowMileage = async (
    generalInfoData: Partial<PricingRecordDto>,
    formData: TowSetupData,
  ) => {
    const { startDateTime, endDateTime, description } = generalInfoData;
    const updatedStartDateTime = changeTime(startDateTime, 'T00:00:00Z');
    const updatedEndDateTime = changeTime(endDateTime, 'T23:59:59Z');

    const updatedData: TowSetupData = {
      ...formData,
      startDateTime: updatedStartDateTime,
      endDateTime: updatedEndDateTime,
      duty: dutyType,
      description: description || '',
    };

    if (
      (data && data.length === 0) ||
      generalInfoDirtyFields.startDateTime ||
      generalInfoDirtyFields.endDateTime
    ) {
      try {
        await mutateCreateTowsSetupBatch(updatedData);
        queryClient.invalidateQueries([QUERY_TOWS_SETUP_ALL]);
        queryClient.invalidateQueries([QUERY_GET_PRICING_INFO]);
        setEditMode(false);
        setIsEditingSection(false);
        setDeletedTowsRate([]);
        toIndividualScreen?.(`${updatedStartDateTime}|${updatedEndDateTime}`);
        onSave();
      } catch (error: any) {
        const { response } = error;
        setNotifications({
          name: 'Error',
          message: response.data.details,
          type: 'error',
        });
      }
    } else {
      const createData: CreateTowSetupRequestDto[] = [];
      const batchUpdateData: TowSetupData = { ...updatedData };
      batchUpdateData.tows = [];

      updatedData.tows.forEach((tow) => {
        if (tow.uuid && tow.uuid.startsWith('fakeUuid-')) {
          createData.push({
            ...tow,
            startDateTime: formData.startDateTime,
            endDateTime: formData.endDateTime,
            description: formData.description,
            duty: dutyType,
            startMileage: parseFloat(tow.startMileage),
            endMileage: parseFloat(tow.endMileage),
            pricePerUnit: parseFloat(tow.pricePerUnit),
            priceCap: tow.priceCap === '' ? null : parseFloat(tow.priceCap),
          });
        } else {
          batchUpdateData.tows.push(tow);
        }
      });

      const updateTows =
        batchUpdateData.tows.length > 0
          ? await mutateUpdateTowsSetupBatch(batchUpdateData)
          : Promise.resolve();

      const createTows =
        createData.length > 0
          ? Promise.all(
              createData.map(async (tow) => {
                await mutateCreateTowSetup(tow);
              }),
            )
          : Promise.resolve();

      const deleteTows =
        deletedTowsRate.length > 0
          ? Promise.all(
              deletedTowsRate.map(async (tow) => {
                !tow.startsWith('fakeUuid-') && (await mutateDeleteTowSetup(tow));
              }),
            )
          : Promise.resolve();

      try {
        await Promise.all([deleteTows, updateTows, createTows]);
        queryClient.invalidateQueries([QUERY_TOWS_SETUP_ALL]);
        queryClient.invalidateQueries([QUERY_GET_PRICING_INFO]);
        setEditMode(false);
        setIsEditingSection(false);
        setDeletedTowsRate([]);
        onSave();
      } catch (error: any) {
        const { response } = error;
        setNotifications({
          name: 'Error',
          message: response.data.details,
          type: 'error',
        });
      }
    }
  };

  const handleDiscard = () => {
    hookForm.reset();
    generalInfoReset();
    !isCreationMode && setEditMode(false);
    !isCreationMode && setIsEditingSection(false);
    setSelectedTowsRate([]);
    setDeletedTowsRate([]);
    handleResetSelect();
    if (isCreationMode) {
      navigate(-1);
    }
  };

  return (
    <Box>
      {isEditMode && (
        <Stack direction="row" mb={2} px={4} justifyContent="space-between">
          <Stack>
            <DynamicFormDeleteAction
              title="Delete tow mileage rate"
              label="Delete tow mileage rate"
              content="Are you sure you want to delete this tow mileage rate?"
              onSubmit={onDeleteTow}
              disabled={selectedTowsRate.length === 0}
            />
          </Stack>
          <Stack direction="column" alignItems="center">
            <ButtonWrapper error={t6ServiceError}>
              <Button variant="outlined" size="large" startIcon={<AddIcon />} onClick={onAddTow}>
                ADD TOW mileage rate
              </Button>
            </ButtonWrapper>
            {t6ServiceError && (
              <FormHelperText error={true}>Adding Tow Mileage Rate is required</FormHelperText>
            )}
          </Stack>
        </Stack>
      )}
      {!!fields?.length && (
        <ControlledFormTable<TowData>
          hasSelectors={true}
          indexName="uuid"
          hookForm={hookForm}
          fields={fields}
          isEditMode={!!isEditMode}
          onSelect={setSelectedTowsRate}
          resetTrigger={resetTrigger}
          columns={[
            { id: 'startMileage', label: 'Start', fieldType: DynamicFieldType.TEXT },
            { id: 'endMileage', label: 'End', fieldType: DynamicFieldType.TEXT },
            {
              id: 'pricePerUnit',
              label: 'Per Mile',
              fieldType: DynamicFieldType.TEXT,
            },
            {
              id: 'type',
              label: 'Type',
              fieldType: DynamicFieldType.SELECT,
              options: [
                { label: 'Premium', value: TowTypes.PREMIUM },
                { label: 'Standard', value: TowTypes.STANDARD },
              ],
            },
            {
              id: 'priceCap',
              label: 'Price Cap',
              fieldType: DynamicFieldType.TEXT,
            },
          ]}
          fieldArrayName="tows"
        />
      )}
      {!fields?.length && (
        <Box display="flex" justifyContent="center" alignItems="center" m={6}>
          <Typography>No Configured Tow Mileage Rates</Typography>
        </Box>
      )}
      <Box sx={{ px: 4 }}>
        <DynamicExpandableFormActions
          isEditMode={isEditMode}
          save={{
            onSubmit: hookForm.handleSubmit(onSubmitAll),
            disabled: !isGeneralInfoDirty && !isTowMileageDirty,
            label: 'SAVE tow mileage rates',
          }}
          discard={{
            onSubmit: handleDiscard,
          }}
        />
      </Box>
      <NotificationSnackbar
        clearNotification={() => setNotifications(undefined)}
        notification={notifications}
      />
    </Box>
  );
};

export default PricingTowMileageRatesEditor;
