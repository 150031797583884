import { useDomainAuth } from 'auth';
import { LoginSelector } from 'components/Login';

export default function Login() {
  const { requestNonFederatedLogin, requestFederatedLogin, isLoading, isDone, errorMessage } =
    useDomainAuth();

  return (
    <LoginSelector
      onNonFederatedLogin={requestNonFederatedLogin}
      onFederatedLogin={requestFederatedLogin}
      isLoading={isLoading}
      isDone={isDone}
      errorMessage={errorMessage}
    />
  );
}
