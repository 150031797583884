import { Box, Typography } from '@mui/material';

import DialogConfirmation from 'components/DialogConfirmation';

import { useUpdateCallStatus, useUpdateCaseStatus } from 'features/Cases/queries';
import { RbpCaseStatus } from 'core/enums/RbpCaseStatus';
import { CaseData } from 'features/Cases/interfaces/CaseData';

interface ForceClsActionDialogProps {
  caseData: CaseData;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onConfirm: () => void;
}

export default function ForceClsActionDialog({
  visible,
  setVisible,
  caseData,
  onConfirm,
}: ForceClsActionDialogProps) {
  const {
    isError,
    mutateAsync: updateCallStatus,
    isLoading,
    errorMessage,
    reset,
  } = useUpdateCallStatus(caseData.uuid);

  const handleSubmit = async () => {
    await updateCallStatus('CLS');
    setVisible(false);
    onConfirm();
  };

  const onClose = () => {
    setVisible(false);
    reset();
  };

  return (
    <DialogConfirmation
      open={visible}
      maxWidth="xl"
      title={`Force CLS to Case ID ${caseData.rapCaseId}`}
      onClose={onClose}
      onConfirm={handleSubmit}
      labelConfirm="Force CLS"
      labelCancel="Close"
      content={`Are you sure you want to force CLS to Case ID ${caseData.rapCaseId}?`}
      isLoading={isLoading}
      sx={{
        '& .MuiDialog-paper': {
          width: 'fit-content',
          maxWidth: 980,
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {isError && (
          <Typography color="error" mt={4}>
            An error has occurred while forcing CLS to this case:
            <br />
            {errorMessage}
          </Typography>
        )}
      </Box>
    </DialogConfirmation>
  );
}
