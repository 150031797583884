import { styled, TableCell, Typography } from '@mui/material';
import Paper, { PaperProps } from '@mui/material/Paper';
import AppColors from 'AppColors';

export const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (propName) => propName !== 'focusHighlight',
})<{ focusHighlight?: boolean }>(
  ({ theme, focusHighlight }) => `
    &:first-of-type {
      padding: ${theme.spacing(1)} ${theme.spacing(4)};
    }
    ${
      focusHighlight &&
      ` &:focus-within {
      border-bottom:none;
      outline: 1px solid ${AppColors.AAA_BLUE};
    }`
    }
    color: inherit;
    font-weight: inherit;
  `,
);

export const StyledPaper = (props: PaperProps) => <Paper {...props} square elevation={0} />;

export const StyledTextLink = styled(Typography)(() => ({
  '&:hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));
