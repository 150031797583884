import { ReactNode, useMemo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { DefaultValues, useForm, UseFormReturn, UseFormSetValue } from 'react-hook-form';
import { DynamicForm, DynamicValue } from 'components/Dynamic';
import { DynamicQueryData, DynamicQueryFieldType } from './types';
import { getQueryString } from './helpers';

interface Props<T extends DynamicValue> {
  fields: DynamicQueryFieldType<T>[];
  colspan?: number;
  defaultValues?: DefaultValues<T>;

  /**
   * Callback will be called after form submit.
   * Will return a string in a QueryString format.
   */
  onSubmit?: (queryString: string) => void;

  /**
   * Call be called after reset actions.
   */
  onReset?: (useFormReturn?: UseFormReturn<T>) => void;

  /**
   * Callback will be called after form submit.
   * Will return the object.
   */
  onRawSubmit?: (value: T) => void;

  /**
   * If defined, replace the current Search label.
   */
  title?: ReactNode;

  /**
   * If defined, replace the current Search label and title.
   */
  header?: (useFormReturn: UseFormReturn<T>, submit: () => void) => ReactNode;

  /**
   * Provide the useForm hook. If define, replace the internal useForm hook.
   */
  useFormReturn?: UseFormReturn<T>;

  /**
   * If defined, show button on the label row
   */
  onHeaderButtonClick?: () => void;
  headerButtonLabel?: string;
  canCreate?: boolean;
  handleCustomQuery?: (
    value: string,
    setValue?: UseFormSetValue<T>,
    data?: DynamicQueryData<T>,
  ) => string;
  name?: string;
  leftContent?: ReactNode;
}

export function DynamicSearchBox<T extends DynamicValue>({
  fields,
  colspan,
  defaultValues,
  onSubmit,
  onReset,
  onRawSubmit,
  title,
  header,
  useFormReturn: useFormReturnProps,
  onHeaderButtonClick,
  headerButtonLabel,
  canCreate,
  handleCustomQuery,
  name,
  leftContent,
  ...rest
}: Props<T>) {
  let hookForm = useForm({ defaultValues });
  if (useFormReturnProps) {
    hookForm = useFormReturnProps;
  }

  const handleSubmit = hookForm.handleSubmit((data) => {
    let customQuery = getQueryString(data, fields);
    if (handleCustomQuery) {
      customQuery = handleCustomQuery(getQueryString(data, fields), hookForm.setValue, data);
    }

    onSubmit?.(customQuery);
    onRawSubmit?.(data);
  });

  const handleReset = () => {
    hookForm.reset(defaultValues);
    onReset?.(hookForm);
  };

  const showHeaderButton = useMemo(() => {
    return !!onHeaderButtonClick && !!headerButtonLabel && canCreate;
  }, [onHeaderButtonClick, headerButtonLabel, canCreate]);

  return (
    <Box component={Paper} sx={{ p: 2 }} {...rest} width="100%">
      <Stack direction="row" justifyContent="space-between" flexGrow={1}>
        <Box sx={{ pb: 2 }} flexGrow={1}>
          {header
            ? header(hookForm, handleSubmit)
            : title ?? <Typography variant="h6">{title || 'Search'}</Typography>}
        </Box>
        {showHeaderButton && (
          <Box>
            <Button variant="outlined" size="small" onClick={onHeaderButtonClick}>
              {headerButtonLabel}
            </Button>
          </Box>
        )}
      </Stack>

      <form onSubmit={handleSubmit}>
        <DynamicForm fields={fields} hookForm={hookForm} isEditMode={true} colspan={colspan} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
          <Box>
            <>{leftContent}</>
          </Box>
          <Box sx={{ marginRight: '6px' }}>
            <Button
              variant="outlined"
              sx={{ marginRight: '20px' }}
              onClick={handleReset}
              data-testid={`${name}-Reset-Btn`}
            >
              Reset
            </Button>
            <Button variant="contained" type="submit" data-testid={`${name}-Search-Btn`}>
              Search
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
}
