import BillingInformationSection from 'features/Setup/BillingInformation';
import { useVendorContext } from '../VendorProvider';
import { CreatePaymentRequestInformationDto } from 'api/setup/manageVendorApi';
import { VendorPaymentInfoForm } from './VendorPaymentInfoForm';
import {
  useGetPaymentRequestInfo,
  useCreatePaymentRequestInfo,
  useUpdatePaymentRequestInfo,
  useGetDefaultPaymentRequestInfo,
  PaymentRequestInformationData,
} from '../VendorQuery';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';

interface Props {
  entityUUID: string;
}

export default function VendorPaymentInfo({ entityUUID }: Props) {
  const { isEditingSection, setIsEditingSection } = useVendorContext();
  const { permissions } = useRapCoreFeaturePermissions('vendors');

  return (
    <BillingInformationSection<PaymentRequestInformationData, CreatePaymentRequestInformationDto>
      entityUUID={entityUUID}
      title="Payment Request Information"
      formContent={(hookForm, isEditMode) => (
        <VendorPaymentInfoForm hookForm={hookForm} isEditMode={isEditMode} />
      )}
      isEditingSection={isEditingSection}
      setIsEditingSection={setIsEditingSection}
      useGetBillingConfig={useGetPaymentRequestInfo}
      useCreateBillingConfig={useCreatePaymentRequestInfo}
      useUpdateBillingConfig={useUpdatePaymentRequestInfo}
      useGetDefaultBillingInfoData={useGetDefaultPaymentRequestInfo}
      readOnly={!permissions.update}
    />
  );
}
