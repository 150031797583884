import { CaseSearchType } from '../CaseSearchTypeSelector';
import { CaseSearchFields } from '../CasePageConfig';
import { parseQueryString } from 'lib/querybuilder/parser';
import { SavedSearch } from 'api/savedSearches/interfaces/SavedSearch';

export function parseFilter(filter: string): object | null {
  try {
    const obj = JSON.parse(filter);
    return trimObject(obj);
  } catch {
    return null;
  }
}

export function trimObject(obj: Record<string, any>): object {
  const trimmedObj = Object.entries(obj).filter(([, entry]) => !!entry);
  return Object.fromEntries(trimmedObj);
}

export function parseSelectedSavedSeach(savedSearch: SavedSearch, caseSearchType: CaseSearchType) {
  try {
    if (savedSearch.systemFilter) {
      const caseFields = CaseSearchFields(caseSearchType);
      const systemFilterQuery = parseQueryString(savedSearch.filter, caseFields);
      return systemFilterQuery;
    } else {
      const parsedValues = JSON.parse(savedSearch.filter);
      return parsedValues;
    }
  } catch (error) {
    console.error('Error trying to load Search Query', error);
  }
}
