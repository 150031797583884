import { StepComponent } from 'components/FormStepper/types';
import { useEntityCreationStep } from 'components/EntityCreationFlow';
import { ClientForm } from '../ClientDetail/ClientForm';
import { GeneralInfoValidationSchema } from '../ClientDetail/fields';
import { ClientData, useClientContext } from '../ClientProvider';

const ClientGeneralInfoStep = ({
  submitted,
  completed,
  stepKey,
  resetSubmitted,
  setCompleted,
}: StepComponent) => {
  const defaultValues: Partial<ClientData> = {
    contractEndDateTime: '',
    contractStartDateTime: '',
    survey: false,
  };
  const { queries } = useClientContext();
  const GeneralInfoStep = useEntityCreationStep<ClientData>({
    initialData: defaultValues,
    validationSchema: GeneralInfoValidationSchema,
    form: ClientForm,
    query: queries.useCreate,
  });
  return (
    <GeneralInfoStep
      stepKey={stepKey}
      submitted={submitted}
      completed={completed}
      resetSubmitted={resetSubmitted}
      setCompleted={setCompleted}
    />
  );
};

export default ClientGeneralInfoStep;
