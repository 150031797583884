import { StatusElement } from 'components/Labels';
import { convertListToSchema } from 'lib/Schema';

export enum BillingRequestStatus {
  ASSEMBLING = 'ASSEMBLING',
  REVIEW_SUBMISSION = 'REVIEW_SUBMISSION',
  LATE_REVIEW_SUBMISSION = 'LATE_REVIEW_SUBMISSION',
  SUBMITTED = 'SUBMITTED',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  SENT = 'SENT',
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED',
}

export const BillingRequestStatusWithoutAdjustment: BillingRequestStatus[] = [
  BillingRequestStatus.SUBMITTED,
  BillingRequestStatus.PAID,
  BillingRequestStatus.OVERDUE,
];

const BillingRequestStatusList = [
  { color: '#7A7A7A', label: 'Assembling', key: BillingRequestStatus.ASSEMBLING },
  { color: '#F4BA5D', label: 'Review & Submit', key: BillingRequestStatus.REVIEW_SUBMISSION },
  { color: '#D5001A', label: 'Review & Submit', key: BillingRequestStatus.LATE_REVIEW_SUBMISSION },
  { color: '#509E2F', label: 'Sent', key: BillingRequestStatus.SENT },
  { color: '#509E2F', label: 'Submitted', key: BillingRequestStatus.SUBMITTED },
  { color: '#049587', label: 'Paid', key: BillingRequestStatus.PAID },
  { color: '#EB7E30', label: 'Overdue', key: BillingRequestStatus.OVERDUE },
  { color: '#CC0000', label: 'Cancelled', key: BillingRequestStatus.CANCELLED },
  { color: '#E0A649', label: 'Closed', key: BillingRequestStatus.CLOSED },
];

export enum BillRequestReportStatus {
  PENDING = 'PENDING',
  ASSEMBLING = 'ASSEMBLING',
  APPROVED = 'APPROVED',
}

const BillRequestReportStatusList = [
  { color: '#F4BA5D', label: 'Pending', key: BillRequestReportStatus.PENDING },
  { color: '#7A7A7A', label: 'Assembling', key: BillRequestReportStatus.ASSEMBLING },
  { color: '#509E2F', label: 'Approved', key: BillRequestReportStatus.APPROVED },
];

export const BillRequestReportStatusSchema = convertListToSchema<
  StatusElement,
  typeof BillRequestReportStatus
>(BillRequestReportStatusList);

export const BillingRequestStatusSchema = convertListToSchema<
  StatusElement,
  typeof BillingRequestStatus
>(BillingRequestStatusList);
