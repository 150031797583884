import Card, { CardProps } from '@mui/material/Card';
import Stack from '@mui/material/Stack';

export type FullCenteredCardProps = CardProps;

export function FullCenteredCard({ children, ...props }: FullCenteredCardProps) {
  return (
    <Card {...props} sx={{ ...props.sx, display: 'flex' }}>
      <Stack flexGrow={1} alignItems="center" justifyContent="center">
        {children}
      </Stack>
    </Card>
  );
}
