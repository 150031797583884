import { Popover, List, Box, ListItemButton, ListItemText } from '@mui/material';
import { SavedSearch } from 'api/savedSearches/interfaces/SavedSearch';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { DeleteSavedSearchConfirm } from './DeleteSavedSearchConfirm';
import { SavedSearchListItem } from './styles';

export interface SavedSearchesPopoverProps {
  anchorButton: HTMLButtonElement | null;
  onSavedSearchEdit: (savedSearchId: string) => void;
  onSavedSearchSelect: (savedSearch: SavedSearch) => void;
  onSavedSearchDelete: (savedSearchId: string) => void;
  onClose: () => void;
  savedSearches: SavedSearch[];
}

const SavedSearchesPopover: React.FC<SavedSearchesPopoverProps> = ({
  anchorButton,
  onSavedSearchEdit,
  onSavedSearchSelect,
  onSavedSearchDelete,
  onClose,
  savedSearches,
}: SavedSearchesPopoverProps) => {
  const [showDeleteSavedSearchPopup, setShowDeleteSavedSearchPopup] = useState<boolean>(false);
  const [savedSearchToDelete, setSavedSearchToDelete] = useState<SavedSearch>();

  const popoverOpen = Boolean(anchorButton);
  const popoverId = popoverOpen ? 'simple-popover' : undefined;

  const onDeleteSavedSearchClicked = (savedSearch: SavedSearch) => {
    setSavedSearchToDelete(savedSearch);
    setShowDeleteSavedSearchPopup(true);
  };

  const onDeleteSavedSearchConfirmed = () => {
    if (savedSearchToDelete) {
      onSavedSearchDelete(savedSearchToDelete.customFilterId);
    }
    setShowDeleteSavedSearchPopup(false);
  };

  const onDeleteSaveSearchPopupClose = () => {
    setShowDeleteSavedSearchPopup(false);
  };

  const onEditSavedSearchClicked = (savedSearchId: string) => {
    onSavedSearchEdit(savedSearchId);
  };

  const onSavedSearchedClicked = (savedSearch: SavedSearch) => {
    onSavedSearchSelect(savedSearch);
    onClose();
  };

  return (
    <>
      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={anchorButton}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List>
          {savedSearches?.map((savedSearch, index) => (
            <SavedSearchListItem
              key={`${savedSearch.customFilterId}-${index}`}
              disablePadding
              secondaryAction={
                !savedSearch.systemFilter && (
                  <Box sx={{ display: 'flex' }}>
                    <EditIcon
                      onClick={() => onEditSavedSearchClicked(savedSearch.customFilterId)}
                      sx={{ cursor: 'pointer', mr: 1 }}
                    />
                    <CloseIcon
                      onClick={() => onDeleteSavedSearchClicked(savedSearch)}
                      sx={{ cursor: 'pointer' }}
                    />
                  </Box>
                )
              }
            >
              <ListItemButton onClick={() => onSavedSearchedClicked(savedSearch)}>
                <ListItemText primary={savedSearch.name} />
              </ListItemButton>
            </SavedSearchListItem>
          ))}
        </List>
      </Popover>
      <DeleteSavedSearchConfirm
        open={showDeleteSavedSearchPopup}
        title={`Delete ${savedSearchToDelete?.name}`}
        message={`Are you sure you want to delete ${savedSearchToDelete?.name}?`}
        onConfirm={onDeleteSavedSearchConfirmed}
        onClose={onDeleteSaveSearchPopupClose}
      />
    </>
  );
};

export default SavedSearchesPopover;
