import { DynamicField, DynamicValue } from 'components/Dynamic';
import { useCallback, useMemo } from 'react';
import { usePermissionRoles } from './PermissionProvider';

interface Props<T extends DynamicValue> {
  table: string;
  fields: DynamicField<T>[];
  isEditMode?: boolean;
  isCreationMode?: boolean;
}

export function useFieldPermission<T extends DynamicValue>({
  table,
  fields,
  isCreationMode,
  isEditMode,
}: Props<T>): DynamicField<T>[] {
  const { permissions } = usePermissionRoles();

  const getFieldAttributes = useCallback(
    (fieldName: string) => {
      const permissionField = permissions[table]?.fields[fieldName];

      if (!permissionField) {
        return;
      }

      const canCreate = permissionField.create && isCreationMode;
      const canUpdate = permissionField.update && isEditMode;
      const isReadOnly = permissionField.read && !canCreate && !canUpdate;
      const isVisible = canCreate || canUpdate || isReadOnly;

      return { canCreate, canUpdate, isReadOnly, isVisible };
    },
    [isCreationMode, isEditMode, permissions, table],
  );

  return useMemo(
    () =>
      fields.reduce<DynamicField<T>[]>((list, field) => {
        const attr = getFieldAttributes(field.name.toString());
        if (attr && attr.isVisible) {
          list.push({ ...field, isReadOnly: attr.isReadOnly });
        }
        return list;
      }, []),
    [fields, getFieldAttributes],
  );
}
