import { Navigate } from 'react-router-dom';
import AppLayout from 'layouts/AppLayout/AppLayout';
import { useDomainAuth } from 'auth';

interface Props {
  notAllowedRoles?: string[];
  element: React.ReactNode;
}

export const RoleBasedComponent = ({ notAllowedRoles = [], element }: Props) => {
  const { userGroup } = useDomainAuth();

  if (!notAllowedRoles.includes(userGroup || '')) return <AppLayout>{element}</AppLayout>;

  return <Navigate to="/no-permissions" replace />;
};
