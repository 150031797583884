import BillingRequestDetail from './BillingRequestDetail';
import BreadcrumbHeader, { Breadcrumb } from 'components/BreadCrumbHeader';
import { CRUDComponent } from 'components/CRUDNavigator';
import { CRUDPageableLayout } from 'components/CRUDPageable';
import { LoaderCard } from 'components/Cards';
import { useBillingRequestContext } from './BillingRequestProvider';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Paper } from '@mui/material';
import { SchemaStatusChip } from 'components/Labels';
import { BillingRequestStatusSchema } from 'api/invoices';
import { AttachedCasesTable } from './components/AttachedCasesTable';
import { BillingRequestStatusActions } from './components/BillingRequestStatusActions';

const BillingRequestView: CRUDComponent = () => {
  const { uuid = '' } = useParams();

  const {
    getById: { isRunning, isDone, data },
    requestById,
  } = useBillingRequestContext();

  useEffect(() => {
    requestById(uuid);
  }, [requestById, uuid]);

  const breadcrumbs: Breadcrumb[] = [
    {
      url: '..',
      label: 'Bill Requests',
    },
    {
      url: '',
      label: (
        <Box display="flex" alignItems="center">
          <span>
            {data?.name} # {data?.number}
          </span>
          <SchemaStatusChip
            status={data?.status ?? ''}
            dataTestId="BillRequestStatus"
            schemelist={BillingRequestStatusSchema}
            sx={{
              fontSize: '0.875rem',
              height: '1.5rem',
              marginLeft: '0.8rem',
            }}
          />
        </Box>
      ),
    },
  ];

  return (
    <CRUDPageableLayout>
      {isRunning && <LoaderCard label="Loading Billing Request..." sx={{ p: 4 }} />}
      {isDone && (
        <>
          <Box component={Paper}>
            <BreadcrumbHeader
              breadcrumbs={breadcrumbs}
              headerPanel={<BillingRequestStatusActions />}
            />
            <BillingRequestDetail />
          </Box>
        </>
      )}
      {data && <AttachedCasesTable uuid={uuid} billingRequestData={data} />}
    </CRUDPageableLayout>
  );
};

export default BillingRequestView;
