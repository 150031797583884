import { useCallback, useMemo, useState } from 'react';
import sumBy from 'lodash.sumby';
import moment from 'moment';
import { Box, CircularProgress, IconButton, Paper, Stack } from '@mui/material';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import DownloadIcon from '@mui/icons-material/Download';
import { useLocation, useParams } from 'react-router-dom';
import BreadcrumbHeader, { Breadcrumb } from 'components/BreadCrumbHeader';
import { CRUDComponent } from 'components/CRUDNavigator';
import { CRUDPageableLayout } from 'components/CRUDPageable';
import { LoaderCard } from 'components/Cards';

import { convertToMMYYYY } from 'utils/convertDate';

import DataTable, { DataTableColumn } from 'components/DataTable';
import { convertToCurrency } from 'utils/formatter';
import { NotificationSnackbar, NotificationType } from 'components/Notifications';
import {
  usePaymentForecastsDownloadOneReports,
  usePaymentForecastsSendOneReport,
} from 'features/PaymentForecasts/PaymentForecastsQuery';
import {
  useDownloadManyUUIDReport,
  usePaymentRequestFetchByMonthYearQuery,
  useSendManyUUIDReportViaEmail,
} from './PaymentRequestQuery';
import { CallbackType, PaymentRequestDetailsData } from './types';
import { downloadReportFile } from 'features/Reports/utils';
import { ReportDto, ReportStatus } from 'features/Reports/types';

const PaymentRequestDetail: CRUDComponent = ({ navigators }) => {
  const location = useLocation();
  const params = useParams();
  const [notifications, setNotifications] = useState<NotificationType>();
  const { mutateAsync: paymentForecastsSendReports, isLoading: isLoadingSendOneReport } =
    usePaymentForecastsSendOneReport();
  const { mutateAsync: paymentForecastsDownloadReports, isLoading: isLoadingDownloadReport } =
    usePaymentForecastsDownloadOneReports();
  const { mutateAsync: sendManyUUIDReportViaEmail, isLoading: isLoadingSendManyReport } =
    useSendManyUUIDReportViaEmail();
  const { mutateAsync: downloadManyUUIDReport, isLoading: isLoadingDownloadManyReport } =
    useDownloadManyUUIDReport();

  const [currentClubName, setCurrentClubName] = useState<string>();
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('rapVendorId');
  const search = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const breadcrumbs: Breadcrumb[] = [
    {
      url: '..',
      label: 'Payment Request',
    },
    {
      url: '',
      label: `Payment Request ID ${params.uuid} - ${convertToMMYYYY(
        search.get('startPeriod') ?? '',
      )}`,
    },
  ];

  const { isLoading, data, refetch } = usePaymentRequestFetchByMonthYearQuery({
    startDate: search.get('startPeriod') ?? '',
    endDate: search.get('endPeriod') ?? '',
  });

  const getPaymentRequestTableColumns = useCallback(
    (
      callback: (value: ReportDto, type: CallbackType, vendorId?: number) => Promise<void>,
    ): DataTableColumn<PaymentRequestDetailsData>[] => {
      return [
        {
          id: 'clubName',
          label: 'Club/Vendor Name',
          sortable: true,
          sortKey: 'rapVendorId',
          accessor: (row) => `${row.rapVendorId} - ${row.clubName}`,
          Footer: () => 'Total',
        },
        {
          id: 'totalCases',
          sortable: true,
          label: 'Total Call Count',
          Footer: (rows) => sumBy(rows, (row) => row.totalCases),
        },
        {
          id: 'revenueShare',
          label: 'Revenue Share Amount',
          sortable: true,
          accessor: (row) => convertToCurrency(row.revenueShare),
          Footer: (rows) => convertToCurrency(sumBy(rows, (row) => row.revenueShare)),
        },
        {
          id: 'casesClosedAmount',
          label: 'Payable Amount',
          sortable: true,
          accessor: (row) => convertToCurrency(row.casesClosedAmount),
          Footer: (rows) => convertToCurrency(sumBy(rows, (row) => row.casesClosedAmount)),
        },
        {
          id: 'status',
          label: 'Status',
          accessor: (row: PaymentRequestDetailsData) => (
            <Stack>
              {row?.reports?.map((report, index) => (
                <Stack
                  key={`${report.reportStatus}_${index}`}
                  sx={{
                    minHeight: '24px',
                  }}
                >
                  {report.reportStatus ?? '-'}
                </Stack>
              ))}
            </Stack>
          ),
          Footer: () => '',
        },
        {
          id: 'files',
          label: 'Files',
          accessor: (row: PaymentRequestDetailsData) => (
            <Stack>
              {row?.reports?.map((report) => (
                <Stack
                  key={report.name}
                  sx={{
                    minHeight: '24px',
                  }}
                >
                  {report.name ?? '-'}
                </Stack>
              ))}
            </Stack>
          ),
          Footer: () => '',
          minWidth: '280px',
        },
        {
          id: 'recipient',
          label: 'Recipients',
          accessor: (row: PaymentRequestDetailsData) => (
            <Stack>
              {row?.reports?.map((report) => (
                <Stack
                  key={report.recipient}
                  sx={{
                    minHeight: '24px',
                  }}
                >
                  {report.recipient ?? '-'}
                </Stack>
              ))}
            </Stack>
          ),
          Footer: () => '',
        },
        {
          id: 'sentAtDateTime',
          label: 'Sent Date',
          accessor: (row: PaymentRequestDetailsData) => (
            <Stack>
              {row?.reports?.map((report, index) => (
                <Stack
                  key={`${report.sentAtDateTime}_${index}`}
                  sx={{
                    minHeight: '24px',
                  }}
                >
                  {report.sentAtDateTime
                    ? moment(new Date(report.sentAtDateTime)).format('MM/DD/YYYY')
                    : '-'}
                </Stack>
              ))}
            </Stack>
          ),
          Footer: () => '',
        },
        {
          id: 'reports',
          label: 'Actions',
          accessor: (row: PaymentRequestDetailsData) =>
            (isLoadingSendOneReport ||
              isLoadingSendManyReport ||
              isLoadingDownloadReport ||
              isLoadingDownloadManyReport) &&
            currentClubName === row.clubName ? (
              <Stack spacing={1} p={1.5}>
                <CircularProgress size={24} />
              </Stack>
            ) : (
              <Stack>
                {row?.reports?.map((report) => (
                  <Stack
                    direction="row"
                    spacing={1}
                    key={report.uuid}
                    sx={{
                      minHeight: '24px',
                    }}
                  >
                    <IconButton
                      sx={{
                        padding: 0,
                        top: '-4px',
                        cursor: 'pointer',
                        color: 'primary.dark',
                        '&:hover': { color: 'primary.main' },
                      }}
                      onClick={(e) => {
                        setCurrentClubName(row.clubName);
                        e.stopPropagation();
                        callback(report, CallbackType.SEND);
                      }}
                      disabled={
                        ![ReportStatus.READY, ReportStatus.SENT].includes(
                          report.reportStatus as ReportStatus,
                        )
                      }
                    >
                      <ForwardToInboxIcon sx={{ fontSize: 18 }} />
                    </IconButton>

                    <IconButton
                      sx={{
                        padding: 0,
                        top: '-4px',
                        cursor: 'pointer',
                        color: 'primary.dark',
                        '&:hover': { color: 'primary.main' },
                      }}
                      onClick={(e) => {
                        setCurrentClubName(row.clubName);
                        e.stopPropagation();
                        callback(report, CallbackType.DOWNLOAD, row.rapVendorId);
                      }}
                      disabled={
                        ![ReportStatus.READY, ReportStatus.SENT].includes(
                          report.reportStatus as ReportStatus,
                        )
                      }
                    >
                      <DownloadIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                  </Stack>
                ))}
              </Stack>
            ),
          Footer: () => '',
        },
      ];
    },
    [
      isLoadingSendOneReport,
      isLoadingSendManyReport,
      isLoadingDownloadReport,
      isLoadingDownloadManyReport,
      currentClubName,
    ],
  );

  const handleReportAction = useCallback(
    async (report: ReportDto, type: CallbackType, vendorId?: number) => {
      if (report.startPeriod && report.endPeriod && report.entityType) {
        if (type === CallbackType.DOWNLOAD) {
          try {
            let res: unknown;
            if (report.uuids) {
              res = await downloadManyUUIDReport(report.uuids);
            } else {
              res = await paymentForecastsDownloadReports(report.uuid);
            }

            let fileName = report.s3Path.split('/').pop() ?? '';
            if (report.uuids) {
              const [month, year] = convertToMMYYYY(search.get('startPeriod') ?? '').split('/');
              fileName = `PAYMENT_SUMMARY_${month}${year}_${vendorId}.zip`;
            }
            const downloadBody = {
              res,
              reportName: report.name,
              setNotifications,
              fileType: report.uuids ? 'application/zip' : 'application/pdf',
              fileName: fileName,
            };
            downloadReportFile(downloadBody);
          } catch {
            setNotifications({
              name: `Download ${report.name} Report`,
              type: 'error',
              message: `The ${report.name} report has not been downloaded, try again later`,
            });
          }
        }
        if (type === CallbackType.SEND) {
          try {
            if (report.uuids) {
              await sendManyUUIDReportViaEmail(report.uuids);
            } else {
              await paymentForecastsSendReports(report.uuid);
            }

            setNotifications({
              name: `Send ${report.name} Report`,
              type: 'success',
              message: `The ${report.name} report has been sent successfully`,
            });
            refetch();
          } catch {
            setNotifications({
              name: `Send ${report.name} Report`,
              type: 'error',
              message: `The ${report.name} report has not been sent, try again later`,
            });
          }
        }
      }
    },
    [refetch, paymentForecastsSendReports, paymentForecastsDownloadReports],
  );

  const sortOptions = useMemo(() => {
    return {
      order,
      hasSort: true,
      orderBy,
      createSortHandler: (event: React.MouseEvent<unknown, MouseEvent>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        const newOrderValue = isAsc ? 'desc' : 'asc';
        setOrder(newOrderValue);
        setOrderBy(property);
      },
    };
  }, [order, orderBy]);

  const handleRowClick = useCallback(
    (row: PaymentRequestDetailsData) => {
      navigators.toDetailScreen?.(params.uuid || '', 'club', row.clubUuid.toString(), {
        startPeriod: search.get('startPeriod') ?? '',
        endPeriod: search.get('endPeriod') ?? '',
        paymentRequestUuid: row.paymentRequestUuid,
      });
    },
    [navigators, params.uuid, search],
  );

  return (
    <CRUDPageableLayout>
      {isLoading && <LoaderCard label="Loading Payment Requests Detail..." sx={{ p: 4 }} />}
      <NotificationSnackbar
        clearNotification={() => setNotifications(undefined)}
        notification={notifications}
      />
      {!isLoading && (
        <Box component={Paper}>
          <BreadcrumbHeader breadcrumbs={breadcrumbs} />
          <DataTable
            columns={getPaymentRequestTableColumns(handleReportAction)}
            rows={data ?? []}
            onRowClick={handleRowClick}
            sortOptions={sortOptions}
            stickyHeader
            stickyOffset={152}
          />
        </Box>
      )}
    </CRUDPageableLayout>
  );
};

export default PaymentRequestDetail;
