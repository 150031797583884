import { Box, Typography } from '@mui/material';

interface InfoProps {
  title: string;
  value: string;
}

const ReceiptInfo = ({ title, value }: InfoProps) => {
  return (
    <>
      <Box component="div" sx={{ width: 635, height: 20, display: 'flex', flexDirection: 'row' }}>
        <Box component="div" sx={{ width: 95, display: 'flex' }}>
          <Typography
            variant="h6"
            sx={{ textAlign: 'right', width: 95, color: '#000', fontSize: 12, fontWeight: 'bold' }}
          >
            {title}
          </Typography>
        </Box>
        <Box component="div" sx={{ width: 400, display: 'flex', marginLeft: '10px' }}>
          <Typography variant="h6" sx={{ textAlign: 'right', color: '#000', fontSize: 12 }}>
            {value}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default ReceiptInfo;
