import { routes } from 'routes';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

export default function LoginForgotPassword() {
  return (
    <Link to={routes.resetPassword} style={{ textDecoration: 'none' }}>
      <Button variant="text" fullWidth>
        Forgot Password
      </Button>
    </Link>
  );
}
