import {
  styled,
  AccordionDetails,
  Box,
  IconButton,
  Accordion,
  IconButtonProps,
  AccordionProps,
} from '@mui/material';
import AppColors from 'AppColors';

export const StyledAccordionDetails = styled(AccordionDetails)({
  padding: '8px 32px 16px',
});

export const StyledHorizRow = styled('hr')(() => ({
  height: 1,
  border: 0,
  borderTop: `1px solid ${AppColors.AAA_BLUE}`,
}));

export const StyledRightPanel = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  border: 0,
  borderRight: `1px solid ${AppColors.AAA_BLUE}`,
  marginRight: 16,
  paddingRight: 16,
  marginLeft: 'auto',
}));

export const StyledSummaryIconBox = styled(Box)(() => ({
  display: 'inline-flex',
  paddingRight: 12,
  alignItems: 'center',
}));

const EditionPropName = 'isEditMode';
type EditionProps = { [EditionPropName]: boolean };

export const StyledEditButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== EditionPropName,
})<IconButtonProps & EditionProps>(({ isEditMode }) => ({
  border: '1px solid',
  borderRadius: '4px',
  padding: '6px 6px 6px 8px',
  color: `${isEditMode ? AppColors.AAA_GREEN : AppColors.AAA_LIGHT_BLUE}`,
  borderColor: 'rgba(0, 0, 0, 0.12)',
}));

export const StyledContainerAccordion = styled(Accordion, {
  shouldForwardProp: (prop) => prop !== EditionPropName,
})<AccordionProps & EditionProps>(({ isEditMode }) => {
  const editModeBorder = `4px solid ${AppColors.AAA_GREEN}`;
  return {
    border: `${isEditMode ? editModeBorder : 'default'}`,
    borderRadius: '4px',
  };
});
