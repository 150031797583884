import { fetchCaseClaims, updateCaseClaim } from 'api/cases/manageCasesApi';
import { DynamicField, DynamicFieldType, DynamicValue } from 'components/Dynamic';
import { useManagedMutation, useMappedReactQuery } from 'hooks/useReactQueryHelpers';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';

const QUERY_CASE_CLAIMS = 'QUERY_CASE_CLAIMS';

export default function useClaims(caseUUID: string, enabled = true) {
  return useMappedReactQuery(
    QUERY_CASE_CLAIMS,
    fetchCaseClaims,
    { enabled, mapper: (claimsDto) => claimsDto },
    caseUUID,
  );
}

export function useUpdateCaseClaim(caseUuid: string, claimUuid: string, clientId: number) {
  const queryClient = useQueryClient();

  return useManagedMutation({
    mutationFn: (body: any) => updateCaseClaim(caseUuid, claimUuid, clientId, body),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_CASE_CLAIMS]);
    },
  });
}

export function useClaimOverrideConfig() {
  const claimOverrideFields: DynamicField<DynamicValue>[] = [
    {
      name: 'claimStatus',
      label: 'Status',
      colspan: 12,
      type: DynamicFieldType.SELECT,
      select: {
        options: [
          { value: 'IGNORED_REJECTION', label: 'IGNORED_REJECTION' },
          { value: 'MANUALLY_APPROVED', label: 'MANUALLY_APPROVED' },
        ],
      },
      required: true,
    },
    {
      name: 'ignoreReason',
      label: 'Reason',
      colspan: 12,
      type: DynamicFieldType.TEXT,
      required: true,
      startNewRow: true,
    },
  ];

  const claimOverrideValidationSchema = Yup.object().shape({
    claimStatus: Yup.string().required().label('Status Reason'),
    ignoreReason: Yup.string().required().label('Compensation Amount'),
  });

  return {
    claimOverrideFields,
    claimOverrideValidationSchema,
  };
}
