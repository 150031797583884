import AppColors from 'AppColors';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow';
import { HeaderGroup } from './utils';
import { RowRenderFunction, DataTableColumn } from './interfaces';
import { StyledTableCell } from './DataTable.styled';
import { SxProps, Theme } from '@mui/material/styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function DataTableBodyRow<DataType extends Record<string, any>>({
  row,
  rowIndex,
  isSelected,
  onRowClick,
  hasSelectors,
  onSelect,
  headerGroup,
  onTextCellClick,
  hasAnyAction,
  Row,
  sx: sxProp,
  renderActions,
}: {
  row: DataType;
  rowIndex: number;
  isSelected: (row: DataType) => boolean;
  onRowClick?: (row: DataType) => void;
  hasSelectors?: boolean;
  onSelect: (row: DataType) => void;
  headerGroup: HeaderGroup;
  onTextCellClick?: (row: DataType, col: DataTableColumn<any>) => void;
  hasAnyAction: boolean;
  Row?: (row: DataType, rowIndex: number) => RowRenderFunction;
  sx?: (row: DataType, rowIndex: number) => SxProps<Theme>;
  renderActions?: (row: DataType, rowIndex: number) => React.ReactNode;
}) {
  {
    const isItemSelected = isSelected(row);
    const RowRender = Row ? Row(row, rowIndex) : TableRow;
    const sx = sxProp ? sxProp(row, rowIndex) : null;

    return (
      <RowRender
        hover={!sx}
        role="checkbox"
        selected={isItemSelected}
        onClick={() => onRowClick?.(row)}
        sx={{
          ...sx,
          ...(onRowClick && {
            '&:hover': {
              cursor: 'pointer',
              '& *': {
                cursor: 'pointer',
              },
            },
          }),
        }}
        data-testid="DataTableBodyRow"
      >
        {hasSelectors && (
          <TableCell
            padding="checkbox"
            sx={{
              borderBottom: `1px solid ${AppColors.AAA_LIGHT_NAVY}`,
            }}
            onClick={(e) => {
              e.stopPropagation();
              onSelect(row);
            }}
          >
            <Checkbox color="primary" checked={isItemSelected} />
          </TableCell>
        )}
        {headerGroup.headers.map((col, columnIndex) => (
          <StyledTableCell
            focusHighlight
            key={`${rowIndex}-${columnIndex}`}
            sx={{
              borderBottom: `1px solid ${AppColors.AAA_LIGHT_NAVY}`,
              minWidth: col.minWidth,
              maxWidth: col.maxWidth,
              width: col.width,
            }}
            data-testid={col.testId ? `${col.testId}_${rowIndex}` : undefined}
          >
            <Box
              onClick={() => onTextCellClick?.(row, col)}
              sx={{ width: 'fit-content', color: 'inherit', cursor: 'default', ...col.sx }}
            >
              {col.accessor ? col.accessor(row, rowIndex) : row[col.id.toString()]}
            </Box>
          </StyledTableCell>
        ))}
        {hasAnyAction && (
          <TableCell sx={{ borderBottom: `1px solid ${AppColors.AAA_LIGHT_NAVY}` }}>
            {renderActions?.(row, rowIndex)}
          </TableCell>
        )}
      </RowRender>
    );
  }
}
