import { CreatePageableCRUDApi } from 'api/helper/PageableCRUDApi';
import { ENV_BACKOFFICE_BASE_API_URL } from 'lib/envConfig';
import { VendorDto } from './interfaces/VendorDto';
import { BaseAPI } from 'api/auth';

export interface PaymentRequestInformationDto {
  uuid: string;
  createdAtDateTime: string;
  updatedAtDateTime: string;
  createdByUserId: string;
  updatedByUserId: string;
  vendorUuid: string;
  paymentReportRecipientEmails: string[];
  callsAccountNumber: string;
  revenueShareAccountNumber: string;
  jdeAccountRoutingNumber: number;
}

export interface CreatePaymentRequestInformationDto {
  createdByUserId?: string;
  updatedByUserId?: string;
  paymentReportRecipientEmails: string[];
  callsAccountNumber: string;
  revenueShareAccountNumber: string;
  jdeAccountRoutingNumber: number | null;
}

export const manageVendorApi = CreatePageableCRUDApi<VendorDto>(
  `${ENV_BACKOFFICE_BASE_API_URL}/v1/vendors`,
);

export function fetchPaymentRequestInfo(vendorUuid: string) {
  return BaseAPI.get<PaymentRequestInformationDto[]>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/vendors/${vendorUuid}/payment-request-informations`,
  );
}

export function addPaymentRequestInfo(
  vendorUuid: string,
  paymentRequestInfo: CreatePaymentRequestInformationDto,
) {
  return BaseAPI.post<CreatePaymentRequestInformationDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/vendors/${vendorUuid}/payment-request-informations`,
    paymentRequestInfo,
  );
}

export function updatePaymentRequestInfo(
  vendorUuid: string,
  uuid: string,
  paymentRequestInfo: Partial<CreatePaymentRequestInformationDto>,
) {
  return BaseAPI.put<Partial<CreatePaymentRequestInformationDto>>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/vendors/${vendorUuid}/payment-request-informations/${uuid}`,
    paymentRequestInfo,
  );
}
