const AgingTransactionColors = {
  /* 5 or less days ago */
  Recent: { bgcolor: 'rgba(80, 158, 47, 0.30)', color: '#509E2F' },
  /* 6-9 days ago */
  Warning: { bgcolor: 'rgba(244, 186, 93, 0.30)', color: '#F4BA5D' },
  /* 10 or more days ago */
  Old: { bgcolor: 'rgba(204, 0, 0, 0.30)', color: '#CC0000' },
};

export const getStylesForCallDate = (callDate: string) => {
  const days = Math.floor((Date.now() - new Date(callDate).getTime()) / (1000 * 3600 * 24));
  if (days <= 5) {
    return AgingTransactionColors.Recent;
  } else if (days <= 9) {
    return AgingTransactionColors.Warning;
  } else {
    return AgingTransactionColors.Old;
  }
};
