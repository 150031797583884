import { Box, Typography } from '@mui/material';

import DialogConfirmation from 'components/DialogConfirmation';

import { useUpdateCaseStatus } from 'features/Cases/queries';
import { RbpCaseStatus } from 'core/enums/RbpCaseStatus';
import { CaseData } from 'features/Cases/interfaces/CaseData';

interface CaseUncancelActionDialogProps {
  caseData: CaseData;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onConfirmCancelCase: () => void;
}

export default function CaseUncancelActionDialog({
  visible,
  setVisible,
  caseData,
  onConfirmCancelCase,
}: CaseUncancelActionDialogProps) {
  const {
    isError,
    mutateAsync: updateCaseStatus,
    isLoading,
    errorMessage,
    reset,
  } = useUpdateCaseStatus(caseData.uuid);

  const handleSubmit = async () => {
    updateCaseStatus(RbpCaseStatus.RECEIVED)
      .then(() => {
        setVisible(false);
        onConfirmCancelCase();
      })
      .catch();
  };

  const onClose = () => {
    setVisible(false);
    reset();
  };

  return (
    <DialogConfirmation
      open={visible}
      maxWidth="xl"
      title={`Activate Case ID ${caseData.rapCaseId}`}
      onClose={onClose}
      onConfirm={handleSubmit}
      labelConfirm="Uncancel Case"
      labelCancel="Close"
      content={`Are you sure you want to activate Case ID ${caseData.rapCaseId}?`}
      isLoading={isLoading}
      sx={{
        '& .MuiDialog-paper': {
          width: 'fit-content',
          maxWidth: 980,
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {isError && (
          <Typography color="error" mt={4}>
            An Error Has Occurred While Uncanceling Case:
            <br />
            {errorMessage}
          </Typography>
        )}
      </Box>
    </DialogConfirmation>
  );
}
