import { useCallback, useEffect, useState } from 'react';
import { DataObserver } from './DataObserver';
import { DataGridColumn } from './types';

export interface DataGridReturn<DataType> {
  observer: DataObserver<DataType>;
  columns: DataGridColumn<DataType>[];
  add: (data: DataType) => void;
  remove: () => void;
  rebase: () => void;
  reload: () => void;
  getData: () => DataType[];
  replace: (data: DataType[]) => void;
}

export function useDataGrid<DataType>(
  data: DataType[],
  columns: DataGridColumn<DataType>[],
): DataGridReturn<DataType> {
  const [state, setState] = useState({
    observer: new DataObserver(data),
    originalData: data,
  });

  useEffect(() => {
    if (state.originalData !== data) {
      setState({
        observer: new DataObserver(data),
        originalData: data,
      });
    }
  }, [data, state.originalData]);

  const reload = useCallback(() => {
    setState((current) => {
      return {
        observer: new DataObserver(current.observer),
        originalData: current.originalData,
      };
    });
  }, []);

  const rebase = useCallback(() => {
    setState((current) => {
      return {
        observer: new DataObserver(current.observer),
        originalData: current.originalData,
      };
    });
  }, []);

  const replace = useCallback((data: DataType[]) => {
    setState({
      observer: new DataObserver(data),
      originalData: data,
    });
  }, []);

  const add = useCallback(
    (data: DataType) => {
      state.observer.add(data);
      reload();
    },
    [reload, state.observer],
  );

  const remove = useCallback(() => {
    state.observer.delete();
    reload();
  }, [reload, state]);

  const getData = useCallback(() => {
    return state.observer.data;
  }, [state.observer]);

  return {
    observer: state.observer,
    columns,
    add,
    remove,
    rebase,
    reload,
    getData,
    replace,
  };
}
