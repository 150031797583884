import CircularProgressSummary from 'components/CircularProgressSummary';
import { BillRequestSummary } from '../types';
import { LoaderCard } from 'components/Cards';

interface Props {
  data: BillRequestSummary | undefined;
  isLoading?: boolean;
  currentFilteredStatuses: string;
  isDefaultFiltered: boolean;
  onStatusClick: (status: string) => void;
}

const BillingRequestSummary = ({
  data,
  isLoading,
  currentFilteredStatuses,
  isDefaultFiltered,
  onStatusClick,
}: Props) => {
  if (isLoading) {
    return <LoaderCard label="Loading Bill Requests Summary" sx={{ boxShadow: 'none' }} />;
  }

  if (data) {
    return (
      <CircularProgressSummary
        data={data}
        currentFilteredStatuses={currentFilteredStatuses}
        isDefaultFiltered={isDefaultFiltered}
        onCardClick={onStatusClick}
      />
    );
  }

  return null;
};

export default BillingRequestSummary;
