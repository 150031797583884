import { CRUDPageableProvider, usePageableContextData } from 'components/CRUDPageable';
import { CRUDPageableReactQuery } from 'hooks/useReactQueryHelpers';
import { PropsWithChildren } from 'react';
import { CreditCardData } from './types';
import { manageCreditCardsApi } from 'api/creditCards/manageCreditCardsApi';
import { CreditCardTransactionDto } from 'api/creditCards/interfaces/CreditCardTransactionDto';
import { CaseGeneralInfoDto } from 'api/cases/interfaces/GeneralInfoDto';
import { CaseData } from 'features/Cases/interfaces/CaseData';
import { mapDtoToData as mapCaseDtoToData } from 'features/Cases/CaseProvider';

const mapDtoToData = (
  data: CreditCardTransactionDto | CaseGeneralInfoDto,
): CreditCardData | CaseData => {
  const creditCardData = data as unknown as CreditCardTransactionDto;
  if (creditCardData.rbpCase) {
    return {
      ...creditCardData,
      rapCaseId: creditCardData.rbpCase.rapCaseId,
      rbpCaseUuid: creditCardData.rbpCase.uuid,
      rapProgramCode: creditCardData.rbpCase.rapProgramCode,
      clientName: creditCardData.rbpCase.clientName,
      programName: creditCardData.rbpCase.programName,
      clubCode: creditCardData.rbpCase.clubCode,
      rapCallCreatedDateTime: creditCardData.rbpCase.rapCallCreatedDateTime,
      rbpCaseStatus: creditCardData.rbpCase.rbpCaseStatus,
    };
  }
  return mapCaseDtoToData(data as unknown as CaseGeneralInfoDto);
};

export const CreditCardTransactionsQuery = CRUDPageableReactQuery<
  CreditCardTransactionDto | CaseGeneralInfoDto,
  CreditCardData | CaseData
>('QUERY_CREDIT_CARD_CASES', manageCreditCardsApi, mapDtoToData);

export function useCreditCardContext() {
  return usePageableContextData<CreditCardTransactionDto, CreditCardData>();
}

export default function CreditCardProvider({ children }: PropsWithChildren) {
  return (
    <CRUDPageableProvider
      queries={CreditCardTransactionsQuery}
      uuidKey={'rbpCaseUuid'}
      reQueryOnMount
    >
      {children}
    </CRUDPageableProvider>
  );
}
