import { PageableResponse } from 'api/interfaces/responses';
import { AxiosError } from 'axios';
import { convertListData } from './convertRawData';
import { PageableParams, ReactQueryOptions, PageableReactQueryResult } from './types';
import { useMappedReactQuery } from './useMappedReactQuery';

/**
 * Wrapps the useQuery to be used for PageableResponse based requests.
 * @param key Unique Identification Key
 * @param requestor API caller
 * @param options useQuery options and mapper callback
 * @param params Request params, will be passed to API caller
 */
export function usePageableReactQuery<SourceType, ResponseType = SourceType>(
  key: string,
  requestor: (params: PageableParams) => Promise<PageableResponse<SourceType>>,
  options: ReactQueryOptions<PageableResponse<SourceType>, ResponseType, AxiosError, SourceType>,
  params: PageableParams,
): PageableReactQueryResult<PageableResponse<SourceType>, ResponseType[]> {
  const { mapper, ...queryOptions } = options;
  const query = useMappedReactQuery(key, requestor, queryOptions, params);

  const { rawData, isDone: queryDone, isEmpty: queryEmpty, ...rest } = query;
  const rowsCount = rawData?.totalElements || 0;
  const isEmpty = queryEmpty || (queryDone && rowsCount === 0);
  const isDone = queryDone && rowsCount > 0;
  const data = rawData ? convertListData(rawData.content, mapper) : [];

  return { ...rest, rawData, data, rowsCount, isEmpty, isDone };
}
