import LoginContent from 'components/Login/LoginContent';
import LoginForm from './LoginForm';
import LoginForgotPassword from './LoginForgotPassword';

interface LoginTypeNonFederatedProps {
  onSubmit?: (email: string, password: string) => void;
  errorMessage?: string;
  isLoading?: boolean;
}

export default function LoginTypeNonFederated(props: LoginTypeNonFederatedProps) {
  return (
    <LoginContent>
      <LoginForm {...props} />
      <LoginForgotPassword />
    </LoginContent>
  );
}
