import InfoIcon from '@mui/icons-material/Info';
import { ReactComponent as BillingIcon } from 'assets/payment.svg';
import GeneralInfoStep from './ClientGeneralInfoStep';
import { StepConfig } from 'components/FormStepper/types';
import BillingInfoStep from './ClientBillingInfoStep';

const GeneralInfoStepConfig: StepConfig = {
  key: 'GENERAL_INFO',
  label: 'General',
  actionBarLabel: 'General Info',
  icon: InfoIcon,
  component: GeneralInfoStep,
};

const BillingInfoStepConfig: StepConfig = {
  key: 'BILLING_CONFIG',
  label: 'Billing',
  icon: BillingIcon,
  component: BillingInfoStep,
};

export const ClientCreationFormSteps = [GeneralInfoStepConfig, BillingInfoStepConfig];
