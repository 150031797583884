/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.36.1070 on 2023-01-11 10:21:54.

import { BillingRequestStatus } from './BillingRequestStatus';

export enum BillingRequestLevel {
  CASE_LEVEL = 'CASE_LEVEL',
  PRE_PAID = 'PRE_PAID',
  SERVICE_LEVEL = 'SERVICE_LEVEL',
}

export interface BillingRequestDto extends BaseDto {
  number: number;
  arInvoiceNumber: number;
  rapAccountingItemNumber: number;
  totalBillableAmount: number;
  status: BillingRequestStatus;
  name: string;
  customerNumber: string;
  invoiceNumber: string;
  purchaseOrderNumber: string;
  invoiceSentBy: string;
  contactPerson: string;
  assignedAccountExecutive: string;
  startPeriod: string;
  endPeriod: string;
  dueDate: string;
  billingLevel: BillingRequestLevel;
  lineItems: BillingRequestItemDto[];
}

export interface BillingRequestStatusHistoryViewDto extends BaseDto {
  status: BillingRequestStatus;
  description: string;
}

export interface BillingRequestViewDto extends Omit<BillingRequestDto, 'billingRequestsItems'> {
  statusHistory: BillingRequestStatusHistoryViewDto[];
}

export interface InvoiceDto extends BaseUuidDto {
  accountId: string;
  amount: number;
  invoiceDate: LocalDate;
  targetDate: LocalDate;
  currency: Currency;
  status: InvoiceStatus;
  invoiceNumber: number;
  balance: number;
  bundleKeys: string;
  creditAdj: number;
  credits: InvoiceItem[];
  items: InvoiceItem[];
  parentInvoice: boolean;
  parentInvoiceId: string;
  parentAccountId: string;
  refundAdj: number;
  trackingIds: string[];
}

export interface BillingRequestItemDto extends BaseDto {
  rbpCaseUuid: string;
  programUuid: string;
  itemNumber: number;
  totalPrice: number;
  reasonCode: number;
  quantity: number;
  description: string;
  comment: string;
  type: BillingRequestItemType;
}

export interface BaseDto {
  uuid: string;
  createdAtDateTime: string;
  updatedAtDateTime: string;
  createdByUserId: string;
  updatedByUserId: string;
}

// export interface LocalDate extends BaseLocal, ReadablePartial, Serializable {}

export interface InvoiceItem extends KillBillObject {
  invoiceItemId: string;
  invoiceId: string;
  linkedInvoiceItemId: string;
  accountId: string;
  childAccountId: string;
  bundleId: string;
  subscriptionId: string;
  productName: string;
  planName: string;
  phaseName: string;
  usageName: string;
  prettyProductName: string;
  prettyPlanName: string;
  prettyPhaseName: string;
  prettyUsageName: string;
  itemType: InvoiceItemType;
  description: string;
  startDate: LocalDate;
  endDate: LocalDate;
  amount: number;
  rate: number;
  currency: Currency;
  quantity: number;
  itemDetails: string;
  catalogEffectiveDate: DateTime;
  childItems: InvoiceItem[];
}

export interface BaseUuidDto {
  uuid: string;
}

// export interface BaseLocal extends AbstractPartial {}

// export interface ReadablePartial extends Comparable<ReadablePartial> {}

// export interface Serializable {}

// export interface DateTime extends BaseDateTime, ReadableDateTime, Serializable {}

export interface KillBillObject {
  auditLogs: any[];
}

// export interface AbstractPartial extends ReadablePartial, Comparable<ReadablePartial> {}

// export interface BaseDateTime extends AbstractDateTime, ReadableDateTime, Serializable {}

export interface ReadableDateTime extends ReadableInstant {}

export interface Comparable<T> {}

export interface AbstractDateTime extends AbstractInstant, ReadableDateTime {}

export interface ReadableInstant extends Comparable<ReadableInstant> {}

export interface AbstractInstant extends ReadableInstant {}

export type Currency =
  | 'AED'
  | 'AFN'
  | 'ALL'
  | 'AMD'
  | 'ANG'
  | 'AOA'
  | 'ARS'
  | 'AUD'
  | 'AWG'
  | 'AZN'
  | 'BAM'
  | 'BBD'
  | 'BDT'
  | 'BGN'
  | 'BHD'
  | 'BIF'
  | 'BMD'
  | 'BND'
  | 'BOB'
  | 'BRL'
  | 'BSD'
  | 'BTN'
  | 'BWP'
  | 'BYR'
  | 'BZD'
  | 'CAD'
  | 'CDF'
  | 'CHF'
  | 'CLP'
  | 'CNY'
  | 'COP'
  | 'CRC'
  | 'CUC'
  | 'CUP'
  | 'CVE'
  | 'CZK'
  | 'DJF'
  | 'DKK'
  | 'DOP'
  | 'DZD'
  | 'EGP'
  | 'ERN'
  | 'ETB'
  | 'EUR'
  | 'FJD'
  | 'FKP'
  | 'GBP'
  | 'GEL'
  | 'GGP'
  | 'GHS'
  | 'GIP'
  | 'GMD'
  | 'GNF'
  | 'GTQ'
  | 'GYD'
  | 'HKD'
  | 'HNL'
  | 'HRK'
  | 'HTG'
  | 'HUF'
  | 'IDR'
  | 'ILS'
  | 'IMP'
  | 'INR'
  | 'IQD'
  | 'IRR'
  | 'ISK'
  | 'JEP'
  | 'JMD'
  | 'JOD'
  | 'JPY'
  | 'KES'
  | 'KGS'
  | 'KHR'
  | 'KMF'
  | 'KPW'
  | 'KRW'
  | 'KWD'
  | 'KYD'
  | 'KZT'
  | 'LAK'
  | 'LBP'
  | 'LKR'
  | 'LRD'
  | 'LSL'
  | 'LTL'
  | 'LVL'
  | 'LYD'
  | 'MAD'
  | 'MDL'
  | 'MGA'
  | 'MKD'
  | 'MMK'
  | 'MNT'
  | 'MOP'
  | 'MRO'
  | 'MUR'
  | 'MVR'
  | 'MWK'
  | 'MXN'
  | 'MYR'
  | 'MZN'
  | 'NAD'
  | 'NGN'
  | 'NIO'
  | 'NOK'
  | 'NPR'
  | 'NZD'
  | 'OMR'
  | 'PAB'
  | 'PEN'
  | 'PGK'
  | 'PHP'
  | 'PKR'
  | 'PLN'
  | 'PYG'
  | 'QAR'
  | 'RON'
  | 'RSD'
  | 'RUB'
  | 'RWF'
  | 'SAR'
  | 'SBD'
  | 'SCR'
  | 'SDG'
  | 'SEK'
  | 'SGD'
  | 'SHP'
  | 'SLL'
  | 'SOS'
  | 'SPL'
  | 'SRD'
  | 'STD'
  | 'SVC'
  | 'SYP'
  | 'SZL'
  | 'THB'
  | 'TJS'
  | 'TMT'
  | 'TND'
  | 'TOP'
  | 'TRY'
  | 'TTD'
  | 'TVD'
  | 'TWD'
  | 'TZS'
  | 'UAH'
  | 'UGX'
  | 'USD'
  | 'UYU'
  | 'UZS'
  | 'VEF'
  | 'VND'
  | 'VUV'
  | 'WST'
  | 'XAF'
  | 'XCD'
  | 'XDR'
  | 'XOF'
  | 'XPF'
  | 'YER'
  | 'ZAR'
  | 'ZMW'
  | 'ZWD'
  | 'BTC';

export type InvoiceStatus = 'DRAFT' | 'COMMITTED' | 'VOID';

export type BillingRequestItemType =
  | 'SERVICE'
  | 'FEE'
  | 'CASE_ADJUSTMENT'
  | 'INVOICE_ADJUSTMENT'
  | 'REV_SHARE'
  | 'ADJUSTMENT'
  | 'RECIPE';

export type InvoiceItemType =
  | 'EXTERNAL_CHARGE'
  | 'FIXED'
  | 'RECURRING'
  | 'REPAIR_ADJ'
  | 'CBA_ADJ'
  | 'CREDIT_ADJ'
  | 'ITEM_ADJ'
  | 'USAGE'
  | 'TAX'
  | 'PARENT_SUMMARY';

type LocalDate = string;
type DateTime = string;

export interface UpdateBillingRequestStatusDto {
  status: BillingRequestStatus;
}
