import { PropsWithChildren } from 'react';
import { CRUDPageableProvider, usePageableContextData } from 'components/CRUDPageable';
import { CRUDPageableReactQuery } from 'hooks/useReactQueryHelpers';
import { VendorData } from './types';
import { VendorDto } from 'api/setup/interfaces/VendorDto';
import { convertPhone } from 'utils/convertPhone';
import { manageVendorApi } from 'api/setup/manageVendorApi';
import { convertToStringLeadZero } from 'utils/convertToStringLeadZero';

const VendorQuery = CRUDPageableReactQuery<VendorDto, VendorData>(
  'QUERY_VENDORS',
  manageVendorApi,
  (dto) => {
    const {
      callCenterPhoneNumber,
      primaryVendorContactPhoneNumber,
      secondaryVendorContactPhoneNumber,
      rapVendorId,
      ...rest
    } = dto;
    return {
      callCenterPhoneNumber: convertPhone(callCenterPhoneNumber),
      primaryVendorContactPhoneNumber: convertPhone(primaryVendorContactPhoneNumber),
      secondaryVendorContactPhoneNumber: convertPhone(secondaryVendorContactPhoneNumber),
      rapVendorId: convertToStringLeadZero(rapVendorId),
      ...rest,
    };
  },
);

export function useVendorContext() {
  return usePageableContextData<VendorDto, VendorData>();
}

export default function VendorProvider({ children }: PropsWithChildren) {
  return <CRUDPageableProvider queries={VendorQuery}>{children}</CRUDPageableProvider>;
}
