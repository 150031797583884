import { StepComponent } from 'components/FormStepper/types';
import {
  useCreatePaymentRequestInfo,
  useGetDefaultPaymentRequestInfo,
  PaymentRequestInformationData,
} from '../VendorQuery';
import { useEntityCreationFlowContext, useEntityCreationStep } from 'components/EntityCreationFlow';
import { VendorPaymentInfoForm } from '../VendorDetail/VendorPaymentInfoForm';
import { CreatePaymentRequestInformationDto } from 'api/setup/manageVendorApi';
import { useMemo } from 'react';

const PaymentInfoStep = ({
  stepKey,
  submitted,
  completed,
  resetSubmitted,
  setCompleted,
}: StepComponent) => {
  const { data: clientData } = useEntityCreationFlowContext();
  const initialData = useGetDefaultPaymentRequestInfo();

  const data = useMemo(
    () => ({
      ...initialData,
      paymentReportRecipientEmails:
        (clientData?.paymentReportRecipientEmails as string[]) ||
        initialData.paymentReportRecipientEmails,
    }),
    [clientData?.paymentReportRecipientEmails, initialData],
  );

  const PaymentStep = useEntityCreationStep<
    PaymentRequestInformationData,
    CreatePaymentRequestInformationDto
  >({
    initialData: data,
    forwardData: false,
    form: VendorPaymentInfoForm,
    // eslint-disable-next-line react-hooks/rules-of-hooks
    query: () => useCreatePaymentRequestInfo(clientData?.uuid as string),
  });

  return (
    <PaymentStep
      stepKey={stepKey}
      submitted={submitted}
      completed={completed}
      resetSubmitted={resetSubmitted}
      setCompleted={setCompleted}
    />
  );
};

export default PaymentInfoStep;
