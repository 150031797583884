import { Auth } from 'aws-amplify';
import { LoggedUser } from './interfaces';
import moment from 'moment';

export async function getCurrentAuthenticatedUser(): Promise<LoggedUser | null> {
  const userData = await Auth.currentAuthenticatedUser();
  const userInfo = userData?.signInUserSession.idToken.payload;

  if (userInfo) {
    return { id: userInfo.sub, email: userInfo.email, groups: userInfo['cognito:groups'] || [] };
  }

  return null;
}

export async function getCurrentTokenAndExpired(): Promise<{
  token: string;
  isTokenExpired: boolean;
}> {
  const session = await Auth.currentSession();
  const accessToken = session.getAccessToken();
  const expiration = accessToken.getExpiration();
  const expirationTime = expiration * 1000 - Date.now();

  return {
    token: accessToken.getJwtToken(),
    isTokenExpired: expirationTime <= 0,
  };
}

export function getItemFromStorage<T extends string>(key: string): T | null {
  const value = localStorage.getItem(key);
  if (value) {
    return value as T;
  }
  return null;
}

export function setItemToStorage(key: string, value: string): void {
  localStorage.setItem(key, value);
}

export function removeItemFromStorage(key: string): void {
  localStorage.removeItem(key);
}

export function forceLogoutAndRedirect(): void {
  const broadcastChannel = new BroadcastChannel('auth-channel');
  Auth.signOut();
  localStorage.clear();
  broadcastChannel.postMessage('logout');
  window.location.href = '/login';
}
