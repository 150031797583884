import { CaseFinancialViewDto } from 'api/cases/interfaces/CaseFinancialDto';
import { fetchCaseFinancials } from 'api/cases/manageCasesApi';
import { useMappedReactQuery } from 'hooks/useReactQueryHelpers';
import { CaseFinancialData } from './types';

function mapper(dto: CaseFinancialViewDto): CaseFinancialData {
  return {
    ...dto,
    caseFinancialItems: dto.caseFinancialItems.map((caseFinancialItem) => {
      const { itemName, itemCode, ...rest } = caseFinancialItem;

      return {
        itemCodeName: itemName && itemCode ? `${itemCode} - ${itemName}` : itemName || itemCode,
        itemCode,
        itemName,
        ...rest,
      };
    }),
  };
}

export default function useCaseFinancials(uuid: string, enabled = true) {
  return useMappedReactQuery(
    'QUERY_CASE_FINANCIALS',
    fetchCaseFinancials,
    { enabled, mapper },
    uuid,
  );
}
