import { Box, Stack } from '@mui/material';
import { StyledHorizRow } from '../DynamicExpandablePanel';
import { DynamicConfirmation, DynamicFormActions } from '../DynamicFormActions';

interface Props {
  isEditMode: boolean;
  save?: DynamicConfirmation;
  discard?: DynamicConfirmation;
}

export function DynamicExpandableFormActions({ isEditMode, discard, save }: Props) {
  return (
    <>
      {isEditMode && (
        <Box sx={{ pt: 2, ml: -4, mr: -4 }}>
          <StyledHorizRow />
          <Stack direction="row" spacing={2} sx={{ p: 2, pr: 4, pl: 4, justifyContent: 'end' }}>
            <DynamicFormActions save={save} discard={discard} />
          </Stack>
        </Box>
      )}
    </>
  );
}
