import { createContext, useContext } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';

interface ContextProps {
  data: Record<string, unknown> | undefined;
  hookFormMap: Map<string, UseFormReturn<FieldValues>>;
  setData: React.Dispatch<React.SetStateAction<Record<string, unknown> | undefined>>;
  setHookFormMap: React.Dispatch<React.SetStateAction<Map<string, UseFormReturn<FieldValues>>>>;
}

export const EntityCreationFlowContext = createContext({} as ContextProps);

export function useEntityCreationFlowContext() {
  return useContext(EntityCreationFlowContext);
}
