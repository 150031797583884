import { FallbackProps } from 'react-error-boundary';

const ErrorFallback = ({ error }: FallbackProps) => {
  return (
    <div role="alert" className="text-center mt-20">
      <div>
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
      </div>
    </div>
  );
};

export default ErrorFallback;
