import { Box, Typography } from '@mui/material';

import DialogConfirmation from 'components/DialogConfirmation';

import { useGetCaseAttachableBillingRequests } from 'features/Cases/queries';
import { CaseData } from 'features/Cases/interfaces/CaseData';
import { DynamicForm } from 'components/Dynamic/DynamicForm';
import { useForm } from 'react-hook-form';
import { AddCaseToBRForm, useAddtoBRConfig } from './helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAddCaseToBillingRequest } from 'features/Invoices/BillingRequestQuery';

interface CaseAddToBRDialogProps {
  caseData: CaseData;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onConfirmAddtoBR: () => void;
}

export default function CaseAddToBRDialog({
  visible,
  setVisible,
  caseData,
  onConfirmAddtoBR,
}: CaseAddToBRDialogProps) {
  const {
    data,
    isFetching: isFetchingBRs,
    isError: isErrorFetchingBRs,
  } = useGetCaseAttachableBillingRequests(caseData.uuid, visible);

  const { addCaseToBRFields, addCaseToBRValidationSchema } = useAddtoBRConfig(data);

  const hookForm = useForm<AddCaseToBRForm>({
    resolver: yupResolver(addCaseToBRValidationSchema),
    defaultValues: {
      billingRequest: '',
    },
    mode: 'onChange',
  });

  const {
    isError: isErrorAddingCaseToBR,
    errorMessage,
    mutateAsync: addCaseToBillingRequest,
  } = useAddCaseToBillingRequest(caseData.uuid);

  const handleSubmit = async () => {
    await hookForm.trigger();
    if (hookForm.formState.isValid) {
      const { billingRequest } = hookForm.getValues();
      await addCaseToBillingRequest(billingRequest);
      setVisible(false);
      onConfirmAddtoBR();
    }
  };

  const handleReset = () => {
    hookForm.reset();
    setVisible(false);
  };

  const isNotValid = !hookForm.formState.isValid;

  return (
    <>
      <DialogConfirmation
        open={visible}
        maxWidth="xl"
        title={`Add Case #${caseData.rapCaseId} to Billing Request`}
        onClose={handleReset}
        onConfirm={data?.length === 0 ? undefined : handleSubmit}
        labelConfirm="Add"
        labelCancel="Close"
        disableConfirmLoader={isNotValid}
        sx={{
          '& .MuiDialog-paper': {
            width: 'fit-content',
            maxWidth: 980,
          },
          '& .MuiFormLabel-root': {
            display: 'none',
          },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {isFetchingBRs && 'Loading BRs...'}
          {!isFetchingBRs &&
            !isErrorFetchingBRs &&
            (data?.length > 0 ? (
              <>
                <Typography
                  sx={{
                    fontSize: '14px',
                  }}
                  mb={2}
                >
                  Chose Billing Request you want to attach case to
                </Typography>
                <form onSubmit={handleSubmit}>
                  <DynamicForm
                    fields={addCaseToBRFields}
                    hookForm={hookForm}
                    isEditMode
                    colspan={12}
                  />
                </form>
              </>
            ) : (
              <Typography color="error">
                No Attachable Billing Requests for the Client/Program
              </Typography>
            ))}

          {isErrorAddingCaseToBR && (
            <Typography color="error" mt={4}>
              An Error Has Occurred While Adding Case to Billing Request:
              <br />
              {errorMessage}
            </Typography>
          )}
        </Box>
      </DialogConfirmation>
    </>
  );
}
