import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RecordDetailHeader } from 'components/RecordDetailHeader';
import PricingSetupPageableLayout from './components/PricingSetupPageableLayout';
import { PricingSetupComponent } from './components/types';
import { LoaderCard } from 'components/Cards';
import { Context } from './components/PricingSetupProvider';
import PricingDetail from './PricingDetail';
import { useGetAllServiceSetup, useGetAllTowsSetup } from './PricingSetupQuery';
import { changeTime, convertUTCDateToString } from 'utils/convertDate';
import { DutyTypes, EntityTypes } from 'api/setup/manageServicesSetupApi';
import { getBreadcrumbConfig } from './helper';

/**
 * A component that renders a detailed view of a specific pricing setup for a given date range.
 *
 * @component
 * @example
 * const navigators = {
 *   toIndividualScreen: () => {},
 *   toCreatorScreen: () => {},
 * };
 * return (
 *   <PricingSetupView navigators={navigators} />
 * );
 */
const PricingSetupView: PricingSetupComponent = ({ navigators }) => {
  const { requestByDateRange, navigation, getAllPricingInfo, entityType } = useContext(Context);
  const { uuid, dateRange, clientUUID } = useParams();

  const [startDateTime, setStartDateTime] = useState<string>('');
  const [endDateTime, setEndDateTime] = useState<string>('');

  const breadcrumbConfig = useMemo(() => {
    return getBreadcrumbConfig(entityType as EntityTypes, uuid, clientUUID);
  }, [entityType, uuid, clientUUID]);

  useEffect(() => {
    requestByDateRange(dateRange);
  }, [requestByDateRange, dateRange]);

  useEffect(() => {
    const [newStartDateTime, newEndDateTime] = dateRange?.split('|') || [];
    setStartDateTime(newStartDateTime);
    setEndDateTime(newEndDateTime);
  }, [dateRange]);

  const handleNext = () => {
    navigation.nextDates && navigators.toIndividualScreen(navigation.nextDates);
  };

  const handlePrevious = () => {
    navigation.previousDates && navigators.toIndividualScreen(navigation.previousDates);
  };

  const currentInformation = useMemo(() => {
    if (getAllPricingInfo) {
      const { data } = getAllPricingInfo;
      const filteredData = data?.filter(
        (record) => record.startDateTime === startDateTime && record.endDateTime === endDateTime,
      );

      if (filteredData && filteredData.length > 0) {
        const currentInfo = { ...filteredData[0] }; // create a new object to avoid direct mutation
        // with this timestamp 2023-06-12T23:59:59Z, react-dates sets 2023-06-13, need to change date in this step to avoid the bug
        currentInfo.endDateTime = changeTime(currentInfo.endDateTime, 'T00:00:00Z');
        return currentInfo;
      }
    }
    return undefined;
  }, [getAllPricingInfo, endDateTime, startDateTime]);

  const getAllLightServiceSetup = useGetAllServiceSetup(
    entityType as EntityTypes,
    uuid || '',
    startDateTime,
    endDateTime,
    !!currentInformation,
    DutyTypes.LIGHT,
  );

  const getAllMediumServiceSetup = useGetAllServiceSetup(
    entityType as EntityTypes,
    uuid || '',
    startDateTime,
    endDateTime,
    !!currentInformation,
    DutyTypes.MEDIUM,
  );

  const getAllHeavyServiceSetup = useGetAllServiceSetup(
    entityType as EntityTypes,
    uuid || '',
    startDateTime,
    endDateTime,
    !!currentInformation,
    DutyTypes.HEAVY,
  );

  const getAllLightTowsSetup = useGetAllTowsSetup(
    entityType as EntityTypes,
    uuid || '',
    startDateTime,
    endDateTime,
    !!currentInformation,
    DutyTypes.LIGHT,
  );

  const getAllMediumTowsSetup = useGetAllTowsSetup(
    entityType as EntityTypes,
    uuid || '',
    startDateTime,
    endDateTime,
    !!currentInformation,
    DutyTypes.MEDIUM,
  );

  const getAllHeavyTowsSetup = useGetAllTowsSetup(
    entityType as EntityTypes,
    uuid || '',
    startDateTime,
    endDateTime,
    !!currentInformation,
    DutyTypes.HEAVY,
  );

  const isLoading = useMemo(() => {
    return (
      getAllLightServiceSetup?.isLoading &&
      getAllMediumServiceSetup?.isLoading &&
      getAllHeavyServiceSetup?.isLoading &&
      getAllLightTowsSetup?.isLoading &&
      getAllMediumTowsSetup?.isLoading &&
      getAllHeavyTowsSetup?.isLoading
    );
  }, [
    getAllLightServiceSetup,
    getAllMediumServiceSetup,
    getAllHeavyServiceSetup,
    getAllLightTowsSetup,
    getAllMediumTowsSetup,
    getAllHeavyTowsSetup,
  ]);

  const isSuccess = useMemo(() => {
    return (
      getAllLightServiceSetup?.isSuccess &&
      getAllMediumServiceSetup?.isSuccess &&
      getAllHeavyServiceSetup?.isSuccess &&
      getAllLightTowsSetup?.isSuccess &&
      getAllMediumTowsSetup?.isSuccess &&
      getAllHeavyTowsSetup?.isSuccess &&
      !!currentInformation
    );
  }, [
    getAllLightServiceSetup,
    getAllMediumServiceSetup,
    getAllHeavyServiceSetup,
    currentInformation,
    getAllLightTowsSetup,
    getAllMediumTowsSetup,
    getAllHeavyTowsSetup,
  ]);

  return (
    <PricingSetupPageableLayout>
      <RecordDetailHeader
        breadcrumbs={breadcrumbConfig}
        label={`${convertUTCDateToString(startDateTime)} - ${convertUTCDateToString(endDateTime)}`}
        hasNext={!!navigation.nextDates}
        hasPrevious={!!navigation.previousDates}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />
      {isLoading && <LoaderCard label="Loading pricing setup" sx={{ flexGrow: 1, p: 1 }} />}
      {isSuccess && (
        <PricingDetail
          lightServicesData={getAllLightServiceSetup.data}
          mediumServicesData={getAllMediumServiceSetup.data}
          heavyServicesData={getAllHeavyServiceSetup.data}
          lightTowsData={getAllLightTowsSetup.data}
          mediumTowsData={getAllMediumTowsSetup.data}
          heavyTowsData={getAllHeavyTowsSetup.data}
          isCreationMode={false}
          currentInformation={currentInformation}
          toIndividualScreen={navigators.toIndividualScreen}
          entityType={entityType}
        />
      )}
    </PricingSetupPageableLayout>
  );
};

export default PricingSetupView;
