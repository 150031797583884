import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DataTable, { DataTableColumn } from 'components/DataTable';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Stack from '@mui/material/Stack';
import { DynamicExpandablePanel } from 'components/Dynamic';
import { EntityTypes, PricingRecordDto } from 'api/setup/manageServicesSetupApi';
import { ErrorCard } from 'components/Cards/ErrorCard';
import { LoaderCard } from 'components/Cards';
import { convertUTCToDateTimeString } from 'utils/convertDate';
import { useExpandablePanelGroup } from 'components/Dynamic/DynamicExpandablePanel/ExpandablePanelGroup';
import { useGetPricingInfo } from './PricingSetupQuery';
import { useNavigate } from 'react-router-dom';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';

/**
 * A component that displays a list of pricing records.
 *
 * @component
 * @example
 * const entityType = EntityTypes.CLIENT;
 * const entityUuid = 'fake-entity-uuid';
 * return (
 *   <PricingSetupPanel entityType={entityType} entityUuid={entityUuid} />
 * );
 */
export interface Props {
  /**
   * The entity type for which the pricing setup is being displayed.
   */
  entityType: EntityTypes;
  /**
   * The unique identifier of the entity.
   */
  entityUuid: string;
}

export const PricingDataTableConfig: DataTableColumn<PricingRecordDto>[] = [
  {
    label: 'Effective Date',
    id: 'startDateTime',
    sortable: true,
    accessor: (row) => convertUTCToDateTimeString(row.startDateTime),
  },
  {
    label: 'Expire Date',
    id: 'endDateTime',
    sortable: true,
    accessor: (row) => convertUTCToDateTimeString(row.endDateTime),
  },
  {
    label: 'Status',
    id: 'status',
    sortable: true,
  },
  {
    label: 'Description',
    id: 'description',
  },
];

export const PricingSetupPanel = ({ entityType, entityUuid }: Props) => {
  const [order, setOrder] = useState<'asc' | 'desc'>();
  const [orderBy, setOrderBy] = useState<string>();
  const [expanded, setExpanded] = useState<boolean>(false);
  const { groupExpanded } = useExpandablePanelGroup();
  const { data, isLoading, error } = useGetPricingInfo(entityType, entityUuid);
  const { permissions } = useRapCoreFeaturePermissions('servicesSetup');

  useEffect(() => {
    setExpanded(groupExpanded);
  }, [groupExpanded]);

  const navigate = useNavigate();

  const onAddPricingSetup = useCallback(() => {
    navigate('./pricing-setup/create');
  }, [navigate]);

  const onRowClick = useCallback(
    (pricing: PricingRecordDto) => {
      const detailsUrl = './pricing-setup/:dateRange';
      navigate(
        `${detailsUrl.replace(':dateRange', `${pricing.startDateTime}|${pricing.endDateTime}`)}`,
      );
    },
    [navigate],
  );

  const sortOptions = useMemo(() => {
    return {
      order,
      orderBy,
      createSortHandler: (event: React.MouseEvent<unknown, MouseEvent>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        const newOrderValue = isAsc ? 'desc' : 'asc';
        setOrder(newOrderValue);
        setOrderBy(property);
      },
    };
  }, [order, orderBy]);

  // TODO: isolate from component
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isNotFound = (error as any)?.response.status === 404;

  return (
    <DynamicExpandablePanel
      data-testid="PricingSetupPanel"
      title="Pricing"
      icon={<PriceChangeIcon />}
      onExpanded={() => setExpanded(!expanded)}
      expanded={expanded}
    >
      {isLoading && <LoaderCard label="Loading Pricing Setup" />}
      {isNotFound && <ErrorCard label="Failed To Load Data" />}

      <Box mx={-4}>
        {permissions?.create && (
          <Stack direction="row" mb={2} justifyContent="center">
            <Button
              variant="outlined"
              size="small"
              startIcon={<AddIcon />}
              onClick={onAddPricingSetup}
            >
              Add Pricing
            </Button>
          </Stack>
        )}

        {data && data.length > 0 && (
          <DataTable<PricingRecordDto>
            data-testid={DataTable.name}
            onRowClick={onRowClick}
            columns={PricingDataTableConfig}
            rows={data}
            sortOptions={sortOptions}
          />
        )}
      </Box>
    </DynamicExpandablePanel>
  );
};
