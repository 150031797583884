import { CRUDComponent } from 'components/CRUDNavigator';
import { ProgramCreationFormSteps } from './ProgramCreation/config';
import EntityCreationFlow, { EntityCreationFlowProvider } from 'components/EntityCreationFlow';

const ProgramPageCreator: CRUDComponent = ({ navigators }) => {
  return (
    <EntityCreationFlowProvider>
      <EntityCreationFlow steps={ProgramCreationFormSteps} navigators={navigators} />
    </EntityCreationFlowProvider>
  );
};

export default ProgramPageCreator;
