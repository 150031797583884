import AppColors from 'AppColors';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DataTable, { DataTableColumn } from 'components/DataTable';
import clsx from 'clsx';
import styles from '../CaseFinancials.module.css';
import sumBy from 'lodash.sumby';
import { CaseFinancialItemData } from '../types';
import { ColumnHeader } from './ColumnHeader';
import { ExpandableHeader } from './ExpandableHeader';
import { convertToCurrency } from 'utils/formatter';
import { useMemo, useState } from 'react';

const isRefund = (row: CaseFinancialItemData) => row.itemName === 'Customer Refund Total';

export const getColumns = ({
  onExpanded,
}: {
  onExpanded?: (expanded: boolean) => void;
}): DataTableColumn<CaseFinancialItemData>[] => {
  return [
    {
      Header: () => <ExpandableHeader onExpanded={onExpanded} />,
      label: 'Case Financials',
      id: 'itemCodeName',
      columns: [
        {
          label: 'Services',
          id: 'itemCodeName',
          testId: 'itemCodeName',
        },
      ],
    },
    {
      id: 'clientBillable',
      Header: () => (
        <ColumnHeader
          label="Billable (Accounts Receivable) Amounts"
          variant="receivable"
          ml="20px"
        />
      ),
      columns: [
        {
          id: 'clientBillable',
          width: 105,
          maxWidth: 105,
          accessor: (row) =>
            !isRefund(row) ? (
              row.clientBillable ? (
                <CheckIcon htmlColor={AppColors.AAA_GREEN} />
              ) : (
                <ClearIcon />
              )
            ) : null,
          Footer: () => 'Total',
          testId: 'clientBillable',
          Header: () => <ColumnHeader label="Billable" />,
        },
        {
          id: 'clientUnitCount',
          testId: 'clientUnitCount',
          accessor: (row) => !isRefund(row) && row.clientUnitCount,
          Footer: () => '',
          Header: () => <ColumnHeader label="Unit Count" />,
        },
        {
          id: 'clientTotalAmount',
          testId: 'clientTotalAmount',
          accessor: (row) => !isRefund(row) && convertToCurrency(row.clientTotalAmount),
          Footer: (rows) => (
            <span data-testId="clientTotalAmountTotalSum">
              {convertToCurrency(sumBy(rows, (row) => row.clientTotalAmount))}
            </span>
          ),
          Header: () => <ColumnHeader label="Client" />,
        },
        {
          id: 'clientCreditCard',
          testId: 'clientCreditCard',
          accessor: (row) => (
            <Box color={row.clientCreditCard >= 0 ? 'inherit' : AppColors.AAA_RED}>
              {convertToCurrency(row.clientCreditCard)}
            </Box>
          ),
          Footer: (rows) => (
            <span data-testId="clientCreditCardTotalSum">
              {convertToCurrency(sumBy(rows, (row) => row.clientCreditCard))}
            </span>
          ),
          Header: () => <ColumnHeader label="Credit Card" />,
        },
      ],
    },
    {
      id: 'vendorPayable',
      testId: 'clientCreditCard',
      Header: () => (
        <ColumnHeader label="Payable (Accounts Payable) Amounts" variant="payable" ml="85px" />
      ),
      columns: [
        {
          id: 'vendorPayable',
          testId: 'vendorPayable',
          width: 105,
          maxWidth: 105,
          accessor: (row) =>
            !isRefund(row) ? (
              row.vendorPayable ? (
                <CheckIcon htmlColor={AppColors.AAA_GREEN} />
              ) : (
                <ClearIcon />
              )
            ) : null,
          Footer: () => '',
          Header: () => <ColumnHeader label="Payable" variant="payable" />,
        },
        {
          id: 'vendorUnitCount',
          testId: 'vendorUnitCount',
          accessor: (row) => !isRefund(row) && row.vendorUnitCount,
          Footer: () => '',
          Header: () => <ColumnHeader label="Unit Count" variant="payable" />,
        },
        {
          id: 'vendorPricePerUnit',
          testId: 'vendorPricePerUnit',
          accessor: (row) => !isRefund(row) && convertToCurrency(row.vendorPricePerUnit),
          Footer: () => '',
          Header: () => <ColumnHeader label="(Approx.) Per Unit Amount" variant="payable" />,
        },
        {
          id: 'vendorTotalAmount',
          testId: 'vendorTotalAmount',
          accessor: (row) => !isRefund(row) && convertToCurrency(row.vendorTotalAmount),
          Footer: (rows) => (
            <span data-testId="vendorTotalAmountSum">
              {convertToCurrency(sumBy(rows, (row) => row.vendorTotalAmount))}
            </span>
          ),
          Header: () => <ColumnHeader label="Vendor Payable Amount" variant="payable" />,
        },
      ],
    },
    {
      label: 'Estimated Gain/Loss',
      id: 'estimatedGainLoss',
      testId: 'estimatedGainLoss',
      accessor: (row) =>
        !isRefund(row) && (
          <Box color={row.estimatedGainLoss >= 0 ? 'inherit' : AppColors.AAA_RED}>
            {convertToCurrency(row.estimatedGainLoss)}
          </Box>
        ),
      Footer: (rows) => {
        const sum = sumBy(rows, (row) => row.estimatedGainLoss);
        return (
          <Box color={sum >= 0 ? 'inherit' : AppColors.AAA_RED} data-testId="estimatedGainLossSum">
            {convertToCurrency(sum)}
          </Box>
        );
      },
    },
  ];
};

type Props = {
  data: CaseFinancialItemData[];
};

export function BalanceSheetTable({ data }: Props) {
  const [isExpanded, setExpanded] = useState(true);
  const columns = useMemo(() => getColumns({ onExpanded: setExpanded }), []);

  return (
    <Box my={3}>
      <DataTable
        data-testid="balance-sheet-table"
        columns={columns}
        rows={data}
        className={clsx(styles.balanceSheet, { [styles.collapsed]: !isExpanded })}
      />
    </Box>
  );
}
