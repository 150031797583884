import { useState } from 'react';
import BillingRequestSummaryCardsHeader from './BillingRequestSummaryCardsHeader';
import Stack from '@mui/material/Stack';
import { useForm } from 'react-hook-form';
import { CRUDComponent } from 'components/CRUDNavigator';
import { CRUDPageableList } from 'components/CRUDPageable';
import { getQueryString } from 'components/Dynamic';
import { NotificationSnackbar, NotificationType } from 'components/Notifications';

import { invoiceDetailFields, invoiceSearchFields } from './config';
import { BillingRequestSearchInitialState } from './types';
import { useBillingRequestContext } from './BillingRequestProvider';

const BillingRequestList: CRUDComponent = ({ navigators }) => {
  const context = useBillingRequestContext();
  const searchFields = invoiceSearchFields();
  const searchForm = useForm({ defaultValues: BillingRequestSearchInitialState });
  const [notifications, setNotifications] = useState<NotificationType>();

  const refetch = () => {
    const filters = getQueryString(searchForm.getValues(), searchFields);
    context.requestAll(filters);
  };

  return (
    <Stack spacing="10px" flexGrow={1}>
      <BillingRequestSummaryCardsHeader searchForm={searchForm} refetch={refetch} />
      <CRUDPageableList
        name="Billing Request"
        context={context}
        navigators={navigators}
        searchFields={searchFields}
        searchDefaultValues={BillingRequestSearchInitialState}
        tableColumns={invoiceDetailFields}
        hasHeaderButton={false}
        useFormReturn={searchForm}
      />
      <NotificationSnackbar
        clearNotification={() => setNotifications(undefined)}
        notification={notifications}
      />
    </Stack>
  );
};

export default BillingRequestList;
