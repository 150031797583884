import InfoIcon from '@mui/icons-material/Info';
import { CRUDPageableSection } from 'components/CRUDPageable';
import { ClientData, useClientContext } from '../ClientProvider';
import { GeneralInfoValidationSchema } from './fields';
import { ClientForm } from './ClientForm';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';

interface Props {
  isCreationMode?: boolean;
  onAfterCreate?: (uuid: string) => void;
  initialData?: ClientData;
}

export default function ClientGeneralInfo({ isCreationMode, onAfterCreate, initialData }: Props) {
  const context = useClientContext();
  const { permissions } = useRapCoreFeaturePermissions('clients');

  return (
    <CRUDPageableSection
      context={context}
      title="General Info"
      icon={<InfoIcon />}
      isCreationMode={isCreationMode}
      onAfterCreate={onAfterCreate}
      initialFormData={initialData}
      validationSchema={GeneralInfoValidationSchema}
      defaultEditMode={isCreationMode}
      formContent={(hookForm, isEditMode) => (
        <ClientForm hookForm={hookForm} isEditMode={isEditMode} />
      )}
      readOnly={!permissions.update}
    />
  );
}
