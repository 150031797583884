import { createContext, useContext, useState } from 'react';
import ClaimItemOverrideDialog from '../CaseDetails/CaseFinancials/components/ClaimItemOverrideDialog';
import { useQueryClient } from 'react-query';

const CaseRejectContext = createContext<{ initOverride: (claimItemUuid: string) => unknown }>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initOverride: (claimItemUuid: string) => {
    throw new Error('CaseRejectContext::onInit not implemented');
  },
});

export const useCaseRejectContext = () => useContext(CaseRejectContext);

export const CaseRejectProvider = ({
  children,
  caseUuid,
  rapCaseId,
  clientId,
}: {
  caseUuid: string;
  rapCaseId: string;
  children: React.ReactNode;
  clientId: number;
}) => {
  const [claimItemUuid, setClaimItemUuid] = useState<string>('null');
  const [visible, setVisible] = useState(false);
  const queryClient = useQueryClient();
  const initOverride = (uuid: string) => {
    setVisible(true);
    setClaimItemUuid(uuid);
  };

  return (
    <CaseRejectContext.Provider value={{ initOverride }}>
      <>
        {children}
        <ClaimItemOverrideDialog
          visible={visible}
          setVisible={setVisible}
          caseUuid={caseUuid}
          claimItemUuid={claimItemUuid}
          rapCaseId={rapCaseId}
          clientId={clientId}
          onConfirmOverrideClaim={() => {
            queryClient.invalidateQueries(['QUERY_CASES_KEY_BYID']);
            setVisible(false);
          }}
        />
      </>
    </CaseRejectContext.Provider>
  );
};
