import {
  Paper,
  PaperProps,
  Select,
  SelectProps,
  styled,
  TableCell,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import AppColors from 'AppColors';

export const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (propName) => propName !== 'focusHighlight',
})<{ focusHighlight?: boolean }>(
  ({ theme, focusHighlight }) => `
    &:first-of-type {
      padding: ${theme.spacing(1)} ${theme.spacing(4)};
    }
    ${
      focusHighlight &&
      ` &:focus-within {
      border-bottom:none;
      outline: 1px solid ${AppColors.AAA_BLUE};
    }`
    }
    color: inherit;
    font-weight: inherit;
  `,
);

export const StyledPaper = (props: PaperProps) => <Paper {...props} square elevation={0} />;

export const StyledTextLink = styled(Typography)(() => ({
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const CustomTextField = styled(TextField, {
  shouldForwardProp: (propName) => propName !== 'inlineEditing',
})<TextFieldProps & { inlineEditing?: boolean }>(({ inlineEditing }) => ({
  ...(inlineEditing && {
    '.MuiInput-root': {
      '&:before': { borderBottom: 'none' },
      '&:after': { borderBottom: 'none' },
      '&:hover:not(.Mui-disabled):before ': { borderBottom: 'none' },
    },
  }),

  '& legend': {
    fontWeight: 600,
  },
  '& label': {
    fontWeight: 600,
    color: AppColors.AAA_BLUE,
  },
  '& fieldset': {
    borderColor: AppColors.AAA_MEDIUM_GRAY,
  },
}));

export const CustomMuiSelect = styled(Select, {
  shouldForwardProp: (propName) => propName !== 'inlineEditing',
})<SelectProps & { inlineEditing?: boolean }>(({ inlineEditing }) => ({
  ...(inlineEditing && {
    '&:before': { borderBottom: 'none' },
    '&:after': { borderBottom: 'none' },
    '&:hover:not(.Mui-disabled):before ': { borderBottom: 'none' },
  }),

  '& fieldset': {
    borderColor: AppColors.AAA_MEDIUM_GRAY,
  },
}));
