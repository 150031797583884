import { Box, Button, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Authentication from 'layouts/Authentication';
import { Link, useLocation } from 'react-router-dom';
import { routes } from 'routes';
import ResetConfirmation from './ResetConfirmation';
import EmailInput from './EmailInput';
import React, { useReducer, useState } from 'react';
import PasswordInput from './PasswordInput';
import EmailSent from './EmailSent';
import FirstLoginReset from './FirstLoginReset';

import {
  authSignIn,
  completeFirstLogin,
  completePasswordReset,
  sendPasswordResetCode,
} from 'auth/tools';
import { AWSCognitoDomains } from 'auth';
import { Amplify } from 'aws-amplify';

const domain = AWSCognitoDomains?.find((item) => item.authType === 'NON-AD');
Amplify.configure(domain?.config);

type Tag = 'firstLoginReset' | 'emailInput' | 'emailSent' | 'passwordInput' | 'confirmation';

type State = {
  emailAddress: string;
  tempPassword: string;
  password: string;
  confirmationPassword: string;
  code: string;
  activePanel: Tag;
  errorMessage?: string;
};

type RouteState = {
  userEmail: string;
  userPass: string;
  isFirstLogin: boolean;
};

type ActionType =
  | 'updateEmail'
  | 'updatePassword'
  | 'updateConfirmationPassword'
  | 'updatePanel'
  | 'updateCode'
  | 'error';
type Action = {
  type: ActionType;
  data: string;
};

type Panel = {
  actionBtnMessage: string;
  showBackToLogin: boolean;
  onAction?: (state?: State, dispatch?: React.Dispatch<Action>) => Promise<void> | void;
};

const panels: Record<Tag, Panel> = {
  firstLoginReset: {
    actionBtnMessage: 'Change Password',
    showBackToLogin: true,
    onAction: async (state, dispatch) => {
      if (!state?.emailAddress || !state.tempPassword || !state.password) {
        return dispatch?.({ type: 'updatePanel', data: 'emailInput' });
      }
      const user = await authSignIn(state.emailAddress, state.tempPassword);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await completeFirstLogin(user, state.password);
      }
      window.location.replace('/');
    },
  },
  emailInput: {
    actionBtnMessage: 'Send Code',
    showBackToLogin: true,
    onAction: async (state, dispatch) => {
      await sendPasswordResetCode(state?.emailAddress ?? '');
      dispatch?.({ type: 'updatePanel', data: 'emailSent' });
    },
  },
  emailSent: {
    actionBtnMessage: 'Change Password',
    showBackToLogin: true,
    onAction: (state, dispatch) => {
      dispatch?.({ type: 'updatePanel', data: 'passwordInput' });
    },
  },
  passwordInput: {
    actionBtnMessage: 'Reset Password',
    showBackToLogin: true,
    onAction: async (state, dispatch) => {
      await completePasswordReset(
        state?.emailAddress ?? '',
        state?.code ?? '',
        state?.password ?? '',
      );
      dispatch?.({ type: 'updatePanel', data: 'confirmation' });
    },
  },
  confirmation: {
    actionBtnMessage: 'Go To Login',
    showBackToLogin: false,
    onAction: () => {
      location.pathname = '/login';
    },
  },
};

const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'updateCode':
      return { ...state, code: action.data };

    case 'updateEmail':
      return { ...state, emailAddress: action.data };

    case 'updatePanel':
      return { ...state, activePanel: action.data as Tag, errorMessage: undefined };

    case 'updatePassword':
      return { ...state, password: action.data };

    case 'updateConfirmationPassword':
      return { ...state, confirmationPassword: action.data };

    case 'error':
      return { ...state, errorMessage: action.data };
    default:
      return state;
  }
};

const defaultState: State = {
  activePanel: 'emailInput',
  emailAddress: '',
  password: '',
  code: '',
  confirmationPassword: '',
  tempPassword: '',
};

const PasswordReset = () => {
  const location = useLocation();

  const locationState = location.state as RouteState;
  const stateInitialzer = (state: State): State =>
    locationState?.isFirstLogin
      ? {
          ...state,
          activePanel: 'firstLoginReset',
          emailAddress: locationState.userEmail,
          tempPassword: locationState.userPass,
        }
      : state;
  const [state, dispatch] = useReducer(stateReducer, defaultState, stateInitialzer);

  const { activePanel, emailAddress, errorMessage, password, confirmationPassword } = state;
  const [isLoading, setIsLoading] = useState(false);
  const setPassword = (password: string) => dispatch({ type: 'updatePassword', data: password });
  const setCode = (code: string) => dispatch({ type: 'updateCode', data: code });
  const setActivePanel = (panel: Tag) => dispatch({ type: 'updatePanel', data: panel });
  const setPasswordConfirmation = (passwordConfirmation: string) =>
    dispatch({ type: 'updateConfirmationPassword', data: passwordConfirmation });

  const setEmailAddress = (emailAddress: string) =>
    dispatch({ type: 'updateEmail', data: emailAddress });

  const panel = panels[activePanel];

  const performAction = async () => {
    setIsLoading(true);
    try {
      await panel.onAction?.(state, dispatch);
    } catch (err: any) {
      dispatch({ type: 'error', data: err.message });
    } finally {
      setIsLoading(false);
    }
  };

  const disableAction =
    isLoading ||
    (['passwordInput', 'firstLoginReset'].includes(activePanel) &&
      (confirmationPassword !== password || password.length === 0));
  return (
    <Authentication>
      {activePanel === 'emailInput' && <EmailInput onChange={setEmailAddress} />}
      {activePanel === 'emailSent' && (
        <EmailSent
          emailAddress={emailAddress}
          onCodeNavigation={() => setActivePanel('passwordInput')}
        />
      )}
      {activePanel === 'passwordInput' && (
        <PasswordInput
          onPasswordChange={setPassword}
          onCodeChange={setCode}
          onConfirmPasswordChange={setPasswordConfirmation}
        />
      )}
      {activePanel === 'confirmation' && <ResetConfirmation />}

      {activePanel === 'firstLoginReset' && (
        <FirstLoginReset
          onPasswordChange={setPassword}
          onConfirmPasswordChange={setPasswordConfirmation}
        />
      )}

      <Box display="flex" flexDirection="column" mt={3.75} gap={2}>
        <Button
          variant="contained"
          disabled={disableAction}
          sx={{ width: 444, letterSpacing: '1.25px' }}
          onClick={performAction}
        >
          {panel.actionBtnMessage}
        </Button>

        {errorMessage && (
          <Box
            sx={{
              mt: 1,
              textAlign: 'center',
              color: 'error.main',
            }}
          >
            <Typography variant="body2">{errorMessage}</Typography>
          </Box>
        )}

        {panel.showBackToLogin && (
          <Link to={routes.login} style={{ textDecoration: 'none' }}>
            <Button variant="text" fullWidth>
              <ArrowBackIcon />
              Go Back To Login
            </Button>
          </Link>
        )}
      </Box>
    </Authentication>
  );
};

export default PasswordReset;
