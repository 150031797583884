import { Box, Button } from '@mui/material';
import AppColors from 'AppColors';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props {
  expanded: boolean;
  toggleExpanded: () => void;
}

const ExpandAllButton = ({ expanded, toggleExpanded }: Props) => {
  return (
    <Box sx={{ textAlign: 'end', paddingRight: '20px' }}>
      <Button
        id="toggle-all-sections"
        startIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={toggleExpanded}
        sx={{ color: AppColors.AAA_WHITE, fontWeight: 500 }}
      >
        {expanded ? 'Collapse All' : 'Expand All'}
      </Button>
    </Box>
  );
};

export default ExpandAllButton;
