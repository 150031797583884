/**
 * This helper library is used to express spring query filters in object notation.
 * It also converts object notation into spring compatible string no
 */

import {
  Like,
  Equal,
  NotEqual,
  GreaterThan,
  GreaterThanOrEqual,
  LessThan,
  LessThanOrEqual,
  IsNull,
  IsNotNull,
  IsEmpty,
  IsNotEmpty,
  In,
  Comparator,
  Value,
} from './comparators';
import { And, Or, Not, Exists, Operator } from './operators';
import {
  Absolute,
  Average,
  Min,
  Max,
  Sum,
  CurrentDate,
  CurrentTime,
  CurrentTimestamp,
  Size,
  Length,
  Trim,
  Upper,
  Lower,
  Concat,
} from './functions';
import { QueryItem } from './item';

/**
 * Retrieves default comparator object based on value type
 * if value is array then comparator is in([])
 * else comparator falls back to '=' operator
 */
const getDefaultComparator = (value: unknown) => {
  // if value is an array then use `in` search
  if (Array.isArray(value)) {
    return QueryBuilder.in;
  }
  // fallback to exact equality
  return QueryBuilder.equal;
};

const isComparator = (item: unknown): item is Comparator => item instanceof Comparator;

const isOperator = (item: unknown): item is Operator => item instanceof Operator;

type QueryConfigValue = Value | Value[] | Comparator | Operator;

type QueryConfig = Record<string, QueryConfigValue>;

const transformQueryConfigValue = (key: string, value: QueryConfigValue) => {
  // if operator or comparator is already defined as value then use that value
  if (isComparator(value) || isOperator(value)) return value;
  // else use default comparator values
  if (Array.isArray(value)) return getDefaultComparator(value)(key, ...value);
  return getDefaultComparator(value)(key, value);
};

export class QueryBuilder {
  static and(...items: QueryItem[]) {
    return new And(...items);
  }

  static or(...items: QueryItem[]) {
    return new Or(...items);
  }

  static not(item: Operator | Comparator) {
    return new Not(item);
  }

  static exists(item: Operator | Comparator) {
    return new Exists(item);
  }

  static like(selector: string, value: string | number) {
    return new Like(selector, value);
  }

  static equal(selector: string, value: string | number) {
    return new Equal(selector, value);
  }

  static notEqual(selector: string, value: string | number) {
    return new NotEqual(selector, value);
  }

  static greaterThan(selector: string, value: string | number) {
    return new GreaterThan(selector, value);
  }

  static greaterThanOrEqual(selector: string, value: string | number) {
    return new GreaterThanOrEqual(selector, value);
  }

  static lessThan(selector: string, value: string | number) {
    return new LessThan(selector, value);
  }

  static lessThanOrEqual(selector: string, value: string | number) {
    return new LessThanOrEqual(selector, value);
  }

  static isNull(selector: string) {
    return new IsNull(selector);
  }

  static isNotNull(selector: string) {
    return new IsNotNull(selector);
  }

  static isEmpty(selector: string) {
    return new IsEmpty(selector);
  }

  static isNotEmpty(selector: string) {
    return new IsNotEmpty(selector);
  }

  static in(selector: string, ...value: Value[]) {
    return new In(selector, ...value);
  }

  static absolute(selector: string) {
    return new Absolute(selector);
  }

  static average(selector: string) {
    return new Average(selector);
  }

  static min(selector: string) {
    return new Min(selector);
  }

  static max(selector: string) {
    return new Max(selector);
  }

  static sum(selector: string) {
    return new Sum(selector);
  }

  static currentDate() {
    return new CurrentDate();
  }

  static currentTime() {
    return new CurrentTime();
  }

  static currentTimestamp() {
    return new CurrentTimestamp();
  }

  static size(selector: string) {
    return new Size(selector);
  }

  static len(selector: string) {
    return new Length(selector);
  }

  static trim(selector: string) {
    return new Trim(selector);
  }

  static upper(selector: string) {
    return new Upper(selector);
  }

  static lower(selector: string) {
    return new Lower(selector);
  }

  static concat(...selector: string[]) {
    return new Concat(...selector);
  }

  /**
   * Transforms config into defined query object.
   * If value is defined as a comparator or operator then it is used.
   * If no operator or comparator is specified then it used default comparators
   * Root operator is always `and` operation so that all filter values with be asserted together
   *
   * @param config definition of query keys and values
   * @returns converted
   */
  static from(config: QueryConfig): QueryItem | null {
    const entries = Object.entries(config);
    if (entries.length === 0) {
      return null;
    }
    return QueryBuilder.and(
      entries.map(([field, value]) => transformQueryConfigValue(field, value)),
    );
  }
}
