export function convertPhone(value?: string): string {
  if (value) {
    const { phoneNumber, extension } = getPhoneNumberAndExtension(value);

    if (extension) {
      return `${formatPhoneNumber(phoneNumber)} ${extension}`;
    } else {
      return formatPhoneNumber(phoneNumber);
    }
  }

  return '';
}

function getPhoneNumberAndExtension(input: string): {
  phoneNumber: string;
  extension: string | null;
} {
  // Remove all non-digit characters from the input string
  const phoneNumber = input.replace(/\D/g, '');

  // Check if the string contains an extension starting with 'x'
  const extensionMatch = input.match(/x\d+/i);
  const extension = extensionMatch ? extensionMatch[0] : null;

  // Remove the extension from the phone number if present
  if (extension) {
    const extensionIndex = input.indexOf(extension);
    if (extensionIndex > 0) {
      const phonePartBeforeExtension = input.substring(0, extensionIndex).replace(/\D/g, '');
      return {
        phoneNumber: phonePartBeforeExtension,
        extension,
      };
    }
  }

  return {
    phoneNumber,
    extension,
  };
}

function formatPhoneNumber(value: string): string {
  const clearNumber = ('' + value).replace(/\D/g, '');

  if (clearNumber.length === 10) {
    const match = clearNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
  } else if (clearNumber.length === 11) {
    const match = clearNumber.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + '-(' + match[2] + ') ' + match[3] + '-' + match[4];
    }
  }

  return value;
}
