import { useMemo } from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';
import { PricingSetupBaseRoutes, PricingSetupComponent, PricingSetupComponentProps } from './types';

/**
 * Props for the PricingSetupNavigator component.
 */
export interface Props {
  // Optional custom routes to override the default ones.
  routes?: Partial<PricingSetupBaseRoutes>;

  // Components to be rendered for Creator and Individual screens.
  components: Partial<{ Creator: PricingSetupComponent; Individual: PricingSetupComponent }>;
}

// Default routes for the PricingSetupNavigator component.
const routes: PricingSetupBaseRoutes = {
  CREATE_SCREEN: '/create',
  INDIVIDUAL_SCREEN: '/:dateRange',
};

/**
 * PricingSetupNavigator component.
 *
 * This component is responsible for handling the navigation
 * between the Creator and Individual screens.
 *
 * @param props - Component properties.
 * @returns JSX element.
 */
const PricingSetupNavigator: React.FC<Props> = ({ components }) => {
  // React Router's navigate function to perform navigation.
  const navigate = useNavigate();

  // Memoize the properties to be passed down to the Creator and Individual components.
  const componentProps: PricingSetupComponentProps = useMemo(
    () => ({
      navigators: {
        toIndividualScreen: (dateRange: string, params?: Record<string, string>) => {
          const queryParams = params ? `?${new URLSearchParams(params).toString()}` : '';
          navigate(`.${routes.INDIVIDUAL_SCREEN.replace(':dateRange', dateRange)}${queryParams}`);
        },
        toCreateScreen: () => {
          navigate(`.${routes.CREATE_SCREEN}`);
        },
      },
      routes,
    }),
    [navigate],
  );

  const { Creator, Individual } = components;

  return (
    <Routes>
      {Creator && <Route path={routes.CREATE_SCREEN} element={<Creator {...componentProps} />} />}

      {Individual && (
        <Route path={routes.INDIVIDUAL_SCREEN} element={<Individual {...componentProps} />} />
      )}
    </Routes>
  );
};

export default PricingSetupNavigator;
