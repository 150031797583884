/**
 *
 * @param obj1 Object2 to be merged
 * @param obj2 Object2 to be merged
 * @param indexesToSeparate Array of strings: fields that need to be get separated from the merged object
 * @returns
 */
export function getFieldsAndValuesFromMergedObjects(
  obj1: any,
  obj2: any,
  indexesToSeparate: string[],
) {
  const mergedObj = { ...obj1, ...obj2 };
  const separatedValues: Record<string, any> = {};
  const rest: Record<string, any> = {};

  for (const key in mergedObj) {
    if (indexesToSeparate.includes(key)) {
      separatedValues[key] = mergedObj[key];
    } else {
      rest[key] = mergedObj[key];
    }
  }

  return { separatedValues, ...rest };
}
