import { EventStatusDescriptions } from './config';

type EventStatus = keyof typeof EventStatusDescriptions;

export const getEventStatusDescription = (eventStatus: EventStatus | string): string => {
  if (eventStatus in EventStatusDescriptions) {
    return `${eventStatus} - ${EventStatusDescriptions[eventStatus as EventStatus]}`;
  }
  return eventStatus ?? 'Unknown status'; // Return 'Unknown status' if eventStatus is nullish
};
