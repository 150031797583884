import * as yup from 'yup';
import { DynamicField, DynamicFieldType } from 'components/Dynamic';
import { ServiceItemsData } from '../types';

const ServiceTypeOptions = [
  { label: 'Payable', value: true },
  { label: 'Non-payable', value: false },
];

export const GeneralInfoFields: DynamicField<ServiceItemsData>[] = [
  {
    label: 'Service Code',
    name: 'code',
    required: true,
  },
  {
    label: 'Service Name',
    name: 'name',
    required: true,
  },
  {
    label: 'Service Type',
    name: 'payable',
    type: DynamicFieldType.SELECT,
    select: {
      options: ServiceTypeOptions,
    },
  },
  {
    label: 'Description',
    name: 'description',
  },
];

export const GeneralInfoValidationSchema = yup.object({
  code: yup.string().required().label('Service Code'),
  name: yup.string().required().label('Service Name'),
  payable: yup.boolean().required(),
});
