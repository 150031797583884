import ClientPage from 'features/Setup/Clients';
import DashboardPage from 'features/Dashboard';
import InvoicesPage from 'features/Invoices';
import PasswordReset from 'features/PasswordReset';
import PaymentRequestPage from 'features/PaymentRequest';
import ProgramPage from 'features/Setup/Programs/ProgramPage';
import ServiceItemsPage from 'features/Setup/ServiceItems';
import VendorPage from 'features/Setup/Vendors';
import { CasePage } from 'features/Cases';
import { EditQuery } from 'features/EditQuery';
import { Login } from 'features/Login';
import { Navigate, PathRouteProps } from 'react-router-dom';
import { NoPermissions } from 'features/NoPermissions';
import { PublicMatchEndRoute } from 'auth';
import { CreditCardPage } from 'features/CreditCards';
import PaymentForecastsPage from 'features/PaymentForecasts';
import ReportsPage from 'features/Reports';

export interface PrivatePathRouteProps extends PathRouteProps {
  notAllowedRoles?: string[];
}

// place route here so that they can be referenced by their handle
export const routes = {
  index: '/',
  login: '/login',
  resetPassword: '/reset-password',
  casePage: '/cases',
  editQuery: '/edit-query',
  noPermissions: '/no-permissions',
  setup: {
    clients: '/setup/clients',
    vendors: '/setup/vendors',
    serviceItems: '/setup/service-items',
    programs: '/setup/clients/:clientUUID/programs',
  },
  dashboard: '/dashboard',
  billRequests: '/bill-requests',
  paymentRequests: '/payment-requests',
  paymentForecasts: '/payment-forecasts',
  creditCards: '/credit-cards',
  reports: '/reports',
};

// add route definitions here
export const privateRoutes: PrivatePathRouteProps[] = [
  {
    path: routes.index,
    element: <Navigate to={routes.dashboard} replace={true} />,
  },
  {
    // This route is used to redirect to the dashboard when the user is logged in.
    // Needed to prevent errors for users who bookmark the login page.
    path: routes.login,
    element: <Navigate to={routes.dashboard} replace={true} />,
  },
  {
    path: `${routes.casePage}/*`,
    element: <CasePage />,
  },

  {
    path: `${routes.editQuery}/:id`,
    element: <EditQuery></EditQuery>,
  },
  {
    path: `${routes.setup.clients}/*`,
    element: <ClientPage />,
    notAllowedRoles: ['NATIONAL_CUSTOMER_RELATION', 'CLUB_ADMIN', 'CLUB_RAP_RECONCILER_ANALYST'],
  },
  {
    path: `${routes.setup.vendors}/*`,
    element: <VendorPage />,
    notAllowedRoles: ['NATIONAL_CUSTOMER_RELATION', 'CLUB_ADMIN', 'CLUB_RAP_RECONCILER_ANALYST'],
  },
  {
    path: `${routes.setup.serviceItems}/*`,
    element: <ServiceItemsPage />,
    notAllowedRoles: ['NATIONAL_CUSTOMER_RELATION', 'CLUB_ADMIN', 'CLUB_RAP_RECONCILER_ANALYST'],
  },
  {
    path: `${routes.setup.programs}/*`,
    element: <ProgramPage />,
    notAllowedRoles: ['NATIONAL_CUSTOMER_RELATION', 'CLUB_ADMIN', 'CLUB_RAP_RECONCILER_ANALYST'],
  },
  {
    path: `${routes.dashboard}/*`,
    element: <DashboardPage />,
  },
  {
    path: `${routes.billRequests}/*`,
    element: <InvoicesPage />,
    notAllowedRoles: ['NATIONAL_CUSTOMER_RELATION', 'CLUB_ADMIN', 'CLUB_RAP_RECONCILER_ANALYST'],
  },
  {
    path: `${routes.paymentRequests}/*`,
    element: <PaymentRequestPage />,
    notAllowedRoles: ['NATIONAL_CUSTOMER_RELATION', 'CLUB_ADMIN', 'CLUB_RAP_RECONCILER_ANALYST'],
  },
  {
    path: `${routes.paymentForecasts}/*`,
    element: <PaymentForecastsPage />,
    notAllowedRoles: ['NATIONAL_CUSTOMER_RELATION', 'CLUB_ADMIN', 'CLUB_RAP_RECONCILER_ANALYST'],
  },
  {
    path: `${routes.reports}/*`,
    element: <ReportsPage />,
    notAllowedRoles: ['NATIONAL_CUSTOMER_RELATION', 'CLUB_ADMIN', 'CLUB_RAP_RECONCILER_ANALYST'],
  },
  {
    path: `${routes.creditCards}/*`,
    element: <CreditCardPage />,
    notAllowedRoles: ['CLUB_ADMIN', 'CLUB_RAP_RECONCILER_ANALYST'],
  },
  {
    path: routes.noPermissions,
    element: <NoPermissions />,
  },
];

export const publicRoutes: PathRouteProps[] = [
  {
    path: routes.index,
    element: <Navigate to={routes.login} replace={true} />,
  },
  {
    path: routes.login,
    element: <Login />,
  },
  {
    path: routes.resetPassword,
    element: <PasswordReset />,
  },
  {
    path: routes.noPermissions,
    element: <NoPermissions />,
  },
  {
    path: '*',
    element: <PublicMatchEndRoute />,
  },
];
