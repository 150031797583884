import React, { startTransition, useCallback, useContext, useEffect, useMemo } from 'react';
import ErrorsAndCommentsEditor from './ErrorsAndCommentsEditor';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { LoaderCard } from 'components/Cards/LoaderCard';
import { ErrorCard } from 'components/Cards/ErrorCard';
import { DynamicExpandablePanel } from 'components/Dynamic/DynamicExpandablePanel';
import {
  useGetCaseErrorsByUUID,
  useGetCaseCommentsByUUID,
  useGetCaseSmsByUUID,
} from 'features/Cases/queries/manageCaseQueries';
import { CaseDetailsContext } from 'features/Cases/contexts/CaseDetails';
import { AuthComponent } from 'auth/permissions/authComponent';
import { CASE_COMMENTS_PERMISSION } from './config';
import SkeletonLoader from 'components/SkeletonLoader';
import { useLocation, useNavigate } from 'react-router-dom';
import { RbpCaseStatus } from 'core/enums/RbpCaseStatus';

interface Props {
  caseUUID: string;
  rbpCaseStatus: RbpCaseStatus;
}

const ErrorsAndComments = ({ caseUUID, rbpCaseStatus }: Props) => {
  const { allSectionsExpanded } = useContext(CaseDetailsContext);
  const { search } = useLocation();
  const navigate = useNavigate();
  const openDefaultErrorPanel = !!new URLSearchParams(location.search).get('openDefaultErrorPanel');
  const [expanded, setExpanded] = React.useState<boolean>(
    () => allSectionsExpanded || openDefaultErrorPanel,
  );

  const clearOpenDefaultErrorPanelUrlParam = useCallback(() => {
    const searchParams = new URLSearchParams(search);
    searchParams.delete('openDefaultErrorPanel');
    navigate({ search: searchParams.toString() });
  }, [navigate, search]);

  useEffect(() => {
    if (allSectionsExpanded && openDefaultErrorPanel) {
      clearOpenDefaultErrorPanelUrlParam();
    }

    setExpanded(allSectionsExpanded);
  }, [allSectionsExpanded, clearOpenDefaultErrorPanelUrlParam, openDefaultErrorPanel]);

  useEffect(() => {
    startTransition(() => {
      const shouldExpand = openDefaultErrorPanel && !expanded;
      if (shouldExpand) {
        setExpanded(shouldExpand);
      }
    });
  }, [expanded, openDefaultErrorPanel, search]);

  const onExpanded = (isExpanded: boolean) => () => {
    if (openDefaultErrorPanel) {
      clearOpenDefaultErrorPanelUrlParam();
    }

    setExpanded(!isExpanded);
  };

  const {
    isLoading: isLoadingCaseErrors,
    data: caseErrorsData,
    isSuccess: isSuccessCaseErrors,
    isError: isCaseErrorsError,
    isFetching: isFetchingCaseErrors,
  } = useGetCaseErrorsByUUID(caseUUID, expanded);

  const {
    isLoading: isLoadingComments,
    data: commentsData,
    isSuccess: isSuccessComments,
    isError: isCommentsError,
    isFetching: isFetchingComments,
  } = useGetCaseCommentsByUUID(caseUUID, expanded);

  const {
    isLoading: isLoadingSms,
    data: smsData,
    isSuccess: isSuccessSms,
    isError: isSmsError,
    isFetching: isFetchingSms,
  } = useGetCaseSmsByUUID(caseUUID, expanded);

  const isLoading = useMemo(() => {
    return isLoadingCaseErrors && isLoadingComments && isLoadingSms;
  }, [isLoadingCaseErrors, isLoadingComments, isLoadingSms]);

  const isSuccess = useMemo(() => {
    return isSuccessCaseErrors && isSuccessComments;
  }, [isSuccessCaseErrors, isSuccessComments]);

  const isError = useMemo(() => {
    return isCaseErrorsError || isCommentsError;
  }, [isCaseErrorsError, isCommentsError]);

  const isFetching = useMemo(() => {
    return isFetchingCaseErrors || isFetchingComments || isFetchingSms;
  }, [isFetchingCaseErrors, isFetchingComments, isFetchingSms]);

  const isRunning = isFetching || isLoading;

  return (
    <DynamicExpandablePanel
      data-testid="CaseErrorsAndCommentsPanel"
      title="Case Errors &amp; Comments"
      icon={<FeedbackIcon />}
      onExpanded={onExpanded(expanded)}
      expanded={expanded}
    >
      {isRunning && <LoaderCard label="Loading Case Errors &amp; Comments" />}

      {isError && (
        <ErrorCard label="An Error Has Occurred While Retrieving Case Errors &amp; Comments" />
      )}

      {isSuccess && (
        <ErrorsAndCommentsEditor
          errorsData={caseErrorsData}
          commentsData={commentsData}
          smsData={smsData}
          caseUuid={caseUUID}
          rbpCaseStatus={rbpCaseStatus}
        />
      )}
    </DynamicExpandablePanel>
  );
};

export default AuthComponent(
  CASE_COMMENTS_PERMISSION,
  ErrorsAndComments,
  <SkeletonLoader loading height={66} variant="rectangular" />,
);
