import { InputAdornment } from '@mui/material';
import { DynamicField, DynamicFieldType, DynamicValue } from 'components/Dynamic/DynamicForm';
import * as Yup from 'yup';

export const billingRequestAdjustmentFields: DynamicField<DynamicValue>[] = [
  {
    name: 'amount',
    label: 'Adjustment Amount',
    colspan: 3,
    type: DynamicFieldType.TEXT,
    required: true,
    endAdornment: <InputAdornment position="end">$</InputAdornment>,
    startNewRow: true,
  },
  {
    name: 'itemNumber',
    label: 'Item Number',
    colspan: 3,
    type: DynamicFieldType.TEXT,
    required: true,
  },
  {
    name: 'description',
    label: 'Description',
    colspan: 12,
    type: DynamicFieldType.TEXT,
    required: true,
  },
];

export const billingRequestAdjustmentFieldsDefaults: DynamicField<DynamicValue>[] = [
  {
    name: 'number',
    label: 'Bill Request Number',
    colspan: 3,
    type: DynamicFieldType.TEXT,
    isReadOnly: true,
  },
  {
    name: 'customerNumber',
    label: 'Customer Number',
    colspan: 3,
    type: DynamicFieldType.TEXT,
    isReadOnly: true,
  },
  {
    name: 'poNumber',
    label: 'PO Number',
    colspan: 3,
    type: DynamicFieldType.TEXT,
    isReadOnly: true,
  },
  {
    name: 'billingPeriodRange',
    label: 'Billing Period Range',
    colspan: 3,
    type: DynamicFieldType.RANGE_DATE,
    isReadOnly: true,
  },
];

export const billingRequestAdjustmentValidationSchema = Yup.object().shape({
  amount: Yup.number()
    .required('Adjustment Amount is required')
    .typeError('Adjustment Amount must be a number'),
  itemNumber: Yup.string().required('Item Number is required'),
  description: Yup.string().required('Description is required'),
});
