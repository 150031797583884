import { Box } from '@mui/material';
import { memo, PropsWithChildren } from 'react';

const LoginContent = ({ children }: PropsWithChildren) => {
  return (
    <Box display="flex" flexDirection="column" mt={1} gap={2} width={446}>
      {children}
    </Box>
  );
};

export default memo(LoginContent);
