export interface VendorDto extends PayableUuidDto {
  rapVendorId?: string;
  mergeDate?: string;
  type: VendorType;
  name: string;
  callCenterPhoneNumber: string;
  primaryVendorContactFirstName: string;
  primaryVendorContactLastName: string;
  primaryVendorContactPhoneNumber: string;
  primaryEmailAddress: string;
  secondaryVendorContactFirstName: string;
  secondaryVendorContactLastName: string;
  secondaryVendorContactPhoneNumber: string;
  secondaryEmailAddress: string;

  client?: Client;
  program?: Program;
  mergedClub?: Vendor;
}

export interface Client extends PayableUuidEntity {
  rapClientId: number;
  abbreviatedName: string;
  rapAccountExecutive: string;
  contractStartDateTime: string;
  contractEndDateTime: string;
  address: string;
  phone: string;
}

export interface Program extends BaseUuidEntity {
  code: string;
  name: string;
  abbreviatedName: string;
  merchantId: string;
  rapProgramId: number;
  oem: boolean;
  warrantyVinRequired: boolean;
  poRequired: boolean;
  poNumber: string;
  poEffectiveDateTime: string;
  poExpirationDateTime: string;
  rapProgramCode: number;
  client: Client;
}

export interface Vendor extends PayableUuidEntity {
  mergeDate: string;
  canadianIndicator: boolean;
  contactName: string;
  contactPhone: string;
  emailAddress: string;
  rapVendorId: number;
  client: Client;
  program: Program;
  mergedVendor: Vendor;
  type: VendorType;
}

export interface PayableUuidDto extends BaseUuidEntityDto {
  name: string;
  jdExternalId: string;
}

export interface PayableUuidEntity extends BaseUuidEntity {
  name: string;
  jdExternalId: string;
}

export interface BaseUuidEntity {
  uuid: string;
}

export interface BaseUuidEntityDto {
  uuid: string;
}

export type VendorType = 'CLUB' | 'EXTERNAL_CLUB' | 'THIRD_PARTY';
export const VendorTypeOptions: VendorType[] = ['CLUB', 'EXTERNAL_CLUB', 'THIRD_PARTY'];
