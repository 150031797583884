import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import DialogConfirmation from 'components/DialogConfirmation';
import { DynamicForm } from 'components/Dynamic/DynamicForm';
import { useDomainAuth } from 'auth';
import { useClaimOverrideConfig, useUpdateCaseClaim } from '../useClaims';

interface ClaimItemOverrideDialogProps {
  caseUuid: string;
  claimItemUuid: string;
  rapCaseId: string;
  clientId: number;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onConfirmOverrideClaim?: () => void;
}

export default function ClaimItemOverrideDialog({
  visible,
  setVisible,
  caseUuid,
  claimItemUuid,
  rapCaseId,
  clientId,
  onConfirmOverrideClaim,
}: ClaimItemOverrideDialogProps) {
  const { isError, mutateAsync, errorMessage, reset } = useUpdateCaseClaim(
    caseUuid!,
    claimItemUuid,
    clientId,
  );
  const { user } = useDomainAuth();
  const { claimOverrideFields, claimOverrideValidationSchema } = useClaimOverrideConfig();

  const hookForm = useForm({
    resolver: yupResolver(claimOverrideValidationSchema),
    mode: 'onChange',
  });

  const handleSubmit = async () => {
    await hookForm.trigger();
    if (hookForm.formState.isValid && user) {
      mutateAsync(hookForm.getValues())
        .then(() => {
          setVisible(false);
          onConfirmOverrideClaim?.();
        })
        .catch();
    }
  };

  useEffect(() => {
    if (visible) {
      reset();
      hookForm.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const onClose = () => {
    reset();
    hookForm.reset();
    setVisible(false);
  };

  const isNotValid = !hookForm.formState.isValid;

  return (
    <DialogConfirmation
      open={visible}
      title={`Override Case ID ${rapCaseId}`}
      onClose={onClose}
      onConfirm={handleSubmit}
      labelConfirm="Submit"
      labelCancel="Cancel"
      disableConfirmLoader={isNotValid}
      isError={isError || isNotValid}
      content={`Are you sure you want to override Case ID ${rapCaseId}?`}
      sx={{
        '& .MuiDialog-paper': {
          width: '443px',
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Stack spacing={0} sx={{ padding: '32px 0 0' }}>
          <DynamicForm hookForm={hookForm} isEditMode fields={claimOverrideFields} rowSpacing={3} />
        </Stack>
        {isError && (
          <Typography color="error" mt={2}>
            An Error Has Occurred While Override Claim:
            {errorMessage}
          </Typography>
        )}
      </Box>
    </DialogConfirmation>
  );
}
