import {
  ProgramDto,
  ManageProgramApi,
  fetchProgramBillingConfig,
  BillingConfigurationProgramDto,
  addProgramBillingConfig,
  updateProgramBillingConfig,
  deleteProgramByUUID,
  fetchPrograms,
} from 'api/setup/manageProgramsApi';
import {
  CRUDPageableReactQuery,
  useManagedMutation,
  usePageableReactQuery,
} from 'hooks/useReactQueryHelpers';
import { SurveyTypes } from 'api/setup/manageClientApi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { BillTypes } from 'api/setup/manageBillingInfoApi';
import { GetAllParam } from 'api/helper/PageableCRUDApi';
import { useProgramContext } from './ProgramProvider';

const QUERY_PROGRAM_BILLING_CONFIG = 'QUERY_PROGRAM_BILLING_CONFIG';
const QUERY_PROGRAM = Symbol('QUERY_PROGRAM');
const QUERY_PROGRAM_KEY_ALL = `${String(QUERY_PROGRAM)}_KEY_ALL`;
const QUERY_PROGRAM_ONLY = 'QUERY_PROGRAM_ONLY';

export interface BillingConfigurationProgramData {
  uuid?: string;
  programUuid: string;
  billType: BillTypes;
  poRequired: boolean;
  poNumber: string;
  poEffectiveDates?: string[];
  costCenterAccountNumber: string;
  revenueShareAccountNumber: string;
  holdingAccountNumberRevenue: string;
  holdingAccountNumberCost: string;
  addressAccountNumber: string;
  billingRequestItemNumber: string;
  rapAccountingItemNumber: string;
  manualAdjustmentRevenueExpenseItemNumber: string;
  manualAdjustmentContractorExpenseItemNumber: string;
  prePaidEventItemNumber: string;
  allowSplitPay: boolean;
  splitPayRapProgramCode: string;
  allowPrePay: boolean;
  outOfWarrantyProgramToRoute: string;
}

export interface ProgramData {
  poEffectiveDateTime: string;
  poExpirationDateTime: string;
  poRequired?: boolean;
  poNumber?: string;
  abbreviatedName?: string;
  name?: string;
  uuid: string;
  clientUUID?: string;
  code?: string;
  rapClientId?: number;
  clientName?: string;
  rapProgramId: number;
  programName?: string;
  rapProgramCode: number;
  survey?: boolean;
  surveyType?: SurveyTypes;
  surveyAdministrator?: string;
  contactCenterPartner?: string;
  customerCallNumber?: string;
  aaaAssignedDid?: string;
  clubCallInNumber?: string;
  billType?: BillTypes;
}

function mapDtoToData(dto: ProgramDto): ProgramData {
  return {
    uuid: dto.uuid,
    programName: dto.name,
    abbreviatedName: dto.abbreviatedName,
    code: dto.code,
    rapProgramCode: dto.rapProgramCode,
    clientName: dto.client?.name || '',
    clientUUID: dto.client?.uuid || '',
    rapClientId: dto.client?.rapClientId,
    rapProgramId: dto.rapProgramId,
    survey: dto.survey,
    surveyType: dto.surveyType,
    surveyAdministrator: dto.surveyAdministrator,
    poEffectiveDateTime: dto.poEffectiveDateTime,
    poExpirationDateTime: dto.poExpirationDateTime,
    contactCenterPartner: dto.contactCenterPartner,
    customerCallNumber: dto.customerCallNumber,
    clubCallInNumber: dto.clubCallInNumber,
    aaaAssignedDid: dto.aaaAssignedDid,
    billType: dto.billType,
  };
}

function mapDataToDto(data: ProgramData): ProgramDto {
  return {
    uuid: data.uuid,
    name: data.programName,
    code: data.code,
    rapProgramCode: data.rapProgramCode,
    rapProgramId: data.rapProgramId,
    client: {
      uuid: data.clientUUID,
    },
    poEffectiveDateTime: data.poEffectiveDateTime,
    poExpirationDateTime: data.poExpirationDateTime,
    survey: data.survey,
    surveyType: data.surveyType,
    surveyAdministrator: data.surveyAdministrator,
    contactCenterPartner: data.contactCenterPartner,
    customerCallNumber: data.customerCallNumber,
    clubCallInNumber: data.clubCallInNumber,
    aaaAssignedDid: data.aaaAssignedDid,
  };
}

function mapBillingDtoToData(
  dto: BillingConfigurationProgramDto[],
): Partial<BillingConfigurationProgramData> {
  const [firstDto] = dto;

  return {
    ...firstDto,
    poEffectiveDates: [firstDto.poEffectiveDate || '', firstDto.poExpirationDate || ''],
    poNumber: firstDto.poNumber || '',
    billingRequestItemNumber: firstDto.billingRequestItemNumber || '',
    prePaidEventItemNumber: firstDto.prePaidEventItemNumber || '',
    rapAccountingItemNumber: firstDto.rapAccountingItemNumber?.toString() || '',
  };
}

function extractDateFromTimestamp(timestamp: string) {
  if (!timestamp) {
    return null;
  }
  return new Date(timestamp).toISOString().split('T')[0];
}

function mapBillingDataToDto(
  data: BillingConfigurationProgramData,
): BillingConfigurationProgramDto {
  const [poEffectiveDate, poExpirationDate] = data.poEffectiveDates || [];
  const formattedPoEffectiveDate = extractDateFromTimestamp(poEffectiveDate);
  const formattedPoExpirationDate = extractDateFromTimestamp(poExpirationDate);

  return {
    ...data,
    poEffectiveDate: formattedPoEffectiveDate,
    poExpirationDate: formattedPoExpirationDate,
    poNumber: data.poNumber === '' ? null : data.poNumber,
    billingRequestItemNumber:
      data.billingRequestItemNumber === '' ? null : data.billingRequestItemNumber,
    prePaidEventItemNumber: data.prePaidEventItemNumber === '' ? null : data.prePaidEventItemNumber,
    rapAccountingItemNumber:
      data.rapAccountingItemNumber === '' ? null : parseFloat(data.rapAccountingItemNumber),
  };
}

export const ProgramQuery = CRUDPageableReactQuery(
  QUERY_PROGRAM,
  ManageProgramApi,
  mapDtoToData,
  mapDataToDto,
);

export function useGetProgramBillingConfig(programUuid: string) {
  return useQuery<
    BillingConfigurationProgramDto[],
    unknown,
    Partial<BillingConfigurationProgramData>
  >([QUERY_PROGRAM_BILLING_CONFIG, programUuid], () => fetchProgramBillingConfig(programUuid), {
    select: mapBillingDtoToData,
    enabled: !!programUuid,
  });
}

export function useGetPrograms(params: GetAllParam) {
  return usePageableReactQuery<ProgramDto, ProgramData>(
    QUERY_PROGRAM_ONLY,
    fetchPrograms,
    {
      keepPreviousData: true,
      enabled: true,
      refetchOnMount: true,
      mapper: mapDtoToData,
    },
    params,
  );
}

export function useCreateProgramBillingConfig(programUuid: string) {
  const queryClient = useQueryClient();
  const {
    queries: { useUpdateByUUID, useFetchByUUID },
  } = useProgramContext();
  const { data: programData } = useFetchByUUID(programUuid);
  const { mutateAsync: updateProgramMutateAsync } = useUpdateByUUID(programUuid);

  return useMutation(
    async (data: BillingConfigurationProgramData) => {
      if (programData?.allowSplitPay !== data.allowSplitPay) {
        await updateProgramMutateAsync({
          ...programData,
          allowSplitPay: data.allowSplitPay,
        } as ProgramData);
      }

      return addProgramBillingConfig(programUuid, mapBillingDataToDto(data));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_PROGRAM_BILLING_CONFIG]);
      },
    },
  );
}

export function useUpdateProgramBillingConfig(programUuid: string) {
  const queryClient = useQueryClient();
  const {
    queries: { useUpdateByUUID, useFetchByUUID },
  } = useProgramContext();
  const { data: programData } = useFetchByUUID(programUuid);
  const { mutateAsync: updateProgramMutateAsync } = useUpdateByUUID(programUuid);

  return useMutation(
    async (data: BillingConfigurationProgramData) => {
      if (programData?.allowSplitPay !== data.allowSplitPay) {
        await updateProgramMutateAsync({
          ...programData,
          allowSplitPay: data.allowSplitPay,
        } as ProgramData);
      }

      return updateProgramBillingConfig(programUuid, data?.uuid || '', mapBillingDataToDto(data));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_PROGRAM_BILLING_CONFIG]);
      },
    },
  );
}

export const useGetDefaultProgramBillingInfoData = (): BillingConfigurationProgramData => ({
  billType: BillTypes.PER_EVENT,
  poRequired: false,
  poNumber: '',
  poEffectiveDates: [],
  costCenterAccountNumber: '',
  revenueShareAccountNumber: '',
  holdingAccountNumberRevenue: '',
  holdingAccountNumberCost: '',
  addressAccountNumber: '',
  billingRequestItemNumber: '',
  prePaidEventItemNumber: '',
  rapAccountingItemNumber: '',
  manualAdjustmentRevenueExpenseItemNumber: '',
  manualAdjustmentContractorExpenseItemNumber: '',
  allowSplitPay: false,
  splitPayRapProgramCode: '',
  allowPrePay: false,
  outOfWarrantyProgramToRoute: '',
  programUuid: '',
});

export function useDeleteProgramByUUID() {
  const queryClient = useQueryClient();
  return useManagedMutation({
    mutationFn: (programUUID: string) => deleteProgramByUUID(programUUID),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_PROGRAM_KEY_ALL]);
    },
  });
}
