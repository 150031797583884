import { CRUDComponent } from 'components/CRUDNavigator';
import EntityCreationFlow, { EntityCreationFlowProvider } from 'components/EntityCreationFlow';
import { VendorCreationFormSteps } from './VendorCreation/config';

const VendorPageCreator: CRUDComponent = ({ navigators }) => {
  return (
    <EntityCreationFlowProvider>
      <EntityCreationFlow steps={VendorCreationFormSteps} navigators={navigators} />
    </EntityCreationFlowProvider>
  );
};

export default VendorPageCreator;
