import Paper from '@mui/material/Paper';
import Stack from '@mui/system/Stack';
import { ReactNode } from 'react';
import { SxProps, Theme } from '@mui/material/styles';

interface Props {
  label: ReactNode;
  color?: string;
  bgcolor: string;
  borderColor?: string;
  sx?: SxProps<Theme>;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  dataTestId?: string;
}
export function StatusChip({
  label,
  color,
  bgcolor,
  borderColor,
  sx,
  onClick,
  startIcon,
  endIcon,
  dataTestId,
}: Props) {
  return (
    <Paper
      onClick={onClick}
      elevation={0}
      data-testid={dataTestId ?? 'chip'}
      sx={{
        padding: 0.5,
        bgcolor,
        color,
        display: 'flex',
        width: 'fit-content',
        alignItems: 'center',
        border: borderColor ? '1px solid transparent' : 'none',
        borderColor,
        ...sx,
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        {startIcon}
        <span>{label}</span>
        {endIcon}
      </Stack>
    </Paper>
  );
}
