import { DynamicActions } from 'components/Dynamic';
import { PropsWithChildren } from 'react';
import BreadcrumbHeader, { Breadcrumb } from '../BreadCrumbHeader';
import RecordDetailNavigator from './RecordNavigator';
import { SubMenuItem } from 'components/SubMenuButton/SubMenuButton';

type Props = {
  breadcrumbs: Breadcrumb[];
  hasNext?: boolean;
  hasPrevious?: boolean;
  onNext?: () => void;
  onPrevious?: () => void;
  centralPanel?: React.ReactNode;
  headerPanel?: React.ReactNode;
  label: string;
  visible?: boolean;
  subMenuActions?: SubMenuItem[];
};

export default function RecordDetailHeader({
  breadcrumbs,
  onNext,
  onPrevious,
  hasNext,
  hasPrevious,
  centralPanel,
  label,
  headerPanel,
  visible = false,
  subMenuActions,
  ...rest
}: PropsWithChildren<Props>) {
  return (
    <BreadcrumbHeader breadcrumbs={breadcrumbs} headerPanel={headerPanel} {...rest}>
      <DynamicActions
        isEditMode={false}
        leftPanel={
          <RecordDetailNavigator
            label={label}
            hasNext={hasNext}
            hasPrevious={hasPrevious}
            onNext={onNext}
            onPrevious={onPrevious}
          />
        }
        centralPanel={centralPanel}
        visible={visible}
        subMenuActions={subMenuActions}
      />
    </BreadcrumbHeader>
  );
}
