import PaymentRequestClubDetail from './PaymentRequestClubDetail';
import PaymentRequestDetail from './PaymentRequestDetail';
import PaymentRequestList from './PaymentRequestList';
import { CRUDNavigator } from 'components/CRUDNavigator';

export default function PaymentRequestPage() {
  return (
    <CRUDNavigator
      components={{
        Main: PaymentRequestList,
        Individual: PaymentRequestDetail,
        Detail: PaymentRequestClubDetail,
      }}
    />
  );
}
