export function getIdsFromStorage(key: string): string[] {
  const storageKey = `DetailNavigator${key}`;
  const savedItems = sessionStorage.getItem(storageKey);

  if (savedItems) {
    try {
      return JSON.parse(savedItems);
    } catch (error) {
      console.error(
        'Error on trying to convert saved navigator items for key ' + storageKey,
        error,
      );
    }
  }

  return [];
}

export function setIdsToFromStorage(key: string, items: string[]): void {
  const storageKey = `DetailNavigator${key}`;
  sessionStorage.setItem(storageKey, JSON.stringify(items));
}
