import { Grid, Stack } from '@mui/material';
import AppColors from 'AppColors';
import clsx from 'clsx';
import SummaryCard from 'components/SummaryCard';
import { Link, createSearchParams } from 'react-router-dom';
import { routes } from 'routes';
import styles from './../Dashboard.module.css';
import ErrorIcon from '@mui/icons-material/Error';
import { ReactComponent as CreditCardIcon } from 'assets/credit-card-filled.svg';
import { ReactComponent as CheckCircleIcon } from 'assets/check-circle.svg';
import { ReactComponent as CancelCircleIcon } from 'assets/cancel-circle.svg';
import { ReactComponent as CreditCardSectionIcon } from 'assets/credit-card.svg';
import { LoaderCard } from 'components/Cards';
import { DashboardSectionHeader } from '../components';
import { useCreditCardsSummaryQuery } from './CreditCardsSummaryQuery';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';

const CreditCardsSummaryCards = () => {
  const { errorCounts: data, isLoading } = useCreditCardsSummaryQuery();
  const { permissions: creditCardsSummaryPermissions } = useRapCoreFeaturePermissions(
    'dashboardCreditCardsSummary',
  );

  return isLoading ? (
    <Stack flexGrow={1}>
      <LoaderCard label="Loading Credit Cards Errors..." sx={{ flexGrow: 1, padding: 2 }} />
    </Stack>
  ) : (
    <Stack gap={1.2}>
      {/* HEADER */}
      <DashboardSectionHeader label="Credit Cards" icon={<CreditCardSectionIcon />} />

      {/* CARDS */}
      <Grid container spacing={2}>
        {creditCardsSummaryPermissions?.read && (
          <>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <Link
                className={styles.cardContainer}
                to={`${routes.creditCards}?${createSearchParams({
                  filters: data.total?.appliedFilter,
                })}`}
              >
                <SummaryCard
                  className={clsx(styles.summaryCard, styles.darkRedStreak)}
                  icon={
                    <span
                      className={clsx(
                        styles.summaryIcon,
                        styles.summaryBigIcon,
                        styles.bgRedOpaque,
                      )}
                    >
                      <ErrorIcon sx={{ color: AppColors.AAA_SANGRIA }} />
                    </span>
                  }
                  label="Credit Card Errors"
                  value={data.total?.count}
                />
              </Link>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <Link
                className={styles.cardContainer}
                to={`${routes.creditCards}?${createSearchParams({
                  filters: data.inQueue?.appliedFilter,
                  orderBy: 'createdAtDateTime',
                  order: 'desc',
                })}`}
              >
                <SummaryCard
                  className={clsx(styles.summaryCard, styles.orangeStreak)}
                  icon={
                    <span className={clsx(styles.summaryIcon, styles.bgOrangeOpaque)}>
                      <CreditCardIcon color={AppColors.AAA_JAFFA} />
                    </span>
                  }
                  label="Credit Card Queue"
                  value={data.inQueue?.count}
                />
              </Link>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <Link
                className={styles.cardContainer}
                to={`${routes.creditCards}?${createSearchParams({
                  filters: data.settledYesterday?.appliedFilter,
                })}`}
              >
                <SummaryCard
                  className={clsx(styles.summaryCard, styles.greenStreak)}
                  icon={
                    <span className={clsx(styles.summaryIcon, styles.bgGreenOpaque)}>
                      <CheckCircleIcon color={AppColors.AAA_DEEP_GREEN} />
                    </span>
                  }
                  label="Settled Yesterday"
                  value={data.settledYesterday?.count}
                />
              </Link>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <Link
                className={styles.cardContainer}
                to={`${routes.creditCards}?${createSearchParams({
                  filters: data.failedToSettleYesterday?.appliedFilter,
                })}`}
              >
                <SummaryCard
                  className={clsx(styles.summaryCard, styles.yellowStreak)}
                  icon={
                    <span className={clsx(styles.summaryIcon, styles.bgYellowOpaque)}>
                      <CancelCircleIcon color={AppColors.AAA_YELLOW} />
                    </span>
                  }
                  label="Failed to Settle Yesterday"
                  value={data.failedToSettleYesterday?.count}
                />
              </Link>
            </Grid>
          </>
        )}
      </Grid>
    </Stack>
  );
};

export default CreditCardsSummaryCards;
