/* eslint-disable @typescript-eslint/no-explicit-any */
import { PageableCRUDApi } from 'api/helper/PageableCRUDApi';
import { useMutation, useQueryClient } from 'react-query';
import {
  CreateMethod,
  CRUDPageableReactQueryType,
  DeleteByUUIDMethod,
  FetchAllMethod,
  FetchByUUIDMethod,
  UpdateByUUIDMethod,
  UploadMethod,
} from './types';
import { useMappedReactQuery, usePageableReactQuery } from '.';

export function CRUDPageableReactQuery<DtoType, DataType = DtoType>(
  baseKey: string | symbol,
  modelApi: PageableCRUDApi<DtoType>,
  mapDtoToData: (dto: DtoType) => DataType = (data: any) => data,
  mapDataToDto: (dto: DataType) => DtoType = (data: any) => data,
): CRUDPageableReactQueryType<DtoType, DataType> {
  const baseKeyString = baseKey.toString();
  const KEY_ALL = baseKeyString + '_KEY_ALL';
  const KEY_BYID = baseKeyString + '_KEY_BYID';
  const KEY_CREATE = baseKeyString + '_KEY_CREATE';
  const KEY_UPDATE = baseKeyString + '_KEY_UPDATE';
  const KEY_DELETE = baseKeyString + '_KEY_UPDATE';
  const KEY_UPLOAD_LOGO = baseKeyString + '_KEY_UPLOAD_LOGO';

  const useFetchAll: FetchAllMethod<DtoType, DataType> = (params, options) => {
    const queryClient = useQueryClient();

    return usePageableReactQuery(
      KEY_ALL,
      modelApi.fetchAll,
      {
        keepPreviousData: true,
        enabled: options?.enabled,
        refetchOnMount: options?.refetchOnMount || true,
        mapper: mapDtoToData,
        onSuccess: () => {
          queryClient.invalidateQueries([KEY_BYID]);
        },
      },
      params,
    );
  };

  const useFetchByUUID: FetchByUUIDMethod<DtoType, DataType> = (uuid = '') => {
    return useMappedReactQuery(
      KEY_BYID,
      modelApi.fetchByUUID,
      {
        enabled: !!uuid,
        keepPreviousData: false,
        mapper: mapDtoToData,
      },
      uuid,
    );
  };

  const useInvalidateAll = () => {
    const queryClient = useQueryClient();
    return () => {
      queryClient.resetQueries();
    };
  };

  const useClearCache = () => {
    const queryClient = useQueryClient();
    return () => {
      queryClient.clear();
    };
  };

  const useUploadLogo: UploadMethod<DtoType, DataType> = () => {
    return useMutation(
      async (data: DataType) => {
        const { logoUrl: file, uuid } = data as any;
        return modelApi.uploadLogo(uuid, file);
      },
      { mutationKey: KEY_UPLOAD_LOGO },
    );
  };

  const useCreate: CreateMethod<DtoType, DataType> = () => {
    const queryClient = useQueryClient();
    const { mutateAsync: uploadLogoMutation } = useUploadLogo();
    return useMutation((data: DataType) => modelApi.create(mapDataToDto(data)), {
      mutationKey: KEY_CREATE,
      onSuccess: async (entity: any, params: any) => {
        if (entity.uuid && params.logoUrl) {
          await uploadLogoMutation({ ...entity, logoUrl: params.logoUrl });
        }
        queryClient.invalidateQueries(KEY_ALL);
        queryClient.invalidateQueries(KEY_BYID);
      },
    });
  };

  const useUpdateByUUID: UpdateByUUIDMethod<DtoType, DataType> = (uuid: string) => {
    const queryClient = useQueryClient();
    const { mutateAsync: uploadLogoMutation } = useUploadLogo();
    return useMutation((data: DataType) => modelApi.updateByUUID(uuid, mapDataToDto(data)), {
      mutationKey: KEY_UPDATE,
      onSuccess: async (entity: any, params: any) => {
        if (entity.uuid && params.logoUrl) {
          await uploadLogoMutation({ ...entity, logoUrl: params.logoUrl });
        }
        queryClient.invalidateQueries(KEY_ALL);
        queryClient.invalidateQueries(KEY_BYID);
      },
    });
  };

  const useDeleteByUUID: DeleteByUUIDMethod = () => {
    const queryClient = useQueryClient();
    return useMutation((uuid: string) => modelApi.deleteByUUID(uuid), {
      mutationKey: KEY_DELETE,
      onSuccess: () => {
        queryClient.invalidateQueries(KEY_ALL);
        queryClient.invalidateQueries(KEY_BYID);
      },
    });
  };

  return {
    useFetchAll,
    useFetchByUUID,
    useCreate,
    useUpdateByUUID,
    useDeleteByUUID,
    useInvalidateAll,
    useClearCache,
    baseKey: baseKeyString,
  };
}
