import { BillingRequestItemType, BillingRequestLevel } from 'api/invoices';
import { BillingRequestStatus } from 'api/invoices/interfaces/BillingRequestStatus';

export interface BillingRequestData {
  uuid: string;
  name: string;
  customerNumber: string;
  purchaseOrderNumber: string;
  contactPerson: string;
  assignedAccountExecutive: string;
  number: number;
  arInvoiceNumber: number;
  billingPeriodRange: string;
  amount: string;
  status: BillingRequestStatus;
  lineItems: BillingRequestItemData[];
  totalDue: string;
  startPeriod: string;
  billingLevel: BillingRequestLevel;
}

export interface BillingRequestAdjustment {
  billingRequestUuid: string;
  billRequestNumber: number;
  customerNumber: number;
  poNumber: string;
  generalLedgerNumber: string;
  number: number;
  billingPeriodRange: string[];
  type: string;
  amount: number;
  itemNumber: number;
  description: string;
}

export interface BillingRequestAdjustmentDefaultParams {
  number: number;
  customerNumber: string;
  poNumber?: string;
  generalLedger?: string;
  billingPeriod?: string;
  unit?: string;
}

export interface BillingRequestAdjustmentRequestDto {
  amount: number;
  itemNumber: number;
  description: string;
  quantity: number;
  totalAmount: string;
}

export interface BillingRequestItemData {
  uuid: string;
  order: number;
  description: string;
  itemNumber: number;
  quantity: number;
  unitPrice: string;
  totalPrice: string;
  type: BillingRequestItemType;
}

export interface AdjustmentLineItemDefaultParams {
  number: number;
  customerNumber: string;
  poNumber?: string;
  generalLedger?: string;
  billingPeriod?: string;
  unit?: string;
}

export interface AddInvoiceLineDataDefaultParams {
  number: number;
  refundReason?: string;
  customerNumber: number;
  poNumber?: string;
  generalLedger?: string;
  billingPeriod?: string;
  unit?: string;
}

/**
 * Map for available status on Bill Request list search and summary
 */
export const BillingRequestStatusMap: Record<string, BillingRequestStatus[]> = {
  [BillingRequestStatus.ASSEMBLING]: [BillingRequestStatus.ASSEMBLING],
  [BillingRequestStatus.REVIEW_SUBMISSION]: [
    BillingRequestStatus.REVIEW_SUBMISSION,
    BillingRequestStatus.LATE_REVIEW_SUBMISSION,
  ],
  [BillingRequestStatus.SUBMITTED]: [BillingRequestStatus.SUBMITTED, BillingRequestStatus.SENT],
  [BillingRequestStatus.PAID]: [BillingRequestStatus.PAID],
  [BillingRequestStatus.OVERDUE]: [BillingRequestStatus.OVERDUE],
  [BillingRequestStatus.CLOSED]: [BillingRequestStatus.CLOSED],
};

/**
 * Initial search values for status
 */
export const DefaultBillingRequestStatusSearch = Object.keys(
  BillingRequestStatusMap,
) as BillingRequestStatus[];

export interface BillingRequestSearch {
  status: string;
  clientUuid: string;
  name: string;
  billingPeriod: string;
  number: string;
}

export const BillingRequestSearchInitialState: BillingRequestSearch = {
  status: DefaultBillingRequestStatusSearch.map((key) =>
    BillingRequestStatusMap[key].join(','),
  ).join(','),
  clientUuid: '',
  billingPeriod: '',
  number: '',
  name: '',
};

export type BillRequestSummaryItem = {
  category: string;
  amount: number;
  color?: string;
  tag?: string;
  icon?: JSX.Element;
  name: string;
  value: string;
};

export interface BillRequestSummary {
  summary: BillRequestSummaryItem[];
  total: number;
}
