import { useMemo } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { CRUDComponent } from 'components/CRUDNavigator';
import { SetupBaseRoutes, SetupComponentProps } from './types';

interface Props {
  routes?: Partial<SetupBaseRoutes>;

  components: Partial<{
    Main: CRUDComponent;
    Creator: CRUDComponent;
    Individual: CRUDComponent;
    Pricing: React.FC;
  }>;
}

const defaultRoutes: SetupBaseRoutes = {
  MAIN_SCREEN: '/',
  CREATE_SCREEN: '/create',
  INDIVIDUAL_SCREEN: '/:uuid',
  PRICING: '/:uuid/pricing-setup/*',
};

export function SetupNavigator({ components, routes }: Props) {
  const navigate = useNavigate();
  const baseRoutes: SetupBaseRoutes = useMemo(() => ({ ...defaultRoutes, ...routes }), [routes]);
  const componentProps: SetupComponentProps = useMemo(
    () => ({
      navigators: {
        toIndividualScreen: (uuid: string) => {
          const filters = new URLSearchParams(location.search).get('filters') ?? '';
          let params: Record<string, string> = {};
          if (filters) {
            params = { filters };
          }
          const queryParams = params ? `?${new URLSearchParams(params).toString()}` : '';
          navigate(`.${baseRoutes.INDIVIDUAL_SCREEN.replace(':uuid', uuid)}${queryParams}`);
        },
        toCreateScreen: () => {
          navigate(`.${baseRoutes.CREATE_SCREEN}`);
        },
        toMainScreen: () => {
          navigate(`.${baseRoutes.MAIN_SCREEN}`);
        },
      },
      routes: baseRoutes,
    }),
    [baseRoutes, navigate],
  );

  const { Main, Creator, Individual, Pricing } = components;

  return (
    <Routes>
      {Main && <Route path={baseRoutes.MAIN_SCREEN} element={<Main {...componentProps} />} />}
      {Creator && (
        <Route path={baseRoutes.CREATE_SCREEN} element={<Creator {...componentProps} />} />
      )}
      {Individual && (
        <Route path={baseRoutes.INDIVIDUAL_SCREEN} element={<Individual {...componentProps} />} />
      )}
      {Pricing && <Route path={baseRoutes.PRICING} element={<Pricing />} />}
    </Routes>
  );
}
