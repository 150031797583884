export enum Role {
  NATIONAL_IT_SUPER_USER_ADMIN = 'NATIONAL_IT_SUPER_USER_ADMIN',
  NATIONAL_RAP_ADMIN = 'NATIONAL_RAP_ADMIN',
  NATIONAL_RAP_RECONCILER_ANALYST = 'NATIONAL_RAP_RECONCILER_ANALYST',
  NATIONAL_RAP_ACCOUNT_EXECUTIVE = 'NATIONAL_RAP_ACCOUNT_EXECUTIVE',
  NATIONAL_FINANCE_ADVISOR = 'NATIONAL_FINANCE_ADVISOR',
  NATIONAL_CUSTOMER_RELATION = 'NATIONAL_CUSTOMER_RELATION',
  CLUB_ADMIN = 'CLUB_ADMIN',
  CLUB_RAP_RECONCILER_ANALYST = 'CLUB_RAP_RECONCILER_ANALYST',
  NATIONAL_IT_SUPER_USER_ADMIN_READ_ONLY = 'NATIONAL_IT_SUPER_USER_ADMIN_READ_ONLY',
}

type RolePermissionsMap = {
  [role in Role]: PermissionFields;
};

export type RolePermissions = RolePermissionsMap;

export interface PermissionList {
  [tableName: string]: PermissionTable;
}

export interface PermissionTable {
  /**
   * general permission for the table
   */
  permissions: CRUDPermissions;

  /**
   * specific permissions for the fields
   */
  fields: PermissionFields;
}

export interface PermissionFields {
  [fieldName: string]: CRUDPermissions;
}

export interface CRUDPermissions {
  create?: boolean;
  read?: boolean;
  update?: boolean;
  delete?: boolean;
}
