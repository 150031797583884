import { Box, Button, Paper } from '@mui/material';
import { OutlinedIconButton, StepperFooter } from './styles';
import ClearIcon from '@mui/icons-material/Clear';
import DialogConfirmation from 'components/DialogConfirmation';
import { useMemo, useState } from 'react';
import { StepConfig } from '../types';

enum ConfirmationDialogType {
  CANCEL,
}

export interface StepperActionBarProps {
  steps: StepConfig[];
  activeStep: number;
  handleBack(): Promise<void> | void;
  handleCancel(): Promise<void> | void;
  handleSubmit(): Promise<void> | void;
}

export default function StepperActionBar({
  steps,
  activeStep: activeStepIndex,
  handleBack,
  handleCancel,
  handleSubmit,
}: StepperActionBarProps) {
  const [confirm, setConfirm] = useState<ConfirmationDialogType | null>(null);

  const confirmCancel = () => {
    setConfirm(ConfirmationDialogType.CANCEL);
  };

  const handleCloseDialog = () => {
    setConfirm(null);
  };

  const activeStep = useMemo(() => steps[activeStepIndex], [steps, activeStepIndex]);

  const previousStep = useMemo(
    () => (activeStepIndex !== 0 ? steps[activeStepIndex - 1] : null),
    [steps, activeStepIndex],
  );

  return (
    <StepperFooter component={Paper}>
      <OutlinedIconButton
        variant="outlined"
        data-testid="CancelButton"
        startIcon={<ClearIcon />}
        onClick={confirmCancel}
      >
        Cancel
      </OutlinedIconButton>
      <DialogConfirmation
        open={confirm === ConfirmationDialogType.CANCEL}
        title={'Discard changes?'}
        content={'Are you sure you want to discard changes made?'}
        onClose={handleCloseDialog}
        onConfirm={handleCancel}
        labelConfirm={'Discard'}
      />
      <Box sx={{ flex: '1 1 auto' }} />
      {previousStep && (
        <Button variant="outlined" data-testid="BackButton" onClick={handleBack} sx={{ mr: 1 }}>
          {`Back to ${previousStep.actionBarLabel || previousStep.label}`}
        </Button>
      )}

      {activeStepIndex !== steps.length && (
        <Button variant="contained" data-testid="SubmitButton" onClick={handleSubmit}>
          {`Save ${activeStep.actionBarLabel || activeStep.label}`}
        </Button>
      )}
    </StepperFooter>
  );
}
