import { styled } from '@mui/material';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import AppColors from 'AppColors';
import { FocusedInputShape } from 'react-dates';

const StyledDatePickerWrapper = styled('div')<{
  disabled?: boolean;
  focusedInput?: FocusedInputShape | boolean | null;
}>(({ disabled, focusedInput }) => ({
  // SingleDatePicker Styles
  '.DateRangePicker': {
    background: disabled ? AppColors.AAA_GRAY : 'inherit',
    borderColor: `${disabled ? AppColors.AAA_GRAY : 'inherit'} !important`,
  },
  '& .SingleDatePicker': {
    padding: '0 14px',
    width: '100%',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.4375em',
    letterSpacing: '0.00938em',
    color: AppColors.AAA_BLUE,
    borderRadius: '4px',

    '> div': {
      width: '100%',
    },
    ...(disabled
      ? {
          backgroundColor: AppColors.AAA_GRAY,
          border: 0,
        }
      : {
          backgroundColor: AppColors.AAA_WHITE,
          border: `1px solid ${AppColors.AAA_BLUE}`,
        }),

    ...(!disabled && {
      '&:hover': {
        border: `1px solid ${AppColors.AAA_BLUE}`,
      },
    }),

    ...(!disabled &&
      focusedInput && {
        border: `2px solid ${AppColors.AAA_LIGHT_BLUE}`,
        '&:hover': {
          border: `2px solid ${AppColors.AAA_LIGHT_BLUE}`,
        },
      }),
  },

  '& .SingleDatePickerInput': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },

  '& .DateInput': {
    width: '75px',

    '&__disabled': {
      background: AppColors.AAA_GRAY,
      color: 'inherit',
    },
  },

  '& .SingleDatePickerInput_calendarIcon, & .DateRangePickerInput_calendarIcon': {
    padding: 0,
    margin: '0 0 0 auto',
  },

  '& .DateInput_input': {
    padding: '6.5px 0',
    color: 'inherit',
    fontWeight: 'inherit',
    fontSize: '0.9rem',
    lineHeight: 'inherit',
    letterSpacing: 'inherit',
    backgroundColor: 'inherit',
    width: '75px',
    border: 'none',

    '&__disabled': {
      background: AppColors.AAA_GRAY,
      fontStyle: 'inherit',
    },
  },

  '& .SingleDatePickerInput__focused': {
    border: '1px solid #127BA9',
  },

  // DateRangePicker Styles
  '& .DateRangePicker': {
    padding: '0 14px',
    width: '100%',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.59em',
    letterSpacing: '0.00938em',
    color: AppColors.AAA_BLUE,
    border: `1px solid ${disabled ? AppColors.AAA_MEDIUM_GRAY : AppColors.AAA_BLUE}`,
    borderRadius: '4px',

    '> div': {
      width: '100%',
    },

    ...(!disabled && {
      '&:hover': {
        border: `1px solid ${AppColors.AAA_BLUE}`,
      },
    }),

    ...(!disabled &&
      focusedInput && {
        border: `2px solid ${AppColors.AAA_LIGHT_BLUE}`,
        '&:hover': {
          border: `2px solid ${AppColors.AAA_LIGHT_BLUE}`,
        },
      }),
  },

  '& .DateRangePickerInput': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',

    '&__disabled': {
      backgroundColor: AppColors.AAA_GRAY,
      cursor: 'default',
      '& svg': {
        cursor: 'default',
      },
    },

    '&_arrow': {
      padding: '0 8px',
    },
  },

  // DayPicker Styling
  '& .DateInput_fang': {
    zIndex: 3,
  },

  '& .SingleDatePicker_picker, & .DateRangePicker_picker': {
    color: AppColors.AAA_BLUE,
    zIndex: 2,
  },

  '& .CalendarMonth_caption': {
    color: 'inherit',
  },

  '& .CalendarDay': {
    '&__selected': {
      backgroundColor: AppColors.AAA_LIGHT_BLUE,
      color: '#ffffff',
    },

    '&__selected_span': {
      backgroundColor: 'rgba(18, 123, 169, 0.15)',
      color: AppColors.AAA_BLUE,
    },

    '&__highlighted_calendar': {
      backgroundColor: 'rgba(18, 123, 169, 0.15)',

      '&:hover': {
        background: AppColors.AAA_GRAY,
        border: 'none',
      },
    },

    border: 'none',
    color: AppColors.AAA_BLUE,
  },

  '& .DayPickerKeyboardShortcuts_buttonReset': {
    display: 'none',
  },

  '& .DayPicker_weekHeader_li': {
    color: AppColors.AAA_BLUE,
    visibility: 'hidden',
    fontSize: '0',
    '& > small': {
      textTransform: 'uppercase',
    },
    '::first-letter': {
      visibility: 'visible',
      fontSize: '12px',
    },
  },

  '& .DayPickerNavigation': {
    '&_svg__horizontal': {
      fill: AppColors.AAA_BLUE,
    },
  },
}));

export default StyledDatePickerWrapper;
