import { keyframes, styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import TableCell from '@mui/material/TableCell';
import AppColors from 'AppColors';
import CachedIcon from '@mui/icons-material/Cached';

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  '&:before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: AppColors.AAA_GRAY,
  flexDirection: 'row-reverse',
  boxShadow: 'none',
  padding: theme.spacing(0, 1),
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (propName) => propName !== 'focusHighlight',
})<{ focusHighlight?: boolean }>(
  ({ theme, focusHighlight }) => `
    &:first-of-type {
      padding: ${theme.spacing(1)} ${theme.spacing(4)};
    }
    ${
      focusHighlight &&
      ` &:focus-within {
      border-bottom:none;
      outline: 1px solid ${AppColors.AAA_BLUE};
    }`
    }
  `,
);

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`;

export const LoadingIcon = styled(CachedIcon)`
  animation: ${spin} 1s linear infinite;
`;
