import { TowSetupDto } from 'api/setup/manageTowSetupApi';
import { TowData, TowSetupData } from '../PricingSetupQuery';
/**
 * Transforms an array of TowSetupDto objects into a TowSetupData object.
 *
 * @param data An array of TowSetupDto objects.
 * @returns A TowSetupData object containing the transformed data.
 */

export const getUpdateTowsSetupData = (data: TowSetupDto[]): TowSetupData => {
  const [firstTow] = data;
  const { startDateTime, endDateTime, description, duty } = firstTow;

  const tows: TowData[] = data.map((item) => ({
    entityType: item.entityType,
    entityUuid: item.entityUuid,
    startMileage: item.startMileage?.toString(),
    endMileage: item.endMileage?.toString(),
    type: item.type,
    pricePerUnit: item.pricePerUnit?.toString(),
    priceCap: item.priceCap == null ? '' : item.priceCap.toString(),
    uuid: item.uuid,
  }));

  return { startDateTime, endDateTime, description, tows, duty };
};
