import { BillingRequestLevel, BillingRequestStatus } from 'api/invoices';
import { useMemo } from 'react';

interface changeBRStatusConfig {
  enableCancelBRAction: boolean;
  enableCloseBRAction: boolean;
  enableActions: boolean;
}

const enableCancelBRActionStatus = [
  BillingRequestStatus.ASSEMBLING,
  BillingRequestStatus.LATE_REVIEW_SUBMISSION,
  BillingRequestStatus.REVIEW_SUBMISSION,
];

const enableCloseBRActionStatus = [
  BillingRequestStatus.REVIEW_SUBMISSION,
  BillingRequestStatus.LATE_REVIEW_SUBMISSION,
];

export function useChangeBRStatusConfig(
  billingRequestStatus: BillingRequestStatus,
  billingRequestLevel: BillingRequestLevel,
): changeBRStatusConfig {
  const enableCancelBRAction = useMemo(() => {
    return enableCancelBRActionStatus.includes(billingRequestStatus);
  }, [billingRequestStatus]);

  const enableCloseBRAction = useMemo(() => {
    return (
      enableCloseBRActionStatus.includes(billingRequestStatus) ||
      billingRequestLevel === BillingRequestLevel.PRE_PAID
    );
  }, [billingRequestLevel, billingRequestStatus]);

  return {
    enableCancelBRAction,
    enableCloseBRAction,
    enableActions: enableCancelBRAction || enableCloseBRAction,
  };
}
