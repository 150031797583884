import {
  Box,
  BoxProps,
  Container,
  ContainerProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import styled from '@mui/system/styled';
import RBPColors from 'AppColors';
import { memo, PropsWithChildren } from 'react';
import Banner from './../../assets/login-banner.png';
import Logo from './../../assets/logo-blue-m.svg';

const MainContainer = styled(Container)<ContainerProps>`
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
`;

type BannerBoxProps = BoxProps & {
  imageUrl: string;
};

const BannerBox = styled(Box)<BannerBoxProps>(({ imageUrl }) => ({
  width: 670,
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: 'cover',
  height: '100%',
  padding: '4.5rem 0 0 4.5rem',
}));

export const Header = styled(Typography)<TypographyProps>`
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 2.125rem;
  line-height: 2.25rem;
  letter-spacing: 0.15px;
  color: ${RBPColors.AAA_BLUE};
`;

export const Text = styled(Typography)<TypographyProps>(() => ({
  width: 397,
  marginBottom: '1.5rem',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  letterSpacing: '0.5px',
  color: RBPColors.AAA_BLUE,
}));

const AuthLayout = ({ children }: PropsWithChildren) => {
  return (
    <MainContainer disableGutters maxWidth={false}>
      <Box>
        <BannerBox imageUrl={Banner}>
          <Box component="img" src={Logo} sx={{ mb: 5 }} />
          <Header variant="h5">
            {'RAP Billing'}
            <br />
            {'& Payment Portal'}
          </Header>
        </BannerBox>
      </Box>
      <Box display="flex" flexDirection="column" maxWidth="456px" m="auto">
        {children}
      </Box>
    </MainContainer>
  );
};

export default memo(AuthLayout);
