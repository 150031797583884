import { FormControl, TextField, Typography } from '@mui/material';
import { Header, Text } from 'layouts/Authentication';
import RBPColors from 'AppColors';

interface Props {
  onPasswordChange: (password: string) => void;
  onConfirmPasswordChange: (confirmPassword: string) => void;
  onCodeChange: (code: string) => void;
}

const PasswordInput = ({ onPasswordChange, onConfirmPasswordChange, onCodeChange }: Props) => {
  return (
    <>
      <Header>Set New Password</Header>
      <Text>
        <>Enter Code you received via email</>
        <br />
        <>and new password</>
      </Text>

      <FormControl variant="outlined" sx={{ width: 444, mb: '0.5rem' }}>
        <TextField
          name="code"
          type="text"
          placeholder="Code"
          onChange={(e) => onCodeChange(e.target.value)}
        />
      </FormControl>

      <FormControl variant="outlined" sx={{ width: 444 }}>
        <TextField
          name="password"
          type="password"
          placeholder="New Password"
          onChange={(e) => onPasswordChange(e.target.value)}
        />
      </FormControl>
      <Typography
        variant="caption"
        component="p"
        mt="3px"
        lineHeight="1rem"
        mb={1.5}
        px={2.25}
        color={RBPColors.AAA_HELP_TEXT}
      >
        Password must be at least eight characters long, contain upper and lower case letters and
        numbers or special characters
      </Typography>

      <FormControl variant="outlined" sx={{ width: 444 }}>
        <TextField
          name="password confirmation"
          type="password"
          placeholder="Confirm Password"
          onChange={(e) => onConfirmPasswordChange(e.target.value)}
        />
      </FormControl>
    </>
  );
};

export default PasswordInput;
