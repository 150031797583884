import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { ClientData } from './ClientProvider';
import { ClientSearch } from './types';
import { DataTableColumn } from 'components/DataTable';
import { DynamicFieldType } from 'components/Dynamic';
import { DynamicQueryFieldType } from 'components/Dynamic/DynamicSearchBox/types';
import { convertUTCDateToString } from 'utils/convertDate';

export const ClientSearchFields: DynamicQueryFieldType<ClientSearch>[] = [
  {
    name: 'name',
    label: 'Client Name',
    searchType: 'like',
    colspan: 2.4,
  },
  {
    name: 'rapClientId',
    label: 'Client ID',
    searchType: 'equal',
    colspan: 2.4,
  },
  {
    name: 'programs/rapProgramCode',
    label: 'RAP Program Code',
    searchType: 'equal',
    colspan: 2.4,
  },
  {
    name: 'programs/rapProgramId',
    label: 'Program ID',
    searchType: 'equal',
    colspan: 2.4,
  },
  {
    name: 'programs/name',
    label: 'Program Name',
    searchType: 'like',
    colspan: 2.4,
  },
  {
    name: 'contract',
    label: 'Contract Dates',
    searchType: 'dateRange',
    searchFor: ['contractStartDateTime', 'contractEndDateTime'],
    type: DynamicFieldType.RANGE_DATE,
    colspan: 2.4,
  },
  {
    name: 'rapAccountExecutive',
    label: 'RAP Account Executive',
    searchType: 'like',
    colspan: 2.4,
  },
  {
    name: 'active',
    label: 'Active',
    searchType: 'equal',
    colspan: 2.4,
    type: DynamicFieldType.SELECT,
    select: {
      options: [
        {
          label: 'Active',
          value: true,
        },
        {
          label: 'Inactive',
          value: false,
        },
      ],
    },
  },
  {
    name: 'contractStartDateTime',
    label: 'Contract Start Date Time',
    searchType: 'date',
    type: DynamicFieldType.HIDDEN,
    colspan: 2.4,
  },
  {
    name: 'contractEndDateTime',
    label: 'Contract End Date Time',
    searchType: 'date',
    type: DynamicFieldType.HIDDEN,
    colspan: 2.4,
  },
];

export const ClientDataTableConfig: DataTableColumn<ClientData>[] = [
  {
    label: 'Client ID',
    id: 'rapClientId',
    sortable: true,
  },
  { label: 'Client Name', id: 'name', sortable: true },
  { label: 'Programs', id: 'numberOfPrograms', sortable: false },
  {
    label: 'Contract Start Date',
    id: 'contractStartDateTime',
    sortable: true,
    accessor: (row) => convertUTCDateToString(row.contractStartDateTime),
  },
  {
    label: 'Contract End Date',
    id: 'contractEndDateTime',
    sortable: true,
    accessor: (row) => convertUTCDateToString(row.contractEndDateTime),
  },
  { label: 'RAP Account Executive', id: 'rapAccountExecutive', sortable: true },
  {
    label: 'Active',
    id: 'active',
    accessor: (row) =>
      row.active ? (
        <CheckIcon fontSize="small" color="success" />
      ) : (
        <ClearIcon sx={{ fontSize: '1.35rem' }} />
      ),
    sortable: false,
  },
];
