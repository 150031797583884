import { QueryItem } from './item';
import { Comparator } from './comparators';

abstract class Operator extends QueryItem {
  items: QueryItem[];
  operatorKeyWord: string;

  constructor(operatorKeyWord: string, ...items: QueryItem[]) {
    super();
    if (new.target === Operator) {
      throw new TypeError('Cannot construct ' + new.target.name + ' instances directly');
    }
    this.operatorKeyWord = operatorKeyWord;
    this.items = items;
  }

  toString() {
    return '(' + this.items.map((item) => item.toString()).join(` ${this.operatorKeyWord} `) + ')';
  }
}

class And extends Operator {
  constructor(...items: QueryItem[]) {
    super('AND', ...items);
  }
}

class Or extends Operator {
  constructor(...items: QueryItem[]) {
    super('OR', ...items);
  }
}

class Not extends Operator {
  constructor(item: Operator | Comparator) {
    if (!(item instanceof Operator || item instanceof Comparator)) {
      throw new TypeError(
        `${new.target.name} parameter 'item' must be a Operator or a Comparator!`,
      );
    }
    super('NOT', item);
  }
}

class Exists extends Operator {
  constructor(item: Operator | Comparator) {
    if (!(item instanceof Operator || item instanceof Comparator)) {
      throw new TypeError(
        `${new.target.name} parameter 'item' must be a Operator or a Comparator!`,
      );
    }
    super('EXISTS', item);
  }

  toString() {
    return (
      'exists(' + this.items.map((item) => item.toString()).join(` ${this.operatorKeyWord} `) + ')'
    );
  }
}

export { Operator, And, Or, Not, Exists };
