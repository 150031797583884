import { BillTypes } from 'api/setup/manageBillingInfoApi';
import { DynamicField, DynamicFieldType } from 'components/Dynamic';
import { TooltipCategories } from 'components/Form/FormInputTooltip';
import * as yup from 'yup';
import { BillingConfigurationProgramData } from '../ProgramQuery';
import { useMemo } from 'react';

interface Props {
  isPoRequired: boolean;
}

const billTypeOptions = [
  { label: 'Per Event', value: BillTypes.PER_EVENT },
  { label: 'Per Vin', value: BillTypes.PER_VIN },
];

export const useGetBillingInformationFields = ({ isPoRequired }: Props) => {
  return useMemo(() => {
    const billingInformationFields: DynamicField<BillingConfigurationProgramData>[] = [
      {
        label: 'Bill Type',
        name: 'billType',
        type: DynamicFieldType.SELECT,
        select: { options: billTypeOptions },
        required: true,
      },
      { label: 'PO Required', name: 'poRequired', type: DynamicFieldType.CHECKBOX },
      {
        label: 'PO Number',
        name: 'poNumber',
        tooltip: TooltipCategories.poNumber,
        isReadOnly: !isPoRequired,
        required: isPoRequired,
      },
      {
        label: 'PO Effective Dates',
        name: 'poEffectiveDates',
        type: DynamicFieldType.RANGE_DATE,
        isReadOnly: !isPoRequired,
        required: isPoRequired,
      },
    ];

    return billingInformationFields;
  }, [isPoRequired]);
};

export const accountingNumbersFields: DynamicField<BillingConfigurationProgramData>[] = [
  {
    label: 'Revenue Share Account Number',
    name: 'revenueShareAccountNumber',
    tooltip: TooltipCategories.revenueShareAccountNumber,
  },
  {
    label: 'Cost Center Account Number',
    name: 'costCenterAccountNumber',
    tooltip: TooltipCategories.costCenter,
  },
  {
    label: 'Revenue Holding Account',
    name: 'holdingAccountNumberRevenue',
  },
  {
    label: 'Cost Holding Account',
    name: 'holdingAccountNumberCost',
    tooltip: TooltipCategories.costHoldingAcc,
  },
  {
    label: 'Address / Account Number',
    name: 'addressAccountNumber',
  },
];

export const accountItemNumbersFields: DynamicField<BillingConfigurationProgramData>[] = [
  {
    label: 'Billing Request Item Number',
    name: 'billingRequestItemNumber',
    tooltip: TooltipCategories.billingRequestItemNumber,
    required: true,
  },
  {
    label: 'RAP Accounting Item Number',
    name: 'rapAccountingItemNumber',
  },
  {
    label: 'Manual Adjstmt: Revenue Expense',
    name: 'manualAdjustmentRevenueExpenseItemNumber',
  },
  {
    label: 'Manual Adjstmt: Contractor Expense',
    name: 'manualAdjustmentContractorExpenseItemNumber',
  },
  {
    label: 'Pre-paid Event Item Number',
    name: 'prePaidEventItemNumber',
    required: true,
  },
];

export const allowManageCreditCardFields: DynamicField<BillingConfigurationProgramData>[] = [
  { label: 'Allow Split Pay', name: 'allowSplitPay', type: DynamicFieldType.CHECKBOX },
  { label: 'Split Pay RAP Program Code', name: 'splitPayRapProgramCode' },
  {
    label: 'Allow Pre-Pay (Out of Warranty)?',
    name: 'allowPrePay',
    type: DynamicFieldType.CHECKBOX,
    startNewRow: true,
  },
  { label: 'Out of Warranty Program to Route To', name: 'outOfWarrantyProgramToRoute' },
];

export const ProgramBillingInfoValidationSchema = yup.object({
  billType: yup.string().required().label('Bill Type'),
  poRequired: yup.boolean(),
  poNumber: yup.string().when('poRequired', {
    is: true,
    then: yup.string().required('PO Number is required'),
    otherwise: yup.string(),
  }),
  poEffectiveDates: yup.array().when('poRequired', {
    is: true,
    then: yup
      .array()
      .required('PO Effective Dates are required')
      .min(2, 'There should be at least 2 dates')
      .max(2, 'There should be at most 2 dates')
      .test('dates-validation', 'PO Effective Dates are required', (value) => {
        return value && value.length === 2 && value[0] && value[1];
      }),
    otherwise: yup.array().notRequired(),
  }),
  billingRequestItemNumber: yup
    .string()
    .test(
      'billingRequestItemNumber-validation',
      'Only one of Billing Request Item Number or Prepaid Event Item Number can be set',
      function (value) {
        const { prePaidEventItemNumber } = this.parent;
        if (value && prePaidEventItemNumber) {
          return false;
        }
        return true;
      },
    )
    .test(
      'billingRequestItemNumber-required',
      'Billing Request Item Number is required',
      function (value) {
        const { prePaidEventItemNumber } = this.parent;
        if (!value && !prePaidEventItemNumber) {
          return false;
        }
        return true;
      },
    )
    .test(
      'billingRequestItemNumber-type',
      'Billing Request Item Number must be a number',
      function (value) {
        if (value && isNaN(Number(value))) {
          return false;
        }
        return true;
      },
    ),

  prePaidEventItemNumber: yup
    .string()
    .test(
      'prePaidEventItemNumber-validation',
      'Only one of Billing Request Item Number or Prepaid Event Item Number can be set',
      function (value) {
        const { billingRequestItemNumber } = this.parent;
        if (value && billingRequestItemNumber) {
          return false;
        }
        return true;
      },
    )
    .test(
      'prePaidEventItemNumber-required',
      'Prepaid Event Item Number is required',
      function (value) {
        const { billingRequestItemNumber } = this.parent;
        if (!value && !billingRequestItemNumber) {
          return false;
        }
        return true;
      },
    )
    .test(
      'prePaidEventItemNumber-type',
      'Prepaid Event Item Number must be a number',
      function (value) {
        if (value && isNaN(Number(value))) {
          return false;
        }
        return true;
      },
    ),
});
