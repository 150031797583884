import { ListItem, styled } from '@mui/material';

export const SavedSearchListItem = styled(ListItem)(() => ({
  minWidth: 234,
  '& .MuiListItemSecondaryAction-root': {
    position: 'static',
    transform: 'none',
    marginRight: '0.5rem',
  },
  '& .MuiListItemButton-root': {
    paddingRight: '0.5rem',
  },
}));
