import InfoIcon from '@mui/icons-material/Info';
import { CRUDPageableSection } from 'components/CRUDPageable';
import ProgramGeneralInfoForm from './ProgramGeneralInfoForm';
import { useProgramContext, ProgramData } from '../ProgramProvider';
import { GeneralInfoValidationSchema } from '../ProgramPageConfig';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';

interface Props {
  isCreationMode?: boolean;
  onAfterCreate?: (uuid: string) => void;
  initialData?: ProgramData;
}

export default function ProgramGeneralInfo({ isCreationMode, onAfterCreate, initialData }: Props) {
  const context = useProgramContext();
  const { permissions } = useRapCoreFeaturePermissions('programs');

  return (
    <CRUDPageableSection
      context={context}
      title="General Info"
      icon={<InfoIcon />}
      isCreationMode={isCreationMode}
      onAfterCreate={onAfterCreate}
      initialFormData={initialData}
      validationSchema={GeneralInfoValidationSchema}
      defaultEditMode={isCreationMode}
      formContent={(hookForm, isEditMode) => (
        <ProgramGeneralInfoForm hookForm={hookForm} isEditMode={isEditMode} />
      )}
      readOnly={!permissions.update}
    />
  );
}
