import Grid from '@mui/material/Grid';
import { ReactNode } from 'react';
import { LabeledValue } from './LabeledValue';

interface Props {
  items: Array<{
    label: string;
    value: ReactNode;
    colspan?: number;
  }>;
  rowSpacing?: number;
  columnSpacing?: number;
  colspan?: number;
  name?: string;
}

export default function LabeledValueGrid({
  items,
  rowSpacing,
  colspan,
  columnSpacing = 2,
  name,
}: Props) {
  return (
    <Grid container rowSpacing={rowSpacing} columnSpacing={columnSpacing} data-testid={name}>
      {items.map((item, index) => (
        <Grid item key={`${item}-${index}`} xs={item.colspan || colspan}>
          <LabeledValue label={item.label}>{item.value}</LabeledValue>
        </Grid>
      ))}
    </Grid>
  );
}
