import { PropsWithChildren, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import AppBar from '../../components/AppBar';
import AppColors from 'AppColors';
import AppDrawer from '../../components/AppDrawer';
import baseTheme from '../../theme';
import {
  generalMenuItems,
  financeMenuItems,
  managementMenuItems,
  useActiveItem,
} from './AppDrawerItems';
import { Role, useDomainAuth } from 'auth';

const AppLayout = ({ children }: PropsWithChildren) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const activeMenuItem = useActiveItem();
  const { userGroup } = useDomainAuth();

  const handleToggleDrawer = () => {
    setIsDrawerOpen((open) => !open);
  };

  const isAllowed = (roles?: string[], userGroup?: Role) => {
    return !roles || !roles.includes(userGroup || '');
  };

  const menuItems = useMemo(() => {
    const allSections = [generalMenuItems, financeMenuItems, managementMenuItems];

    return allSections
      .map((section) => ({
        ...section,
        items: section.items.filter((item) => isAllowed(item.notAllowedRoles, userGroup)),
      }))
      .filter((section) => section.items.length > 0);
  }, [userGroup]);

  return (
    <Stack minHeight="100%">
      <Stack height={baseTheme.mixins.toolbar.minHeight}>
        <AppBar
          title={activeMenuItem?.title ?? 'Dashboard'}
          onToggleDrawer={handleToggleDrawer}
          isDrawerOpen={isDrawerOpen}
        />
      </Stack>
      <Stack direction="row" flexGrow={1}>
        <Stack>
          <AppDrawer open={isDrawerOpen} segments={menuItems} />
        </Stack>
        <Stack flexGrow={1} p="10px" bgcolor={AppColors.AAA_OXFORD_BLUE}>
          {children}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AppLayout;
