import { BaseAPI } from 'api/auth';
import { CreatePageableCRUDApi } from 'api/helper/PageableCRUDApi';
import { ENV_BACKOFFICE_BASE_API_URL } from 'lib/envConfig';
import { BillTypes } from './manageBillingInfoApi';
import { PageableResponse } from 'api/interfaces/responses';
import { QueryBuilder } from 'lib/querybuilder';

export enum SurveyTypes {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  PHONE = 'PHONE',
}

export interface ClientDto {
  uuid: string;
  rapClientId: number;
  name: string;
  contractStartDateTime: string;
  contractEndDateTime: string;
  numberOfPrograms?: number;
  rapAccountExecutive?: string;
  active?: boolean;
  jdExternalId?: string;
  abbreviatedName?: string;
  survey?: boolean;
  surveyType?: SurveyTypes;
  surveyAdministrator?: string | null;
  contactCenterPartner?: string;
  customerCallNumber?: string;
  aaaAssignedDid?: string;
  clubCallInNumber?: string;
  primaryClientContactFirstName?: string;
  primaryClientContactLastName?: string;
  primaryClientContactPhoneNumber?: string;
  primaryEmailAddress?: string;
  secondaryClientContactFirstName?: string;
  secondaryClientContactLastName?: string;
  secondaryClientContactPhoneNumber?: string;
  secondaryEmailAddress?: string;
  createdAtDateTime?: Date;
  updatedAtDateTime?: Date;
  createdByUserId?: string;
  updatedByUserId?: string;
  logoUrl?: string;
  addressNumber?: number;
  allowSplitPay?: boolean;
}

const findClientById = async (id: number) => {
  return BaseAPI.get<PageableResponse<ClientDto>>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/clients?filter=${QueryBuilder.equal('rapClientId', id)}`,
    { params: { size: 999 } },
  );
};

export const ManageClientApi = CreatePageableCRUDApi<ClientDto>(
  `${ENV_BACKOFFICE_BASE_API_URL}/v1/clients`,
  {
    create: async (data: ClientDto) => {
      const { rapClientId } = data;

      const { content } = await findClientById(rapClientId);

      if (content && content.length > 0) {
        throw new Error('The entered Client ID is already in use, please try another ID.');
      }

      return BaseAPI.post<ClientDto>(`${ENV_BACKOFFICE_BASE_API_URL}/v1/clients`, data);
    },
  },
);

export function fetchClientByUuid(uuid: string) {
  return BaseAPI.get<ClientDto>(`${ENV_BACKOFFICE_BASE_API_URL}/v1/clients/${uuid}`);
}

export interface BillingConfigurationClientDto {
  uuid?: string;
  createdAtDateTime?: string;
  updatedAtDateTime?: string;
  createdByUserId?: string;
  updatedByUserId?: string;
  clientUuid: string;
  billType: BillTypes;
  poRequired: boolean;
  poNumber: string | null;
  poEffectiveDate: string | null;
  poExpirationDate: string | null;
  holdingAccountNumberRevenue: string;
  holdingAccountNumberCost: string;
  addressAccountNumber: string;
  splitPayRapProgramCode: string;
  allowPrePay: boolean;
  outOfWarrantyProgramToRoute: string;
}

export function fetchClientBillingConfig(clientUuid: string) {
  return BaseAPI.get<BillingConfigurationClientDto[]>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/clients/${clientUuid}/billing-configurations`,
  );
}

export function addClientBillingConfig(
  clientUuid: string,
  billingConfig: BillingConfigurationClientDto,
) {
  return BaseAPI.post<BillingConfigurationClientDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/clients/${clientUuid}/billing-configurations`,
    billingConfig,
  );
}

export function updateClientBillingConfig(
  clientUuid: string,
  uuid: string,
  billingConfig: Partial<BillingConfigurationClientDto>,
) {
  return BaseAPI.put<Partial<BillingConfigurationClientDto>>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/clients/${clientUuid}/billing-configurations/${uuid}`,
    billingConfig,
  );
}
