import { useState } from 'react';
import { Box } from '@mui/material';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';
import { BillingRequestLevel, BillingRequestStatus } from 'api/invoices';
import SubMenuButton from 'components/SubMenuButton';
import { SubMenuItem } from 'components/SubMenuButton/SubMenuButton';
import { NotificationSnackbar, NotificationType } from 'components/Notifications';

import { useBillingRequestContext } from '../BillingRequestProvider';
import { useUpdateBillingRequestStatus } from '../BillingRequestQuery';
import { useChangeBRStatusConfig } from '../helpers/useChangeBRStatusConfig';

export function BillingRequestStatusActions() {
  const [notifications, setNotifications] = useState<NotificationType>();

  const {
    getById: { data },
  } = useBillingRequestContext();

  const { mutateAsync: updateBillingRequestStatus } = useUpdateBillingRequestStatus(
    data?.uuid ?? '',
  );

  const { enableActions, enableCancelBRAction, enableCloseBRAction } = useChangeBRStatusConfig(
    data?.status as BillingRequestStatus,
    data?.billingLevel as BillingRequestLevel,
  );
  const { permissions: cancelBillingRequest } =
    useRapCoreFeaturePermissions('cancelBillingRequest');
  const { permissions: closeBillingRequest } = useRapCoreFeaturePermissions('closeBillingRequest');

  const handleCancelBillingRequest = async () => {
    try {
      await updateBillingRequestStatus(BillingRequestStatus.CANCELLED);
      setNotifications({
        type: 'success',
        name: 'Success',
        message: 'Bill request cancelled.',
      });
    } catch (error: any) {
      setNotifications({
        type: 'error',
        name: 'Error',
        message: error.response.data.message,
      });
    }
  };

  const handleCloseBillingRequest = async () => {
    try {
      await updateBillingRequestStatus(BillingRequestStatus.CLOSED);
      setNotifications({
        type: 'success',
        name: 'Success',
        message: 'Bill request closed.',
      });
    } catch (error: any) {
      setNotifications({
        type: 'error',
        name: 'Error',
        message: error.response.data.message,
      });
    }
  };

  const subMenuActions: SubMenuItem[] = [
    {
      label: 'Cancel Billing Request',
      title: `Cancel Billing Request #${data?.number}`,
      content: `There might be cases attached to Billing Request #${data?.number}.\nAll attached cases will be unattached from the Billing Request.\nAre you sure you want to cancel it?`,
      labelConfirm: 'Cancel Billing Request',
      labelConfirmColor: 'error',
      labelCancel: 'Close',
      onClick: handleCancelBillingRequest,
      disabled: !cancelBillingRequest.update || !enableCancelBRAction,
    },
    {
      label: 'Close Billing Request',
      title: `Close Billing Request #${data?.number}`,
      content: `This will not send Billing Request #${data?.number} to accounting, but attached cases will be closed and elegible for payment.\nAre you sure you want to close it?`,
      labelConfirm: 'Close Billing Request',
      labelConfirmColor: 'warning',
      labelCancel: 'Cancel',
      onClick: handleCloseBillingRequest,
      disabled: !closeBillingRequest.update || !enableCloseBRAction,
    },
  ];

  return (
    <Box>
      <SubMenuButton label="Actions" menu={subMenuActions} enabled={enableActions} />
      <NotificationSnackbar
        clearNotification={() => setNotifications(undefined)}
        notification={notifications}
      />
    </Box>
  );
}
