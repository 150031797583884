import { ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import AppColors from 'AppColors';
import { DrawerItem } from './AppDrawer';
import DrawerMenuItemWrapper from './DrawerMenuItemWrapper';

type Props = {
  menuItem: DrawerItem;
  isCollapsed?: boolean;
  isActive?: boolean;
};

const DrawerMenuItem = ({ menuItem, isCollapsed, isActive }: Props) => {
  return (
    <DrawerMenuItemWrapper menuItem={menuItem} isActive={isActive}>
      <ListItemButton
        sx={{
          justifyContent: !isCollapsed ? 'space-between' : 'center',
          '&:hover': {
            color: AppColors.AAA_BLUE,
          },
        }}
      >
        {menuItem.icon && (
          <ListItemIcon
            sx={{
              marginLeft: !isCollapsed ? '-7px' : 'auto',
              justifyContent: 'center',
              color: 'inherit',
            }}
          >
            {menuItem.icon}
          </ListItemIcon>
        )}

        <ListItemText
          primary={
            <Typography
              sx={{
                fontSize: '13px',
                lineHeight: '24px',
              }}
            >
              {menuItem.title}
            </Typography>
          }
          sx={{
            opacity: !isCollapsed ? 1 : 0,
          }}
        />
      </ListItemButton>
    </DrawerMenuItemWrapper>
  );
};

export default DrawerMenuItem;
