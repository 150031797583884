import { useFeaturePermissionDetails } from 'auth';

export const CASE_FINANCIAL_PERMISSION = 'CaseFinancialDto';
export const CASE_FINANCIAL_VIEW_PERMISSION = 'CaseFinancialViewDto';
export const CASE_FINANCIAL_DETAILS_PERMISSION = 'CaseFinancialDetailsDto';

export function useCaseFinancialsConfig() {
  return {
    caseFinancialTable: useFeaturePermissionDetails({ table: CASE_FINANCIAL_PERMISSION }),
    caseFinancialViewTable: useFeaturePermissionDetails({ table: CASE_FINANCIAL_VIEW_PERMISSION }),
    caseFinancialDetailsTable: useFeaturePermissionDetails({
      table: CASE_FINANCIAL_DETAILS_PERMISSION,
    }),
  };
}
