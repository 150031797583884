import {
  Box,
  Button,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  styled,
} from '@mui/material';
import AppColors from 'AppColors';

export const StyledSteppper = styled(Stepper)(() => ({
  '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
    marginTop: '8px',
  },
}));

export const StyledStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    left: 'calc(-42.5% + 16px)',
    right: 'calc(57.5% + 16px)',
    top: '1.5rem',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.text.primary,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.text.primary,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: AppColors.AAA_DUSTY_GREY,
    borderTopWidth: 2,
    borderRadius: 1,
  },
}));

export const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  '& .MuiStepLabel-iconContainer': {
    width: 24,
    height: 24,
    alignItems: 'center',
    color: AppColors.AAA_DUSTY_GREY,
    '&.Mui-active, &.Mui-completed': {
      color: theme.palette.text.primary,
    },
  },
}));

export const StepperFooter = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  borderTop: '1px solid #003366;',
  borderRadius: '0 0 4px 4px',
  padding: '14px 16px',
}));

export const OutlinedIconButton = styled(Button)(() => ({
  mr: 1,
  '.MuiButton-startIcon': {
    marginLeft: '-5px',
    marginRight: '4px',
    '.MuiSvgIcon-root': { fontSize: '24px' },
  },
}));
