import { useState } from 'react';
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Table,
  TableHead,
  TableBody,
  Button,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Revision, { REVISION_ITEM_HEADERS } from './RevisionItemRow';
import { GMClaimItemDto } from 'api/cases/interfaces/CaseFinancialDto';
import { formatDate } from '../helpers';
import { convertToCurrency } from 'utils/formatter';
import AppColors from 'AppColors';
import ClaimItemOverrideDialog from './ClaimItemOverrideDialog';
import { useCaseContext } from 'features/Cases/CaseProvider';

export const CLAIM_ITEM_HEADERS = [
  'Status',
  'Create date',
  'Labor Code',
  'Labor Description',
  'T-Codes',
  'Unit count',
  'Total amount',
  'Action',
];

interface RowProps {
  data: GMClaimItemDto;
  showActions: boolean;
}

function ClaimItemRow({ data, showActions }: RowProps) {
  const [open, setOpen] = useState(false);
  const [showOverrideDialog, setShowOverrideDialog] = useState(false);

  const {
    getById: { data: caseData },
  } = useCaseContext();

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{data.status}</TableCell>
        <TableCell>{formatDate(data.createdAtDateTime)}</TableCell>
        <TableCell>{data.laborCode}</TableCell>
        <TableCell>{data.laborDescription}</TableCell>
        <TableCell>{data.tCodes}</TableCell>
        <TableCell>{data.unitCount}</TableCell>
        <TableCell>{convertToCurrency(data.totalAmount)}</TableCell>
        {showActions && (
          <TableCell>
            {data.status === 'REJECTED' && (
              <Button
                onClick={() => setShowOverrideDialog(true)}
                variant="outlined"
                sx={{
                  width: '55px',
                  height: '24px',
                  textTransform: 'capitalize',
                  color: AppColors.AAA_WHITE,
                  backgroundColor: AppColors.AAA_BLUE,
                  '&:disabled': {
                    opacity: 0.5,
                    color: AppColors.AAA_WHITE,
                  },
                  '&:hover': {
                    backgroundColor: AppColors.AAA_BLUE,
                  },
                }}
              >
                Override
              </Button>
            )}
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box ml={6} mr={0}>
              <Table
                size="small"
                sx={{
                  '& .MuiTableHead-root .MuiTableCell-root': {
                    background: '#ECF4F8 !important',
                    borderTop: '1px solid #6080A0 !important',
                  },
                  '& .MuiTableHead-root .MuiTableCell-root:not(:last-child)': {
                    borderRight: '1px solid #FFFFFF !important',
                  },
                  '& .MuiTableBody-root .MuiTableCell-body': {
                    background: 'transparent !important',
                    border: '1px solid #6080A0',
                  },
                  '& .MuiTableBody-root .MuiTableCell-body:not(:last-child)': {
                    background: 'transparent !important',
                    borderRight: '1px solid #E6EBF0',
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    {REVISION_ITEM_HEADERS.map((header) => (
                      <TableCell key={header}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.revisions.map((revision, idx) => (
                    <Revision
                      key={`${revision.documentId}_${idx}`}
                      idx={idx + 1}
                      revision={revision}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {caseData && (
        <ClaimItemOverrideDialog
          caseUuid={caseData.uuid}
          claimItemUuid={data.uuid}
          rapCaseId={caseData.rapCaseId}
          clientId={caseData.clientId}
          visible={showOverrideDialog}
          setVisible={setShowOverrideDialog}
          onConfirmOverrideClaim={() => setShowOverrideDialog(false)}
        />
      )}
    </>
  );
}

export default ClaimItemRow;
