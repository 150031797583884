import { IconButton, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export type Props = {
  hasNext?: boolean;
  onNext?: () => void;
  hasPrevious?: boolean;
  onPrevious?: () => void;
  label: string;
};

export default function RecordDetailNavigator({
  label,
  hasNext,
  hasPrevious,
  onNext,
  onPrevious,
}: Props): JSX.Element {
  return (
    <>
      <IconButton disabled={!hasPrevious} onClick={onPrevious} aria-label="previous button">
        <ChevronLeftIcon />
      </IconButton>
      <Typography variant="h5">{label}</Typography>
      <IconButton disabled={!hasNext} onClick={onNext} aria-label="next button">
        <ChevronRightIcon />
      </IconButton>
    </>
  );
}
