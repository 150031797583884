import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';
import { ServiceItemsSearchFields, ServiceItemsTableConfig } from './ServiceItemsPageConfig';
import { useServiceItemsContext } from './ServiceItemsProvider';
import { CRUDComponent } from 'components/CRUDNavigator';
import { CRUDPageableList } from 'components/CRUDPageable/components/CRUDPageableList';

const ServiceItemsPageList: CRUDComponent = ({ navigators }) => {
  const context = useServiceItemsContext();
  const { permissions } = useRapCoreFeaturePermissions('servicesProvided');

  return (
    <CRUDPageableList
      name="Service"
      context={context}
      navigators={navigators}
      searchFields={ServiceItemsSearchFields}
      searchDefaultValues={{
        code: '',
        description: '',
        name: '',
        payable: false,
      }}
      tableColumns={ServiceItemsTableConfig}
      canCreate={permissions.create}
    />
  );
};

export default ServiceItemsPageList;
