import LoginContent from 'components/Login/LoginContent';
import Button from '@mui/material/Button';
import { AuthType } from 'auth';

export interface LoginType {
  label: string;
  value: AuthType;
}

interface LoginTypeSelectorProps {
  onSelect?: (selection: LoginType) => void;
}

const loginTypes: LoginType[] = [
  {
    label: 'Log in as aaa NATIONAL user',
    value: 'AD',
  },
  {
    label: 'Log in as external user',
    value: 'NON-AD',
  },
];

export default function LoginTypeSelector({ onSelect }: LoginTypeSelectorProps) {
  return (
    <LoginContent>
      {loginTypes.map((item, index) => (
        <Button
          key={index}
          variant="contained"
          sx={{
            letterSpacing: '1.25px',
          }}
          onClick={() => onSelect?.(item)}
          data-testid={`login-as-${item.value}`}
        >
          {item.label}
        </Button>
      ))}
    </LoginContent>
  );
}
