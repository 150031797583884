import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { convertPermissionData } from './utils/convertPermissionData';
import { usePermissionQuery } from './PermissionQuery';
import { PermissionList, RolePermissions } from './types';
import { useDomainAuth } from 'auth/domain-auth';
import { rapCorePermissions } from './rapCorePermissions';

interface PermissionContextType {
  permissions: PermissionList;
  rapCorePermissions: RolePermissions;
  isLoading?: boolean;
}

export const PermissionContext = createContext<PermissionContextType>({
  permissions: {},
  rapCorePermissions,
});

export function usePermissionRoles() {
  return useContext(PermissionContext);
}

export const PermissionProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isLogged } = useDomainAuth();
  const { data = [], isLoading } = usePermissionQuery(isLogged);

  const permissions = useMemo(() => {
    return convertPermissionData(data);
  }, [data]);

  return (
    <PermissionContext.Provider value={{ permissions, isLoading, rapCorePermissions }}>
      {children}
    </PermissionContext.Provider>
  );
};
