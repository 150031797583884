import { useMemo } from 'react';
import * as yup from 'yup';
import { DynamicField, DynamicFieldType } from 'components/Dynamic';
import { SurveyTypes } from 'api/setup/manageClientApi';
import AddIcon from '@mui/icons-material/Add';
import { ClientData } from '../ClientProvider';
import { BillTypes } from 'api/setup/manageBillingInfoApi';
import { TooltipCategories } from 'components/Form/FormInputTooltip';
import { BillingConfigurationClientData } from '../ClientQuery';

interface UseSurveyInfoFieldsProps {
  isSurvey: boolean;
}

interface UseBillingInformationFieldsProps {
  isPoRequired: boolean;
}

export const GeneralInfoValidationSchema = yup.object({
  rapClientId: yup
    .number()
    .positive()
    .integer()
    .typeError('Client ID must be a number')
    .required()
    .label('Client ID'),
  name: yup.string().required().label('Client Name'),
  contractStartDateTime: yup
    .date()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .required()
    .label('Contract Start Date'),
  contractEndDateTime: yup
    .date()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .required()
    .label('Contract End Date'),
  primaryEmailAddress: yup.string().email().label('Primary Email'),
  secondaryEmailAddress: yup.string().email().label('Secondary Email'),
  survey: yup.boolean(),
  surveyType: yup.string().when('survey', {
    is: true,
    then: yup.string().nullable().required('Survey Type is required'),
    otherwise: yup.string().nullable(),
  }),
  surveyAdministrator: yup.string().when('survey', {
    is: true,
    then: yup.string().nullable().required('Survey Administrator is required'),
    otherwise: yup.string().nullable(),
  }),
});

export const useClientGeneralInfoFields = (isCreationMode: boolean): DynamicField<ClientData>[] => {
  return useMemo(() => {
    const GeneralInfoFields: DynamicField<ClientData>[] = [
      {
        label: 'Client Name',
        name: 'name',
        required: true,
      },
      {
        label: 'Client ID',
        name: 'rapClientId',
        required: true,
        isReadOnly: !isCreationMode,
      },
      {
        label: 'Contract Start Date',
        name: 'contractStartDateTime',
        type: DynamicFieldType.DATE,
        required: true,
      },
      {
        label: 'Contract End Date',
        name: 'contractEndDateTime',
        type: DynamicFieldType.DATE,
        required: true,
      },
      {
        label: 'RAP Account Executive',
        name: 'rapAccountExecutive',
      },
    ];

    return GeneralInfoFields;
  }, [isCreationMode]);
};

export const LogoFields: DynamicField<ClientData>[] = [
  {
    label: 'Upload Logo',
    name: 'logoUrl',
    type: DynamicFieldType.IMAGE_UPLOAD,
    fileInput: { icon: <AddIcon /> },
  },
];

const surveyTypeOptions = [
  { label: 'Email', value: SurveyTypes.EMAIL },
  { label: 'Phone', value: SurveyTypes.PHONE },
  { label: 'Sms', value: SurveyTypes.SMS },
];

export const useSurveyInfoFields = ({ isSurvey }: UseSurveyInfoFieldsProps) => {
  return useMemo(() => {
    const SurveyInfoFields: DynamicField<ClientData>[] = [
      {
        label: 'Survey',
        name: 'survey',
        type: DynamicFieldType.CHECKBOX,
        colspan: 1,
      },
      {
        label: 'Survey Type',
        name: 'surveyType',
        type: DynamicFieldType.SELECT,
        select: { options: surveyTypeOptions },
        isReadOnly: !isSurvey,
        required: isSurvey,
      },
      {
        label: 'Survey Administrator',
        name: 'surveyAdministrator',
        isReadOnly: !isSurvey,
        required: isSurvey,
      },
    ];

    return SurveyInfoFields;
  }, [isSurvey]);
};

export const InboundContactFields: DynamicField<ClientData>[] = [
  {
    label: 'Contact Center Partner',
    name: 'contactCenterPartner',
    type: DynamicFieldType.TEXT,
  },
  {
    label: 'Customer/Guest Call Number',
    name: 'customerCallNumber',
    type: DynamicFieldType.TEXT,
  },
  {
    label: 'AAA Assigned DID',
    name: 'aaaAssignedDid',
    type: DynamicFieldType.TEXT,
  },
  {
    label: "Club's Call-in Number",
    name: 'clubCallInNumber',
    type: DynamicFieldType.TEXT,
  },
];

export const ClientContactFields: DynamicField<ClientData>[] = [
  {
    label: 'Primary Client Contact First Name',
    name: 'primaryClientContactFirstName',
    type: DynamicFieldType.TEXT,
  },
  {
    label: 'Primary Client Contact Last Name',
    name: 'primaryClientContactLastName',
    type: DynamicFieldType.TEXT,
  },
  {
    label: 'Primary Client Contact Phone Number',
    name: 'primaryClientContactPhoneNumber',
    type: DynamicFieldType.TEXT,
  },
  {
    label: 'Primary Email',
    name: 'primaryEmailAddress',
    type: DynamicFieldType.TEXT,
  },
  {
    label: 'Secondary Client Contact First Name',
    name: 'secondaryClientContactFirstName',
    type: DynamicFieldType.TEXT,
  },
  {
    label: 'Secondary Client Contact Last Name',
    name: 'secondaryClientContactLastName',
    type: DynamicFieldType.TEXT,
  },
  {
    label: 'Secondary Client Contact Phone Number',
    name: 'secondaryClientContactPhoneNumber',
    type: DynamicFieldType.TEXT,
  },
  {
    label: 'Secondary Email',
    name: 'secondaryEmailAddress',
    type: DynamicFieldType.TEXT,
  },
];

const billTypeOptions = [
  { label: 'Per Event', value: BillTypes.PER_EVENT },
  { label: 'Per Vin', value: BillTypes.PER_VIN },
];

export const useBillingInformationFields = ({ isPoRequired }: UseBillingInformationFieldsProps) => {
  return useMemo(() => {
    const billingInformationFields: DynamicField<BillingConfigurationClientData>[] = [
      {
        label: 'Bill Type',
        name: 'billType',
        type: DynamicFieldType.SELECT,
        select: { options: billTypeOptions },
        required: true,
      },
      { label: 'PO Required', name: 'poRequired', type: DynamicFieldType.CHECKBOX },
      {
        label: 'PO Number',
        name: 'poNumber',
        tooltip: TooltipCategories.poNumber,
        isReadOnly: !isPoRequired,
        required: isPoRequired,
      },
      {
        label: 'PO Effective Dates',
        name: 'poEffectiveDates',
        type: DynamicFieldType.RANGE_DATE,
        isReadOnly: !isPoRequired,
        required: isPoRequired,
      },
    ];

    return billingInformationFields;
  }, [isPoRequired]);
};

export const accountingNumbersFields: DynamicField<BillingConfigurationClientData>[] = [
  { label: 'Revenue Holding Account', name: 'holdingAccountNumberRevenue' },
  {
    label: 'Cost Holding Account',
    name: 'holdingAccountNumberCost',
    tooltip: TooltipCategories.costHoldingAcc,
  },
  { label: 'Address / Account Number', name: 'addressAccountNumber' },
];

export const allowManageCreditCardFields: DynamicField<BillingConfigurationClientData>[] = [
  { label: 'Allow Split Pay', name: 'allowSplitPay', type: DynamicFieldType.CHECKBOX },
  { label: 'Split Pay RAP Program Code', name: 'splitPayRapProgramCode' },
  {
    label: 'Allow Pre-Pay (Out of Warranty)?',
    name: 'allowPrePay',
    type: DynamicFieldType.CHECKBOX,
    startNewRow: true,
  },
  { label: 'Out of Warranty Program to Route To', name: 'outOfWarrantyProgramToRoute' },
];

export const ClientBillingInfoValidationSchema = yup.object({
  billType: yup.string().required().label('Bill Type'),
  poRequired: yup.boolean(),
  poNumber: yup.string().when('poRequired', {
    is: true,
    then: yup.string().required('PO Number is required'),
    otherwise: yup.string(),
  }),
  poEffectiveDates: yup.array().when('poRequired', {
    is: true,
    then: yup
      .array()
      .required('PO Effective Dates are required')
      .min(2, 'There should be at least 2 dates')
      .max(2, 'There should be at most 2 dates')
      .test('dates-validation', 'PO Effective Dates are required', (value) => {
        return value && value.length === 2 && value[0] && value[1];
      }),
    otherwise: yup.array().notRequired(),
  }),
});
