import { useNavigate, useParams } from 'react-router-dom';
import ClientDetail from './ClientDetail';
import { CRUDComponent } from 'components/CRUDNavigator';
import { CRUDPageableDetail } from 'components/CRUDPageable';
import { ClientData, useClientContext } from './ClientProvider';
import { ActiveFlagLabel } from 'components/Labels';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';

const breadcrumbs = [
  {
    url: '..',
    label: 'Client List',
  },
  {
    url: '',
    label: 'Client Details',
  },
];

const ClientPageView: CRUDComponent = (props) => {
  const { uuid = '' } = useParams();
  const navigate = useNavigate();
  const context = useClientContext();
  const { queries, setNotifications } = context;
  const { mutateAsync } = queries.useDeleteByUUID();
  const { permissions } = useRapCoreFeaturePermissions('clients');
  const clientStatus = context.getById.data?.active;

  const handleDeleteAction = async (data: ClientData) => {
    try {
      await mutateAsync(data.uuid);
      navigate('/setup/clients', {
        state: {
          notifications: {
            type: 'success',
            name: 'Success',
            message: 'Client Successfully Deleted',
          },
        },
      });
    } catch (error: any) {
      setNotifications({
        type: 'error',
        name: 'Error',
        message: error.response.data.message,
      });
    }
  };

  return (
    <CRUDPageableDetail
      breadcrumbs={breadcrumbs}
      context={context}
      uuid={uuid}
      componentProps={props}
      details={({ data, isRunning }) => <ClientDetail data={data} isLoading={isRunning} />}
      handleLabel={(data) => data.name}
      headerPanel={
        clientStatus !== undefined && (
          <ActiveFlagLabel active={clientStatus} label="Client Status" />
        )
      }
      visible={permissions.delete}
      subMenuActions={(data) => [
        {
          label: 'Delete Client',
          onClick: () => handleDeleteAction(data),
          title: `Delete ${data.name}?`,
          content: `Are you sure you want to delete ${data.name}?`,
          labelConfirm: 'Delete',
          labelCancel: 'Cancel',
        },
      ]}
    />
  );
};

export default ClientPageView;
