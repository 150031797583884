export interface CaseErrorDto extends BaseDto {
  code: string;
  description: string;
  statusReason: string;
  ignorable: boolean;
  resolutionType: ResolutionTypes;
  responsible: ResponsibleTypes;
}

interface BaseDto {
  uuid: string;
  createdAtDateTime?: string;
  updatedAtDateTime?: string;
  createdByUserId?: string;
  updatedByUserId?: string;
}

export enum ResolutionTypes {
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  IGNORED = 'IGNORED',
}

export enum ResponsibleTypes {
  CLUB = 'CLUB',
  NATIONAL = 'NATIONAL',
}
