import { ReportStatus, ReportEntityType, ReportDto } from 'features/Reports/types';

export interface PaymentRequestData {
  dateIndex: string;
  monthYear: string;
  totalCases: number;
  totalPayments: number;
  items: PaymentRequestItemData[];
  status: ReportStatusData[];
  paymentIds: number[];
}

export interface PaymentRequestItemData {
  uuid: string;
  vendor: string;
  callAmount: number;
  casesClosedAmount: string;
  doubleRevenueShare: string;
  revenueShare: string;
  message: string;
  reports: PaymentRequestReportData[];
  paymentId: number;
}

export interface ReportStatusData {
  reportStatus: ReportStatus;
  entityType: ReportEntityType;
}

export interface PaymentRequestReportData extends ReportStatusData {
  uuid: string;
  name: string;
}

export interface PaymentRequestGroupItemData {
  items: PaymentRequestItemData[];
  totalCallAmount: string;
  totalCaseClosedAmount: string;
  totalDoubleRevenueShare: string;
  totalRevenueShare: string;
}

//  New types

export interface PaymentRequestSummaryData {
  paymentGroupNumber: number;
  startPeriod: string;
  endPeriod: string;
  totalCases: number;
  totalClubPayments: number;
  files?: string;
  recipient?: string;
  sentAtDateTime?: string;
  reports?: ReportDto[];
}

export enum CallbackType {
  DOWNLOAD,
  SEND,
}

export interface PaymentRequestDetailsData {
  paymentRequestUuid: string;
  clubUuid: string;
  clubName: string;
  totalCases: number;
  revenueShare: number;
  casesClosedAmount: number;
  rapVendorId: number;
  files?: string;
  recipient?: string;
  sentAtDateTime?: string;
  reports?: ReportDto[];
  status?: string;
}
