import { BillingRequestStatusSchema } from 'api/invoices/interfaces/BillingRequestStatus';
import { BillRequestSummary, BillRequestSummaryItem } from '../types';

export const getPlaceholderBillRequests = (): BillRequestSummary => {
  const placeholders: BillRequestSummaryItem[] = [];
  Object.entries(BillingRequestStatusSchema).forEach(([name, schema]) => {
    placeholders.push({
      amount: 0,
      category: schema.label,
      color: schema.color,
      name,
      value: '',
    });
  });

  placeholders.push({
    category: 'Total',
    amount: 0,
    color: 'primary.main',
    name: 'Total',
    value: '',
  });

  return { summary: placeholders, total: 0 };
};
