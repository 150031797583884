import { useMemo } from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ColumnHeader } from 'components/DataTable/DataTable';
import FormDivider from 'components/Form/FormDivider';
import AppColors from 'AppColors';
import useClaims from './useClaims';
import { LoaderCard } from 'components/Cards/LoaderCard';
import ClaimRow, { CLAIM_ITEM_HEADERS } from './components/ClaimItemRow';
import { ErrorResponseCard } from 'components/Cards';
import { ErrorData } from 'hooks/useReactQueryHelpers';
import { GMClaimDto } from 'api/cases/interfaces/CaseFinancialDto';

interface Props {
  caseUUID: string;
}

const getShowActions = (claims: GMClaimDto[]): boolean => {
  let showActions = false;
  claims.forEach((claim) => {
    claim?.claimItems?.forEach((claimItem) => {
      if (claimItem?.status === 'REJECTED') {
        showActions = claimItem?.revisions?.some((revision) => revision.status === 'REJECTED');
      }
    });
  });

  return showActions;
};

function CaseClaim({ caseUUID }: Props) {
  const { isLoading, data: claims, isSuccess, isError, errorCode } = useClaims(caseUUID);
  const hasData = useMemo(() => claims && claims?.length > 0, [claims]);
  const showActions = useMemo(() => getShowActions(claims || []), [claims]);

  return (
    <>
      {isLoading && <LoaderCard label="Loading Financial Details" />}
      {isError && <ErrorResponseCard errorCode={errorCode} title="Claims" />}
      {isSuccess && hasData && claims && (
        <>
          <FormDivider label="Client Reject Details" sx={{ mb: 2, mt: 3 }} />
          <Box mx={-4}>
            <Table
              sx={{
                '& .MuiTableHead-root': {
                  border: '2px solid #8099B3',
                },
                '& .MuiTableCell-body': {
                  background: '#F5F5F5',
                },
              }}
            >
              <TableHead
                sx={{
                  borderTop: `1px solid ${AppColors.AAA_LIGHT_NAVY}`,
                  borderBottom: `1px solid ${AppColors.AAA_LIGHT_NAVY}`,
                }}
              >
                <TableRow
                  sx={{
                    borderBottom: `1px solid ${AppColors.AAA_LIGHT_NAVY}`,
                  }}
                >
                  <TableCell
                    sx={{
                      maxWidth: '50px',
                    }}
                  ></TableCell>
                  {CLAIM_ITEM_HEADERS.map((label) => {
                    if (!showActions && label === 'Action') return;

                    return (
                      <TableCell key={label}>
                        <ColumnHeader>{label}</ColumnHeader>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {claims
                  ?.flatMap((claim) => claim.claimItems)
                  ?.map((row, idx) => (
                    <ClaimRow key={idx} data={row} showActions={showActions} />
                  ))}
              </TableBody>
            </Table>
          </Box>
          {isSuccess && !hasData && (
            <ErrorResponseCard errorCode={ErrorData.NotFound} title="Client Reject Details" />
          )}
        </>
      )}
    </>
  );
}

export default CaseClaim;
