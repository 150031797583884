import { useState } from 'react';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';

type UseManagedMutationResult<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown,
> = UseMutationResult<TData, TError, TVariables, TContext> & {
  errorMessage?: string;
};

export interface IResponseError {
  details: string[];
  message: string;
}

export function useManagedMutation<TData = unknown, TVariables = void, TContext = unknown>(
  options: UseMutationOptions<TData, AxiosError<IResponseError>, TVariables, TContext>,
): UseManagedMutationResult<TData, AxiosError<IResponseError>, TVariables, TContext> {
  const [errorMessage, setErrorMessage] = useState('');
  const mutation = useMutation({
    ...options,
    onError: (error: AxiosError<IResponseError>) => {
      setErrorMessage(error.response?.data?.message || '');
    },
  });
  return {
    ...mutation,
    errorMessage,
    reset: () => {
      mutation.reset();
      setErrorMessage('');
    },
  };
}
