import { ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import { PropsWithChildren, useRef } from 'react';

export type SettingsMenuItem = {
  title: string;
  icon?: JSX.Element;
  action?: () => void;
};

export type Props = PropsWithChildren<{
  items?: SettingsMenuItem[];
  isOpen?: boolean;
  onClose?: () => void;
}>;

export default function SettingsMenu({ items, children, isOpen = false, onClose }: Props) {
  const childContainerRef = useRef(null);
  return (
    <div>
      <div ref={childContainerRef}>{children}</div>
      <Menu
        sx={{ mt: '45px' }}
        anchorEl={childContainerRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen}
        onClose={onClose}
      >
        {items?.map((menuItem) => (
          <MenuItem
            key={menuItem.title}
            onClick={menuItem.action}
            data-testid={`${menuItem.title}-Btn`}
          >
            {menuItem.icon && <ListItemIcon>{menuItem.icon}</ListItemIcon>}
            <Typography textAlign="center">{menuItem.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
