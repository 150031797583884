import { CardContent, Stack, Breadcrumbs, Typography, styled, Card, Box } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';
import { PropsWithChildren } from 'react';

const StickyCard = styled(Card)(() => ({ position: 'sticky', top: 64, zIndex: 2 }));

export type Breadcrumb = {
  url?: string;
  label: string | React.ReactNode;
};

export type Props = {
  breadcrumbs: Breadcrumb[];
  headerPanel?: React.ReactNode;
};

export default function BreadcrumbHeader({
  breadcrumbs,
  children,
  headerPanel,
  ...rest
}: PropsWithChildren<Props>) {
  return (
    <StickyCard {...rest}>
      <CardContent data-testid={'BreadcrumbHeader'}>
        <Stack justifyContent="space-between" flexDirection="row">
          <BreadcrumbItems breadcrumbs={breadcrumbs} />
          {headerPanel}
        </Stack>
        {children}
      </CardContent>
    </StickyCard>
  );
}

type BreadcrumbItemsProps = {
  breadcrumbs: Breadcrumb[];
};

export function BreadcrumbItems({ breadcrumbs }: BreadcrumbItemsProps) {
  return (
    <Breadcrumbs
      color="text.primary"
      aria-label="breadcrumb"
      separator={<NavigateNextIcon />}
      sx={{ mb: 3 }}
    >
      {breadcrumbs.map((breadcrumb, index) =>
        index === breadcrumbs.length - 1 || !breadcrumb.url ? (
          <Box
            color="text.primary"
            sx={{ fontSize: '14px', fontWeight: 'bold' }}
            key={breadcrumb.url ?? `breadcrumb-${index}-${breadcrumb.label}`}
          >
            {breadcrumb.label}
          </Box>
        ) : (
          <Link to={breadcrumb.url} key={breadcrumb.url}>
            <Typography color="text.primary" sx={{ fontSize: '14px' }}>
              {breadcrumb.label}
            </Typography>
          </Link>
        ),
      )}
    </Breadcrumbs>
  );
}
