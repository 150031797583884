import { useEntityCreationStep } from 'components/EntityCreationFlow';
import { StepComponent } from 'components/FormStepper/types';
import { VendorData } from '../types';
import { generalInfoSchema } from '../VendorDetail/fields';
import { VendorForm } from '../VendorDetail/VendorForm';
import { useVendorContext } from '../VendorProvider';

export const VendorGeneralInfoStep = ({
  submitted,
  completed,
  stepKey,
  resetSubmitted,
  setCompleted,
}: StepComponent) => {
  const initialData: Partial<VendorData> = {
    type: 'CLUB',
  };
  const { queries } = useVendorContext();
  const GeneralInfoStep = useEntityCreationStep<VendorData>({
    initialData,
    validationSchema: generalInfoSchema,
    form: VendorForm,
    query: queries.useCreate,
  });
  return (
    <GeneralInfoStep
      stepKey={stepKey}
      submitted={submitted}
      completed={completed}
      resetSubmitted={resetSubmitted}
      setCompleted={setCompleted}
    />
  );
};

export default VendorGeneralInfoStep;
