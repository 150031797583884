import { DynamicFieldType } from 'components/Dynamic';
import { DynamicQueryFieldType } from 'components/Dynamic/DynamicSearchBox/types';
import moment from 'moment';
function parseQueryString(queryString: string, searchFields: DynamicQueryFieldType<any>[]) {
  // Remove outer parenthesis
  queryString = queryString.slice(1, -1);

  // Split by " AND " or " OR "
  const conditions = queryString.split(/ AND | OR | and | or /);

  // Initialize the result object
  const result: any = {};

  // Iterate over each condition
  conditions.forEach((condition) => {
    // Split the condition by the first space
    const firstSpaceIndex = condition.indexOf(' ');

    if (firstSpaceIndex !== -1) {
      const fieldName = condition
        .slice(0, firstSpaceIndex)
        .trim()
        .replace(/^exists\(/, '')
        .replaceAll(/\(/g, '')
        .replace('.', '/');
      const rest = condition.slice(firstSpaceIndex + 1).trim();
      const fieldProps = searchFields.find(
        (search) =>
          search.searchFor === fieldName ||
          search.name === fieldName ||
          search.searchFor === fieldName.replace('/', '.'),
      );

      if (!fieldProps) return;

      // Parse the value based on the operator
      let value: string | string[] = '';
      if (fieldProps.searchType === 'equal' || fieldProps.searchType === 'exists-equal') {
        const values = rest.match(/'([^']+)'/);
        if (values) {
          value = values[1];
        }
      } else if (fieldProps.searchType === 'in') {
        const values = rest.match(/\(([^)]+)\)/);
        if (values && fieldProps.type === DynamicFieldType.TEXT) {
          value = values[1].split("','").join(',').replace(/'/g, ''); // Remove single quotes
        } else if (values && fieldProps.type === DynamicFieldType.SELECT) {
          value = values[1].slice(1, -1).split("', '");
        }
      } else if (fieldProps.searchType === 'like') {
        const values = rest.match(/~\s*'%([^%]+)%'/);
        if (values) {
          value = values[1];
        }
      } else if (fieldProps.searchType === 'date') {
        const values = rest.match(/\d{4}-\d{2}-\d{2}/);
        if (values) {
          value = values[0];
        }
      } else if (fieldProps.searchType === 'dateRange') {
        const values = rest.match(/\d{4}-\d{2}-\d{2}/);
        if (values) {
          value = [];
          if (rest.startsWith('>')) {
            value[0] = values[0];
          } else if (rest.startsWith('<')) {
            value[0] = result[fieldProps.name][0];
            value[1] = values[0];
          }
        }

        // if only have start date, fill end date with actual date
        if (value.length === 1) {
          value = [value[0], moment().format('YYYY-MM-DD')];
        }
      }

      // Store the field and value in the result object
      if (
        fieldProps.searchType === 'in' &&
        result[fieldProps.name] &&
        !result[fieldProps.name].includes(value)
      ) {
        value = [result[fieldProps.name], value].join(',');
      }
      result[fieldProps.name] = value;
    }
  });

  return result;
}
export { parseQueryString };
