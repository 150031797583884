import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import {
  BillingRequestAdjustmentRequestDto,
  BillingRequestAdjustmentDefaultParams,
} from '../types';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  billingRequestAdjustmentFields,
  billingRequestAdjustmentValidationSchema,
  billingRequestAdjustmentFieldsDefaults,
} from '../helpers/billingRequestAdjustmentConfig';
import { DynamicForm } from 'components/Dynamic/DynamicForm';
import DialogConfirmation from 'components/DialogConfirmation';
import { useAddBillingRequestAdjustment } from '../BillingRequestQuery';

interface BillingRequestAdjustmentProps {
  billingRequestUuid: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  defaultAdjustmentValues?: Partial<BillingRequestAdjustmentDefaultParams>;
  onConfirmAddAdjustment: () => void;
}

const BillingRequestAdjustmentForm = ({
  visible,
  setVisible,
  billingRequestUuid,
  defaultAdjustmentValues,
  onConfirmAddAdjustment,
}: BillingRequestAdjustmentProps) => {
  const { isError, mutateAsync, isLoading, errorMessage } =
    useAddBillingRequestAdjustment(billingRequestUuid);

  const hookFormDefault = useForm<BillingRequestAdjustmentDefaultParams>({
    defaultValues: defaultAdjustmentValues,
  });

  const hookForm = useForm<BillingRequestAdjustmentRequestDto>({
    resolver: yupResolver(billingRequestAdjustmentValidationSchema),
    mode: 'onChange',
  });

  const onSubmit = async (data: BillingRequestAdjustmentRequestDto) => {
    try {
      await mutateAsync(data);
      setVisible(false);
      onConfirmAddAdjustment();
    } catch (error) {
      console.log(error);
    }
  };

  const onClose = () => {
    setVisible(false);
    hookForm.reset();
  };

  return (
    <DialogConfirmation
      open={visible}
      title="Add Bill Request Adjustment"
      onClose={onClose}
      onConfirm={hookForm.handleSubmit(onSubmit)}
      labelConfirm="ADD ADJUSTMENT"
      labelCancel="CANCEL"
      isLoading={isLoading}
      sx={{
        '& .MuiDialog-paper': {
          width: 'fit-content',
          maxWidth: 980,
        },
        '& .MuiDialogContent-root': {
          overflowY: 'unset',
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <DynamicForm
          hookForm={hookFormDefault}
          isEditMode
          fields={billingRequestAdjustmentFieldsDefaults}
          rowSpacing={3}
        />
        <Box sx={{ mt: 3 }}>
          <DynamicForm
            hookForm={hookForm}
            isEditMode
            fields={billingRequestAdjustmentFields}
            rowSpacing={3}
          />
        </Box>
        {isError && (
          <Typography color="error" marginTop={3}>
            An Error Has Occurred While Adding Adjustment:
            {errorMessage}
          </Typography>
        )}
      </Box>
    </DialogConfirmation>
  );
};

export default BillingRequestAdjustmentForm;
