import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  updateBillingRequestStatus,
  addInvoiceLine,
  addBillingRequestAdjustment,
  fetchCasesBillingRequest,
  addCaseToBillingRequest,
} from 'api/invoices/manageBillingRequestApi';
import { BillingRequestStatus } from 'api/invoices';
import { fetchClientByUuid } from 'api/setup/manageClientApi';
import { BillingRequestAdjustmentRequestDto } from './types';
import {
  PageableParams,
  useManagedMutation,
  useMappedReactQuery,
} from 'hooks/useReactQueryHelpers';

export const useAddBillingRequestAdjustment = (uuid: string) => {
  const queryClient = useQueryClient();

  return useManagedMutation({
    mutationFn: (data: BillingRequestAdjustmentRequestDto) =>
      addBillingRequestAdjustment(uuid, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['QUERY_BILLING_REQUEST_KEY_BYID']);
    },
  });
};

export const useUpdateBillingRequestStatus = (uuid: string) => {
  const queryClient = useQueryClient();
  return useMutation((status: BillingRequestStatus) => updateBillingRequestStatus(uuid, status), {
    onSuccess: async () => {
      queryClient.invalidateQueries('QUERY_BILLING_REQUEST_KEY_BYID');
      queryClient.invalidateQueries('QUERY_BILLING_REQUEST');
      queryClient.invalidateQueries('QUERY_BILL_REQUEST_CASES');
    },
  });
};

export const useGetClientByUuid = (uuid: string) => {
  return useQuery(['QUERY_CLIENT_BY_UUID', uuid], () => fetchClientByUuid(uuid));
};

export const useAddInvoiceLine = (uuid: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Partial<BillingRequestAdjustmentRequestDto>) => addInvoiceLine(uuid, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('QUERY_BILLING_REQUEST_KEY_BYID');
        queryClient.invalidateQueries('QUERY_BILLING_REQUEST');
      },
    },
  );
};

export function useGetBillRequestCasesByBRId(
  billRequestUuid: string,
  billingLevel: string,
  params: PageableParams,
) {
  return useMappedReactQuery(
    'QUERY_BILL_REQUEST_CASES',
    () => fetchCasesBillingRequest(billRequestUuid, billingLevel, params),
    {},
    params,
  );
}
export const useAddCaseToBillingRequest = (caseUuid: string) => {
  const queryClient = useQueryClient();

  return useManagedMutation({
    mutationFn: (billingRequestUuid: string) =>
      addCaseToBillingRequest(billingRequestUuid, caseUuid),
    onSuccess: () => {
      queryClient.invalidateQueries(['QUERY_CASE_FINANCIALS']);
    },
  });
};
