import { Routes, Route } from 'react-router-dom';
import { publicRoutes } from 'routes';

export function AppPublicRoutes() {
  return (
    <Routes>
      {publicRoutes.map((route) => (
        <Route {...route} key={route.path} />
      ))}
    </Routes>
  );
}
