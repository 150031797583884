/* eslint-disable @typescript-eslint/no-explicit-any */
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ChangeEvent } from 'react';
import { CustomMuiSelect, CustomTextField } from './styled';
import { DataGridColumn } from './types';
import { SelectChangeEvent } from '@mui/material/Select';

interface Props<DataType> {
  column: DataGridColumn<DataType>;
  isEditMode?: boolean;
  value: any;
  onChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeInputSelect: (e: SelectChangeEvent<any>) => void;
}

export function DataInput<DataType>({
  column,
  isEditMode,
  value,
  onChangeInput,
  onChangeInputSelect,
}: Props<DataType>) {
  const { id } = column;
  const name = id.toString();

  switch (column.fieldType) {
    case 'TEXTFIELD':
      return (
        <CustomTextField
          id={name}
          name={name}
          fullWidth
          variant="standard"
          inlineEditing
          placeholder={column.label}
          value={value}
          onChange={onChangeInput}
          inputProps={{
            readOnly: !isEditMode,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
        />
      );
    case 'SELECT':
      return (
        <CustomMuiSelect
          id={name}
          name={name}
          readOnly={!isEditMode}
          inlineEditing
          variant="standard"
          value={value}
          input={<OutlinedInput notched />}
          onChange={onChangeInputSelect}
          size="small"
        >
          {column.options?.map((option, i: number) => (
            <MenuItem key={i} value={option.value as any}>
              {option.label}
            </MenuItem>
          ))}
          ˝
        </CustomMuiSelect>
      );
    default:
      return value;
  }
}
