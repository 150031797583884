import { Controller } from 'react-hook-form';
import { ControlFieldProps } from '../types';

import {
  FormControl,
  InputLabel,
  InputLabelProps,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  MenuItem,
  FormHelperText,
  styled,
  OutlinedInput,
  Box,
  Chip,
  ListSubheader,
} from '@mui/material';
import AppColors from 'AppColors';

type SelectOption = {
  label: string;
  value: string | number | boolean;
  group?: string;
};

export type ControlledSelectProps = ControlFieldProps &
  MuiSelectProps & {
    helperText?: string;
    labelSize?: InputLabelProps['size'];
    selectSize?: MuiSelectProps['size'];
    options?: SelectOption[];
    inlineEditing?: boolean;
    Tooltip?: React.ReactNode;
    isEditMode?: boolean;
  };

type GroupedSelectOptions = {
  [group: string]: SelectOption[];
};

const CustomInputLabel = styled(InputLabel)({
  fontWeight: 600,
});

const CustomMuiSelect = styled(MuiSelect, {
  shouldForwardProp: (propName) => propName !== 'inlineEditing',
})<MuiSelectProps & { inlineEditing?: boolean }>(({ inlineEditing, disabled }) => ({
  ...(inlineEditing && {
    '&:before': { borderBottom: 'none' },
    '&:after': { borderBottom: 'none' },
    '&:hover:not(.Mui-disabled):before ': { borderBottom: 'none' },
  }),

  '& fieldset': {
    // Overwrite the MUI default color
    borderColor: `${disabled ? AppColors.AAA_GRAY : AppColors.AAA_BLUE} !important`,
  },

  ...(disabled && {
    '& div.MuiSelect-select': {
      textFillColor: AppColors.AAA_BLUE,
    },
    '& .MuiSvgIcon-root': {
      color: AppColors.AAA_LIGHT_GRAY,
    },
  }),
}));

const getDropdownOptions = (options?: SelectOption[]) => {
  if (!options) return;

  const menuItems: JSX.Element[] = [];

  // Group select items by group name
  const optionsByGroup: GroupedSelectOptions = options.reduce((groupedOptions, currentOption) => {
    const currentGroup = currentOption.group || 'Other';
    if (groupedOptions[currentGroup]) {
      groupedOptions[currentGroup].push(currentOption);
    } else {
      groupedOptions[currentGroup] = [currentOption];
    }
    return groupedOptions;
  }, {} as GroupedSelectOptions);

  if (Object.keys(optionsByGroup).length > 1) {
    // Select Options are grouped
    Object.keys(optionsByGroup).forEach((optionGroup, i) => {
      menuItems.push(
        <ListSubheader key={`${optionGroup}-${i}`} style={{ fontSize: '18px', fontWeight: 'bold' }}>
          {optionGroup}
        </ListSubheader>,
      );
      optionsByGroup[optionGroup].forEach((option, i) => {
        menuItems.push(
          <MenuItem key={`${optionGroup}-${option.label}-${i}`} value={option.value as any}>
            {option.label}
          </MenuItem>,
        );
      });
    });
  } else {
    // Select Options are not grouped
    options.forEach((option, i: number) =>
      // Type casting to `any` since MUI's MenuItem only takes `string | number` as value
      // and we need to pass `boolean` also.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      menuItems.push(
        <MenuItem key={`${option.label}-${i}`} value={option.value as any}>
          {option.label}
        </MenuItem>,
      ),
    );
  }

  return menuItems;
};

const ControlledSelect = ({
  control,
  name,
  rules,
  error,
  label,
  labelSize = 'small',
  selectSize = 'small',
  options,
  helperText,
  readOnly,
  inlineEditing,
  Tooltip,
  required,
  isEditMode,
  multiple,
  ...rest
}: ControlledSelectProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        // eslint-disable-next-line no-nested-ternary
        const fieldValue = multiple ? (Array.isArray(field.value) ? field.value : []) : field.value;
        return (
          <FormControl error={error} fullWidth>
            <CustomInputLabel
              id={`${name}-${label}`}
              size={labelSize}
              shrink
              sx={{ background: readOnly ? 'transparent' : 'white' }}
            >
              {required && !readOnly ? `${label} *` : label}
            </CustomInputLabel>
            <CustomMuiSelect
              {...field}
              labelId={`${name}-${label}`}
              label={label}
              size={selectSize}
              {...rest}
              {...(rest.type === 'chip'
                ? { renderValue: (selected) => renderChipValue(selected) }
                : {})}
              disabled={readOnly}
              inlineEditing={inlineEditing}
              input={<OutlinedInput notched label={label} />}
              inputProps={{
                'data-testid': 'controlled-select-input',
              }}
              style={{ background: isEditMode && readOnly ? '#f5f5f5' : 'inherit' }}
              endAdornment={Tooltip}
              multiple={multiple}
              value={fieldValue}
            >
              {
                // @ts-expect-error: called function handles undefined options
                getDropdownOptions(options).map((item) => item)
              }
            </CustomMuiSelect>
            {error && <FormHelperText>{helperText}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};

const renderChipValue = (selected: any) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {selected.map((value: string) => (
        <Box key={value}>
          <Chip
            label={value}
            sx={{
              backgroundColor: '#127BA91A',
              color: (theme) => theme.palette.primary.dark,
              fontWeight: 600,
              padding: '0px 4px',
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default ControlledSelect;
