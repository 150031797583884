import { BehaviorSubject } from 'rxjs';

export class DataObserver<DataType> {
  private _data: DataType[] = [];
  private _selected: number[] = [];

  // OBSERVABLES
  public readonly selectedCount: BehaviorSubject<number>;
  public readonly dataCount: BehaviorSubject<number>;

  constructor(data: DataType[] | DataObserver<DataType>) {
    if (data instanceof DataObserver) {
      this._data = data._data;
      this._selected = data._selected;
    } else {
      this._data = JSON.parse(JSON.stringify(data));
    }
    this.selectedCount = new BehaviorSubject(this._selected.length);
    this.dataCount = new BehaviorSubject(this._data.length);
  }

  get data() {
    return this._data;
  }

  dataByIndex(index: number) {
    return {
      row: this._data[index],
      selected: this._selected.includes(index),
    };
  }

  update(index: number, data: DataType) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.assign(this._data[index] as any, data);
  }

  selectItem(index: number, checked: boolean) {
    if (checked) {
      !this._selected.includes(index) && this._selected.push(index);
    } else {
      this._selected = this._selected.filter((item) => item !== index);
    }
    this.selectedCount.next(this._selected.length);
  }

  selectAll(checked: boolean) {
    if (checked) {
      this._selected = this._data.map((_, index) => index);
    } else {
      this._selected = [];
    }
  }

  add(data: DataType) {
    this._data.push(data);
    this.dataCount.next(this._data.length);
  }

  delete() {
    this._data = this._data.filter((_, index) => !this._selected.includes(index));
    this._selected = [];
    this.dataCount.next(this._data.length);
  }

  getSelectedItems() {
    return this._data.filter((_, index) => this._selected.includes(index));
  }
}
