import { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation';
import { ButtonColors } from 'AppColors';

export interface SubMenuItem {
  label: string;
  title?: string;
  content?: string;
  labelConfirm?: string;
  labelConfirmColor?: ButtonColors;
  labelCancel?: string;
  onClick?: () => void;
  hasCustomAction?: boolean;
  disabled?: boolean;
}

export interface DialogState {
  title: string;
  content: string;
  labelConfirm: string;
  labelConfirmColor: ButtonColors;
  labelCancel: string;
  onConfirm?: () => void;
}

interface Props {
  label: string;
  menu: SubMenuItem[];
  enabled?: boolean;
}

export default function SubMenuButton({ menu, label, enabled = true }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialog, setDialog] = useState<DialogState | null>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogConfirm = () => {
    setDialog(null);
    dialog?.onConfirm?.();
  };

  const handleMenuItemClick = (item: SubMenuItem) => {
    setDialog({
      title: item.title || 'Confirmation',
      content: item.content || 'Are you sure you want to perform this action?',
      labelConfirm: item.labelConfirm || 'Confirm',
      labelConfirmColor: item.labelConfirmColor || 'primary',
      labelCancel: item.labelCancel || 'Cancel',
      onConfirm: item.onClick,
    });
  };

  const menuAction = (item: SubMenuItem) => {
    if (item.hasCustomAction) {
      item?.onClick?.();
    } else {
      handleMenuItemClick(item);
    }
    handleClose();
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<ArrowDropDownIcon />}
        onClick={handleClick}
        disabled={!enabled}
      >
        {label}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menu.map((item) => (
          <MenuItem key={item.label} onClick={() => menuAction(item)} disabled={item.disabled}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
      {dialog && (
        <DialogConfirmation
          open={!!dialog}
          title={dialog.title}
          content={dialog.content}
          onClose={() => setDialog(null)}
          onConfirm={handleDialogConfirm}
          labelConfirm={dialog.labelConfirm}
          labelConfirmColor={dialog.labelConfirmColor}
          labelCancel={dialog.labelCancel}
        />
      )}
    </>
  );
}
