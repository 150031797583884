import BillingRequestList from './BillingRequestList';
import BillingRequestProvider from './BillingRequestProvider';
import BillingRequestView from './BillingRequestView';
import { CRUDNavigator } from 'components/CRUDNavigator';

export default function BillingRequestPage() {
  return (
    <BillingRequestProvider>
      <CRUDNavigator
        components={{
          Main: BillingRequestList,
          Individual: BillingRequestView,
        }}
      />
    </BillingRequestProvider>
  );
}
