import { QueryItem } from './item';

type Selector = string;

abstract class QueryFunction extends QueryItem {
  selector?: Selector;
  functionKeyWord: string;

  constructor(functionKeyWord: string, selector?: Selector) {
    if (new.target === QueryFunction) {
      throw new TypeError('Cannot construct ' + new.target.name + ' instances directly');
    }
    if (typeof selector !== 'string') {
      throw new TypeError(new.target.name + " parameter 'selector' must be a String!");
    }
    super();
    this.selector = selector;
    this.functionKeyWord = functionKeyWord;
  }

  toString() {
    return `${this.functionKeyWord}(${this.selector ?? ''})`;
  }
}

class Absolute extends QueryFunction {
  constructor(selector: Selector) {
    super('ABSOLUTE', selector);
  }
}

class Average extends QueryFunction {
  constructor(selector: Selector) {
    super('AVERAGE', selector);
  }
}

class Min extends QueryFunction {
  constructor(selector: Selector) {
    super('MIN', selector);
  }
}

class Max extends QueryFunction {
  constructor(selector: Selector) {
    super('MAX', selector);
  }
}

class Sum extends QueryFunction {
  constructor(selector: Selector) {
    super('SUM', selector);
  }
}

class CurrentDate extends QueryFunction {
  constructor() {
    super('CURRENTDATE');
  }
}

class CurrentTime extends QueryFunction {
  constructor() {
    super('CURRENTTIME');
  }
}

class CurrentTimestamp extends QueryFunction {
  constructor() {
    super('CURRENTTIMESSTAMP');
  }
}

class Size extends QueryFunction {
  constructor(selector: Selector) {
    super('SIZE', selector);
  }
}

class Length extends QueryFunction {
  constructor(selector: Selector) {
    super('LENGTH', selector);
  }
}

class Trim extends QueryFunction {
  constructor(selector: Selector) {
    super('TRIM', selector);
  }
}

class Upper extends QueryFunction {
  constructor(selector: Selector) {
    super('UPPER', selector);
  }
}

class Lower extends QueryFunction {
  constructor(selector: Selector) {
    super('LOWER', selector);
  }
}

class Concat extends QueryFunction {
  constructor(...selectors: Array<Selector>) {
    super('CONCAT', "('" + selectors.join(', ') + "')");
  }
}

export {
  QueryFunction,
  Absolute,
  Average,
  Min,
  Max,
  Sum,
  CurrentDate,
  CurrentTime,
  CurrentTimestamp,
  Size,
  Length,
  Trim,
  Upper,
  Lower,
  Concat,
};
