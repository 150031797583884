import { Grid, Stack } from '@mui/material';
import AppColors from 'AppColors';
import clsx from 'clsx';
import SummaryCard from 'components/SummaryCard';
import { Link, createSearchParams } from 'react-router-dom';
import { routes } from 'routes';
import styles from './../Dashboard.module.css';
import ErrorIcon from '@mui/icons-material/Error';
import CasesRoundedIcon from '@mui/icons-material/CasesRounded';
import { useErrorsQuery } from './CasesSummaryQuery';
import { LoaderCard } from 'components/Cards';
import { DashboardSectionHeader } from '../components';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';

const CasesSummaryCards = () => {
  const { errorCounts: data, isLoading } = useErrorsQuery();
  const { permissions: clubUser } = useRapCoreFeaturePermissions('clubUser');

  const onCaseErrorsCardClick = () => {
    if (clubUser?.read) {
      localStorage.setItem('applyFilters', 'true');
    } else {
      localStorage.removeItem('applyFilters');
    }
  };

  return isLoading ? (
    <Stack flexGrow={1}>
      <LoaderCard label="Loading Cases Errors..." sx={{ flexGrow: 1, padding: 2 }} />
    </Stack>
  ) : (
    <Stack gap={1.2}>
      {/* HEADER */}
      <DashboardSectionHeader label="Cases" icon={<CasesRoundedIcon />} />

      {/* CARDS */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <Link
            className={styles.cardContainer}
            to={`${routes.casePage}?${createSearchParams({
              filters: data.total?.appliedFilter,
            })}`}
            onClick={onCaseErrorsCardClick}
          >
            <SummaryCard
              className={clsx(styles.summaryCard, styles.darkRedStreak)}
              icon={
                <span
                  className={clsx(styles.summaryIcon, styles.summaryBigIcon, styles.bgRedOpaque)}
                >
                  <ErrorIcon sx={{ color: AppColors.AAA_SANGRIA }} />
                </span>
              }
              label="Case Errors"
              value={data.total?.count}
            />
          </Link>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default CasesSummaryCards;
