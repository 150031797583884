import ClientGeneralInfo from './ClientGeneralInfo';
import Programs from './Programs';
import { PricingSetupPanel } from 'features/Setup/PricingSetup/PricingSetupPanel';
import { ClientData } from '../ClientProvider';
import { EntityTypes } from 'api/setup/manageBillingInfoApi';
import ClientBillingInfo from './ClientBillingInfo';

interface Props {
  data?: ClientData;
  toIndividualScreen?: (uuid: string) => void;
  isCreationMode?: boolean;
}

export default function ClientDetailGroup({ data, toIndividualScreen, isCreationMode }: Props) {
  return (
    <>
      <ClientGeneralInfo
        isCreationMode={isCreationMode}
        onAfterCreate={toIndividualScreen}
        initialData={data}
      />
      {data?.uuid && (
        <>
          <Programs clientId={data.uuid} />
          <ClientBillingInfo entityUUID={data.uuid} />
          <PricingSetupPanel entityType={EntityTypes.CLIENT} entityUuid={data.uuid} />
        </>
      )}
    </>
  );
}
