import { useCallback, useEffect, useState } from 'react';
import { DataObserver } from './DataObserver';

export function useDataRow<DataType>(obs: DataObserver<DataType>, index: number) {
  const [data, setData] = useState(obs.dataByIndex(index));

  useEffect(() => {
    const obsData = obs.dataByIndex(index);
    if (obsData.row !== data.row || obsData.selected !== data.selected) {
      setData(obsData);
    }
  }, [data.row, data.selected, index, obs]);

  const updateByField = useCallback(
    (name: string, value: unknown) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      obs.update(index, { [name]: value } as any);
      setData(obs.dataByIndex(index));
    },
    [index, obs],
  );

  const selectItem = useCallback(
    (checked: boolean) => {
      obs.selectItem(index, checked);
      setData(obs.dataByIndex(index));
    },
    [index, obs],
  );

  return { row: data.row, selected: data.selected, updateByField, selectItem };
}
