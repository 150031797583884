/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Helper to convert RawData from query to response type if mapper
 * was provided.
 */
export function convertRawData<SourceType, ResponseType = SourceType>(
  rawData: SourceType | undefined,
  mapper?: (content: SourceType) => ResponseType,
): ResponseType | undefined {
  if (rawData) {
    if (mapper) {
      return mapper(rawData);
    }
    return rawData as any;
  }
  return;
}

/**
 * Helper to convert RawData.Content from query to response type if mapper
 * was provided.
 */
export function convertListData<SourceType, ResponseType = SourceType>(
  rawData: SourceType[],
  mapper?: (content: SourceType) => ResponseType,
): ResponseType[] {
  if (mapper) {
    return rawData.map(mapper);
  }
  return rawData as any;
}
