import { Breadcrumb } from 'components/BreadCrumbHeader';
import { CRUDComponentProps } from 'components/CRUDNavigator';
import { CRUDPageableLayout } from './CRUDPageableLayout';
import { PageableProviderContextType } from '../types';
import { ReactNode, useEffect } from 'react';
import { RecordDetailHeader } from 'components/RecordDetailHeader';
import { SubMenuItem } from 'components/SubMenuButton/SubMenuButton';
import { NotificationSnackbar, NotificationType } from 'components/Notifications';
import { useLocation, useNavigate } from 'react-router-dom';

interface LocationState {
  notifications?: NotificationType;
}

interface DetailProps<DataType> {
  data: DataType | undefined;
  isRunning: boolean;
}

interface Props<DtoType, DataType = DtoType> {
  uuid: string;
  handleLabel: (data: DataType) => string;
  breadcrumbs: Breadcrumb[];
  context: PageableProviderContextType<DtoType, DataType>;
  componentProps: CRUDComponentProps;
  details: (props: DetailProps<DataType>) => ReactNode;
  headerPanel?: React.ReactNode;
  subMenuActions?: (data: DataType) => SubMenuItem[];
  visible?: boolean;
}

/**
 * component to wrap all Details methods and views
 */
export function CRUDPageableDetail<DtoType, DataType = DtoType>({
  uuid,
  handleLabel,
  breadcrumbs,
  context: {
    requestById,
    getById: { isRunning, data },
    navigation: { nextId, previousId },
    notifications,
    setNotifications,
  },
  componentProps: { navigators },
  details,
  headerPanel,
  visible = false,
  subMenuActions,
}: Props<DtoType, DataType>) {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const { notifications: stateNotifications } = (state as LocationState) || {};

  useEffect(() => {
    if (stateNotifications) {
      setNotifications(stateNotifications);

      // Remove notifications from the location state
      navigate(location.pathname, { replace: true });
    }
  }, [setNotifications, stateNotifications, navigate, pathname]);

  useEffect(() => {
    requestById(uuid);
  }, [requestById, uuid]);

  const handleNext = () => {
    nextId && navigators.toIndividualScreen(nextId);
  };

  const handlePrevious = () => {
    previousId && navigators.toIndividualScreen(previousId);
  };

  return (
    <CRUDPageableLayout>
      <RecordDetailHeader
        breadcrumbs={breadcrumbs}
        label={data ? handleLabel(data) : ''}
        hasNext={!!nextId}
        hasPrevious={!!previousId}
        onNext={handleNext}
        onPrevious={handlePrevious}
        headerPanel={headerPanel}
        visible={visible}
        subMenuActions={data && subMenuActions ? subMenuActions(data) : []}
      />
      {details({ isRunning, data })}
      <NotificationSnackbar
        clearNotification={() => setNotifications(undefined)}
        notification={notifications}
      />
    </CRUDPageableLayout>
  );
}
