import { DynamicForm } from 'components/Dynamic';
import FormDivider from 'components/Form/FormDivider';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  accountItemNumbersFields,
  accountingNumbersFields,
  allowManageCreditCardFields,
  useGetBillingInformationFields,
} from './fields';
import { Box } from '@mui/material';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hookForm: UseFormReturn<any>;
  isEditMode?: boolean;
}

export const ProgramBillingInfoForm = ({ hookForm, isEditMode }: Props) => {
  const { control } = hookForm;
  const watchPoRequired = useWatch({ control, name: 'poRequired' });

  const billingInformationFields = useGetBillingInformationFields({
    isPoRequired: watchPoRequired,
  });

  return (
    <Box sx={{ mt: 2, mb: 1 }}>
      <DynamicForm
        hookForm={hookForm}
        isEditMode={isEditMode}
        fields={billingInformationFields}
        rowSpacing={3}
      />

      <FormDivider label="Account Numbers" sx={{ mt: 2, mb: 3 }} />
      <DynamicForm hookForm={hookForm} isEditMode={isEditMode} fields={accountingNumbersFields} />

      <FormDivider label="Account Item Numbers" sx={{ mt: 2, mb: 3 }} />
      <DynamicForm hookForm={hookForm} isEditMode={isEditMode} fields={accountItemNumbersFields} />

      <FormDivider label="Allow/Manage Credit Card Case Activity" sx={{ mt: 2, mb: 3 }} />
      <DynamicForm
        hookForm={hookForm}
        isEditMode={isEditMode}
        fields={allowManageCreditCardFields}
        rowSpacing={3}
      />
    </Box>
  );
};
