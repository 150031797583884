import { InputAdornment } from '@mui/material';
import { DynamicField, DynamicFieldType, DynamicValue } from 'components/Dynamic/DynamicForm';
import * as Yup from 'yup';

export const addInvoiceLineFields: DynamicField<DynamicValue>[] = [
  {
    name: 'description',
    label: 'Description',
    colspan: 4,
    type: DynamicFieldType.TEXT,
    required: true,
    testID: 'description-field-test-id',
  },
  {
    name: 'itemNumber',
    label: 'Item Number',
    colspan: 4,
    type: DynamicFieldType.TEXT,
    required: true,
    testID: 'itemNumber-field-test-id',
  },
  {
    name: 'quantity',
    label: 'Quantity',
    colspan: 4,
    type: DynamicFieldType.TEXT,
    required: true,
    testID: 'quantity-field-test-id',
  },
  {
    name: 'amount',
    label: 'Unit Price',
    colspan: 4,
    type: DynamicFieldType.TEXT,
    endAdornment: <InputAdornment position="end">$</InputAdornment>,
    required: true,
    testID: 'amount-field-test-id',
  },
  {
    name: 'totalAmount',
    label: 'Total Price',
    colspan: 4,
    type: DynamicFieldType.TEXT,
    isReadOnly: true,
    testID: 'totalAmount-field-test-id',
  },
];

export const addInvoiceLineFieldsDefault: DynamicField<DynamicValue>[] = [
  {
    name: 'number',
    label: 'Bill Request Number',
    colspan: 4,
    type: DynamicFieldType.TEXT,
    isReadOnly: true,
  },
  {
    name: 'customerNumber',
    label: 'Customer Number',
    colspan: 4,
    type: DynamicFieldType.TEXT,
    isReadOnly: true,
  },
  {
    name: 'poNumber',
    label: 'PO Number',
    colspan: 4,
    type: DynamicFieldType.TEXT,
    isReadOnly: true,
  },
  {
    name: 'billingPeriodRange',
    label: 'Billing Period Range',
    colspan: 4,
    type: DynamicFieldType.RANGE_DATE,
    isReadOnly: true,
  },
];

export const addInvoiceLineValidationSchema = Yup.object().shape({
  description: Yup.string().required().label('Description'),
  itemNumber: Yup.string().required().label('Item Number'),
  quantity: Yup.number().required().typeError('Quantity must be a number').label('Quantity'),
  amount: Yup.number().required().typeError('Unit Price must be a number').label('Unit Price'),
});
