import RecordDetailGroup from 'components/RecordDetailGroup';
import VendorDetailGroup from './VendorDetailGroup';
import { VendorData } from '../types';

interface Props {
  data?: VendorData;
  isLoading?: boolean;
  toIndividualScreen?: (uuid: string) => void;
  isCreationMode?: boolean;
}

const VendorDetail = ({ isLoading, data, isCreationMode, toIndividualScreen }: Props) => {
  return (
    <RecordDetailGroup
      loadingLabel="Loading Vendor Information"
      isLoading={isLoading}
      showExpandAll={!isCreationMode}
      defaultExpanded={isCreationMode}
    >
      <VendorDetailGroup vendorData={data} toIndividualScreen={toIndividualScreen} />
    </RecordDetailGroup>
  );
};

export default VendorDetail;
