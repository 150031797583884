import ErrorFallback from './ErrorFallback';
import theme from './theme';
import { AppPrivateRoutes } from 'AppPrivateRoutes';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { DomainAuthProvider, PermissionProvider, useDomainAuth } from 'auth';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from '@mui/material/styles';
import { AppPublicRoutes } from 'AppPublicRoutes';
import LogoutWatcher from 'auth/domain-auth/LogoutWatcher';

const rqClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 10 * 60 * 1000, // 10 minutes
      staleTime: 30 * 1000, // 30 seconds
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

function AppSelector() {
  const { isLogged } = useDomainAuth();

  // private authenticated routes
  if (isLogged) {
    return (
      <>
        <LogoutWatcher />
        <QueryClientProvider client={rqClient}>
          <PermissionProvider>
            <ReactQueryDevtools initialIsOpen={false} />
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <AppPrivateRoutes />
            </ErrorBoundary>
          </PermissionProvider>
        </QueryClientProvider>
      </>
    );
  }

  // public routes. ex.: login, reset-password, etc
  return <AppPublicRoutes />;
}

const App = () => (
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <DomainAuthProvider>
        <AppSelector />
      </DomainAuthProvider>
    </BrowserRouter>
    <CssBaseline />
  </ThemeProvider>
);

export default App;
