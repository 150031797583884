import RecordDetailGroup from 'components/RecordDetailGroup';
import { ProgramData } from '../ProgramProvider';
import ProgramDetailsGroup from './ProgramDetailsGroup';

interface Props {
  data?: ProgramData;
  isLoading?: boolean;
  isEditMode?: boolean;
  toIndividualScreen?: (uuid: string) => void;
  isCreationMode?: boolean;
}

const ProgramDetails = ({ isLoading, data, toIndividualScreen, isCreationMode }: Props) => {
  return (
    <RecordDetailGroup
      loadingLabel="Loading Program Information"
      isLoading={isLoading}
      showExpandAll={!isCreationMode}
      defaultExpanded={isCreationMode}
    >
      <ProgramDetailsGroup
        data={data}
        toIndividualScreen={toIndividualScreen}
        isCreationMode={isCreationMode}
      />
    </RecordDetailGroup>
  );
};

export default ProgramDetails;
