import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const SaveSearchButton = styled(Button)(() => ({
  height: '16px',
  paddingTop: '20px',
  marginLeft: 'auto',
  '.MuiButton-startIcon': { marginRight: '4px', '.MuiSvgIcon-root': { fontSize: '24px' } },
}));

export const ShowSavedSearchesButton = styled(Button)(() => ({
  height: '16px',
  '.MuiButton-startIcon .MuiSvgIcon-root': { fontSize: '24px' },
}));
