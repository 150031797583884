import ProgramGeneralInfo from './ProgramGeneralInfo';
import { ProgramData } from '../ProgramProvider';
import { EntityTypes } from 'api/setup/manageServicesSetupApi';
import { PricingSetupPanel } from 'features/Setup/PricingSetup/PricingSetupPanel';
import { ProgramBillingInfo } from './ProgramBillingInfo';
interface Props {
  data?: ProgramData;
  toIndividualScreen?: (uuid: string) => void;
  isCreationMode?: boolean;
}

const ProgramDetailsGroup = ({ data, toIndividualScreen, isCreationMode }: Props) => {
  return (
    <>
      <ProgramGeneralInfo
        isCreationMode={isCreationMode}
        onAfterCreate={toIndividualScreen}
        initialData={data}
      />
      {data?.uuid && (
        <>
          <ProgramBillingInfo entityUUID={data.uuid} />
          <PricingSetupPanel entityType={EntityTypes.PROGRAM} entityUuid={data.uuid} />
        </>
      )}
    </>
  );
};

export default ProgramDetailsGroup;
