import { ServiceProvidedDto, EntityTypes, PricingTypes } from 'api/setup/manageServicesSetupApi';
import { ServiceData } from '../PricingSetupQuery';
import { serviceCodeComparator } from 'utils/getComparator';
import { nanoid } from 'nanoid';

/**
 * Generates a configuration object for creating new service setups.
 *
 * @param serviceItems - An array of service items to be included in the configuration.
 * @param entityType - The type of the entity related to the services.
 * @param entityUuid - The unique identifier of the entity related to the services.
 * @param serviceCodes - An optional array of service codes. If provided, only the services with these codes will be included in the configuration.
 *
 * @returns An object containing an array of services, each with default values, provided entity information, and details from the corresponding service item. The array is sorted by service code.
 */
export const getCreateServicesConfiguration = (
  serviceItems: ServiceProvidedDto[],
  entityType: EntityTypes,
  entityUuid: string,
  serviceCodes: string[] = [], // Default value is an empty array
) => {
  const services: ServiceData[] = serviceItems
    .filter(({ code }) => serviceCodes.length === 0 || serviceCodes.includes(code)) // Filter the services based on the serviceCodes
    .map(({ name, code, uuid }) => ({
      serviceName: name,
      serviceCode: code,
      serviceProvidedUuid: uuid,
      entityType: entityType,
      entityUuid: entityUuid,
      pricePerUnit: '0',
      priceCap: '',
      overridingPricePerUnit: '',
      freeUnits: '0',
      payable: entityType === EntityTypes.CLUB,
      billable: entityType !== EntityTypes.CLUB,
      pricingType: PricingTypes.AT_COST,
      uuid: `fakeUuid-${nanoid()}`,
    }))
    .sort((a, b) => serviceCodeComparator(a, b, 'serviceCode'));

  return {
    services,
  };
};
