import { createContext, useState, useMemo, ReactNode, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useGetPricingInfo } from 'features/Setup/PricingSetup/PricingSetupQuery';
import { EntityTypes, PricingRecordDto } from 'api/setup/manageServicesSetupApi';
import { PricingSetupContextValue } from './types';
import { getIdsFromStorage, setIdsToFromStorage } from 'components/CRUDPageable/helpers';

/**
 * Props for the PricingSetupProvider component.
 */
export interface Props {
  // Children components to be rendered within the provider.
  children: ReactNode;

  // Entity type for the pricing setup.
  entityType: EntityTypes;
}

// PricingSetup context.
const Context = createContext<PricingSetupContextValue>({
  navigation: {
    allDateRange: [],
    currentDatesIndex: -1,
    nextDates: '',
    previousDates: '',
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  requestByDateRange: () => {},
  entityType: EntityTypes.CLIENT,
  isServiceRatesDirtyTabs: [false, false, false],
  isTowMileageDirtyTabs: [false, false, false],
  isEditingSection: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsEditingSection: () => {},
  activeTab: 0,
  setActiveTab: () => null,
});

/**
 * PricingSetupProvider component.
 *
 * This component provides context for managing pricing setup related data.
 *
 * @param props - Component properties.
 * @returns JSX element.
 */
const PricingSetupProvider = ({ children, entityType }: Props) => {
  // State and callback definitions.
  const [current, setCurrent] = useState<string | undefined>();
  const [isServiceRatesDirtyTabs, setIsServiceRatesDirtyTabs] = useState<boolean[]>([
    false,
    false,
    false,
  ]);
  const [isTowMileageDirtyTabs, setIsTowMileageDirtyTabs] = useState<boolean[]>([
    false,
    false,
    false,
  ]);
  const [isEditingSection, setIsEditingSection] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const { uuid } = useParams();
  const getAllPricingInfo = useGetPricingInfo(entityType as EntityTypes, uuid);

  const requestByDateRange = useCallback((dateRange?: string) => {
    setCurrent(dateRange);
  }, []);

  const handleIsServiceRatesDirty = useCallback((tabIndex: number, isDirty: boolean) => {
    setIsServiceRatesDirtyTabs((current) => {
      const newState = [...current];
      newState[tabIndex] = isDirty;
      return newState;
    });
  }, []);

  const handleIsTowMileageDirty = useCallback((tabIndex: number, isDirty: boolean) => {
    setIsTowMileageDirtyTabs((current) => {
      const newState = [...current];
      newState[tabIndex] = isDirty;
      return newState;
    });
  }, []);

  const navigation = useMemo(() => {
    let allDateRange: string[];

    if (getAllPricingInfo.status === 'success') {
      allDateRange = getAllPricingInfo.data.map(
        (item: PricingRecordDto) => `${item.startDateTime}|${item.endDateTime}`,
      );
      setIdsToFromStorage('QUERY_GET_PRICING_INFO', allDateRange);
    } else {
      allDateRange = getIdsFromStorage('QUERY_GET_PRICING_INFO');
    }

    const currentDatesIndex =
      !!current && allDateRange.length > 0 ? allDateRange.indexOf(current) : -1;
    const nextDates: string | undefined = allDateRange[currentDatesIndex + 1];
    const previousDates: string | undefined = allDateRange[currentDatesIndex - 1];

    return { allDateRange, currentDatesIndex, nextDates, previousDates };
  }, [getAllPricingInfo, current]);

  return (
    // Context provider.
    <Context.Provider
      value={{
        getAllPricingInfo,
        requestByDateRange,
        navigation,
        entityType,
        isServiceRatesDirtyTabs,
        handleIsServiceRatesDirty,
        isTowMileageDirtyTabs,
        handleIsTowMileageDirty,
        isEditingSection,
        setIsEditingSection,
        activeTab,
        setActiveTab,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default PricingSetupProvider;
export { Context };
