import {
  ENV_AWS_IDENTITY_PROVIDER,
  ENV_AWS_OAUTH_CLIENTID,
  ENV_AWS_OAUTH_DOMAIN,
  ENV_AWS_REGION,
  ENV_AWS_REGION_2,
  ENV_AWS_USER_POOL_ID,
  ENV_AWS_USER_POOL_ID_2,
  ENV_AWS_USER_POOL_WEBCLIENTID,
  ENV_AWS_USER_POOL_WEBCLIENTID_2,
} from 'lib/envConfig';

/* eslint-disable camelcase */
interface Oauth {
  domain: string;
  scope: string[];
  redirectSignIn: string;
  redirectSignOut: string;
  responseType: string;
  client_id: string;
}

interface Auth {
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
  oauth?: Oauth;
}

interface AWSConfig {
  Auth: Auth;
}

export type AuthType = 'AD' | 'NON-AD';

export interface AWSCognitoDomainConfig {
  description: string;
  hostname: string;
  provider?: string;
  isFederated?: boolean;
  config: AWSConfig;
  authType: AuthType;
}

const DEFAULT_SCOPE = ['phone', 'email', 'openid'];
const DEFAULT_RESPONSE_TYPE = 'token';
const DEFAULT_REDIRECT_URI = window.location.origin;

export const AWSCognitoDomains: AWSCognitoDomainConfig[] = [
  {
    authType: 'NON-AD',
    description: 'AWS Cognito',
    hostname: '-default-',
    config: {
      Auth: {
        region: ENV_AWS_REGION,
        userPoolId: ENV_AWS_USER_POOL_ID,
        userPoolWebClientId: ENV_AWS_USER_POOL_WEBCLIENTID,
      },
    },
  },
  {
    authType: 'AD',
    description: 'National AAA',
    hostname: 'national.aaa.com',
    isFederated: true,
    provider: ENV_AWS_IDENTITY_PROVIDER,
    config: {
      Auth: {
        region: ENV_AWS_REGION_2,
        userPoolId: ENV_AWS_USER_POOL_ID_2,
        userPoolWebClientId: ENV_AWS_USER_POOL_WEBCLIENTID_2,
        oauth: {
          domain: ENV_AWS_OAUTH_DOMAIN,
          scope: DEFAULT_SCOPE,
          redirectSignIn: DEFAULT_REDIRECT_URI,
          redirectSignOut: DEFAULT_REDIRECT_URI,
          responseType: DEFAULT_RESPONSE_TYPE,
          client_id: ENV_AWS_OAUTH_CLIENTID,
        },
      },
    },
  },
];
