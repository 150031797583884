import Stack from '@mui/material/Stack';
import { Box, Paper } from '@mui/material';
import FormDivider from 'components/Form/FormDivider';
import DataTable, { DataTableColumn } from 'components/DataTable';
import { CreditCardData } from 'features/CreditCards/types';
import { convertUTCDateToString } from 'utils/convertDate';
import { convertToCurrency } from 'utils/formatter';

export const RefundCodesTable: DataTableColumn<any>[] = [
  {
    label: 'Refund Amount',
    id: 'refundAmount',
    sx: { fontWeight: 'bold', color: '#CC0000' },
    sortable: false,
    width: '15%',
  },
  { label: 'Refund Date', id: 'refundDate', sortable: false, width: '15%' },
  { label: 'Refund Reason', id: 'refundReason', sortable: false, width: '70%' },
];

interface Props {
  data: CreditCardData;
}

const RefundCreditCardTable = ({ data }: Props) => {
  if (!data.refundDate && !data.refundAmount) return null;

  const rows = [
    {
      refundAmount: convertToCurrency((data.refundAmount || 0) * -1),
      refundDate: convertUTCDateToString(data.refundDate),
      refundReason: data.refundReason,
    },
  ];
  return (
    <Stack spacing={0}>
      <FormDivider label="Refund Information" sx={{ mt: 2, mb: 3, padding: '0 32px' }} />
      <Box component={Paper}>
        <DataTable<any>
          data-testid={DataTable.name}
          indexName="uuid"
          onRowClick={() => null}
          columns={RefundCodesTable}
          rows={rows}
          stickyHeader
        />
      </Box>
    </Stack>
  );
};

export default RefundCreditCardTable;
