import {
  CreatePaymentRequestInformationDto,
  PaymentRequestInformationDto,
  addPaymentRequestInfo,
  fetchPaymentRequestInfo,
  updatePaymentRequestInfo,
} from 'api/setup/manageVendorApi';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const QUERY_VENDOR_PAYMENT_REQUEST_INFO = 'QUERY_VENDOR_PAYMENT_REQUEST_INFO';

export interface PaymentRequestInformationData {
  uuid?: string;
  vendorUuid?: string;
  paymentReportRecipientEmails: string[];
  callsAccountNumber: string;
  revenueShareAccountNumber: string;
  jdeAccountRoutingNumber: string;
}

function mapDtoToData(dto: PaymentRequestInformationDto[]): PaymentRequestInformationData {
  const [firstDto] = dto;

  return {
    ...firstDto,
    jdeAccountRoutingNumber: firstDto.jdeAccountRoutingNumber?.toString() || '',
  };
}

function mapPaymentRequestInformationDataToCreateDto(
  data: PaymentRequestInformationData,
): CreatePaymentRequestInformationDto {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const defaultData = useGetDefaultPaymentRequestInfo();

  return {
    ...defaultData,
    ...data,
    jdeAccountRoutingNumber:
      data.jdeAccountRoutingNumber === ''
        ? parseFloat(defaultData.jdeAccountRoutingNumber)
        : parseFloat(data.jdeAccountRoutingNumber),
  };
}

export function useGetPaymentRequestInfo(vendorUuid: string) {
  return useQuery<PaymentRequestInformationDto[], unknown, PaymentRequestInformationData>(
    [QUERY_VENDOR_PAYMENT_REQUEST_INFO, vendorUuid],
    () => fetchPaymentRequestInfo(vendorUuid),
    {
      select: mapDtoToData,
      enabled: !!vendorUuid,
      retry: false,
    },
  );
}

export function useCreatePaymentRequestInfo(vendorUuid: string) {
  const queryClient = useQueryClient();

  return useMutation(
    (data: PaymentRequestInformationData) =>
      addPaymentRequestInfo(vendorUuid, mapPaymentRequestInformationDataToCreateDto(data)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_VENDOR_PAYMENT_REQUEST_INFO]);
      },
    },
  );
}

export function useUpdatePaymentRequestInfo(vendorUuid: string) {
  const queryClient = useQueryClient();

  return useMutation(
    (data: PaymentRequestInformationData) =>
      updatePaymentRequestInfo(
        vendorUuid,
        data?.uuid || '',
        mapPaymentRequestInformationDataToCreateDto(data),
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_VENDOR_PAYMENT_REQUEST_INFO]);
      },
    },
  );
}

export const useGetDefaultPaymentRequestInfo = (): PaymentRequestInformationData => ({
  paymentReportRecipientEmails: [],
  callsAccountNumber: '',
  revenueShareAccountNumber: '',
  jdeAccountRoutingNumber: '',
});
