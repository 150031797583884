import InfoIcon from '@mui/icons-material/Info';
import { ReactComponent as BillingIcon } from 'assets/payment.svg';
import { StepConfig } from 'components/FormStepper/types';
import PaymentInfoStep from './PaymentInfoStep';
import VendorGeneralInfoStep from './GeneralInfoStep';

const GeneralInfoStepConfig: StepConfig = {
  key: 'GENERAL_INFO',
  label: 'General',
  actionBarLabel: 'General Info',
  icon: InfoIcon,
  component: VendorGeneralInfoStep,
};

const BillingInfoStepConfig: StepConfig = {
  key: 'PAYMENT_INFO',
  label: 'Payment Request Information',
  icon: BillingIcon,
  component: PaymentInfoStep,
};

export const VendorCreationFormSteps = [GeneralInfoStepConfig, BillingInfoStepConfig];
