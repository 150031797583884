import { useMemo } from 'react';
import { EntityTypes, PricingTypes } from 'api/setup/manageServicesSetupApi';
import { DynamicFieldType } from 'components/Dynamic';
import { TooltipCategories } from 'components/Form/FormInputTooltip';

interface Props {
  entityType: EntityTypes;
}

export function usePricingServiceRatesConfig({ entityType }: Props) {
  const pricingServiceRatesFields = useMemo(
    () => [
      { id: 'serviceCode', label: 'Service Code' },
      { id: 'serviceName', label: 'Service Name' },
      ...(entityType === EntityTypes.CLUB
        ? [
            {
              id: 'payable',
              label: 'Payable',
              fieldType: DynamicFieldType.SELECT,
              options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ],
            },
          ]
        : []),
      ...(entityType !== EntityTypes.CLUB
        ? [
            {
              id: 'billable',
              label: 'Billable',
              fieldType: DynamicFieldType.SELECT,
              options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ],
            },
          ]
        : []),
      {
        id: 'pricingType',
        label: 'Bill Pricing Type',
        fieldType: DynamicFieldType.SELECT,
        options: [
          { label: 'Fixed', value: PricingTypes.FIXED },
          { label: 'At Cost', value: PricingTypes.AT_COST },
          { label: 'Cost Plus', value: PricingTypes.AT_COST_PLUS },
          { label: 'Per Unit', value: PricingTypes.UNIT },
          { label: 'No Charge', value: PricingTypes.NO_COST },
          { label: 'Double Unit', value: PricingTypes.DOUBLE_UNIT },
        ],
      },
      {
        id: 'pricePerUnit',
        label: 'Bill Base Rate',
        fieldType: DynamicFieldType.CURRENCY,
        tooltip: true,
      },
      {
        id: 'priceCap',
        label: 'Price Cap',
        fieldType: DynamicFieldType.CURRENCY,
      },
      {
        id: 'overridingPricePerUnit',
        label: 'Club Rate',
        fieldType: DynamicFieldType.CURRENCY,
        headerTooltip: TooltipCategories.clubRate,
      },
    ],
    [entityType],
  );

  return { pricingServiceRatesFields };
}
