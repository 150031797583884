import { Header, Text } from 'layouts/Authentication';

type Props = {
  emailAddress: string;
  onCodeNavigation: () => void;
};

const EmailSent = ({ emailAddress, onCodeNavigation }: Props) => {
  return (
    <>
      <Header>E-mail sent</Header>

      <Text sx={{ marginBottom: '0px' }}>
        <>We sent password reset code to {emailAddress}.</>
        <br />
        <>Please check your inbox.</>
        <br />
      </Text>
    </>
  );
};

export default EmailSent;
