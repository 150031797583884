import { LoaderCard } from 'components/Cards';
import ExpandablePanelGroup, {
  useExpandablePanelGroup,
} from 'components/Dynamic/DynamicExpandablePanel/ExpandablePanelGroup';
import { ExpandAllButton } from 'components/ExpandAllButton';

import { PropsWithChildren, useEffect } from 'react';

type Props = PropsWithChildren<{
  isLoading?: boolean;
  showExpandAll?: boolean;
  defaultExpanded?: boolean;
  loadingLabel: string;
}>;
function RecordDetailGroupToggle({
  loadingLabel,
  isLoading = false,
  showExpandAll = true,
  defaultExpanded,
  children,
}: Props) {
  const { groupExpanded, toggleGroup, setGroupExpanded } = useExpandablePanelGroup();

  useEffect(() => {
    defaultExpanded !== undefined && setGroupExpanded(defaultExpanded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <LoaderCard label={loadingLabel} sx={{ p: 4 }} />;
  }

  return (
    <>
      {showExpandAll && <ExpandAllButton expanded={groupExpanded} toggleExpanded={toggleGroup} />}
      {children}
    </>
  );
}

export default function RecordDetailGroup(props: Props) {
  return (
    <ExpandablePanelGroup>
      <RecordDetailGroupToggle {...props} />
    </ExpandablePanelGroup>
  );
}
