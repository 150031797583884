import { CreatePageableCRUDApi } from 'api/helper/PageableCRUDApi';
import { ENV_BACKOFFICE_BASE_API_URL } from 'lib/envConfig';

export interface ServiceItemsDto {
  uuid: string;
  code: string;
  name: string;
  description: string;
  unitType: 'MILES' | 'UNIT';
  payable: boolean;
}

export const ManageServiceItemsApi = CreatePageableCRUDApi<ServiceItemsDto>(
  `${ENV_BACKOFFICE_BASE_API_URL}/v1/services-provided`,
);
