import { useState, useCallback, ReactNode, useEffect, useMemo } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { OptionalObjectSchema } from 'yup/lib/object';
import { ReactComponent as PaymentIcon } from 'assets/payment.svg';
import {
  DynamicExpandableFormActions,
  DynamicExpandablePanel,
  useExpandablePanelGroup,
} from 'components/Dynamic';
import { LoaderCard } from 'components/Cards';
import { NotificationSnackbar, NotificationType } from 'components/Notifications';
import { UseMutationResult, UseQueryResult } from 'react-query';
import { getFieldsAndValuesFromMergedObjects } from 'utils/getFieldsAndValuesFromMergedObjects';

export interface Props<DataType, DtoType> {
  entityUUID: string;
  title: string;
  defaultEditMode?: boolean;
  readOnly?: boolean;
  setIsEditingSection: (value: boolean) => void;
  isEditingSection: boolean;

  /**
   * Hooks for creation and update mutations
   */
  useGetBillingConfig: (entityUuid: string) => UseQueryResult<Partial<DataType>>;
  useCreateBillingConfig: (entityUuid: string) => UseMutationResult<DtoType, unknown, DataType>;
  useUpdateBillingConfig: (
    entityUuid: string,
  ) => UseMutationResult<Partial<DtoType>, unknown, DataType>;

  /**
   * Hook for default form data
   */
  useGetDefaultBillingInfoData: () => DataType;

  /**
   * Callback that returns a React Node of the form inputs
   */
  formContent?: (hookForm: UseFormReturn<any>, isEditMode: boolean) => ReactNode;

  /**
   * Provides yup validation schema for the form
   */
  validationSchema?: OptionalObjectSchema<any>;

  /**
   * Base entity Data (client/program)
   */
  entityData?: any;

  /**
   * Fields to be taken from base entity to show in the form and to update
   */
  fieldsToUpdateInEntity?: string[];
}

function BillingInformationSection<DataType, DtoType>({
  entityUUID,
  title,
  defaultEditMode,
  readOnly,
  formContent,
  validationSchema,
  setIsEditingSection,
  isEditingSection,
  useGetBillingConfig,
  useCreateBillingConfig,
  useUpdateBillingConfig,
  useGetDefaultBillingInfoData,
  entityData,
  fieldsToUpdateInEntity,
}: Props<DataType, DtoType>) {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [isEditMode, setEditMode] = useState<boolean>(defaultEditMode || false);
  const [notifications, setNotifications] = useState<NotificationType>();
  const { data, isLoading, isFetched } = useGetBillingConfig(entityUUID);
  const { mutateAsync: createBillingConfigMutateAsync } = useCreateBillingConfig(entityUUID);
  const { mutateAsync: updateBillingConfigMutateAsync } = useUpdateBillingConfig(entityUUID);
  const { groupExpanded } = useExpandablePanelGroup();
  const defaultValues = useGetDefaultBillingInfoData();

  const hookForm = useForm<any>({
    defaultValues,
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
  });

  useEffect(() => {
    setExpanded(groupExpanded);
  }, [groupExpanded]);

  const dataFromEntity = useMemo(() => {
    let fields = {};
    if (fieldsToUpdateInEntity && fieldsToUpdateInEntity?.length > 0) {
      const { separatedValues, ...rest } = getFieldsAndValuesFromMergedObjects(
        data,
        entityData,
        fieldsToUpdateInEntity,
      );
      fields = separatedValues;
    }

    return fields;
  }, [data, entityData, fieldsToUpdateInEntity]);

  useEffect(() => {
    if (isFetched && data) {
      hookForm.reset({
        ...data,
        ...dataFromEntity,
      });
    }
  }, [data, dataFromEntity, hookForm, isFetched]);

  const handleEdit = useCallback(() => {
    setEditMode(true);
    setIsEditingSection(true);
  }, [setIsEditingSection]);

  const handleDiscard = useCallback(() => {
    hookForm.reset();
    setEditMode(false);
    setIsEditingSection(false);
  }, [hookForm, setIsEditingSection]);

  const handleSave = useCallback(async () => {
    const submitForm = hookForm.handleSubmit(async (data) => {
      if (!data.uuid) {
        try {
          await createBillingConfigMutateAsync(data);
          setEditMode(false);
          setIsEditingSection(false);
        } catch (error: any) {
          const { response } = error;
          setNotifications({
            name: 'Error',
            message: response.data.message,
            type: 'error',
          });
        }
      } else {
        try {
          await updateBillingConfigMutateAsync(data);
          setEditMode(false);
          setIsEditingSection(false);
        } catch (error: any) {
          const { response } = error;
          setNotifications({
            name: 'Error',
            message: response.data.message,
            type: 'error',
          });
        }
      }
    });

    submitForm();
  }, [
    createBillingConfigMutateAsync,
    hookForm,
    setIsEditingSection,
    updateBillingConfigMutateAsync,
  ]);

  return (
    <>
      <DynamicExpandablePanel
        data-testid={title}
        title={title}
        icon={<PaymentIcon />}
        onExpanded={() => setExpanded(!expanded)}
        expanded={expanded}
        actions={{
          isEditMode,
          onEdit: !readOnly ? handleEdit : undefined,
        }}
        isEditingSection={isEditingSection}
      >
        {isLoading && <LoaderCard label={`Loading ${title}`} />}
        {isFetched && formContent?.(hookForm, isEditMode)}

        <DynamicExpandableFormActions
          isEditMode={isEditMode}
          save={{
            onSubmit: handleSave,
          }}
          discard={{
            onSubmit: handleDiscard,
          }}
        />
      </DynamicExpandablePanel>
      <NotificationSnackbar
        clearNotification={() => setNotifications(undefined)}
        notification={notifications}
      />
    </>
  );
}

export default BillingInformationSection;
