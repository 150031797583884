import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import AppColors from 'AppColors';
import { SxProps, Theme } from '@mui/material';

interface FormDividerProps {
  label: ReactNode;
  sx?: SxProps<Theme>;
  typographySx?: SxProps<Theme>;
}

export default function FormDivider({ label, sx, typographySx }: FormDividerProps) {
  return (
    <Box sx={sx}>
      <Typography variant="body2" sx={{ color: `${AppColors.AAA_QUARTZ}`, ...typographySx }}>
        {label}
      </Typography>
      <Divider sx={{ borderColor: `${AppColors.AAA_QUARTZ}` }} />
    </Box>
  );
}
