import { BaseAPI } from 'api/auth';
import { CreatePageableCRUDApi } from 'api/helper/PageableCRUDApi';
import { PageableResponse } from 'api/interfaces/responses';
import { ENV_BACKOFFICE_BASE_API_URL } from 'lib/envConfig';
import { DutyTypes } from './manageServicesSetupApi';

enum EntityTypes {
  PROGRAM = 'PROGRAM',
  CLIENT = 'CLIENT',
  CLUB = 'CLUB',
}

export enum TowTypes {
  STANDARD = 'STANDARD',
  PREMIUM = 'PREMIUM',
}

export interface TowSetupDto {
  uuid: string;
  createdAtDateTime: string;
  updatedAtDateTime: string;
  createdByUserId: string;
  updatedByUserId: string;
  entityType: EntityTypes;
  entityUuid: string;
  startDateTime: string;
  endDateTime: string;
  description: string;
  startMileage: number;
  endMileage: number;
  type: TowTypes;
  pricePerUnit: number;
  priceCap: number | null;
  duty: DutyTypes;
}

export interface BaseCreateTowSetupRequestDto {
  createdByUserId?: string;
  entityType: EntityTypes;
  entityUuid: string;
  startMileage: number;
  endMileage: number;
  type: TowTypes;
  pricePerUnit: number;
  priceCap: number | null;
}

export interface CreateTowSetupBatchRequestDto {
  createdByUserId?: string;
  startDateTime: string;
  endDateTime: string;
  description: string;
  duty: DutyTypes;
  tows: BaseCreateTowSetupRequestDto[];
}

export interface CreateTowSetupRequestDto {
  createdByUserId?: string;
  entityType: EntityTypes;
  entityUuid: string;
  startMileage: number;
  endMileage: number;
  type: TowTypes;
  pricePerUnit: number;
  priceCap: number | null;
  startDateTime: string;
  endDateTime: string;
  description: string;
  duty: DutyTypes;
}

export interface UpdateTowSetupRequestItemDto {
  updatedByUserId?: string;
  entityType: EntityTypes;
  entityUuid: string;
  startMileage: number;
  endMileage: number;
  type: TowTypes;
  pricePerUnit: number;
  priceCap: number | null;
  uuid: string;
  startDateTime?: string;
  endDateTime?: string;
  description?: string;
}

export interface UpdateTowSetupBatchRequestDto {
  updatedByUserId?: string;
  tows: UpdateTowSetupRequestItemDto[];
}

export const ManageTowsSetupApi = CreatePageableCRUDApi<TowSetupDto>(
  `${ENV_BACKOFFICE_BASE_API_URL}/v1/tows-setup`,
);

export function fetchAllTowsSetup(
  entityType: EntityTypes,
  entityUuid: string,
  startDateTime: string,
  endDateTime: string,
  duty: DutyTypes,
) {
  return BaseAPI.get<PageableResponse<TowSetupDto>>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/tows-setup?page=0&size=999&duty=${duty}&entityType=${entityType}&entityUuid=${entityUuid}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`,
  );
}

export function addTowsSetupBatch(towsSetup: CreateTowSetupBatchRequestDto) {
  return BaseAPI.post<CreateTowSetupBatchRequestDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/tows-setup/batch`,
    towsSetup,
  );
}

export function updateTowsSetupBatch(towsSetup: UpdateTowSetupBatchRequestDto) {
  return BaseAPI.put<UpdateTowSetupBatchRequestDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/tows-setup/batch`,
    towsSetup,
  );
}

export function addTowSetup(towSetup: CreateTowSetupRequestDto) {
  return BaseAPI.post<CreateTowSetupRequestDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/tows-setup`,
    towSetup,
  );
}

export function deleteTowsSetup(uuid: string) {
  return BaseAPI.delete<string>(`${ENV_BACKOFFICE_BASE_API_URL}/v1/tows-setup/${uuid}`);
}
