import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';
import styles from '../CaseFinancials.module.css';
import { useState } from 'react';

export function ExpandableHeader({ onExpanded }: { onExpanded?: (expanded: boolean) => void }) {
  const [isExpanded, setExpanded] = useState(true);

  const handleExpansion = () => {
    setExpanded(!isExpanded);
    onExpanded?.(!isExpanded);
  };

  return (
    <Box display="flex" alignItems="center" onClick={() => handleExpansion()}>
      <ExpandMoreIcon className={clsx(styles.icon, isExpanded && styles.expanded)} /> Case
      Financials
    </Box>
  );
}
