import { useContext, useEffect, useMemo, useState } from 'react';
import GeneralInfoEditor from './GeneralInfoEditor';
import InfoIcon from '@mui/icons-material/Info';
import { LoaderCard } from 'components/Cards/LoaderCard';
import {
  DynamicExpandablePanel,
  ExpandablePanelEditOptions,
} from 'components/Dynamic/DynamicExpandablePanel';
import { useGetCaseGeneralInfoByUUID, useGetCaseAddonsByUUID } from './GeneralInfoQuery';
import { CaseDetailsContext } from 'features/Cases/contexts/CaseDetails';
import { ErrorCard } from 'components/Cards/ErrorCard';
import { AuthComponent } from 'auth/permissions/authComponent';
import { GENERAL_INFO_PERMISSION, useGeneralInfoPermissions } from './config';
import SkeletonLoader from 'components/SkeletonLoader';

interface GeneralInfoProps {
  caseUUID: string;
  editOptions?: ExpandablePanelEditOptions;
}

const GeneralInfo = ({ caseUUID, editOptions }: GeneralInfoProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [isEditMode, setEditMode] = useState(false);
  const {
    isLoading: isLoadingGeneralInfo,
    isFetching: isFetchingGeneralInfo,
    data: generalInfoData,
    refetch: refetchGeneralInfo,
    isError: isErrorGeneralInfo,
  } = useGetCaseGeneralInfoByUUID(caseUUID, expanded);
  const {
    isLoading: isLoadingAddons,
    isFetching: isFetchingAddons,
    data: addonsData,
    refetch: refetchAddons,
    isError: isErrorAddons,
  } = useGetCaseAddonsByUUID(caseUUID, expanded);
  const { allSectionsExpanded, isEditingSection, setIsEditingSection } =
    useContext(CaseDetailsContext);

  useEffect(() => {
    setExpanded(allSectionsExpanded);
  }, [allSectionsExpanded]);

  const onExpanded = (isExpanded: boolean) => () => {
    setExpanded(!isExpanded);
  };

  const handleEdit = () => {
    setEditMode(true);
    setIsEditingSection(true);
  };

  const isLoading = isLoadingGeneralInfo && isLoadingAddons;
  const isFetching = isFetchingGeneralInfo && isFetchingAddons;
  const isError = isErrorGeneralInfo && isErrorAddons;
  const isRunning = isFetching || isLoading;

  const disableEdit = useMemo(
    () => isError || isRunning || editOptions?.disabled,
    [editOptions?.disabled, isError, isRunning],
  );

  const permissions = useGeneralInfoPermissions();

  return (
    <DynamicExpandablePanel
      data-testid="GeneralInfoPanel"
      title="General Info"
      icon={<InfoIcon />}
      onExpanded={onExpanded(expanded)}
      expanded={expanded}
      actions={{
        isEditMode: isEditMode,
        onEdit: permissions?.update ? () => handleEdit() : undefined,
      }}
      editOptions={{
        ...editOptions,
        disabled: disableEdit,
      }}
      isEditingSection={isEditingSection}
    >
      {isRunning && <LoaderCard label="Loading Case General Information" />}

      {isError && (
        <ErrorCard label="An Error Has Occurred While Retrieving Case General Information" />
      )}

      {generalInfoData && addonsData && (
        <GeneralInfoEditor
          generalInfoData={generalInfoData}
          addonsData={addonsData}
          refetchGeneralInfo={refetchGeneralInfo}
          refetchAddons={refetchAddons}
          isLoadingAddons={isLoadingAddons || isFetchingAddons}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          setIsEditingSection={setIsEditingSection}
        />
      )}
    </DynamicExpandablePanel>
  );
};

export default AuthComponent(
  GENERAL_INFO_PERMISSION,
  GeneralInfo,

  <SkeletonLoader loading height={66} variant="rectangular" />,
);
