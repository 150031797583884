import { CRUDPageableReactQueryType, FetchOptions } from 'hooks/useReactQueryHelpers';
import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CRUDPageableContext } from './CRUDPageableContext';
import { getIdsFromStorage, setIdsToFromStorage } from './helpers';
import { NavigationProps, OrderType, PageableProviderData } from '.';
import useSyncedSearchParams from 'hooks/useSyncedSearchParams';
import { NotificationType } from 'components/Notifications';
import { getItemFromStorage, setItemToStorage } from 'auth/domain-auth/helpers';

interface Props<DtoType, DataType> extends PropsWithChildren {
  initialState?: Partial<PageableProviderData>;
  queries: CRUDPageableReactQueryType<DtoType, DataType>;
  idMapper?: (data: DataType) => string;
  fetchAllOptions?: FetchOptions;
  // If true, the queries from URL search parameters will be re-run on mount.
  reQueryOnMount?: boolean;
  uuidKey?: string;
}

const ROWS_PER_PAGE = 'rows-per-page';

export function CRUDPageableProvider<DtoType, DataType>({
  initialState,
  children,
  queries,
  reQueryOnMount = false,
  fetchAllOptions: options,
  uuidKey,
  idMapper,
}: Props<DtoType, DataType>) {
  const initialRowsPerPage = () => {
    const storedValue = getItemFromStorage(ROWS_PER_PAGE);
    return storedValue ? Number(storedValue) : 25;
  };

  const [order, setOrder] = useSyncedSearchParams('order', initialState?.order) as [
    OrderType,
    React.Dispatch<OrderType>,
  ];
  const [orderBy, setOrderBy] = useSyncedSearchParams('orderBy', initialState?.orderBy);
  const [rowsPerPage, setRowsPerPage] = useState<number>(initialRowsPerPage);
  const [currentPage, setCurrentPage] = useState<number>(initialState?.currentPage || 0);
  const [filters, setFilters] = useSyncedSearchParams('filters', initialState?.filters);
  const [fetchAllEnabled, setFetchAllEnabled] = useState<boolean>(!!options?.enabled);
  const [current, setCurrent] = useState<string | undefined>();
  const [notifications, setNotifications] = useState<NotificationType>();
  const [isEditingSection, setIsEditingSection] = useState<boolean>(false);

  const isInitialFetchComplete = useRef(false);

  const { useFetchAll, useFetchByUUID, useInvalidateAll, useClearCache } = queries;

  useEffect(() => {
    setItemToStorage(ROWS_PER_PAGE, rowsPerPage.toString());
  }, [rowsPerPage]);

  const getAll = useFetchAll(
    {
      page: currentPage,
      size: rowsPerPage,
      sort: order ? `${orderBy},${order}` : '',
      filter: `${filters}`,
    },
    { enabled: fetchAllEnabled, ...options },
  );

  const getById = useFetchByUUID(current);

  const requestAll = useCallback(
    (newFilters = '') => {
      setFilters(newFilters);
      setCurrentPage(0);
      setCurrent(undefined);
      setFetchAllEnabled(true);
    },
    [setFilters],
  );

  const resetAll = useInvalidateAll();
  const clearQueryCache = useClearCache();

  // on first render, request all data for filters in URL
  useEffect(() => {
    if (
      !isInitialFetchComplete.current &&
      reQueryOnMount &&
      ((filters && filters !== initialState?.filters) ||
        (orderBy && orderBy !== initialState?.orderBy))
    ) {
      requestAll(filters);
      isInitialFetchComplete.current = true;
    }
  }, [filters, orderBy, initialState?.filters, initialState?.orderBy, reQueryOnMount, requestAll]);

  const requestById = useCallback(
    (uuid?: string) => {
      setCurrent(uuid);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getById],
  );

  const navigation: NavigationProps = useMemo(() => {
    let allIds: string[];

    if (getAll.isDone) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      allIds = getAll.data.map(idMapper || ((item: any) => item[`${uuidKey || 'uuid'}`]));
      setIdsToFromStorage(queries.baseKey, allIds);
    } else {
      allIds = getIdsFromStorage(queries.baseKey);
    }

    const currentIndex = !!current && allIds.length > 0 ? allIds.indexOf(current) : -1;
    const nextId: string | undefined = allIds[currentIndex + 1];
    const previousId: string | undefined = allIds[currentIndex - 1];

    return { allIds, currentIndex, nextId, previousId };
  }, [current, getAll.data, getAll.isDone, idMapper, queries.baseKey, uuidKey]);

  const paginationOptions = {
    rowsCount: getAll.rowsCount,
    currentPage,
    rowsPerPage,
    onPageChange: setCurrentPage,
    onRowsPerPageChange: setRowsPerPage,
    rowsPerPageOptions: [25, 50, 100, 150, 200, 250, 300],
  };

  const sortOptions = {
    hasSort: true,
    order,
    orderBy,
    createSortHandler: (_: React.MouseEvent<unknown>, property: string) => {
      setOrder(orderBy === property && order === 'asc' ? 'desc' : 'asc');
      setOrderBy(property);
    },
    externalSorting: true,
  };

  return (
    <CRUDPageableContext.Provider
      value={{
        currentPage,
        setCurrentPage,
        rowsPerPage,
        setRowsPerPage,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        filters,
        setFilters,
        requestAll,
        requestById,
        getAll,
        getById,
        queries,
        navigation,
        paginationOptions,
        sortOptions,
        notifications,
        setNotifications,
        isEditingSection,
        setIsEditingSection,
        resetAll,
        clearQueryCache,
      }}
    >
      {children}
    </CRUDPageableContext.Provider>
  );
}
