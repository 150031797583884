import { PaletteOptions } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import AppColors from 'AppColors';

const AppDrawer = {
  width: '12.5rem',
  collapsedWidth: '4.625rem',
};

const palette: PaletteOptions = {
  text: {
    primary: AppColors.AAA_BLUE,
  },
  primary: {
    main: AppColors.AAA_LIGHT_BLUE,
    contrastText: AppColors.AAA_WHITE,
  },
  secondary: {
    main: AppColors.AAA_BLUE,
    contrastText: AppColors.AAA_WHITE,
  },
};

const theme = createTheme({
  custom: {
    stickyOffset: 193,
  },
  typography: {
    label: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '1.25px',
      textTransform: 'uppercase',
      color: AppColors.AAA_LIGHT_BLUE,
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
  },

  AppDrawer,

  mixins: {
    toolbar: {
      minHeight: '64px',
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          minWidth: AppDrawer.collapsedWidth,
          width: AppDrawer.width,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: AppColors.AAA_GRAY,
          zIndex: 1,
          '& .MuiTableCell-root': {
            backgroundColor: AppColors.AAA_GRAY,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderRight: '1px solid transparent',
          borderRightColor: AppColors.AAA_MEDIUM_GRAY,
        },
        head: {
          borderRight: '1px solid transparent',
          borderRightColor: AppColors.AAA_WHITE,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          marginLeft: 0,
          width: '100%',
        },
        spacer: {
          display: 'none',
        },
        displayedRows: {
          marginLeft: 'auto',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            '.MuiTableSortLabel-icon': {
              color: AppColors.AAA_BLUE,
              opacity: 1,
            },
          },
        },
        icon: {
          opacity: 0.5,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: AppColors.AAA_LIGHT_BLUE,
        },
        outlined: {
          borderColor: AppColors.AAA_ALTO_GRAY,
        },
        text: {
          padding: 0,
          '&:hover, &:active': {
            background: 'none',
            boxShadow: 'none',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: AppColors.AAA_UCLA_BLUE,
        },
        input: {
          color: AppColors.AAA_BLUE,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: AppColors.AAA_BLUE,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: AppColors.AAA_CORNFLOWER_BLUE,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          label: {
            color: AppColors.AAA_BLUE,
          },
        },
      },
    },
  },
  palette,
});

export default theme;
