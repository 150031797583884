import { Box, CircularProgress, IconButton, Paper, Stack, Tooltip } from '@mui/material';
import DataTable, { DataTableColumn } from 'components/DataTable';
import { NotificationType } from 'components/Notifications';
import { useCallback, useMemo, useState } from 'react';
import { ReportData, ReportSummaryData } from './types';
import DownloadIcon from '@mui/icons-material/Download';
import CachedIcon from '@mui/icons-material/Cached';
import { useGetReportSummary } from './ReportsQuery';
import { ErrorResponseCard, LoaderCard } from 'components/Cards';
import {
  usePaymentForecastsDownloadOneReports,
  useRegenerateReports,
} from 'features/PaymentForecasts/PaymentForecastsQuery';
import { downloadReportFile } from './utils';
import { CRUDComponent } from 'components/CRUDNavigator';
import { convertUTCToDateTimeString } from 'utils/convertDate';
import { useParams } from 'react-router-dom';
import BreadCrumbHeader, { Breadcrumb } from 'components/BreadCrumbHeader';
import { CRUDPageableLayout } from 'components/CRUDPageable';

const ReportsList: CRUDComponent = ({ navigators }) => {
  const [notifications, setNotifications] = useState<NotificationType>();
  const [selectedReport, setSelectedReport] = useState<string>();
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('');
  const params = useParams();

  const [rowsPerPage, setRowsPerPage] = useState<number>(300);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const { data, isLoading, isError, errorCode, refetch } = useGetReportSummary({
    page: currentPage,
    size: rowsPerPage,
    filter: params.uuid,
  });

  const { mutateAsync: downloadReport, isLoading: isLoadingDownloadReport } =
    usePaymentForecastsDownloadOneReports();

  const { mutateAsync: regenerateReport, isLoading: isLoadingRegenerateReport } =
    useRegenerateReports();

  const createSortHandler = (_: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const paginationOptions = useMemo(() => {
    return {
      rowsCount: data?.totalElements ?? 25,
      currentPage,
      rowsPerPage,
      onPageChange: setCurrentPage,
      onRowsPerPageChange: setRowsPerPage,
      rowsPerPageOptions: [25, 50, 100, 150, 200, 250, 300],
    };
  }, [currentPage, data, rowsPerPage, setRowsPerPage]);

  const breadcrumbs: Breadcrumb[] = [
    {
      url: '..',
      label: 'Reports List',
    },
    {
      url: '',
      label: `Reports List - ${params.uuid?.split('-').reverse().join('/')}`,
    },
  ];

  const handleDownloadReport = (report: ReportData) => {
    downloadReport(report.reportUuid)
      .then((res: any) => {
        const fileName = report.s3Path.split('/').pop() ?? '';

        const downloadBody = {
          res,
          reportName: report.reportName,
          setNotifications,
          fileType: 'text/csv',
          fileName: fileName,
        };
        downloadReportFile(downloadBody);
      })
      .catch(() => {
        setNotifications({
          name: `Download ${report.reportName} Report`,
          type: 'error',
          message: `The ${report.reportName} report has not been downloaded, try again later`,
        });
      });
  };

  const handleRegenerateReport = (reportNumber: string, s3Path: string) => {
    regenerateReport({ number: reportNumber, s3Path })
      .then((res: any) => {
        setNotifications({
          name: `Regenerated Report ${reportNumber}`,
          type: 'success',
          message: `The Report ${reportNumber} has been regenerated successfully. Attention: It may take a few minutes to generate the updated report.`,
        });
        refetch();
      })
      .catch(() => {
        setNotifications({
          name: `Regenerated Report ${reportNumber}`,
          type: 'error',
          message: `The Report ${reportNumber} has not been regenerated, try again later`,
        });
      });
  };

  const reportsColumns: DataTableColumn<ReportSummaryData>[] = [
    {
      id: 'number',
      label: 'Report Number',
      sortable: true,
      accessor: (row) => `Report ${row.number}`,
    },
    {
      id: 'name',
      sortable: true,
      label: 'Report Name',
    },
    {
      id: 'monthYear',
      label: 'Month/Year',
      sortable: true,
    },
    {
      id: 'reportRunDate' as keyof ReportSummaryData,
      label: 'Report Run Date',
      sortable: true,
      accessor: (row) => {
        if (row.reports.length > 1) {
          return '-';
        }

        return convertUTCToDateTimeString(row.reports[0].generatedAtDateTime);
      },
    },
    {
      id: 'reports' as keyof ReportSummaryData,
      label: 'Actions',
      accessor: (row) => {
        const reportIdx = `${row.name}-${row.number}-${row.monthYear}`;

        if (row.reports.length > 1) {
          return '-';
        }

        return (isLoadingDownloadReport || isLoadingRegenerateReport) &&
          selectedReport === reportIdx ? (
          <Stack spacing={1}>
            <CircularProgress size={24} />
          </Stack>
        ) : (
          <Stack
            spacing={1}
            direction={'row'}
            sx={{
              minHeight: '24px',
            }}
          >
            <Tooltip title="Download Report" placement="top">
              <IconButton
                sx={{
                  padding: 0,
                  top: '2px',
                  cursor: 'pointer',
                  color: 'primary.dark',
                  '&:hover': { color: 'primary.main' },
                }}
                onClick={(e) => {
                  setSelectedReport(reportIdx);
                  e.stopPropagation();
                  handleDownloadReport(row.reports[0]);
                }}
              >
                <DownloadIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Regenerate Report" placement="top">
              <IconButton
                sx={{
                  padding: 0,
                  top: '2px',
                  cursor: 'pointer',
                  color: 'primary.dark',
                  '&:hover': { color: 'primary.main' },
                }}
                onClick={(e) => {
                  setSelectedReport(reportIdx);
                  e.stopPropagation();
                  handleRegenerateReport(row.reports[0].billingNumber, row.reports[0].s3Path);
                }}
              >
                <CachedIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  const handleRowClick = useCallback(
    (row: ReportSummaryData) => {
      if (row.reports.length > 1 && row.number) {
        navigators.toDetailScreen?.(params.uuid || '', 'report', row.number, {
          startPeriod: row.startPeriod,
          endPeriod: row.endPeriod,
        });
      }
    },
    [navigators],
  );

  if (isLoading) {
    return <LoaderCard label="Loading Reports List ...." sx={{ p: 2 }} />;
  }

  if (data) {
    return (
      <CRUDPageableLayout>
        {isLoading && <LoaderCard label="Loading Reports..." sx={{ p: 4 }} />}

        {isError && <ErrorResponseCard errorCode={errorCode} title="Reports" />}

        {!isLoading && data && data?.content.length > 0 && (
          <Box component={Paper}>
            <BreadCrumbHeader breadcrumbs={breadcrumbs} />
            <DataTable
              columns={reportsColumns}
              rows={data.content}
              sortOptions={{ hasSort: true, order, orderBy, createSortHandler }}
              paginationOptions={paginationOptions}
              onRowClick={handleRowClick}
            />
          </Box>
        )}
      </CRUDPageableLayout>
    );
  }

  return null;
};

export default ReportsList;
