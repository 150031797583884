import Box from '@mui/material/Box';
import FormDivider from 'components/Form/FormDivider';
import Info from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import {
  DynamicExpandablePanel,
  useExpandablePanelGroup,
  DynamicForm,
  DynamicExpandableFormActions,
} from 'components/Dynamic';
import { PricingRecordDto, StatusTypes, UpdatePricingDto } from 'api/setup/manageServicesSetupApi';
import { getUpdateInformationData } from '../helper';
import { useContext, useEffect, useState } from 'react';
import { generalInfoFields } from '../PricingSetupConfig';
import { UseFormReturn } from 'react-hook-form';
import { Context } from '../components/PricingSetupProvider';
import { useUpdatePricingGeneralInfo } from '../PricingSetupQuery';
import { useParams } from 'react-router-dom';
import { changeTime } from 'utils/convertDate';

interface Props {
  data?: PricingRecordDto;
  isError?: boolean;
  error?: string;
  isCreationMode: boolean;
  hookForm: UseFormReturn<Partial<PricingRecordDto>>;
  toIndividualScreen?: (uuid: string, params?: Record<string, string>) => void;
}

export const PricingGeneralInfo = ({
  data,
  isError,
  error,
  isCreationMode,
  hookForm,
  toIndividualScreen,
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const { groupExpanded } = useExpandablePanelGroup();
  const { reset } = hookForm;
  const { isEditingSection, entityType } = useContext(Context);
  const { uuid } = useParams();

  const {
    mutateAsync: updatePricingGeneralInfo,
    isError: isUpdatePricingGeneralInfoError,
    errorMessage: updatePricingGeneralInfoErrorMessage,
  } = useUpdatePricingGeneralInfo();

  useEffect(() => {
    setExpanded(groupExpanded);
  }, [groupExpanded]);

  useEffect(() => {
    const generalInfoDefaultValues = () => {
      if (data) {
        return getUpdateInformationData(data);
      } else {
        return {
          startDateTime: '',
          endDateTime: '',
          description: '',
        };
      }
    };
    reset(generalInfoDefaultValues());
  }, [isCreationMode, data, reset]);

  const handleDiscard = () => {
    hookForm.reset();
    setEditMode(false);
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = async () => {
    const previousPricingDto: PricingRecordDto = { ...(data as PricingRecordDto) };
    previousPricingDto.endDateTime = changeTime(previousPricingDto.endDateTime, 'T23:59:59Z');

    const updatedPricingDto: PricingRecordDto = hookForm.getValues() as PricingRecordDto;
    updatedPricingDto.status = data?.status as StatusTypes;
    updatedPricingDto.endDateTime = changeTime(updatedPricingDto.endDateTime, 'T23:59:59Z');

    const pricingGeneralInfoData: UpdatePricingDto = {
      entityType,
      entityUuid: uuid as string,
      previousPricingDto,
      updatedPricingDto: updatedPricingDto as PricingRecordDto,
    };

    if (updatedPricingDto.endDateTime !== previousPricingDto.endDateTime) {
      try {
        await updatePricingGeneralInfo(pricingGeneralInfoData);

        setEditMode(false);

        toIndividualScreen?.(`${updatedPricingDto.startDateTime}|${updatedPricingDto.endDateTime}`);
      } catch (error: any) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <DynamicExpandablePanel
        title="General Info"
        icon={<Info />}
        onExpanded={() => setExpanded(!expanded)}
        expanded={expanded}
        actions={{
          isEditMode: isCreationMode || isEditMode,
          onEdit: handleEdit,
        }}
        editOptions={{
          disabled: isEditingSection,
        }}
      >
        <FormDivider label="Information" sx={{ mt: 1, mb: 3 }} />
        <DynamicForm
          hookForm={hookForm}
          fields={generalInfoFields(isCreationMode)}
          colspan={3}
          isEditMode={isCreationMode || isEditMode}
        />
        {isEditMode && (
          <DynamicExpandableFormActions
            isEditMode={isEditMode}
            save={{
              onSubmit: handleSave,
            }}
            discard={{
              onSubmit: handleDiscard,
            }}
          />
        )}

        {isError && (
          <Box sx={{ mt: 1 }}>
            <Typography variant="body1" color="#ff0000">
              {error}
            </Typography>
          </Box>
        )}
        {isUpdatePricingGeneralInfoError && (
          <Box sx={{ mt: 1 }}>
            <Typography color="error">
              An Error Has Occurred While Updating Pricing:
              {updatePricingGeneralInfoErrorMessage}
            </Typography>
          </Box>
        )}
      </DynamicExpandablePanel>
    </>
  );
};
