import { ListItem, ListItemProps } from '@mui/material';
import AppColors from 'AppColors';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { DrawerItem } from './AppDrawer';

export type DrawerMenuItemWrapperProps = ListItemProps &
  PropsWithChildren<{
    menuItem: DrawerItem;
    isActive?: boolean;
  }>;

const DrawerMenuItemWrapper = ({
  children,
  menuItem,
  isActive,
  ...rest
}: DrawerMenuItemWrapperProps) => {
  const navigate = useNavigate();

  const handleAction = () => {
    menuItem.action?.();
    if (menuItem.redirect && menuItem.path) {
      navigate(menuItem.path);
    }
  };

  return (
    <ListItem
      data-testid={`DrawerMenuItem-${menuItem.title}`}
      title={menuItem.title}
      disablePadding
      onClick={handleAction}
      sx={{
        display: 'flex',
        backgroundColor: isActive ? AppColors.AAA_LIGHT_BLUE : 'initial',
        whiteSpace: 'break-spaces',
      }}
      {...rest}
    >
      {children}
    </ListItem>
  );
};

export default DrawerMenuItemWrapper;
