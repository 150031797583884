import { Box, Button, FormControl } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, ControllerRenderProps, FieldValues } from 'react-hook-form';
import { ControlFieldProps } from '../types';
import { FileUploadInputElement } from './types';

export type ControlledImageUploadProps = FileUploadInputElement &
  ControlFieldProps & { label?: string; icon?: JSX.Element };

const ControlledImageUpload = ({
  control,
  name,
  rules,
  readOnly,
  label,
  icon,
  accept = 'image/*',
  ...rest
}: ControlledImageUploadProps) => {
  const [file, setFile] = useState<File | null>();
  const [imageUrl, setImageUrl] = useState<string>('');

  useEffect(() => {
    let fileReader: FileReader;
    let isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e: ProgressEvent<FileReader>) => {
        const url = e.target?.result;
        if (url && !isCancel) {
          setImageUrl(url as string);
        }
      };
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader?.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  const onFileSelected = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: ControllerRenderProps<FieldValues, string>,
  ) => {
    // eslint-disable-next-line prefer-destructuring
    const newFile = event.target.files && event.target.files[0];
    if (newFile) {
      field.onChange(newFile);
      setFile(newFile);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <FormControl>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!readOnly && (
              <Box>
                <Button variant="outlined" component="label" startIcon={icon}>
                  {label}
                  <input
                    {...rest}
                    data-testid="ImageUploadInput"
                    hidden
                    accept={accept}
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFileSelected(e, field)}
                  />
                </Button>
                <input hidden {...field} />
              </Box>
            )}
            {(imageUrl || field.value) && (
              <Box
                component="img"
                src={imageUrl || field.value}
                alt="Image Preview"
                sx={{ height: 75, width: 75, marginLeft: 2 }}
              />
            )}
          </Box>
        </FormControl>
      )}
    />
  );
};

export default ControlledImageUpload;
