import { ClientDto } from 'api/setup/manageClientApi';
import { CRUDComponent } from 'components/CRUDNavigator';
import { CRUDPageableList } from 'components/CRUDPageable';
import { ClientDataTableConfig, ClientSearchFields } from './ClientPageConfig';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';
import { ClientData, useClientContext } from './ClientProvider';
import { ClientSearch } from './types';

const ClientPageList: CRUDComponent = ({ navigators }) => {
  const context = useClientContext();
  const { permissions } = useRapCoreFeaturePermissions('clients');

  return (
    <CRUDPageableList<ClientDto, ClientData, ClientSearch>
      name="Client"
      context={context}
      navigators={navigators}
      searchFields={ClientSearchFields}
      searchDefaultValues={{
        rapClientId: '',
        name: '',
        contractStartDateTime: '',
        contractEndDateTime: '',
        rapAccountExecutive: '',
        active: '',
        'programs/rapProgramCode': '',
        'programs/name': '',
        'programs/rapProgramId': '',
      }}
      tableColumns={ClientDataTableConfig}
      canCreate={permissions.create}
    />
  );
};

export default ClientPageList;
