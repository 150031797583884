import { useCallback, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import PlusOne from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import LabeledValueGrid from 'components/LabeledValue/LabeledValueGrid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';
import { useUpdateBillingRequestStatus } from './BillingRequestQuery';
import { BillingRequestLevel, BillingRequestStatus } from 'api/invoices';
import { deleteInvoiceLine, updateInvoiceLine } from 'api/invoices/manageBillingRequestApi';
import DialogConfirmation from 'components/DialogConfirmation';
import { NotificationSnackbar, NotificationType } from 'components/Notifications';
import { useDataGrid } from 'components/DataGrid';
import { convertToCurrency } from 'utils/formatter';
import { BillingRequestItemData } from './types';
import { BillingRequestItemsTable, columns } from './components';
import BillingRequestAdjustmentForm from './components/BillingRequestAdjustmentForm';
import { useBillingRequestContext } from './BillingRequestProvider';
import BillingRequestAddInvoiceLine from './BillingRequestAddInvoiceLine';

function findUpdatedLines(array1: any[], array2: any[]) {
  const result = [];

  for (let i = 0; i < array1.length; i++) {
    const obj1 = array1[i];
    const obj2 = array2.find((item) => item.uuid === obj1.uuid);

    if (obj2) {
      // Compare properties for differences
      const keys = Object.keys(obj1);
      const diff = keys.filter((key) => obj1[key] !== obj2[key]);

      if (diff.length > 0) {
        result.push(obj2);
      }
    }
  }

  return result;
}

export default function BillingRequestDetail() {
  const [notifications, setNotifications] = useState<NotificationType>();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showAddInvoiceDialog, setAddInvoiceDialog] = useState<boolean>(false);
  const [showBillRequestDialog, setShowBillRequestDialogowDialog] = useState<boolean>(false);
  const [editingList, setEditingList] = useState<boolean>(false);
  const context = useBillingRequestContext();
  const { data } = context.getById;

  const { mutateAsync } = useUpdateBillingRequestStatus(data?.uuid ?? '');
  const { permissions: addInvoiceLineItem } = useRapCoreFeaturePermissions('addInvoiceLineItem');
  const { permissions: addBillRequestAdjust } =
    useRapCoreFeaturePermissions('addBillRequestAdjust');
  const { permissions: submitArBR } = useRapCoreFeaturePermissions('submitArBR');

  const refreshReportsList = useCallback(() => {
    if (data?.uuid) {
      context.getById.refetch();
    }
  }, [context, data?.uuid]);

  const gridValueItems = useMemo(
    () => [
      { label: 'Bill Request Number', value: data?.number },
      { label: 'Customer Number', value: data?.customerNumber },
      { label: 'PO Number', value: data?.purchaseOrderNumber },
      { label: 'Billing Period Range', value: data?.billingPeriodRange },
      { label: 'Contact Person', value: data?.contactPerson },
      { label: 'Assigned Account Executive', value: data?.assignedAccountExecutive },
    ],
    [data],
  );

  const defaultAdjustmentValues = useMemo(
    () =>
      data && {
        number: data.number,
        customerNumber: data.customerNumber,
        poNumber: data.purchaseOrderNumber,
        billingPeriodRange: data.billingPeriodRange.split(' - '),
      },
    [data],
  );

  const isSubmitToArDisabled = useMemo(
    () =>
      (data?.status !== BillingRequestStatus.REVIEW_SUBMISSION &&
        data?.status !== BillingRequestStatus.LATE_REVIEW_SUBMISSION) ||
      data?.totalDue === convertToCurrency(0) ||
      data?.billingLevel === BillingRequestLevel.PRE_PAID,
    [data],
  );

  const handleDialogConfirm = async () => {
    try {
      await mutateAsync(BillingRequestStatus.SUBMITTED);
      setNotifications({
        type: 'success',
        name: 'Success',
        message: 'Bill request submitted to AR.',
      });
      refreshReportsList();
    } catch (error: any) {
      setNotifications({
        type: 'error',
        name: 'Error',
        message: error.response.data.message,
      });
    }
    setShowDialog(false);
  };

  const disabledActions =
    (data?.status &&
      [
        BillingRequestStatus.OVERDUE,
        BillingRequestStatus.SUBMITTED,
        BillingRequestStatus.PAID,
        BillingRequestStatus.SENT,
      ].includes(data?.status)) ||
    data?.billingLevel === BillingRequestLevel.PRE_PAID;

  const datagridData = useMemo(() => {
    return (
      data?.lineItems.map((item) => ({
        ...item,
        totalPrice: editingList ? item.totalPrice.replace('$', '') : item.totalPrice,
        unitPrice: editingList ? item.unitPrice.replace('$', '') : item.unitPrice,
      })) || []
    );
  }, [data, editingList]);

  const datagrid = useDataGrid(datagridData, columns);

  const onSaveList = () => {
    const newData = datagrid.getData();
    const updatedLines = findUpdatedLines(datagridData, newData);

    if (updatedLines.length > 0) {
      Promise.all(
        updatedLines.map((item: BillingRequestItemData) =>
          updateInvoiceLine(data?.uuid || '', item.uuid, {
            ...item,
            amount: Number(item.unitPrice.replace(/,/g, '')),
          }),
        ),
      )
        .then(() => {
          setEditingList(false);
          refreshReportsList();
        })
        .catch((err) => {
          setNotifications({
            type: 'error',
            name: 'Error',
            message: err.response.data.message,
          });
        });
    } else {
      setEditingList(false);
    }
  };

  const onDeleteSelectedLines = () => {
    Promise.all(
      datagrid.observer
        .getSelectedItems()
        .filter((item) => item.type === 'ADJUSTMENT')
        .map((item: BillingRequestItemData) => deleteInvoiceLine(data?.uuid || '', item.uuid)),
    )
      .then((data: any[]) => {
        setEditingList(false);

        // if has deleted items, refresh
        if (data.length > 0) refreshReportsList();
      })
      .catch((err) => {
        setNotifications({
          type: 'error',
          name: 'Error',
          message: err.response.data.message,
        });
      });
  };

  if (data) {
    return (
      <>
        <Box pt={2} px={2}>
          <LabeledValueGrid colspan={2.4} items={gridValueItems} name={'BillRequest-Top-Grid'} />
          {editingList ? (
            <Stack direction="row" justifyContent="space-between" spacing={0} pt={2} pb={0}>
              <Button
                variant="outlined"
                startIcon={<DeleteIcon />}
                disabled={disabledActions || !defaultAdjustmentValues}
                onClick={onDeleteSelectedLines}
                data-testid={'BillRequest-DeleteLineItem-Btn'}
              >
                Delete Line Item
              </Button>
              <Button
                variant="outlined"
                color={'success'}
                startIcon={<EditIcon />}
                disabled={
                  disabledActions ||
                  !defaultAdjustmentValues ||
                  datagridData.length === 0 ||
                  editingList
                }
                onClick={() => setEditingList(true)}
                data-testid={'BillRequest-EditList-Btn'}
              >
                Edit List
              </Button>
            </Stack>
          ) : (
            <Stack direction="row" justifyContent="end" spacing={0} pb={0} pt={2}>
              <Button
                variant="outlined"
                color={editingList ? 'success' : undefined}
                startIcon={<EditIcon />}
                disabled={disabledActions || !defaultAdjustmentValues || datagridData.length === 0}
                onClick={() => setEditingList(true)}
                data-testid={'BillRequest-EditList-Btn'}
              >
                Edit List
              </Button>
            </Stack>
          )}

          <Stack mx={-2} pt={2} pb={2} data-testid={'BillingRequestItemsTable'}>
            <BillingRequestItemsTable hook={datagrid} editingList={editingList} />
          </Stack>

          <Stack direction="row" justifyContent="end" pb={2} data-testid={'BillRequest-TotalDue'}>
            <Typography pr={1}>Total Due:</Typography>
            <Typography fontWeight="bold">{data.totalDue}</Typography>
          </Stack>
          {!editingList ? (
            <Stack direction="row" justifyContent="end" spacing={2} pb={4}>
              <Button
                variant="outlined"
                startIcon={<PlusOne />}
                onClick={() => setAddInvoiceDialog(true)}
                disabled={!addInvoiceLineItem.update || disabledActions || !defaultAdjustmentValues}
                data-testid={'BillRequest-AddInvoiceLineItem-Btn'}
              >
                Add invoice line item
              </Button>
              <Button
                variant="outlined"
                startIcon={<DescriptionIcon />}
                disabled={
                  !addBillRequestAdjust.update || disabledActions || !defaultAdjustmentValues
                }
                onClick={() => setShowBillRequestDialogowDialog(true)}
                data-testid={'BillRequest-AddBillRequestAdjustment-Btn'}
              >
                Add Bill Request Adjustment
              </Button>
              <Button
                variant="contained"
                color="success"
                startIcon={<CheckIcon />}
                onClick={() => setShowDialog(true)}
                disabled={!submitArBR.update || isSubmitToArDisabled}
                data-testid={'BillRequest-SubmitToAR-Btn'}
              >
                submit to ar
              </Button>
            </Stack>
          ) : (
            <Stack direction="row" justifyContent="end" spacing={2} pb={4}>
              <Button
                variant="outlined"
                startIcon={<CloseIcon />}
                disabled={disabledActions || !defaultAdjustmentValues}
                onClick={() => setEditingList(false)}
                color="error"
                data-testid={'BillRequest-Cancel-Btn'}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                color="success"
                startIcon={<CheckIcon />}
                onClick={onSaveList}
                data-testid={'BillRequest-SaveList-Btn'}
              >
                Save List
              </Button>
            </Stack>
          )}
        </Box>
        <NotificationSnackbar
          clearNotification={() => setNotifications(undefined)}
          notification={notifications}
        />
        <DialogConfirmation
          open={showDialog}
          title="Confirmation"
          content="Are you sure you want to submit this bill request to AR?"
          onClose={() => setShowDialog(false)}
          onConfirm={handleDialogConfirm}
          labelConfirm="Submit to AR"
          labelCancel="Cancel"
        />
        {defaultAdjustmentValues && (
          <>
            <BillingRequestAddInvoiceLine
              setVisible={setAddInvoiceDialog}
              visible={showAddInvoiceDialog}
              onConfirmAddInvoice={refreshReportsList}
              billingRequestUuid={data.uuid}
              defaultAdjustmentValues={defaultAdjustmentValues}
            />
            <BillingRequestAdjustmentForm
              billingRequestUuid={data.uuid}
              visible={showBillRequestDialog}
              setVisible={setShowBillRequestDialogowDialog}
              defaultAdjustmentValues={defaultAdjustmentValues}
              onConfirmAddAdjustment={refreshReportsList}
            />
          </>
        )}
      </>
    );
  }

  return null;
}
