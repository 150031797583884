import { useCallback, useEffect, useRef, useState } from 'react';
import { Chip, InputBase, Typography, styled } from '@mui/material';
import AppColors from 'AppColors';
import { PrimaryContainer } from './PrimaryContainer';

const Pill = styled(Chip)`
  background-color: ${AppColors.AAA_CORNFLOWER_BLUE};
  height: 26px;

  .MuiChip-deleteIcon {
    color: ${AppColors.AAA_BLUE};
  }

  &.Mui-disabled {
    opacity: 1;
  }
`;

type ChipType = string | Record<string, unknown>;

export interface ChipInputProps<T extends ChipType> {
  id?: string;
  value?: T[];
  onChange?: (values: T[]) => void;
  decorateChip?: (chip: string) => T;
  getChipLabel?: (value: T) => string;
  readOnly?: boolean;
  options?: {
    maxLength?: {
      value: number;
      message: string;
    };
    validation?: {
      regex: RegExp;
      message: string;
    };
  };
}

function chipIsObject(chip: ChipType | null): chip is Record<string, unknown> {
  return Boolean(chip) && typeof chip === 'object';
}

export default function ChipInput<T extends ChipType>({
  onChange,
  value: chips = [],
  decorateChip,
  getChipLabel,
  readOnly,
  options,
}: ChipInputProps<T>) {
  const [message, setMessage] = useState<string | null>(null);
  const [blockInput, setBlockInput] = useState<boolean>(false);
  const [focused, setFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  // validate maxLength
  useEffect(() => {
    const maxLengthValue = options?.maxLength?.value;
    const maxLengthMessage = options?.maxLength?.message || null;

    if (maxLengthValue && maxLengthMessage) {
      if (chips.length === maxLengthValue) {
        setMessage(maxLengthMessage);
        setBlockInput(true);
        setFocused(false);
        inputRef.current && inputRef.current.blur();
      } else if (chips.length < maxLengthValue && message === null) {
        setBlockInput(false);
      }
    }
  }, [chips, message, options?.maxLength]);

  // handle readOnly change
  useEffect(() => {
    if (readOnly) {
      inputRef.current && inputRef.current.blur();
    }
  }, [readOnly]);

  // handle block change
  useEffect(() => {
    if (!blockInput) {
      inputRef.current && inputRef.current.focus();
    }
  }, [blockInput]);

  const getLabel = useCallback(
    (chip: T) => {
      if (chipIsObject(chip)) {
        return getChipLabel?.(chip) ?? JSON.stringify(chip);
      }
      return chip as string;
    },
    [getChipLabel],
  );

  const isInvalid = useCallback(
    (value: string) => {
      return options?.validation?.regex ? !options?.validation?.regex.test(value) : false;
    },
    [options],
  );

  const validateAndAddChip = useCallback(
    (chipValue: string) => {
      if (!isInvalid(chipValue)) {
        const newChip = decorateChip?.(chipValue) ?? (chipValue as T);
        const newChips = Array.from(new Set([...chips, newChip]));
        onChange?.(newChips);
        return true;
      } else {
        options?.validation && setMessage(options?.validation?.message);
        return false;
      }
    },
    [chips, decorateChip, isInvalid, onChange, options?.validation],
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { key, currentTarget } = event;
      const confirmKeys = ['Enter', ',', ';'];

      if (confirmKeys.includes(key) && !!currentTarget.value) {
        event.preventDefault();
        setMessage(null);

        if (validateAndAddChip(currentTarget.value)) {
          currentTarget.value = '';
        }
      }

      if (key === 'Backspace' && !currentTarget.value) {
        const newChips = chips.slice(0, -1);
        onChange?.(newChips);
        setMessage(null);
      }

      if (key === 'Backspace' && currentTarget.value.length === 1) {
        currentTarget.value = '';
        setMessage(null);
      }
    },
    [validateAndAddChip, chips, onChange],
  );

  const onBlur = useCallback(() => {
    if (inputRef.current && inputRef.current.value !== '') {
      if (validateAndAddChip(inputRef.current.value)) {
        inputRef.current.value = '';
      }
    }

    setFocused(false);
  }, [validateAndAddChip]);

  const handleDelete = useCallback(
    (index: number) => {
      const newChips = [...chips];
      newChips.splice(index, 1);
      onChange?.(newChips);
      setFocused(true);
      setMessage(null);
      inputRef.current && inputRef.current.focus();
    },
    [chips, onChange],
  );

  return (
    <>
      <PrimaryContainer focused={focused} disabled={readOnly || false}>
        {chips.map((chip, index) => {
          const label = getLabel(chip);
          return (
            <Pill
              key={`${label}-chip`}
              label={label}
              aria-label={`${label} chip`}
              disabled={readOnly}
              onDelete={() => handleDelete(index)}
            />
          );
        })}
        <InputBase
          inputRef={inputRef}
          onKeyDown={onKeyDown}
          disabled={readOnly || blockInput}
          onFocus={() => setFocused(true)}
          onBlur={onBlur}
          sx={{
            height: '26px',
          }}
        />
      </PrimaryContainer>

      {message && !readOnly && (
        <Typography
          variant="caption"
          display="flex"
          pt="3px"
          px="0.875rem"
          alignSelf="stretch"
          flexGrow={0}
          color="#00000099"
        >
          {message}
        </Typography>
      )}
    </>
  );
}
