import { CRUDComponent } from 'components/CRUDNavigator';
import { EntityCreationFlowProvider } from 'components/EntityCreationFlow';
import EntityCreationFlow from 'components/EntityCreationFlow/EntityCreationFlow';
import { ServiceItemFormSteps } from './ServiceItemsCreation/config';

const ServiceItemPageCreator: CRUDComponent = ({ navigators }) => {
  return (
    <EntityCreationFlowProvider>
      <EntityCreationFlow steps={ServiceItemFormSteps} navigators={navigators} />
    </EntityCreationFlowProvider>
  );
};

export default ServiceItemPageCreator;
