import { BaseAPI } from 'api/auth';
import { PageableResponse } from 'api/interfaces/responses';
import { ENV_BACKOFFICE_BASE_API_URL } from 'lib/envConfig';

export enum EntityTypes {
  PROGRAM = 'PROGRAM',
  CLIENT = 'CLIENT',
  CLUB = 'CLUB',
}

export enum DutyTypes {
  LIGHT = 'LIGHT',
  MEDIUM = 'MEDIUM',
  HEAVY = 'HEAVY',
}

export enum PricingTypes {
  FIXED = 'FIXED',
  AT_COST = 'AT_COST',
  AT_COST_PLUS = 'AT_COST_PLUS',
  UNIT = 'UNIT',
  NO_COST = 'NO_COST',
  DOUBLE_UNIT = 'DOUBLE_UNIT',
}

export enum UnitTypes {
  MILES = 'MILES',
  UNIT = 'UNIT',
}

export enum StatusTypes {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
}

export interface PricingRecordDto {
  startDateTime: string;
  endDateTime: string;
  status: StatusTypes;
  description: string;
}

export interface ServiceProvidedDto {
  uuid: string;
  createdAtDateTime?: string;
  updatedAtDateTime?: string;
  createdByUserId?: string;
  updatedByUserId?: string;
  code: string;
  name: string;
  description: string;
  unitType: UnitTypes;
  payable: boolean;
}

export interface ServiceSetupDto {
  uuid: string;
  createdAtDateTime?: string;
  updatedAtDateTime?: string;
  createdByUserId?: string;
  updatedByUserId?: string;
  startDateTime: string;
  endDateTime: string;
  description: string;
  serviceProvided: ServiceProvidedDto;
  entityType: EntityTypes;
  entityUuid: string;
  pricePerUnit: number;
  priceCap: number | null;
  overridingPricePerUnit: number | null;
  freeUnits: number;
  payable: boolean;
  billable: boolean;
  duty: DutyTypes;
  pricingType: PricingTypes;
}

export interface BaseCreateServiceSetupRequestDto {
  createdByUserId?: string;
  serviceProvidedUuid: string;
  entityType: EntityTypes;
  entityUuid: string;
  pricePerUnit: number;
  priceCap: number | null;
  overridingPricePerUnit: number | null;
  freeUnits: number;
  payable: boolean;
  billable: boolean;
  pricingType: PricingTypes;
}

export interface CreateServiceSetupBatchRequestDto {
  startDateTime: string;
  endDateTime: string;
  description: string;
  duty: DutyTypes;
  services: BaseCreateServiceSetupRequestDto[];
}

export interface UpdateServiceSetupRequestItemDto {
  updatedByUserId?: string;
  serviceProvidedUuid: string;
  entityType: EntityTypes;
  entityUuid: string;
  pricePerUnit: number;
  priceCap: number | null;
  overridingPricePerUnit: number | null;
  freeUnits: number;
  payable: boolean;
  billable: boolean;
  pricingType: PricingTypes;
  uuid: string;
}
export interface UpdateServiceSetupBatchRequestDto {
  duty: DutyTypes;
  services: UpdateServiceSetupRequestItemDto[];
}

export interface CreateServiceSetupRequestDto {
  createdByUserId?: string;
  serviceProvidedUuid: string;
  entityType: EntityTypes;
  entityUuid: string;
  pricePerUnit: number;
  priceCap: number | null;
  overridingPricePerUnit: number | null;
  freeUnits: number;
  payable: boolean;
  billable: boolean;
  pricingType: PricingTypes;
  startDateTime: string;
  endDateTime: string;
  description: string;
  duty: DutyTypes;
}

export interface UpdatePricingDto {
  entityType: EntityTypes;
  entityUuid: string;
  previousPricingDto: PricingRecordDto;
  updatedPricingDto: PricingRecordDto;
}

export function fetchAllServicesSetup(
  entityType: EntityTypes,
  entityUuid: string,
  startDateTime: string,
  endDateTime: string,
  duty?: DutyTypes,
) {
  return BaseAPI.get<PageableResponse<ServiceSetupDto>>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/services-setup?page=0&size=999&duty=${duty}&entityType=${entityType}&entityUuid=${entityUuid}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`,
  );
}

export function addServicesSetupBatch(servicesSetup: CreateServiceSetupBatchRequestDto) {
  return BaseAPI.post<CreateServiceSetupBatchRequestDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/services-setup/batch`,
    servicesSetup,
  );
}

export function updateServicesSetupBatch(servicesSetup: UpdateServiceSetupBatchRequestDto) {
  return BaseAPI.put<UpdateServiceSetupBatchRequestDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/services-setup/batch`,
    servicesSetup,
  );
}

export function addServiceSetup(serviceSetup: CreateServiceSetupRequestDto) {
  return BaseAPI.post<CreateServiceSetupRequestDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/services-setup`,
    serviceSetup,
  );
}

export function deleteServiceSetup(uuid: string) {
  return BaseAPI.delete<string>(`${ENV_BACKOFFICE_BASE_API_URL}/v1/services-setup/${uuid}`);
}

export function fetchPricingInfo(entityType: EntityTypes, entityUuid?: string) {
  return BaseAPI.get<PricingRecordDto[]>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/services-setup/pricing?entityType=${entityType}&entityUuid=${entityUuid}`,
  );
}

export function updatePricingGeneralInfo(data: UpdatePricingDto) {
  return BaseAPI.put<PricingRecordDto>(`${ENV_BACKOFFICE_BASE_API_URL}/v1/pricing-setup`, data);
}

// TODO: remove and use the correct fetch from serviceItemsApi and update the D
export function fetchServicesProvided() {
  return BaseAPI.get<PageableResponse<ServiceProvidedDto>>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/services-provided?page=0&size=999`,
  );
}
