export function removeDuplicatesAndSort<T>(list: T[]): T[] {
  return list.sort().filter((item, pos, ary) => !pos || item != ary[pos - 1]);
}

export function sumValues<T>(list: T[], filterCb: (value: T) => number): number {
  return list.reduce((total, item) => total + (filterCb(item) || 0), 0);
}

export function combineArraysAndRemoveDuplicates(arr1: any[], arr2: any[], index: string) {
  // Combine arrays
  const combinedArray = arr1.concat(arr2);

  // Create a map to keep track of unique objects based on the index field
  const map = new Map();
  combinedArray.forEach((obj) => {
    if (!map.has(obj[index])) {
      map.set(obj[index], obj);
    }
  });

  // Convert map values back to an array
  const uniqueArray = Array.from(map.values());

  return uniqueArray;
}
