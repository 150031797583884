import { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';

export function useDataSubscribe<T>(subject: BehaviorSubject<T>) {
  const [value, setValue] = useState(subject.value);

  useEffect(() => {
    const listener = subject.subscribe((listenerValue) => setValue(listenerValue));
    return () => listener.unsubscribe();
  }, [subject]);

  return value;
}
