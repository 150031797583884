import { useCallback, useState } from 'react';
import Card from '@mui/material/Card';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';

import DataTable, { DataTableColumn } from 'components/DataTable';
import { CRUDComponent } from 'components/CRUDNavigator';
import { LoaderCard } from 'components/Cards';
import { CallbackType, PaymentForecastsData } from './types';
import {
  usePaymentForecastsDownloadOneReports,
  usePaymentForecastsFetchAllQuery,
  usePaymentForecastsSendReports,
  usePaymentForecastsDownloadManyReports,
  useGenerateMonthlyReport,
} from './PaymentForecastsQuery';
import { convertToCurrency } from 'utils/formatter';
import { convertToMMYYYY } from 'utils/convertDate';
import { NotificationSnackbar, NotificationType } from 'components/Notifications';
import { ReportEntityType, ReportDto, ReportStatus } from 'features/Reports/types';
import { downloadReportFile, getFileType } from 'features/Reports/utils';

const PaymentForecastsList: CRUDComponent = ({ navigators }) => {
  const [notifications, setNotifications] = useState<NotificationType>();
  const { isLoading, data, refetch } = usePaymentForecastsFetchAllQuery();
  const { mutateAsync: paymentForecastsSendReports, isLoading: isReportSendLoading } =
    usePaymentForecastsSendReports();
  const { mutateAsync: generateMonthlyReport, isLoading: isGenerateMonthlyReportLoading } =
    useGenerateMonthlyReport();
  const {
    mutateAsync: paymentForecastsDownloadManyReports,
    isLoading: isReportManyDownloadLoading,
  } = usePaymentForecastsDownloadManyReports();
  const { mutateAsync: paymentForecastsDownloadOneReports, isLoading: isReportOneDownloadLoading } =
    usePaymentForecastsDownloadOneReports();
  const [currentPaymentGroupNumber, setCurrentPaymentGroupNumber] = useState<number>();

  const handleRowClick = useCallback(
    (row: PaymentForecastsData) => {
      navigators.toIndividualScreen(row.paymentGroupNumber.toString() ?? '', {
        startPeriod: row.startPeriod,
        endPeriod: row.endPeriod,
      });
    },
    [navigators],
  );

  const getPaymentRequestTableColumns = useCallback(
    (
      callback: (value: ReportDto, type: CallbackType) => void,
    ): DataTableColumn<PaymentForecastsData>[] => {
      return [
        { id: 'paymentGroupNumber', label: 'Payment Forecast ID', sortable: true },
        {
          id: 'startPeriod',
          label: 'Month/Year',
          accessor: (row) => convertToMMYYYY(row.startPeriod),
          sortable: true,
        },
        {
          id: 'totalCases',
          label: 'Total Cases',
          accessor: (row) => (
            <Tooltip title="Number of Payable Cases" placement="top">
              <Stack>{row?.totalCases}</Stack>
            </Tooltip>
          ),
        },
        {
          id: 'totalClubPayments',
          label: 'Total Club Payments',
          accessor: (row) => convertToCurrency(row.totalClubPayments),
        },
        {
          id: 'files',
          label: 'Files',
          accessor: (row: PaymentForecastsData) => (
            <Stack>
              {row?.reports?.map((report) => (
                <Stack
                  key={report.name}
                  sx={{
                    minHeight: '24px',
                  }}
                >
                  {report.name}
                </Stack>
              ))}
            </Stack>
          ),
          minWidth: '240px',
        },
        {
          id: 'recipient',
          label: 'Recipients',
          accessor: (row: PaymentForecastsData) => (
            <Stack>
              {row?.reports?.map((report) => (
                <Stack
                  key={report.recipient}
                  sx={{
                    minHeight: '24px',
                  }}
                >
                  {report.recipient}
                </Stack>
              ))}
            </Stack>
          ),
        },
        {
          id: 'sentAtDateTime',
          label: 'Sent Date',
          accessor: (row: PaymentForecastsData) => (
            <Stack>
              {row?.reports?.map((report, reportId) => (
                <Stack
                  key={`${report.sentAtDateTime}-${reportId}`}
                  sx={{
                    minHeight: '24px',
                  }}
                >
                  {report.sentAtDateTime
                    ? moment(new Date(report.sentAtDateTime)).format('MM/DD/YYYY')
                    : '-'}
                </Stack>
              ))}
            </Stack>
          ),
        },
        {
          id: 'reports',
          label: 'Actions',
          accessor: (row: PaymentForecastsData) =>
            (isReportOneDownloadLoading || isReportManyDownloadLoading || isReportSendLoading) &&
            currentPaymentGroupNumber === row.paymentGroupNumber ? (
              <Stack spacing={1} p={1.5}>
                <CircularProgress size={24} />
              </Stack>
            ) : (
              <Stack>
                {row?.reports?.map((report, reportId) => (
                  <Stack
                    direction="row"
                    spacing={1}
                    key={`${report.uuid}-${reportId}`}
                    sx={{
                      minHeight: '24px',
                    }}
                  >
                    <Tooltip title="This action will send out multiple files" placement="top">
                      <span>
                        <IconButton
                          sx={{
                            padding: 0,
                            top: '-4px',
                            cursor: 'pointer',
                            color: 'primary.dark',
                            '&:hover': { color: 'primary.main' },
                          }}
                          onClick={(e) => {
                            setCurrentPaymentGroupNumber(row.paymentGroupNumber);
                            e.stopPropagation();
                            callback(report, CallbackType.SEND);
                          }}
                          disabled={
                            !!report.sentAtDateTime ||
                            ![ReportStatus.READY, ReportStatus.SENT].includes(
                              report.reportStatus as ReportStatus,
                            )
                          }
                        >
                          <ForwardToInboxIcon sx={{ fontSize: 18 }} />
                        </IconButton>
                      </span>
                    </Tooltip>

                    <IconButton
                      sx={{
                        padding: 0,
                        top: '-4px',
                        cursor: 'pointer',
                        color: 'primary.dark',
                        '&:hover': { color: 'primary.main' },
                      }}
                      onClick={(e) => {
                        setCurrentPaymentGroupNumber(row.paymentGroupNumber);
                        e.stopPropagation();
                        callback(report, CallbackType.DOWNLOAD);
                      }}
                      disabled={
                        ![ReportStatus.READY, ReportStatus.SENT].includes(
                          report.reportStatus as ReportStatus,
                        )
                      }
                    >
                      <DownloadIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                  </Stack>
                ))}
              </Stack>
            ),
        },
      ];
    },
    [
      isReportManyDownloadLoading,
      isReportOneDownloadLoading,
      isReportSendLoading,
      currentPaymentGroupNumber,
    ],
  );

  const handleReportAction = useCallback(
    (report: ReportDto, type: CallbackType) => {
      if (report.startPeriod && report.endPeriod && report.entityType) {
        if (type === CallbackType.DOWNLOAD) {
          if (report.entityType === ReportEntityType.FORECAST) {
            paymentForecastsDownloadManyReports({
              startDate: report.startPeriod,
              endDate: report.endPeriod,
              reportEntityType: report.entityType,
            })
              .then((res: any) => {
                const month = report?.startPeriod?.split('-')[1];
                const year = report?.startPeriod?.split('-')[0];
                const downloadBody = {
                  res,
                  reportName: report.name,
                  setNotifications,
                  fileType: 'application/zip',
                  fileName: `reports_FORECAST_${month}${year}.zip`,
                };
                downloadReportFile(downloadBody);
              })
              .catch(() => {
                setNotifications({
                  name: `Download ${report.name} Report`,
                  type: 'error',
                  message: `The ${report.name} report has not been downloaded, try again later`,
                });
              });
          } else {
            paymentForecastsDownloadOneReports(report.uuid)
              .then((res: any) => {
                const downloadBody = {
                  res,
                  reportName: report.name,
                  setNotifications,
                  fileType: getFileType(report.s3Path),
                  fileName: report.s3Path.split('/').pop() ?? '',
                };
                downloadReportFile(downloadBody);
              })
              .catch(() => {
                setNotifications({
                  name: `Download ${report.name} Report`,
                  type: 'error',
                  message: `The ${report.name} report has not been downloaded, try again later`,
                });
              });
          }
        }
        if (type === CallbackType.SEND) {
          paymentForecastsSendReports({
            startDate: report.startPeriod,
            endDate: report.endPeriod,
            reportEntityType: report.entityType,
          })
            .then(() => {
              setNotifications({
                name: `Send ${report.name} Report`,
                type: 'success',
                message: `The ${report.name} report has been sent successfully`,
              });
              refetch();
            })
            .catch(() => {
              setNotifications({
                name: `Send ${report.name} Report`,
                type: 'error',
                message: `The ${report.name} report has not been sent, try again later`,
              });
            });
        }
      }
    },
    [
      refetch,
      paymentForecastsDownloadManyReports,
      paymentForecastsDownloadOneReports,
      paymentForecastsSendReports,
    ],
  );

  const handleGenerateMonthlyReport = useCallback(() => {
    generateMonthlyReport()
      .then(() => {
        refetch().then(() => {
          setNotifications({
            name: 'Generate Monthly Report',
            type: 'success',
            message: 'The monthly report has been generated successfully',
          });
        });
      })
      .catch((error) => {
        refetch().then(() => {
          setNotifications({
            name: 'Generate Monthly Report',
            type: 'error',
            message:
              error?.response?.data?.message ??
              'The monthly report has not been generated, try again later',
          });
        });
      });
  }, [generateMonthlyReport, refetch]);

  if (isLoading) {
    return <LoaderCard label="Loading Payment Forecast List ...." sx={{ p: 2 }} />;
  }

  if (data) {
    return (
      <Card>
        <NotificationSnackbar
          clearNotification={() => setNotifications(undefined)}
          notification={notifications}
        />
        <Box sx={{ p: 2 }} justifyContent="space-between" alignItems="center" display="flex">
          <Typography fontWeight="bold" component="div">
            Payment Forecast
          </Typography>
          <Button
            variant="contained"
            onClick={handleGenerateMonthlyReport}
            disabled={isGenerateMonthlyReportLoading}
          >
            Generate Monthly payment forecasts Report
          </Button>
        </Box>
        <DataTable
          columns={getPaymentRequestTableColumns(handleReportAction)}
          rows={data}
          onRowClick={handleRowClick}
        />
      </Card>
    );
  }

  return null;
};

export default PaymentForecastsList;
