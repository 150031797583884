import { Box, CircularProgress, CircularProgressProps, Typography } from '@mui/material';
import { useMemo } from 'react';
import { BackgroundCircularPath, ContentBox } from './CircularProgressWithContent.styled';

export type CircularProgressWithContentProps = CircularProgressProps & {
  content?: JSX.Element;
  /** If true, `content` is ignored and `value` is displayed as a percentile. */
  displayValue?: boolean;
  maxValue?: number;
  progressBarColor?: string;
  shade?: boolean;
  textColor?: string;
  value: number;
};

const CircularProgressWithContent = ({
  content,
  progressBarColor = 'primary',
  displayValue,
  maxValue = 100,
  shade,
  size = 50,
  textColor = 'text.secondary',
  value,
  ...rest
}: CircularProgressWithContentProps) => {
  // CircularProgress renders the progress bar fill in base 100.
  // Therefore, `value` needs to be calculated as a percentage of `maxValue`.
  const progressBarFillValue = useMemo(() => (value / maxValue) * 100, [value, maxValue]);

  const progressLabel = useMemo(() => `${Math.round(value)}%`, [value]);

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', width: size, height: size }}>
      {shade && (
        <BackgroundCircularPath
          size={size}
          variant="determinate"
          value={100}
          pathColor={progressBarColor}
        />
      )}
      <CircularProgress
        size={size}
        variant="determinate"
        value={progressBarFillValue}
        {...rest}
        sx={{
          color: progressBarColor,
        }}
      />
      <ContentBox>
        {displayValue ? (
          <Typography variant="caption" component="div" color={textColor}>
            {progressLabel}
          </Typography>
        ) : (
          content
        )}
      </ContentBox>
    </Box>
  );
};

export default CircularProgressWithContent;
