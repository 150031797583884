import {
  BillingRequestStatus,
  BillingRequestStatusSchema,
} from 'api/invoices/interfaces/BillingRequestStatus';
import { SchemaStatusChip } from 'components/Labels';

interface Props {
  status: BillingRequestStatus;
}

export function BillingRequestStatusChip({ status }: Props) {
  return <SchemaStatusChip status={status} schemelist={BillingRequestStatusSchema} />;
}
