import { useMappedReactQuery } from 'hooks/useReactQueryHelpers';
import { useMemo } from 'react';
import { CreditCardTransactionCounts } from '../types';
import { ErrorCountResponse } from 'api/dashboard/types';
import {
  fetchInQueue,
  fetchSettledYesterday,
  fetchFailedToSettleYesterday,
  fetchTotalErrors,
} from 'api/dashboard/creditCardsSummaryApi';

export const useTotalErrorsQuery = () =>
  useMappedReactQuery('QUERY_TOTAL_CASES_ERRORS', fetchTotalErrors, {});

export const useInQueueQuery = () => useMappedReactQuery('QUERY_IN_QUEUE', fetchInQueue, {});

export const useSettledYesterdayQuery = () =>
  useMappedReactQuery('QUERY_SETTLED_YESTERDAY', fetchSettledYesterday, {});

export const useFailedToSettleYesterdayQuery = () =>
  useMappedReactQuery('QUERY_FAILED_TO_SETTLE_YESTERDAY', fetchFailedToSettleYesterday, {});

export const useCreditCardsSummaryQuery = () => {
  const { data: totalErrors, isLoading: loadingTotal } = useTotalErrorsQuery();
  const { data: inQueue, isLoading: loadingInQueue } = useInQueueQuery();
  const { data: settledYesterday, isLoading: loadingSettledYesterday } = useSettledYesterdayQuery();
  const { data: failedToSettleYesterday, isLoading: loadingFailedToSettleYesterday } =
    useFailedToSettleYesterdayQuery();

  const errorCounts: CreditCardTransactionCounts = useMemo(() => {
    const defaultCountData: ErrorCountResponse = { count: 0, appliedFilter: '' };
    return {
      total: totalErrors || defaultCountData,
      inQueue: inQueue || defaultCountData,
      settledYesterday: settledYesterday || defaultCountData,
      failedToSettleYesterday: failedToSettleYesterday || defaultCountData,
    };
  }, [totalErrors, inQueue, settledYesterday, failedToSettleYesterday]);

  const isLoading = useMemo(
    () =>
      loadingTotal || loadingInQueue || loadingSettledYesterday || loadingFailedToSettleYesterday,
    [loadingTotal, loadingInQueue, loadingSettledYesterday, loadingFailedToSettleYesterday],
  );

  return { errorCounts, isLoading };
};
