import React, { useMemo } from 'react';
import { ErrorCard, FullCenteredCardProps } from 'components/Cards';
import { ErrorData } from 'hooks/useReactQueryHelpers';

type ErrorCardProps = FullCenteredCardProps & {
  title?: string;
  errorCode?: ErrorData;
};

const errorLabels = (suffix: string) => ({
  [ErrorData.BadRequest]: `Bad Request was occurred to sent ${suffix}`,
  [ErrorData.Unauthorized]: `Your token is not valid to get ${suffix}`,
  [ErrorData.Forbidden]: `Your profile is not authorized to get ${suffix}`,
  [ErrorData.NotFound]: `There is no data for ${suffix}`,
  [ErrorData.ServerError]: `A server error occurred fetching ${suffix}`,
  [ErrorData.Undefined]: '',
});

const ErrorResponseCard: React.FC<ErrorCardProps> = ({
  title = 'Data',
  errorCode = ErrorData.Undefined,
  ...props
}) => {
  const label = useMemo(() => {
    return errorLabels(title)[errorCode] || `An error occurred to fetch ${title}`;
  }, [title, errorCode]);
  return <ErrorCard {...props} label={label} />;
};

export default ErrorResponseCard;
