import { useGetPrograms } from 'features/Setup/Programs/ProgramQuery';

export function useGeneralInfoCoreData() {
  const { data: programData } = useGetPrograms({
    page: 0,
    size: 999,
    sort: 'rapProgramId,asc',
  });

  return { programData };
}
