import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren & {
  stackSpacing?: string | number;
};

export function CRUDPageableLayout({ stackSpacing = '10px', children }: Props) {
  return (
    <Box mt="50" flexGrow={1}>
      <Stack spacing={stackSpacing}>{children}</Stack>
    </Box>
  );
}
