import { Box, Button, TextField, Typography } from '@mui/material';
import AppColors from 'AppColors';
import { FullCenteredCard } from 'components/Cards';
import DataTable from 'components/DataTable';
import DialogConfirmation from 'components/DialogConfirmation';
import FormDivider from 'components/Form/FormDivider';
import { useCaseErrorUpdate } from 'features/Cases/queries';
import { useCallback, useState } from 'react';
import { CASE_ERRORS_PERMISSION, useCaseErrorsConfig } from './config';
import { AuthComponent } from 'auth/permissions/authComponent';
import { CaseErrorDto, ResolutionTypes } from 'api/cases/interfaces/CaseErrorDto';
import { RbpCaseStatus } from 'core/enums/RbpCaseStatus';

interface Props {
  errors?: CaseErrorDto[];
  caseUUID: string;
  rbpCaseStatus: RbpCaseStatus;
}

function ErrorsTable({ errors, caseUUID, rbpCaseStatus }: Props) {
  const { columnsPermissions, errorTable } = useCaseErrorsConfig();
  const { columns: errorsColumns } = columnsPermissions;

  const [ignoreCandidate, setIgnoreCandidate] = useState<string | null>(null);
  const { mutateAsync, isError, errorMessage } = useCaseErrorUpdate(caseUUID);
  const showIgnoreDialog = Boolean(ignoreCandidate);
  const [statusReason, setStatusReason] = useState<string>('');

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('');

  const createSortHandler = (_: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const closeIgnoreDialog = useCallback(() => {
    setIgnoreCandidate(null);
    setStatusReason('');
  }, []);

  const handleIgnoreError = useCallback(() => {
    if (!ignoreCandidate) return;
    mutateAsync([{ statusReason, resolutionType: ResolutionTypes.IGNORED, uuid: ignoreCandidate }]);
    closeIgnoreDialog();
  }, [closeIgnoreDialog, mutateAsync, statusReason, ignoreCandidate]);

  const renderActions = useCallback(
    (row: CaseErrorDto) => {
      const canIgnore = row.ignorable && row.resolutionType !== ResolutionTypes.RESOLVED;

      const disabled =
        (!errorTable?.fields.resolutionType.update && !errorTable?.fields.statusReason.update) ||
        row.resolutionType === ResolutionTypes.IGNORED ||
        rbpCaseStatus === RbpCaseStatus.CANCELLED;

      return (
        canIgnore && (
          <Button
            onClick={() => setIgnoreCandidate(row.uuid)}
            variant="outlined"
            disabled={disabled}
            sx={{
              width: '55px',
              height: '24px',
              textTransform: 'capitalize',
              color: AppColors.AAA_WHITE,
              backgroundColor: AppColors.AAA_BLUE,
              '&:disabled': {
                opacity: 0.5,
                color: AppColors.AAA_WHITE,
              },
              '&:hover': {
                backgroundColor: AppColors.AAA_BLUE,
              },
            }}
          >
            Ignore
          </Button>
        )
      );
    },

    [
      errorTable?.fields.resolutionType.update,
      errorTable?.fields.statusReason.update,
      rbpCaseStatus,
    ],
  );

  return (
    <>
      <FormDivider label="Case Errors" sx={{ mb: 3 }} />
      <Box sx={{ pb: 4, mx: -4 }}>
        {errors && errors.length ? (
          <DataTable<CaseErrorDto>
            columns={errorsColumns}
            rows={errors}
            sortOptions={{ hasSort: true, order, orderBy, createSortHandler }}
            renderActions={renderActions}
          />
        ) : (
          <FullCenteredCard sx={{ fontSize: 20, boxShadow: 0, color: AppColors.AAA_QUARTZ }}>
            No Case Errors
          </FullCenteredCard>
        )}
      </Box>

      <DialogConfirmation
        open={showIgnoreDialog}
        title="Ignore Error"
        onClose={closeIgnoreDialog}
        onConfirm={handleIgnoreError}
        labelConfirm="Ignore Error"
        sx={{
          '& .MuiDialog-paper': {
            width: 'fit-content',
            maxWidth: '100%',
          },
          '& .MuiDialogTitle-root': {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 400,
          },
        }}
      >
        <Box width="50vw">
          <TextField
            id="outlined-multiline-flexible"
            label="Reason"
            multiline
            rows={3}
            fullWidth
            onChange={(e) => setStatusReason(e.target.value)}
          />

          {isError && (
            <Typography color="error">
              An Error Has Occurred While Ignoring Error:
              {errorMessage}
            </Typography>
          )}
        </Box>
      </DialogConfirmation>
    </>
  );
}

export default AuthComponent(CASE_ERRORS_PERMISSION, ErrorsTable);
