import VendorGeneralInfo from './VendorGeneralInfo';
import { VendorData } from '../types';
import { EntityTypes } from 'api/setup/manageServicesSetupApi';
import { PricingSetupPanel } from 'features/Setup/PricingSetup/PricingSetupPanel';
import VendorPaymentInfo from './VendorPaymentInfo';

interface Props {
  vendorData?: VendorData;
  toIndividualScreen?: (uuid: string) => void;
}

export default function VendorDetailGroup({ vendorData, toIndividualScreen }: Props) {
  return (
    <>
      <VendorGeneralInfo initialData={vendorData} onAfterCreate={toIndividualScreen} />

      {vendorData && (
        <>
          <VendorPaymentInfo entityUUID={vendorData?.uuid} />
          <PricingSetupPanel entityType={EntityTypes.CLUB} entityUuid={vendorData?.uuid} />
        </>
      )}
    </>
  );
}
