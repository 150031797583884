import { useQuery, useQueryClient } from 'react-query';
import {
  fetchBreakdownLocationByUUID,
  fetchTowDestinationsByUUID,
  updateTowDestinationByUUID,
  deleteTowDestinationByUUID,
  addTowDestinationByUUID,
  updateBreakdownLocationByUUID,
} from 'api/cases/manageCasesApi';
import { TowDestinationDto } from 'api/cases/interfaces/TowDestinationDto';
import { TowDestinationRecord } from 'features/Cases/interfaces/CaseViewPanels';
import { extractAddressParts } from 'utils/extractAddressPart';
import { extractPhoneParts } from 'utils/extractPhoneParts';
import { useManagedMutation } from 'hooks/useReactQueryHelpers';
import { BreakdownLocationDto } from 'api/cases/interfaces/BreakdownLocationDto';

const QUERY_BREAKDOWN_LOCATION_BY_UUID = 'QUERY_BREAKDOWN_LOCATION_BY_UUID';
const QUERY_TOW_DESTINATIONS_BY_UUID = 'QUERY_TOW_DESTINATIONS_BY_UUID_AAA';

export interface BreakdownLocationData {
  uuid: string;
  compositeAddress: string;
  streetNumber: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  latitude: string;
  longitude: string;
  streetDirection: string;
  mileMarker: string;
  crossStreet: string;
  landmark: string;
}

const mapBreakdownLocationDtoToData = (data: BreakdownLocationDto): BreakdownLocationData => {
  const {
    uuid,
    compositeAddress = '',
    streetNumber = '',
    street = '',
    city = '',
    state = '',
    zipCode = '',
    latitude = '',
    longitude = '',
    streetDirection = '',
    mileMarker = '',
    crossStreet = '',
    landmark = '',
  } = data;

  return {
    uuid,
    compositeAddress,
    streetNumber,
    street,
    city,
    state,
    zipCode,
    latitude,
    longitude,
    streetDirection,
    mileMarker,
    crossStreet,
    landmark,
  };
};

const mapBreakdownLocationDataToDto = (
  data: Partial<BreakdownLocationData>,
): Partial<BreakdownLocationDto> => {
  const { uuid, ...rest } = data;

  return { ...rest };
};

const mapTowDestinationDtoToData = (data: TowDestinationDto[]): TowDestinationRecord[] => {
  return data.map((row) => {
    const cityStateZipParts =
      [row.city, row.state].filter((part) => part).join(', ') +
      (row.postalCode ? ` ${row.postalCode}` : '');
    const fullPhoneNumberParts = [row.phoneExtension, row.phoneNumber]
      .filter((part) => part)
      .join(' ');

    return {
      uuid: row.uuid,
      locationName: row.locationName || '',
      addressType: row.addressType || '',
      computedTowMiles: row?.computedTowMiles?.toString() || '0',
      address: row.address || '',
      cityStateZip: cityStateZipParts,
      fullPhoneNumber: fullPhoneNumberParts,
      dealerId: row.dealerId || '',
      landmark: row.landmark || '',
    };
  });
};

const mapTowDestinationDataToDto = (
  data: Partial<TowDestinationRecord>,
): Partial<TowDestinationDto> => {
  const { city, state, postalCode } = extractAddressParts(data.cityStateZip);
  const { phoneExtension, phoneNumber } = extractPhoneParts(data.fullPhoneNumber);

  return {
    uuid: data.uuid,
    locationName: data.locationName,
    addressType: data.addressType,
    computedTowMiles: data?.computedTowMiles ? parseFloat(data.computedTowMiles) : 0,
    address: data.address,
    city: city,
    state: state,
    postalCode: postalCode,
    phoneExtension: phoneExtension,
    phoneNumber: phoneNumber,
    dealerId: data.dealerId,
    landmark: data.landmark,
  };
};

export function useGetBreakdownLocationByUUID(caseId: string, enabled: boolean) {
  return useQuery(
    [QUERY_BREAKDOWN_LOCATION_BY_UUID, caseId],
    async () => {
      try {
        const data = await fetchBreakdownLocationByUUID(caseId);
        return data;
      } catch (error: any) {
        if (error?.response?.status === 404) {
          return {} as BreakdownLocationDto;
        }
        throw error;
      }
    },
    { enabled, select: mapBreakdownLocationDtoToData },
  );
}

export function useUpdateBreakdownLocationByUUID(caseUUID: string) {
  const queryClient = useQueryClient();
  return useManagedMutation({
    mutationFn: (breakdownLocationData: Partial<BreakdownLocationData>) =>
      updateBreakdownLocationByUUID(
        caseUUID,
        breakdownLocationData.uuid || '',
        mapBreakdownLocationDataToDto(breakdownLocationData),
      ),
    onSuccess: () => queryClient.invalidateQueries([QUERY_BREAKDOWN_LOCATION_BY_UUID]),
  });
}

export function useGetTowDestinationsByUUID(caseId: string, enabled: boolean) {
  return useQuery(
    [QUERY_TOW_DESTINATIONS_BY_UUID, caseId],
    () => fetchTowDestinationsByUUID(caseId),
    { enabled, select: mapTowDestinationDtoToData },
  );
}

export function useUpdateTowDestinationByUUID(caseUUID: string) {
  const queryClient = useQueryClient();
  return useManagedMutation({
    mutationFn: (towDestinationData: Partial<TowDestinationRecord>) =>
      updateTowDestinationByUUID(
        caseUUID,
        towDestinationData.uuid,
        mapTowDestinationDataToDto(towDestinationData),
      ),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_TOW_DESTINATIONS_BY_UUID]);
    },
  });
}

export function useDeleteTowDestinationByUUID(caseUUID: string) {
  const queryClient = useQueryClient();
  return useManagedMutation({
    mutationFn: (towDestinationUUID: string) =>
      deleteTowDestinationByUUID(caseUUID, towDestinationUUID),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_TOW_DESTINATIONS_BY_UUID]);
    },
  });
}

export function useAddTowDestinationByUUID(caseUUID: string) {
  const queryClient = useQueryClient();

  return useManagedMutation({
    mutationFn: (towDestinationData: Partial<TowDestinationRecord>) =>
      addTowDestinationByUUID(caseUUID, mapTowDestinationDataToDto(towDestinationData)),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_TOW_DESTINATIONS_BY_UUID]);
    },
  });
}
