import { RolePermissions } from './types';

export const rapCorePermissions: RolePermissions = {
  NATIONAL_IT_SUPER_USER_ADMIN: {
    vendors: { create: true, read: true, update: true, delete: true },
    clients: { create: true, read: true, update: true, delete: true },
    programs: { create: true, read: true, update: true, delete: true },
    servicesProvided: { create: true, read: true, update: true, delete: true },
    servicesSetup: { create: true, read: true, update: true, delete: true },
    dashboardCreditCardsSummary: { read: true },
    addOnsTable: { update: true },
    caseFinancialSession: { read: true },
    attachCaseToBR: { update: true },
    forceCls: { update: true },
    uncancelCase: { update: true },
    cancelBillingRequest: { update: true, read: true },
    closeBillingRequest: { update: true, read: true },
    addInvoiceLineItem: { update: true, read: true },
    addBillRequestAdjust: { update: true, read: true },
    submitArBR: { update: true, read: true },
    cancelCase: { update: true, read: true },
  },
  NATIONAL_IT_SUPER_USER_ADMIN_READ_ONLY: {
    vendors: { read: true },
    clients: { read: true },
    programs: { read: true },
    servicesProvided: { read: true },
    servicesSetup: { read: true },
    dashboardCreditCardsSummary: { read: true },
    addOnsTable: { read: true },
    caseFinancialSession: { read: true },
    attachCaseToBR: { read: true },
    forceCls: { read: true },
    uncancelCase: { read: true },
    cancelBillingRequest: { update: false, read: true },
    closeBillingRequest: { update: false, read: true },
    addInvoiceLineItem: { update: false, read: true },
    addBillRequestAdjust: { update: false, read: true },
    submitArBR: { update: false, read: true },
    cancelCase: { update: false, read: true },
  },
  NATIONAL_RAP_ADMIN: {
    vendors: { create: true, read: true, update: true, delete: true },
    clients: { create: true, read: true, update: true, delete: true },
    programs: { create: true, read: true, update: true, delete: true },
    servicesProvided: { create: true, read: true, update: true, delete: true },
    servicesSetup: { create: true, read: true, update: true, delete: true },
    dashboardCreditCardsSummary: { read: true },
    addOnsTable: { update: true },
    caseFinancialSession: { read: true },
    attachCaseToBR: { update: true },
    forceCls: { update: true },
    uncancelCase: { update: true },
    cancelBillingRequest: { update: true, read: true },
    closeBillingRequest: { update: true, read: true },
    addInvoiceLineItem: { update: true, read: true },
    addBillRequestAdjust: { update: true, read: true },
    submitArBR: { update: true, read: true },
    cancelCase: { update: true, read: true },
  },
  NATIONAL_RAP_RECONCILER_ANALYST: {
    vendors: { create: false, read: true, update: false, delete: false },
    clients: { create: false, read: true, update: false, delete: false },
    programs: { create: false, read: true, update: false, delete: false },
    servicesProvided: { create: false, read: true, update: false, delete: false },
    servicesSetup: { create: false, read: true, update: false, delete: false },
    dashboardCreditCardsSummary: { read: true },
    addOnsTable: { update: true },
    caseFinancialSession: { read: true },
    uncancelCase: { update: true },
    cancelBillingRequest: { update: true, read: true },
    closeBillingRequest: { update: true, read: true },
    addInvoiceLineItem: { update: true, read: true },
    addBillRequestAdjust: { update: true, read: true },
    submitArBR: { update: true, read: true },
    cancelCase: { update: true, read: true },
  },
  NATIONAL_RAP_ACCOUNT_EXECUTIVE: {
    vendors: { create: false, read: true, update: false, delete: false },
    clients: { create: false, read: true, update: false, delete: false },
    programs: { create: false, read: true, update: false, delete: false },
    servicesProvided: { create: false, read: true, update: false, delete: false },
    servicesSetup: { create: false, read: true, update: false, delete: false },
    dashboardCreditCardsSummary: { read: true },
    addOnsTable: { update: true },
    caseFinancialSession: { read: true },
    uncancelCase: { update: true },
    cancelBillingRequest: { update: true, read: true },
    closeBillingRequest: { update: true, read: true },
    addInvoiceLineItem: { update: true, read: true },
    addBillRequestAdjust: { update: true, read: true },
    submitArBR: { update: true, read: true },
    cancelCase: { update: true, read: true },
  },
  NATIONAL_FINANCE_ADVISOR: {
    vendors: { create: true, read: true, update: true, delete: true },
    clients: { create: true, read: true, update: true, delete: true },
    programs: { create: true, read: true, update: true, delete: true },
    servicesProvided: { create: true, read: true, update: true, delete: true },
    servicesSetup: { create: true, read: true, update: true, delete: true },
    dashboardCreditCardsSummary: { read: true },
    caseFinancialSession: { read: true },
    uncancelCase: { update: true },
    cancelBillingRequest: { update: true, read: false },
    closeBillingRequest: { update: true, read: false },
    addInvoiceLineItem: { update: true, read: false },
    addBillRequestAdjust: { update: true, read: false },
    submitArBR: { update: true, read: false },
    cancelCase: { update: true, read: false },
  },
  NATIONAL_CUSTOMER_RELATION: {
    vendors: { create: false, read: false, update: false, delete: false },
    clients: { create: false, read: false, update: false, delete: false },
    programs: { create: false, read: false, update: false, delete: false },
    servicesProvided: { create: false, read: false, update: false, delete: false },
    servicesSetup: { create: false, read: false, update: false, delete: false },
    dashboardCreditCardsSummary: { read: true },
    caseFinancialSession: { read: true },
    uncancelCase: { update: true },
    cancelBillingRequest: { update: true, read: true },
    closeBillingRequest: { update: true, read: true },
    addInvoiceLineItem: { update: true, read: true },
    addBillRequestAdjust: { update: true, read: true },
    submitArBR: { update: true, read: true },
    cancelCase: { update: true, read: true },
  },
  CLUB_ADMIN: {
    vendors: { create: false, read: false, update: false, delete: false },
    clients: { create: false, read: false, update: false, delete: false },
    programs: { create: false, read: false, update: false, delete: false },
    servicesProvided: { create: false, read: false, update: false, delete: false },
    servicesSetup: { create: false, read: false, update: false, delete: false },
    caseFinancialSession: { read: false },
    enabledStartDate: { read: true },
    clubUser: { read: true },
    cancelBillingRequest: { update: true, read: true },
    closeBillingRequest: { update: true, read: true },
    addInvoiceLineItem: { update: true, read: true },
    addBillRequestAdjust: { update: true, read: true },
    submitArBR: { update: true, read: true },
    cancelCase: { update: true, read: true },
  },
  CLUB_RAP_RECONCILER_ANALYST: {
    vendors: { create: false, read: false, update: false, delete: false },
    clients: { create: false, read: false, update: false, delete: false },
    programs: { create: false, read: false, update: false, delete: false },
    servicesProvided: { create: false, read: false, update: false, delete: false },
    servicesSetup: { create: false, read: false, update: false, delete: false },
    caseFinancialSession: { read: false },
    enabledStartDate: { read: true },
    clubUser: { read: true },
    cancelBillingRequest: { update: true, read: true },
    closeBillingRequest: { update: true, read: true },
    addInvoiceLineItem: { update: true, read: true },
    addBillRequestAdjust: { update: true, read: true },
    submitArBR: { update: true, read: true },
    cancelCase: { update: true, read: true },
  },
};
