import { useColumnPermission, useFeaturePermissionDetails } from 'auth';
import { DataTableColumn } from 'components/DataTable';
import { CaseComment } from 'features/Cases/interfaces/CaseComments';
import { convertUTCToDateTimeString } from 'utils/convertDate';
import { Tooltip, Typography } from '@mui/material';
import * as Yup from 'yup';
import { CaseSms } from 'features/Cases/interfaces/CaseSms';
import { CaseErrorDto } from 'api/cases/interfaces/CaseErrorDto';

export const CASE_ERRORS_PERMISSION = 'CaseErrorDto';
export const CASE_COMMENTS_PERMISSION = 'CommentDto';
export const CASE_SMS_PERMISSION = 'SmsDto';
export const CASE_ERROR_STATUSES = ['RULE_NOK', 'FINANCIAL_NOK'] as const;

export function useCaseErrorsConfig() {
  const errorsColumns: DataTableColumn<CaseErrorDto>[] = [
    {
      id: 'createdAtDateTime',
      label: 'Date & Time',
      accessor: (row) => convertUTCToDateTimeString(row.createdAtDateTime),
      sortable: true,
    },
    { id: 'code', label: 'Error Code', sortable: true },
    { id: 'description', label: 'Error Description', sortable: true },
    { id: 'resolutionType', label: 'Resolution Type', sortable: true },
    {
      id: 'statusReason',
      label: 'Reason',
      sortable: false,
      maxWidth: 320,
      accessor: (row) => (
        <Tooltip title={row.statusReason}>
          <Typography variant="body1" textOverflow="ellipsis" maxWidth="300px" overflow="hidden">
            {row.statusReason}
          </Typography>
        </Tooltip>
      ),
    },
  ];

  return {
    errorTable: useFeaturePermissionDetails({ table: CASE_ERRORS_PERMISSION }),
    columnsPermissions: useColumnPermission({
      fields: errorsColumns,
      table: CASE_ERRORS_PERMISSION,
    }),
  };
}

export function useCaseCommentsConfig() {
  const commentsColumns: DataTableColumn<CaseComment>[] = [
    { id: 'text', label: 'Comments' },
    {
      id: 'ingestedCreatedDateTime',
      label: 'Date & Time',
      sortable: true,
      accessor: (row) => convertUTCToDateTimeString(row.ingestedCreatedDateTime),
    },
    {
      id: 'utcCreatedDateTime',
      label: 'UTC Date & Time',
      sortable: true,
      accessor: (row) => (
        <Tooltip
          title={`${convertUTCToDateTimeString(
            row.utcCreatedDateTime,
            true,
            Intl.DateTimeFormat().resolvedOptions().timeZone,
          )} in your Time Zone`}
        >
          <span>{convertUTCToDateTimeString(row.utcCreatedDateTime)}</span>
        </Tooltip>
      ),
    },
    { id: 'type', label: 'Type', sortable: true },
    { id: 'userEmail', label: 'User ID', sortable: true },
  ];

  return useColumnPermission({
    fields: commentsColumns,
    table: CASE_COMMENTS_PERMISSION,
  });
}

export const commentValidationSchema = Yup.object().shape({
  comment: Yup.string().required(),
});

export function useCaseSmsConfig() {
  const smsColumns: DataTableColumn<CaseSms>[] = [
    { id: 'message', label: 'Messages', width: '75%' },
    {
      id: 'sentAt',
      label: 'Date & Time',
      sortable: true,
      accessor: (row) => convertUTCToDateTimeString(row.sentAt),
    },
    { id: 'phoneNumber', label: 'Phone Number' },
  ];

  return {
    columns: smsColumns,
  };
}
