import {
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Button,
  Typography,
  Link,
  CircularProgress,
} from '@mui/material';
import { useSavedSearchesContext } from 'features/Cases/SavedSearchesBar/SavedSearchesBarProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

interface EditQueryFormProps {
  onSubmit: () => void;
  onCancel: () => void;
}

export const EditQueryForm: React.FC<EditQueryFormProps> = ({
  onSubmit,
  onCancel,
}: EditQueryFormProps) => {
  const [searchQueryName, setSearchQueryName] = useState<string>('');
  const [defaultSearchQuery, setDefaultSearchQuery] = useState<boolean>(false);
  const [formValid, setFormValid] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState('');

  const { id } = useParams();
  const {
    requestAll,
    getAll: { data: savedSearches },
    queries: { useFetchByUUID, useUpdateByUUID },
  } = useSavedSearchesContext();
  const { isLoading, data } = useFetchByUUID(id || '');

  useEffect(() => {
    requestAll();
  }, [requestAll]);

  useEffect(() => {
    if (data) {
      setSearchQueryName(data.name);
      setDefaultSearchQuery(data.defaultFilter);
    }
  }, [data]);

  const onDefaultSearchQueryChecked = () => setDefaultSearchQuery(!defaultSearchQuery);

  const editSaveSearchMutation = useUpdateByUUID(data?.customFilterId || '');

  const onSaveButtonClicked = async () => {
    if (data) {
      const updatedSavedSearch = {
        ...data,
        name: searchQueryName,
        defaultFilter: defaultSearchQuery,
      };
      await editSaveSearchMutation.mutateAsync(updatedSavedSearch).catch();
      onSubmit();
    }
  };

  const onSearchQueryNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const nameExists =
      data?.name !== value && !!savedSearches?.find((savedSearch) => savedSearch.name === value);
    const valid = value.length >= 3 && !nameExists;
    setErrorMessage(nameExists ? 'Name already exists.' : '');
    setFormValid(valid);
    setSearchQueryName(value);
  };

  const getQueryNotFoundMessage = () => (
    <Typography variant="body2" color="error" textAlign="center">
      {!isLoading ? (
        <>
          Search query not found. Please try again or
          <Link href="/cases" sx={{ ml: 0.5 }}>
            click here to go back
          </Link>
          .
        </>
      ) : (
        <CircularProgress />
      )}
    </Typography>
  );

  return data ? (
    <>
      <FormControl sx={{ width: 363, mr: 2 }}>
        <TextField
          id="search-query-name"
          value={searchQueryName}
          label="Search Query Name"
          size="small"
          error={searchQueryName !== data.name && !formValid}
          helperText={errorMessage}
          onChange={onSearchQueryNameChanged}
        />
      </FormControl>
      <FormControlLabel
        id="default-search-query-checkbox"
        control={<Checkbox onChange={onDefaultSearchQueryChecked} checked={defaultSearchQuery} />}
        label="Default Search Query"
      />
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={!formValid} variant="contained" onClick={onSaveButtonClicked}>
          Save
        </Button>
      </DialogActions>
    </>
  ) : (
    getQueryNotFoundMessage()
  );
};
