import { EntityTypes } from 'api/setup/manageServicesSetupApi';
import PricingSetupNavigator from './components/PricingSetupNavigator';
import PricingSetupProvider from './components/PricingSetupProvider';
import PricingSetupCreator from './PricingSetupCreator';
import PricingSetupView from './PricingSetupView';

/**
 * A container component for managing pricing setup and navigation.
 *
 * @component
 * @example
 * const entityType = EntityTypes.CLIENT;
 * return (
 *   <PricingSetupPage entityType={entityType} />
 * );
 */
interface Props {
  /**
   * The entity type for which the pricing setup is being managed.
   */
  entityType: EntityTypes;
}

const PricingSetupPage: React.FC<Props> = ({ entityType }) => {
  return (
    <PricingSetupProvider entityType={entityType}>
      <PricingSetupNavigator
        components={{
          Creator: PricingSetupCreator,
          Individual: PricingSetupView,
        }}
      />
    </PricingSetupProvider>
  );
};

export default PricingSetupPage;
