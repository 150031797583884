import Box from '@mui/material/Box';
import clsx from 'clsx';
import styles from '../CaseFinancials.module.css';
import { CaseFinancialData } from '../types';
import { DynamicField, DynamicForm } from 'components/Dynamic';
import { convertToCurrency } from 'utils/formatter';
import { useForm } from 'react-hook-form';

type Props = {
  data: CaseFinancialData;
};

const fields: DynamicField<CaseFinancialData>[] = [
  {
    label: 'Billing Type',
    name: 'billingType',
  },
  {
    label: 'Billing To Client',
    name: 'totalBillAmount',
  },
  {
    label: 'Charged To Credit Card',
    name: 'chargedToCreditCard',
  },
  {
    label: 'Total Pay Amount',
    name: 'totalPayAmount',
  },
  {
    label: 'Estimated Gain / Loss on Case',
    name: 'totalEstimatedGainLoss',
  },
];

export function BalanceSheetDetail({ data }: Props) {
  const convertedData = {
    billingType: data.billingType,
    totalBillAmount: convertToCurrency(data.totalBillAmount),
    chargedToCreditCard: convertToCurrency(data.chargedToCreditCard),
    totalPayAmount: convertToCurrency(data.totalPayAmount),
    totalEstimatedGainLoss: convertToCurrency(data.totalEstimatedGainLoss),
  };
  const hookForm = useForm({ defaultValues: convertedData });
  return (
    <Box mx={4}>
      <DynamicForm
        fields={fields}
        hookForm={hookForm}
        isEditMode={false}
        colspan={2.4}
        className={clsx(styles.balanceSheetSummary, {
          [styles.netLoss]: data.totalEstimatedGainLoss < 0,
        })}
      />
    </Box>
  );
}
