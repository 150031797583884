import { useDomainAuth } from 'auth/domain-auth';
import { usePermissionRoles } from './PermissionProvider';
import { Role } from './types';

export function useRapCoreFeaturePermissions(section: string) {
  const { userGroup } = useDomainAuth();

  const { rapCorePermissions } = usePermissionRoles();

  const rolePermissions = rapCorePermissions[userGroup || Role['CLUB_ADMIN']];

  const sectionPermissions = rolePermissions[section];

  return { permissions: sectionPermissions };
}
