import { BaseAPI } from 'api/auth';
import { ENV_BACKOFFICE_BASE_API_URL } from 'lib/envConfig';

export enum EntityTypes {
  PROGRAM = 'PROGRAM',
  CLIENT = 'CLIENT',
  CLUB = 'CLUB',
}

export enum BillTypes {
  PER_EVENT = 'PER_EVENT',
  PER_VIN = 'PER_VIN',
}

export enum BillResponsibilityTypes {
  CLIENT = 'CLIENT',
  PROGRAM = 'PROGRAM',
}

export interface BillingInfoDto {
  uuid?: string;
  entityType: EntityTypes;
  entityUuid: string;
  billType: BillTypes;
  billResponsibility?: BillResponsibilityTypes;
  poRequired: boolean;
  poNumber: string;
  poEffectiveDate: string;
  poExpirationDate: string;
  costCenterAccountNumber: string;
  holdingAccountNumberRevenue: string;
  holdingAccountNumberCost: string;
  addressAccountNumber: string;
  rapCostAccount: string;
  revenueShareAccountNumber: string;
  revenueShareAmount: string;
  contractorExpenses: string;
  revenueExpenses: string;
  rapAccountingItemNumber: number;
  prePaidEventItemNumber: string;
  clientBillingRequests: string;
  allowPrePay: boolean;
  allowSplitPay: boolean;
  splitPayReceivingClubCode: string;
  outOfWarrantyProgramToRoute: string;
  billingRequestItemNumber: string;
  manualAdjustmentContractorExpenseItemNumber: string;
  manualAdjustmentRevenueExpenseItemNumber: string;
  callsAccountNumber: string;
}

export interface ReportAllInOneLineItem {
  serviceCode: string;
  serviceName: string;
  countCurrentMonth: number;
  countLastMonth: number;
  currentMonthlyTotal: number;
  currentYTDTotal: number;
  currentMonthlyPercentage: number;
  currentYTDPercentage: number;
}

export interface ReportAllInOne
  extends Omit<ReportAllInOneLineItem, 'serviceCode' | 'serviceName'> {
  reportAllInOneLineItems: ReportAllInOneLineItem[];
}

export function fetchReportAllInOne(clientId: string) {
  return BaseAPI.get<ReportAllInOne>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/reports/all-in-one?billingRequestUuid=${clientId}`,
  );
}

export function approveReportAllInOne(uuid: string) {
  return BaseAPI.patch<BillingInfoDto>(`${ENV_BACKOFFICE_BASE_API_URL}/v1/reports/${uuid}`);
}
