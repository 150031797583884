import { FormControl, TextField, Typography } from '@mui/material';
import { Header } from 'layouts/Authentication';
import RBPColors from 'AppColors';

type Props = {
  onPasswordChange: (password: string) => void;
  onConfirmPasswordChange: (confirmPassword: string) => void;
};

export default function FirstLoginReset({ onPasswordChange, onConfirmPasswordChange }: Props) {
  return (
    <>
      <Header>Set New Password</Header>
      <FormControl variant="outlined" sx={{ width: 444 }}>
        <TextField
          name="password"
          type="password"
          placeholder="New Password"
          onChange={(e) => onPasswordChange(e.target.value)}
        />
      </FormControl>
      <Typography
        variant="caption"
        component="p"
        mt="3px"
        lineHeight="1rem"
        mb={1.5}
        px={2.25}
        color={RBPColors.AAA_HELP_TEXT}
      >
        Password must be at least eight characters long, contain upper and lower case letters and
        numbers or special characters
      </Typography>

      <FormControl variant="outlined" sx={{ width: 444 }}>
        <TextField
          name="password confirmation"
          type="password"
          placeholder="Confirm Password"
          onChange={(e) => onConfirmPasswordChange(e.target.value)}
        />
      </FormControl>
    </>
  );
}
