import { useCallback, useEffect, useState, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ConstructionIcon from '@mui/icons-material/Construction';
import { DynamicExpandablePanel, useExpandablePanelGroup } from 'components/Dynamic';
import { DutyTypes, ServiceSetupDto } from 'api/setup/manageServicesSetupApi';
import { useGetServiceItems } from '../PricingSetupQuery';
import { dutyOptions } from '../PricingSetupConfig';
import PricingServiceRatesEditor from './PricingServiceRatesEditor';
import { Context } from '../components/PricingSetupProvider';
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';

interface Props {
  isCreationMode?: boolean;
  toIndividualScreen?: (uuid: string, params?: Record<string, string>) => void;
  lightServicesData?: ServiceSetupDto[];
  mediumServicesData?: ServiceSetupDto[];
  heavyServicesData?: ServiceSetupDto[];
  generalInfoHookForm: any;
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  setTowMileageEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setTowMileageExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  setHasT6Service: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PricingServiceRates = ({
  isCreationMode,
  toIndividualScreen,
  lightServicesData,
  mediumServicesData,
  heavyServicesData,
  generalInfoHookForm,
  setTowMileageEditMode,
  setTowMileageExpanded,
  expanded,
  setExpanded,
  setHasT6Service,
}: Props) => {
  const [searchParams] = useSearchParams();
  const [isEditMode, setEditMode] = useState<boolean>(isCreationMode || false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [nextTab, setNextTab] = useState<number | null>(null);
  const { groupExpanded } = useExpandablePanelGroup();
  const { data: serviceItems } = useGetServiceItems();
  const {
    isServiceRatesDirtyTabs,
    isEditingSection,
    setIsEditingSection,
    activeTab,
    setActiveTab,
  } = useContext(Context);
  const { permissions } = useRapCoreFeaturePermissions('servicesSetup');

  useEffect(() => {
    const redirectTo = searchParams.get('redirectTo');
    if (redirectTo !== 'TOW_MILEAGE_RATES') {
      setExpanded(groupExpanded);
    }
  }, [groupExpanded]);

  const handleEdit = useCallback(() => {
    setEditMode(true);
    setIsEditingSection(true);
  }, [setIsEditingSection]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    event.stopPropagation();
    if (isServiceRatesDirtyTabs?.[activeTab]) {
      setNextTab(newValue);
      setShowDialog(true);
      return;
    }
    setActiveTab(newValue);
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleConfirm = () => {
    if (nextTab !== null) {
      setActiveTab(nextTab);
      setNextTab(null);
    }
    setShowDialog(false);
  };

  const fillTowMileage = () => {
    setExpanded(false);
    setHasT6Service(true);
    setTowMileageEditMode(true);
    setTowMileageExpanded(true);
  };

  return (
    <>
      <DynamicExpandablePanel
        title="Service Rates"
        icon={<ConstructionIcon />}
        onExpanded={() => setExpanded(!expanded)}
        expanded={expanded}
        actions={{
          isEditMode,
          onEdit: permissions.update ? handleEdit : undefined,
        }}
        leftPanel={
          <ButtonGroup size="small" disabled={!isEditMode && (isEditingSection || isCreationMode)}>
            {dutyOptions.map(({ label }, index) => (
              <Button
                key={label}
                variant={index === activeTab ? 'contained' : 'outlined'}
                onClick={(event) => handleChange(event, index)}
              >
                {label}
              </Button>
            ))}
          </ButtonGroup>
        }
        isEditingSection={isEditingSection}
      >
        <Box sx={{ mx: -4 }}>
          {activeTab === 0 && (
            <PricingServiceRatesEditor
              data={lightServicesData}
              dutyType={DutyTypes.LIGHT}
              serviceItems={serviceItems}
              isCreationMode={isCreationMode}
              isEditMode={isEditMode}
              setEditMode={setEditMode}
              toIndividualScreen={toIndividualScreen}
              tabIndex={0}
              generalInfoHookForm={generalInfoHookForm}
              onSave={fillTowMileage}
            />
          )}
          {activeTab === 1 && (
            <PricingServiceRatesEditor
              data={mediumServicesData}
              dutyType={DutyTypes.MEDIUM}
              serviceItems={serviceItems}
              isCreationMode={isCreationMode}
              isEditMode={isEditMode}
              setEditMode={setEditMode}
              toIndividualScreen={toIndividualScreen}
              tabIndex={1}
              generalInfoHookForm={generalInfoHookForm}
              onSave={fillTowMileage}
            />
          )}
          {activeTab === 2 && (
            <PricingServiceRatesEditor
              data={heavyServicesData}
              dutyType={DutyTypes.HEAVY}
              serviceItems={serviceItems}
              isCreationMode={isCreationMode}
              isEditMode={isEditMode}
              setEditMode={setEditMode}
              toIndividualScreen={toIndividualScreen}
              tabIndex={2}
              generalInfoHookForm={generalInfoHookForm}
              onSave={fillTowMileage}
            />
          )}
        </Box>
      </DynamicExpandablePanel>
      <DialogConfirmation
        open={showDialog}
        title="You have unsaved changes"
        content="If you navigate to another pricing table, you will lose all the changes you have made."
        onClose={handleClose}
        onConfirm={handleConfirm}
        labelCancel="Go back"
        labelConfirm="Discard changes"
      />
    </>
  );
};
