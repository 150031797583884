import { CRUDPageableProvider, usePageableContextData } from 'components/CRUDPageable';
import { CRUDPageableReactQuery } from 'hooks/useReactQueryHelpers';
import { ClientDto, ManageClientApi } from 'api/setup/manageClientApi';
import { PropsWithChildren } from 'react';
import { BillingConfigurationClientData } from './ClientQuery';

export type ClientData = ClientDto & Partial<Pick<BillingConfigurationClientData, 'allowSplitPay'>>;

const mapDtoToData = (dto: ClientDto): ClientData => dto;

const mapDataToDto = (data: ClientData): ClientDto => ({
  ...data,
  surveyAdministrator: data.surveyAdministrator === '' ? null : data.surveyAdministrator,
});

export const ClientQuery = CRUDPageableReactQuery(
  'QUERY_CLIENT',
  ManageClientApi,
  mapDtoToData,
  mapDataToDto,
);

export function useClientContext() {
  return usePageableContextData<ClientDto, ClientData>();
}

export default function ClientProvider({ children }: PropsWithChildren) {
  return <CRUDPageableProvider queries={ClientQuery}>{children}</CRUDPageableProvider>;
}
