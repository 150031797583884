import { useEntityCreationStep } from 'components/EntityCreationFlow';
import { StepComponent } from 'components/FormStepper/types';
import { GeneralInfoValidationSchema } from '../ProgramPageConfig';
import ProgramGeneralInfoForm from '../ProgramDetails/ProgramGeneralInfoForm';
import { useGetInitialData } from '../hooks/useGetInitialData';
import { useProgramContext } from '../ProgramProvider';
import { ProgramData } from '../ProgramQuery';

const ProgramGeneralInfoStep = ({
  submitted,
  completed,
  stepKey,
  resetSubmitted,
  setCompleted,
}: StepComponent) => {
  const { data: initialData } = useGetInitialData();
  const { queries } = useProgramContext();
  const GeneralInfoStep = useEntityCreationStep<ProgramData>({
    initialData,
    validationSchema: GeneralInfoValidationSchema,
    form: ProgramGeneralInfoForm,
    query: queries.useCreate,
  });
  return (
    <GeneralInfoStep
      stepKey={stepKey}
      submitted={submitted}
      completed={completed}
      resetSubmitted={resetSubmitted}
      setCompleted={setCompleted}
    />
  );
};

export default ProgramGeneralInfoStep;
