import { TextField, styled } from '@mui/material';
import { MuiTextFieldProps } from '@mui/x-date-pickers/internals';
import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { ControlFieldProps } from '../types';
import AppColors from 'AppColors';

const Wrapper = styled('div')(({ isTableForm }: { isTableForm?: boolean }) => {
  return {
    '.Mui-disabled': {
      color: `${AppColors.AAA_BLUE} !important`,
      '-webkit-text-fill-color': `${AppColors.AAA_BLUE} !important`,
    },
    '.MuiFormControl-root': {
      width: '100%',
      '& div': {
        height: isTableForm ? 'inherit' : 40,
      },
    },
    '& label': {
      display: isTableForm ? 'none' : 'block',
      fontWeight: 'bold',
    },
    '& fieldset': {
      border: isTableForm ? 'none' : '1px solid',
    },
    input: {
      padding: 0,
    },
  };
});

export type ControlledTextFieldProps = MuiTextFieldProps &
  ControlFieldProps & {
    inlineEditing?: boolean;
    Tooltip?: React.ReactNode;
    startAdornment?: string;
    isEditMode?: boolean;
    currencySymbol?: string;
    decimalScale?: number;
    isTableForm?: boolean;
  };

const ControlledCurrencyInput = ({
  control,
  name,
  rules,
  currencySymbol = '$',
  label,
  decimalScale = 2,
  readOnly,
  isTableForm,
  error,
  helperText,
}: ControlledTextFieldProps) => {
  const textFieldProps = {
    label,
    InputLabelProps: {
      shrink: true,
    },
    InputProps: {
      startAdornment: <span>{currencySymbol}</span>,
    },
  };

  return (
    <Wrapper isTableForm={isTableForm}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, name, value } }) => (
          <NumericFormat
            error={error}
            helperText={helperText}
            customInput={TextField}
            placeholder={label as string}
            thousandSeparator=""
            decimalScale={decimalScale}
            decimalSeparator="."
            name={name}
            value={value}
            onChange={onChange}
            fixedDecimalScale
            disabled={readOnly}
            {...textFieldProps}
          />
        )}
      />
    </Wrapper>
  );
};

export default ControlledCurrencyInput;
