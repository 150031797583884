const mustOverwriteMethods = ['toString'];

const assertOverrideRules = <T extends QueryItem>(target: T) => {
  const protoLits = [Object.getPrototypeOf(target)];
  while (Object.getPrototypeOf(protoLits[0]).constructor.name !== 'Object') {
    protoLits.unshift(Object.getPrototypeOf(protoLits[0]));
  }
  const superProto = protoLits.shift();
  const missing = mustOverwriteMethods.find(
    (name) =>
      typeof superProto[name] === 'function' &&
      // eslint-disable-next-line no-prototype-builtins
      !protoLits.some((proto) => proto.hasOwnProperty(name)),
  );
  if (missing) {
    throw new TypeError(`${target.constructor.name} need to overwrite ${missing}`);
  }
};

export abstract class QueryItem {
  // Abstract
  constructor() {
    if (new.target === QueryItem) {
      throw new TypeError(`Cannot construct ${new.target.name} instances directly`);
    }
    assertOverrideRules(this);
  }
}
