import { useState } from 'react';
import { FormControl, FormHelperText, InputLabel, FormControlProps } from '@mui/material';
import { DateRangePicker, DateRangePickerInputShape, FocusedInputShape } from 'react-dates';
import { Controller } from 'react-hook-form';
import moment from 'moment';
import EventIcon from '@mui/icons-material/Event';
import StyledDatePickerWrapper from '../ControlledSingleDatePicker/ControlledSingleDatePicker.styled';
import { ControlFieldProps } from '../types';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import AppColors from 'AppColors';

type ControlledRangeDatePickerProps = DateRangePickerInputShape &
  ControlFieldProps &
  FormControlProps & {
    helperText?: string;
    label: string;
    required?: boolean;
  };

const ControlledRangeDatePicker = ({
  name,
  control,
  readOnly,
  error,
  label,
  helperText,
  required,
}: ControlledRangeDatePickerProps) => {
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);

  return (
    <div>
      <Controller
        control={control}
        name={name}
        render={({ field: range }) => (
          <FormControl error={error} fullWidth>
            <InputLabel
              id={`${name}-${label}`}
              size="small"
              shrink
              sx={{
                fontWeight: 600,
                background: readOnly ? 'transparent' : AppColors.AAA_WHITE,
                padding: '0 5px',
                textFillColor: focusedInput ? AppColors.AAA_LIGHT_BLUE : AppColors.AAA_BLUE,
              }}
            >
              {required && !readOnly ? `${label} *` : label}
            </InputLabel>
            <StyledDatePickerWrapper disabled={readOnly} focusedInput={focusedInput}>
              <DateRangePicker
                startDate={range.value?.[0] ? moment(range.value?.[0]) : null}
                startDateId="start_date"
                endDate={range.value?.[1] ? moment(range.value?.[1]) : null}
                endDateId="end_date"
                onDatesChange={({ startDate, endDate }) => {
                  range.onChange([startDate?.format(), endDate?.format()]);
                }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                onClose={range.onBlur}
                readOnly={readOnly}
                disabled={readOnly}
                displayFormat={() => 'MM/DD/YYYY'}
                firstDayOfWeek={1}
                numberOfMonths={1}
                minimumNights={0}
                isOutsideRange={() => false}
                customArrowIcon={<>-</>}
                customInputIcon={!readOnly ? <EventIcon fontSize="small" /> : <></>}
                inputIconPosition="after"
                noBorder
                endDatePlaceholderText=""
                startDatePlaceholderText=""
                isDayHighlighted={(momentDate: moment.Moment) => {
                  const format = 'YYYY-MM-DD';
                  return !range.value && momentDate.format(format) === moment().format(format);
                }}
              />
            </StyledDatePickerWrapper>
            {error && <FormHelperText>{helperText}</FormHelperText>}
          </FormControl>
        )}
      />
    </div>
  );
};

export default ControlledRangeDatePicker;
