import { DynamicExpandablePanel } from 'components/Dynamic';
import StarIcon from '@mui/icons-material/Star';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useExpandablePanelGroup } from 'components/Dynamic/DynamicExpandablePanel/ExpandablePanelGroup';
import AddIcon from '@mui/icons-material/Add';
import DataTable, { DataTableColumn } from 'components/DataTable';
import { ProgramData, ProgramQuery } from 'features/Setup/Programs/ProgramQuery';
import { Button, Stack } from '@mui/material';
import { LoaderCard } from 'components/Cards';
import { QueryBuilder } from 'lib/querybuilder';
import { convertUTCDateToString } from 'utils/convertDate';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';
import { getItemFromStorage, setItemToStorage } from 'auth/domain-auth/helpers';
import { BillTypes } from 'api/setup/manageBillingInfoApi';

const billTypeLabel = {
  [BillTypes.PER_EVENT]: 'Per Event',
  [BillTypes.PER_VIN]: 'Per Vin',
};

const programListColumns: DataTableColumn<ProgramData>[] = [
  { label: 'RAP Program Code', id: 'rapProgramCode', sortable: true },
  { label: 'Program Name', id: 'programName', sortable: true },
  { label: 'Program Abbreviation', id: 'abbreviatedName', sortable: true },
  { label: 'Program ID', id: 'rapProgramId', sortable: true },
  {
    label: 'Bill Type',
    id: 'billType',
    accessor: (row) => (row.billType ? billTypeLabel[row.billType] : ''),
  },
  { label: 'PO Required', id: 'poRequired', accessor: (row) => '' }, // TODO: update when we have definitions. See RBP-1527
  { label: 'PO Number', id: 'poNumber' },
  {
    label: 'PO Effective Date',
    id: 'poEffectiveDateTime',
    accessor: (row) => convertUTCDateToString(row.poEffectiveDateTime),
  },
  {
    label: 'PO Expiration Date',
    id: 'poExpirationDateTime',
    accessor: (row) => convertUTCDateToString(row.poExpirationDateTime),
  },
];
type Props = {
  clientId?: string;
};

const ROWS_PER_PAGE = 'rows-per-page';

export default function GeneralInfo({ clientId }: Props) {
  const initialRowsPerPage = () => {
    const storedValue = getItemFromStorage(ROWS_PER_PAGE);
    return storedValue ? Number(storedValue) : 25;
  };

  const [expanded, setExpanded] = useState<boolean>(false);
  const [order, setOrder] = useState<'asc' | 'desc'>();
  const [orderBy, setOrderBy] = useState<string>();
  const [rowsPerPage, setRowsPerPage] = useState<number>(initialRowsPerPage);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const queryFilter = QueryBuilder.equal('client.uuid', clientId ?? '').toString();
  const { groupExpanded } = useExpandablePanelGroup();
  const { permissions } = useRapCoreFeaturePermissions('programs');

  const { isRunning, data, rowsCount, isLoading } = ProgramQuery.useFetchAll({
    page: currentPage,
    size: rowsPerPage,
    filter: queryFilter,
  });
  const paginationOptions = useMemo(() => {
    return {
      rowsCount,
      currentPage: currentPage,
      rowsPerPage: rowsPerPage,
      onPageChange: setCurrentPage,
      onRowsPerPageChange: setRowsPerPage,
      rowsPerPageOptions: [25, 50, 100],
    };
  }, [currentPage, rowsCount, rowsPerPage, setRowsPerPage]);

  const navigate = useNavigate();
  const onRowClick = useCallback(
    (program: ProgramData) => {
      navigate(`.${'/programs/:uuid'.replace(':uuid', program.uuid)}`);
    },
    [navigate],
  );

  const onAddProgram = useCallback(() => {
    navigate('./programs/create');
  }, [navigate]);

  const sortOptions = useMemo(() => {
    return {
      order,
      orderBy,
      createSortHandler: (event: React.MouseEvent<unknown, MouseEvent>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        const newOrderValue = isAsc ? 'desc' : 'asc';
        setOrder(newOrderValue);
        setOrderBy(property);
      },
      externalSorting: true,
    };
  }, [order, orderBy]);

  useEffect(() => {
    setExpanded(groupExpanded);
  }, [groupExpanded]);

  useEffect(() => {
    setItemToStorage(ROWS_PER_PAGE, rowsPerPage.toString());
  }, [rowsPerPage]);

  return (
    <DynamicExpandablePanel
      data-testid="ProgramsPanel"
      title="Programs"
      icon={<StarIcon />}
      onExpanded={() => setExpanded(!expanded)}
      expanded={expanded}
    >
      <Stack direction="row" mb={2} justifyContent="center">
        {permissions.create && (
          <Button variant="outlined" size="small" startIcon={<AddIcon />} onClick={onAddProgram}>
            Add Program
          </Button>
        )}
      </Stack>
      <Stack flexGrow={1} mx={-4}>
        {isRunning && <LoaderCard label="Loading Clients..." sx={{ flexGrow: 1 }} />}

        {!isLoading && (
          <DataTable<ProgramData>
            data-testid="ProgramList"
            indexName={'uuid'}
            hasSelectors={false}
            columns={programListColumns}
            rows={data}
            paginationOptions={paginationOptions}
            sortOptions={sortOptions}
            onRowClick={onRowClick}
          />
        )}
      </Stack>
    </DynamicExpandablePanel>
  );
}
