import { PaletteOptions } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import AppColors from 'AppColors';

const palette: PaletteOptions = {
  text: {
    primary: AppColors.AAA_BLUE,
  },
  primary: {
    main: AppColors.AAA_LIGHT_BLUE,
    contrastText: AppColors.AAA_WHITE,
  },
  secondary: {
    main: AppColors.AAA_BLUE,
    contrastText: AppColors.AAA_WHITE,
  },
};

const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: AppColors.AAA_GRAY,
          zIndex: 1,
          '& .MuiTableCell-root': {
            backgroundColor: AppColors.AAA_GRAY,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderRight: '1px solid transparent',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          marginLeft: 0,
          width: '100%',
        },
        spacer: {
          display: 'none',
        },
        displayedRows: {
          marginLeft: 'auto',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            '.MuiTableSortLabel-icon': {
              color: AppColors.AAA_BLUE,
              opacity: 1,
            },
          },
        },
        icon: {
          opacity: 0.5,
        },
      },
    },
  },
  palette,
});

export default theme;
