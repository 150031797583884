import { UserPermissionsDto } from 'api/auth';
import { revalidatedPermissions } from './revalidatedPermissions';
import { PermissionList } from '../types';

export function convertPermissionData(data: UserPermissionsDto) {
  return data.reduce<PermissionList>((permissionsTable, source) => {
    let currentTable = permissionsTable[source.name];

    if (!currentTable) {
      currentTable = {
        fields: {},
        permissions: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
      };
      permissionsTable[source.name] = currentTable;
    }

    source.attributes.forEach((attribute) => {
      Object.keys(attribute).forEach((fieldName) => {
        const fieldpermissions = {
          create: attribute[fieldName].includes('C'),
          read: attribute[fieldName].includes('R'),
          update: attribute[fieldName].includes('U'),
          delete: attribute[fieldName].includes('D'),
        };

        currentTable.fields[fieldName] = fieldpermissions;
        currentTable.permissions = revalidatedPermissions(
          currentTable.permissions,
          fieldpermissions,
        );
      });
    });

    return permissionsTable;
  }, {});
}
