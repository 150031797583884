import { Box, Typography } from '@mui/material';
import Logo from './../../../../assets/logo-blue-red.svg';
import ReceiptInfo from './components/Info';
import DataBox from './components/DataBox';
import { CaseGeneralInfoData } from '../GeneralInfo/interface/CaseGeneralInfoData';
import { BreakdownLocationDto } from 'api/cases/interfaces/BreakdownLocationDto';
import { convertUTCDateToString } from 'utils/convertDate';
import { CaseFinancialSingleLineItemViewDto } from 'api/cases/interfaces/CaseFinancialDto';
import { CreditCardReceiptDto } from 'api/cases/interfaces/CreditCardReceipt';

interface CreditCardReceiptProps {
  generalInfo?: CaseGeneralInfoData;
  breakdownLocation?: BreakdownLocationDto;
  caseFinancialItems?: CaseFinancialSingleLineItemViewDto[];
  receiptData?: CreditCardReceiptDto;
}

const CreditCardReceipt = ({
  generalInfo,
  breakdownLocation,
  receiptData,
  caseFinancialItems,
}: CreditCardReceiptProps) => {
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ padding: '0 20px', width: 635, display: 'block' }}
        id="credit-card-receipt"
      >
        <Box
          src={Logo}
          component="img"
          alt="Logo"
          sx={{ height: 50, display: 'block', marginLeft: '30px' }}
        />
        <Box component="div" sx={{ width: '100%', height: 50, display: 'block', margin: '15px 0' }}>
          <Typography variant="h5" sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}>
            Receipt for Service
          </Typography>
        </Box>
        <ReceiptInfo
          title="Customer Name"
          value={`${receiptData?.contactFirstName} ${receiptData?.contactLastName}`}
        />
        <ReceiptInfo title="Address" value={receiptData?.address || ''} />
        <ReceiptInfo title="City" value="" />
        <ReceiptInfo title="Fax" value={receiptData?.contactPhoneNumber || ''} />
        <Box
          component="div"
          sx={{
            width: '100%',
            height: 24,
            marginTop: '30px',
            display: 'flex',
            flexDirection: 'row',
            borderRight: 'solid 1px #000',
          }}
        >
          <DataBox text="Breakdown Location" width="30%" bold textAlign="left" />
          <DataBox text="Odometer" width="15%" bold />
          <DataBox width="20%" empty bold />
          <DataBox text="Date" width="15%" bold />
          <DataBox text="Receipt #" width="20%" bold />
        </Box>
        <Box
          component="div"
          sx={{
            width: '100%',
            height: 24,
            display: 'flex',
            flexDirection: 'row',
            borderRight: 'solid 1px #000',
          }}
        >
          <DataBox text={breakdownLocation?.compositeAddress} width="30%" textAlign="left" />
          <DataBox text={String(receiptData?.odometer || '')} width="15%" />
          <DataBox width="20%" empty />
          <DataBox text={convertUTCDateToString(receiptData?.date)} width="15%" />
          <DataBox text={String(receiptData?.receiptNumber)} width="20%" />
        </Box>
        <Box
          component="div"
          sx={{
            width: '100%',
            height: 24,
            display: 'flex',
            flexDirection: 'row',
            borderRight: 'solid 1px #000',
          }}
        >
          <DataBox text={generalInfo?.programName} width="100%" bold />
        </Box>
        <Box
          component="div"
          sx={{
            width: '100%',
            height: 24,
            display: 'flex',
            flexDirection: 'row',
            borderRight: 'solid 1px #000',
          }}
        >
          <DataBox text="Case #" width="20%" bold />
          <DataBox text="Payment Method" width="22%" bold />
          <DataBox text="Service Date" width="18%" bold />
          <DataBox text="Make" width="10%" bold />
          <DataBox text="Year" width="12%" bold />
          <DataBox text="VIN #" width="18%" bold />
        </Box>
        <Box
          component="div"
          sx={{
            width: '100%',
            height: 24,
            display: 'flex',
            flexDirection: 'row',
            borderRight: 'solid 1px #000',
          }}
        >
          <DataBox text={String(receiptData?.caseNumber)} width="20%" />
          <DataBox text="Credit Card" width="22%" />
          <DataBox text={receiptData?.serviceDate?.split('T')[0]} width="18%" />
          <DataBox text={receiptData?.make} width="10%" fontSize={'9px'} />
          <DataBox text={String(receiptData?.year || '')} width="12%" />
          <DataBox text={`************${(receiptData?.vin || '').slice(-5)}`} width="18%" />
        </Box>
        <Box
          component="div"
          sx={{
            width: '100%',
            height: 24,
            display: 'flex',
            flexDirection: 'row',
            borderRight: 'solid 1px #000',
          }}
        >
          <DataBox text="Service Code" width="20%" bold />
          <DataBox text="Service Description" width="50%" bold textAlign="left" />
          <DataBox text="Quantity" width="12%" bold />
          <DataBox text="Amount" width="18%" bold />
        </Box>
        <Box
          component="div"
          sx={{
            width: '100%',
            height: 200,
            display: 'flex',
            flexDirection: 'row',
            borderBottom: 'solid 1px #000',
            borderRight: 'solid 1px #000',
          }}
        >
          <DataBox text={caseFinancialItems?.map((i) => i.itemCode)} width="20%" />
          <DataBox
            text={caseFinancialItems?.map((i) => i.itemName || '')}
            width="50%"
            textAlign="left"
          />
          <DataBox
            text={caseFinancialItems?.map(
              (i) => `${i.clientUnitCount ?? 0} ${i.itemCode === 'T6' ? 'ML' : 'EA'}`,
            )}
            width="12%"
          />
          <DataBox
            text={caseFinancialItems?.map((i) =>
              (i.clientCreditCard ?? 0).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              }),
            )}
            width="18%"
          />
        </Box>
        <Box
          component="div"
          sx={{
            width: '100%',
            height: 30,
            display: 'flex',
            flexDirection: 'row',
            marginTop: '-30.5px',
            marginLeft: '-0.5px',
          }}
        >
          <DataBox empty width="50%" hideBorder />
          <DataBox
            text="GRAND TOTAL"
            width="32%"
            textAlign="left"
            fontSize="18px"
            showBorderBottom
            lineHeight="30px"
            bold
          />
          <DataBox
            text={receiptData?.totalAmount?.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
            showBorderRight
            showBorderBottom
            width="18%"
            fontSize="18px"
            lineHeight="30px"
          />
        </Box>
        <Box
          component="div"
          sx={{ width: '100%', height: 50, display: 'block', marginTop: '20px' }}
        >
          <Typography
            variant="h5"
            color="#ca2127"
            sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 12 }}
          >
            THIS IS NOT A BILL. Services have already been billed to your credit/debit card.
          </Typography>
          <Typography
            variant="h5"
            color="#ca2127"
            sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 12 }}
          >
            RESPONSE Roadside Assistance is the service provider for this Roadside Assistance
            Program
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default CreditCardReceipt;
