import { Box, Typography, IconButton, styled } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MuiToolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import { ReactComponent as Caret } from 'assets/caret.svg';
import { ReactComponent as Logo } from 'assets/logo.svg';

import { memo, useState } from 'react';
import SettingsMenu from './SettingsMenu';
import baseTheme from 'theme';
import { useDomainAuth } from 'auth/domain-auth/useDomainAuth';

const DrawerButton = styled(IconButton, {
  shouldForwardProp: (propName) => propName !== 'isActive',
})<{ isActive?: boolean }>`
  transition: 0.4s;
  transform: rotate(${({ isActive }) => (isActive ? '0deg' : '90deg')});
`;

const Toolbar = styled(MuiToolbar, {
  shouldForwardProp: (propName) => propName !== 'isDrawerOpen',
})<{ isDrawerOpen?: boolean }>(({ theme, isDrawerOpen }) => {
  return {
    display: 'flex',
    paddingRight: '20px',
    justifyContent: 'space-between',
    width: `calc(100% - ${theme.AppDrawer.collapsedWidth})`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(isDrawerOpen && {
      width: `calc(100% - ${theme.AppDrawer.width})`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  };
});

const SettingsCaret = styled(Caret, { shouldForwardProp: (propName) => propName !== 'isOpen' })<{
  isOpen?: boolean;
}>`
  transform: rotate(${({ isOpen }) => (isOpen ? -180 : 0)}deg);
`;

const AppBar = styled(MuiAppBar)`
  display: flex;
  flex-direction: row;
  z-index: ${baseTheme.zIndex.drawer + 1};
`;

export type HeaderProps = {
  title: string;
  onToggleDrawer?: () => void;
  isDrawerOpen: boolean;
};

export function Header({ title, onToggleDrawer, isDrawerOpen }: HeaderProps) {
  const { signOut, user } = useDomainAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Box data-testid="appbar-header">
      <AppBar position="fixed" elevation={0} color="secondary">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          ml="18px"
          mr="30.25px"
          flexGrow={1}
        >
          <Logo fill="white" width="4.375rem" height="2.625rem" />
        </Box>
        <Toolbar isDrawerOpen={isDrawerOpen} disableGutters>
          <Box display="flex" alignItems="center" flexDirection="row">
            <DrawerButton
              isActive={isDrawerOpen}
              data-testid="OpenSidePanelButton"
              edge="start"
              color="inherit"
              aria-label="open side panel"
              onClick={onToggleDrawer}
            >
              <MenuIcon />
            </DrawerButton>

            <Typography variant="h6" noWrap>
              {title}
            </Typography>
          </Box>

          <SettingsMenu
            items={[
              {
                title: 'Logout',
                action: () => {
                  signOut(true);
                },
              },
            ]}
            isOpen={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
          >
            <Box display="flex" alignItems="center" onClick={() => setIsMenuOpen(true)}>
              <Typography mr={1.5}>{user?.email}</Typography>
              <IconButton>
                <SettingsCaret isOpen={isMenuOpen} />
              </IconButton>
            </Box>
          </SettingsMenu>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default memo(Header);
