import { DataTableColumn } from 'components/DataTable';
import { HmaGmaRejectData, TmnaRejectData } from './types';
import { convertUTCDateToString } from 'utils/convertDate';
import ClaimRejectActionComponent from './CaseRejectActionComponent';
import { RbpCaseStatus } from 'core/enums/RbpCaseStatus';

export function hmaGmaColumns(
  rbpCaseStatus: RbpCaseStatus,
): DataTableColumn<HmaGmaRejectData & ({ actions: unknown } | undefined)>[] {
  const columns: DataTableColumn<HmaGmaRejectData & ({ actions: unknown } | undefined)>[] = [
    {
      id: 'rapCaseId',
      label: 'RAP Case ID',
    },
    {
      id: 'serviceDate',
      label: 'Service Date',
      accessor: (row) => convertUTCDateToString(row.serviceDate),
    },
    {
      id: 'billable',
      label: 'Billable Claim',
      accessor: (row) => (row.billable === true ? 'Y' : 'N'),
    },
    {
      id: 'sendDate',
      label: 'Send Date',
      accessor: (row) => convertUTCDateToString(row.sendDate),
    },
    {
      id: 'rejectDate',
      label: 'Reject Date',
      accessor: (row) => convertUTCDateToString(row.rejectDate),
    },
    {
      id: 'rejectCode',
      label: 'Reject Code',
    },
    {
      id: 'rejectDescription',
      label: 'Reject Description',
    },
    {
      id: 'vin',
      label: 'VIN',
    },
    {
      id: 'apiId',
      label: 'API ID',
    },
    {
      id: 'serviceType',
      label: 'Service Type',
    },
    {
      id: 'billAmount',
      label: 'Bill Amount',
    },
    {
      id: 'serviceOdometer',
      label: 'Service Odometer',
    },
    {
      id: 'vendorName',
      label: 'Vendor Name',
    },
    {
      id: 'weeklyInvoice',
      label: 'Weekly Invoice',
    },
  ];
  if (rbpCaseStatus === RbpCaseStatus.CLIENT_RULE_NOK) {
    columns.push({
      id: 'actions',
      label: 'Actions',
      accessor: (row) => <ClaimRejectActionComponent row={row} />,
    });
  }
  return columns;
}

export function tmnaColumns(
  rbpCaseStatus: RbpCaseStatus,
): DataTableColumn<TmnaRejectData & { actions: unknown }>[] {
  const columns: DataTableColumn<TmnaRejectData & ({ actions: unknown } | undefined)>[] = [
    {
      id: 'caseNumber',
      label: 'RAP Case ID',
    },
    {
      id: 'serviceDate',
      label: 'Service Date',
      accessor: (row) => convertUTCDateToString(row.serviceDate),
    },
    {
      id: 'billable',
      label: 'Billable Claim',
      accessor: (row) => (row.billable === true ? 'Y' : 'N'),
    },
    {
      id: 'sendDate',
      label: 'Send Date',
      accessor: (row) => convertUTCDateToString(row.sendDate),
    },
    {
      id: 'rejectDate',
      label: 'Reject Date',
      accessor: (row) => convertUTCDateToString(row.rejectDate),
    },
    {
      id: 'rejectCode',
      label: 'Reject Code',
    },
    {
      id: 'rejectReason',
      label: 'Reject Reason',
    },
    {
      id: 'vin',
      label: 'VIN',
    },
    {
      id: 'agreementNumber',
      label: 'Agreement Number',
    },
    {
      id: 'serviceType',
      label: 'Service Type',
    },
    {
      id: 'billAmount',
      label: 'Bill Amount',
    },
    {
      id: 'serviceOdometer',
      label: 'Service Odometer',
    },
    {
      id: 'vendorName',
      label: 'Vendor Name',
    },
  ];

  if (rbpCaseStatus === RbpCaseStatus.CLIENT_RULE_NOK) {
    columns.push({
      id: 'actions',
      label: 'Actions',
      accessor: (row) => <ClaimRejectActionComponent row={row} />,
    });
  }

  return columns;
}
