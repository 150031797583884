import { useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import { CreditCardData } from 'features/CreditCards/types';
import { DynamicForm } from 'components/Dynamic';
import { convertToCurrency } from 'utils/formatter';
import { useCreditCardConfig } from './config';
import { convertUTCDateToString } from 'utils/convertDate';
import FormDivider from 'components/Form/FormDivider';

interface CreditCardViewProps {
  data: CreditCardData;
}

const CreditCardView = ({ data }: CreditCardViewProps) => {
  const convertedData = {
    fourNumbers: `**** **** **** ${data?.fourNumbers?.replaceAll('*', '') || '****'}`,
    cardType: data.cardType,
    cardAuthCode: data.cardAuthCode || '',
    settlementId: data.settlementId || '-',
    zipCode: data.zipCode || '-',
    cardChargeAmount: data.cardChargeAmount
      ? convertToCurrency(Number(data.cardChargeAmount))
      : convertToCurrency(0),
    preAuthAmount: data.preAuthAmount
      ? convertToCurrency(Number(data.preAuthAmount))
      : convertToCurrency(0),
    updatedAtDateTime: convertUTCDateToString(data.updatedAtDateTime) || '',
    settlementDate: convertUTCDateToString(data.settlementDate) || '',
    authorizationDate: convertUTCDateToString(data.authorizationDate) || '',
  };

  const hookForm = useForm({ defaultValues: convertedData });

  const { creditCardFields } = useCreditCardConfig({ isEditMode: false });

  return (
    <Stack spacing={0} sx={{ padding: '0 32px' }}>
      <FormDivider label="General Information" sx={{ mt: 2, mb: 3 }} />
      <DynamicForm hookForm={hookForm} isEditMode={false} fields={creditCardFields} />
    </Stack>
  );
};

export default CreditCardView;
