import React, { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';

interface PanelGroupContextProps {
  groupExpanded: boolean;
  setGroupExpanded: (expanded: boolean) => void;
  toggleGroup: () => void;
}

export type Props = {
  defaultExpanded?: boolean;
};

export const PanelGroupContext = createContext<PanelGroupContextProps>({
  groupExpanded: false,
  setGroupExpanded: () => null,
  toggleGroup: () => null,
});

export function useExpandablePanelGroup() {
  return useContext(PanelGroupContext);
}

export default function ExpandablePanelGroup({
  children,
  defaultExpanded = false,
}: PropsWithChildren<Props>) {
  const [groupExpanded, setGroupExpanded] = useState(defaultExpanded);

  const toggleGroup = useCallback(() => {
    setGroupExpanded(!groupExpanded);
  }, [groupExpanded]);

  return (
    <PanelGroupContext.Provider value={{ groupExpanded, toggleGroup, setGroupExpanded }}>
      {children}
    </PanelGroupContext.Provider>
  );
}
