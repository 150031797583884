import { CircularProgress, Box, styled } from '@mui/material';

export const BackgroundCircularPath = styled(CircularProgress, {
  shouldForwardProp: (propName) => propName !== 'pathColor',
})<{ pathColor: string }>(({ pathColor }) => ({
  position: 'absolute',
  svg: {
    color: pathColor,
    opacity: 0.3,
    circle: {
      strokeDashoffset: '0px !important',
    },
  },
}));

export const ContentBox = styled(Box)(() => ({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
