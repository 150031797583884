import AppColors from 'AppColors';
import DataBody from './DataBody';
import DataHead from './DataHead';
import Table from '@mui/material/Table';
import TableContainer, { TableContainerProps } from '@mui/material/TableContainer';
import { DataGridReturn } from './useDataGrid';
import { StyledPaper } from './styled';

interface Props<DataType> {
  hook: DataGridReturn<DataType>;
  hasSelectors?: boolean;
  isEditMode?: boolean;
  tableContainerProps?: TableContainerProps;
  stickyHeader?: boolean;
  stickyOffset?: number | string;

  /**
   * Sticky TableHead relative to TableContainer (sets stickyHeader prop on Table Component)
   */
  relativeStickyHeader?: boolean;

  /**
   * Min Width of the entire table
   */
  minWidth?: number;
  readonlyRow?: (row: DataType) => boolean;
}

export function DataGrid<DataType>({
  hook,
  hasSelectors,
  isEditMode,
  tableContainerProps,
  stickyHeader,
  stickyOffset,
  relativeStickyHeader,
  minWidth,
  readonlyRow,
}: Props<DataType>) {
  const { columns, observer } = hook;

  return (
    <TableContainer
      {...tableContainerProps}
      data-testid="DataTable"
      component={StyledPaper}
      sx={{
        ...tableContainerProps?.sx,
        borderTop: `1px solid ${AppColors.AAA_LIGHT_NAVY}`,
        overflow: stickyHeader ? 'revert' : 'auto',
      }}
    >
      <Table sx={{ minWidth }} stickyHeader={relativeStickyHeader}>
        <DataHead
          hook={hook}
          hasSelectors={hasSelectors}
          isEditMode={isEditMode}
          stickyHeader={stickyHeader}
          stickyOffset={stickyOffset}
        />
        <DataBody
          columns={columns}
          hasSelectors={hasSelectors}
          observer={observer}
          isEditMode={isEditMode}
          readonlyRow={readonlyRow}
        />
      </Table>
    </TableContainer>
  );
}
