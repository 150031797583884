import * as Yup from 'yup';
import { DynamicField, DynamicFieldType } from 'components/Dynamic';
import { PricingRecordDto, DutyTypes, PricingTypes } from 'api/setup/manageServicesSetupApi';
import { TowTypes } from 'api/setup/manageTowSetupApi';
import { yupResolver } from '@hookform/resolvers/yup';
import { TowSetupData } from './PricingSetupQuery';
import { Resolver } from 'react-hook-form';

interface Errors {
  tows?: {
    [key: number]: {
      startMileage?: {
        message: string;
        type: string;
      };
      endMileage?: {
        message: string;
        type: string;
      };
    };
  };
}

export const dutyOptions = [
  { label: 'LIGHT', value: DutyTypes.LIGHT },
  { label: 'MEDIUM', value: DutyTypes.MEDIUM },
  { label: 'HEAVY', value: DutyTypes.HEAVY },
];

export const generalInfoValidationSchema = Yup.object({
  startDateTime: Yup.string().required().label('Effective Date'),
  endDateTime: Yup.string().required().label('Expire Date'),
  description: Yup.string().required().label('Description'),
});

export const generalInfoFields = (isCreationMode: boolean): DynamicField<PricingRecordDto>[] => [
  {
    label: 'Effective Date',
    name: 'startDateTime',
    type: DynamicFieldType.DATE,
    required: true,
    isReadOnly: !isCreationMode,
  },
  {
    label: 'Expire Date',
    name: 'endDateTime',
    type: DynamicFieldType.DATE,
    required: true,
  },
  {
    label: 'Description',
    name: 'description',
    colspan: 6,
    required: true,
    isReadOnly: !isCreationMode,
  },
];

export const defaultServiceCodes = [
  'T0',
  'T1',
  'T2',
  'T3',
  'T5',
  'T6',
  'T7',
  'T8',
  'T9',
  '7A',
  '7B',
  'RS',
];

export const serviceRatesValidationSchema = Yup.object({
  services: Yup.array().of(
    Yup.object().shape({
      payable: Yup.boolean().required('Payable field is required'),
      billable: Yup.boolean().required('Billable field is required'),
      pricingType: Yup.string().required('Pricing Type field is required'),
      pricePerUnit: Yup.number()
        .nullable()
        .typeError('Base Rate field is required and must be a number')
        .when('pricingType', {
          is: PricingTypes.AT_COST_PLUS,
          then: Yup.number()
            .positive('Base Rate must be greater than 0 when Pricing Type is "At Cost Plus"')
            .nullable()
            .typeError('Base Rate field is required and must be a number'),
        })
        .when('pricingType', {
          is: PricingTypes.AT_COST,
          then: Yup.number()
            .test(
              'is-zero',
              'Base Rate must be 0 when Pricing Type is "At Cost"',
              (value) => value === 0,
            )
            .nullable()
            .typeError('Base Rate field is required and must be a number'),
        }),
    }),
  ),
});

export const towsValidationSchema = Yup.object({
  tows: Yup.array().of(
    Yup.object().shape({
      startMileage: Yup.number()
        .required('Start Mileage field is required')
        .typeError('Start Mileage must be a number'),
      endMileage: Yup.number()
        .required('End Mileage field is required')
        .typeError('End Mileage must be a number'),
      type: Yup.string().oneOf(Object.values(TowTypes)).required('Tow Type field is required'),
      pricePerUnit: Yup.number()
        .typeError('Price per unit be a number')
        .required('Base Rate field is required'),
      priceCap: Yup.number()
        .transform((value, originalValue) => (originalValue.trim() === '' ? null : value))
        .nullable()
        .typeError('Price Cap must be a number'),
    }),
  ),
});

export const towsValidationResolver: Resolver<TowSetupData> = async (
  data,
  context,
  validateAllFieldCriteria,
) => {
  const yupResolverInstance = yupResolver(towsValidationSchema);
  const yupValidated = await yupResolverInstance(data, context, validateAllFieldCriteria);

  if (Object.keys(yupValidated.errors).length > 0) {
    return yupValidated;
  }

  const errors: Errors = {};
  let highestEndMileage = 0;

  data.tows.forEach((tow, index) => {
    const startMileage = parseInt(tow.startMileage, 10);
    const endMileage = parseInt(tow.endMileage, 10);

    if (endMileage <= startMileage) {
      errors.tows = errors.tows || {};
      errors.tows[index] = {
        ...errors.tows[index],
        endMileage: {
          message: 'End mileage must be greater than start mileage',
          type: 'validation',
        },
      };
    }

    if (index !== 0 && startMileage <= highestEndMileage) {
      errors.tows = errors.tows || {};
      errors.tows[index] = {
        ...errors.tows[index],
        startMileage: {
          message: 'Start mileage must be greater than previous end mileages',
          type: 'validation',
        },
      };
    }

    if (endMileage > highestEndMileage) {
      highestEndMileage = endMileage;
    }
  });

  return {
    values: data,
    errors: errors,
  };
};
