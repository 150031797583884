import { CRUDComponent } from 'components/CRUDNavigator';
import { CRUDPageableDetail } from 'components/CRUDPageable';
import { useNavigate, useParams } from 'react-router-dom';
import ServiceItemDetails from './ServiceItemDetails';
import { useServiceItemsContext } from './ServiceItemsProvider';
import { ServiceItemsData } from './types';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';

const breadcrumbs = [
  {
    url: '..',
    label: 'Service Items',
  },
  {
    url: '',
    label: 'Service Details',
  },
];

const ServiceItemPageView: CRUDComponent = (props) => {
  const { uuid = '' } = useParams();
  const context = useServiceItemsContext();
  const { queries, setNotifications } = context;
  const { mutateAsync } = queries.useDeleteByUUID();
  const navigate = useNavigate();
  const { permissions } = useRapCoreFeaturePermissions('servicesProvided');

  const handleDeleteAction = async (data: ServiceItemsData) => {
    try {
      await mutateAsync(data.uuid);
      navigate('/setup/service-items', {
        state: {
          notifications: {
            type: 'success',
            name: 'Success',
            message: 'Service Item Successfully Deleted',
          },
        },
      });
    } catch (error: any) {
      setNotifications({
        type: 'error',
        name: 'Error',
        message: error.response.data.message,
      });
    }
  };

  return (
    <CRUDPageableDetail
      breadcrumbs={breadcrumbs}
      context={context}
      uuid={uuid}
      componentProps={props}
      details={({ data, isRunning }) => <ServiceItemDetails data={data} isLoading={isRunning} />}
      handleLabel={(data) => `${data.code} - ${data.name}`}
      visible={permissions.delete}
      subMenuActions={(data) => [
        {
          label: 'Delete Service Item',
          onClick: () => handleDeleteAction(data),
          title: `Delete ${data.name}?`,
          content: `Are you sure you want to delete ${data.name}?`,
          labelConfirm: 'Delete',
          labelCancel: 'Cancel',
        },
      ]}
    />
  );
};

export default ServiceItemPageView;
