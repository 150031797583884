import BillingInformationSection from 'features/Setup/BillingInformation';
import ClientBillingInfoForm from './ClientBillingInfoForm';
import { useClientContext } from '../ClientProvider';
import {
  useGetClientBillingConfig,
  useCreateClientBillingConfig,
  useUpdateClientBillingConfig,
  useGetDefaultClientBillingInfoData,
  BillingConfigurationClientData,
} from 'features/Setup/Clients/ClientQuery';
import { BillingConfigurationClientDto } from 'api/setup/manageClientApi';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';
import { ClientBillingInfoValidationSchema } from './fields';

interface Props {
  entityUUID: string;
}

export default function ClientBillingInfo({ entityUUID }: Props) {
  const { isEditingSection, setIsEditingSection, queries } = useClientContext();
  const { permissions } = useRapCoreFeaturePermissions('clients');
  const { data: entityData } = queries.useFetchByUUID(entityUUID || '');

  return (
    <BillingInformationSection<BillingConfigurationClientData, BillingConfigurationClientDto>
      entityUUID={entityUUID}
      title="Billing Information"
      formContent={(hookForm, isEditMode) => (
        <ClientBillingInfoForm hookForm={hookForm} isEditMode={isEditMode} />
      )}
      validationSchema={ClientBillingInfoValidationSchema}
      isEditingSection={isEditingSection}
      setIsEditingSection={setIsEditingSection}
      useGetBillingConfig={useGetClientBillingConfig}
      useCreateBillingConfig={useCreateClientBillingConfig}
      useUpdateBillingConfig={useUpdateClientBillingConfig}
      useGetDefaultBillingInfoData={useGetDefaultClientBillingInfoData}
      readOnly={!permissions.update}
      entityData={entityData}
      fieldsToUpdateInEntity={['allowSplitPay']}
    />
  );
}
