/**
 *Add color names here
 */

const AppColors = {
  AAA_BLUE: '#003366',
  AAA_LIGHT_BLUE: '#127BA9',
  AAA_WHITE: '#FFFFFF',
  AAA_GRAY: '#F5F5F5',
  AAA_UCLA_BLUE: '#567493',
  AAA_OXFORD_BLUE: '#002346',
  AAA_QUARTZ: '#4A4A4A',
  AAA_HELP_TEXT: 'rgba(0, 0, 0, 0.6)',
  AAA_CORNFLOWER_BLUE: '#E7F2F6',
  AAA_LIGHT_GRAY: '#99ADC2',
  AAA_MEDIUM_GRAY: '#BBBBBB',
  AAA_LIGHT_NAVY: '#8099B3',
  AAA_ALTO_GRAY: '#DCDCDC',
  AAA_GREEN: '#509E2F',
  AAA_DEEP_GREEN: '#127E00',
  AAA_RED: '#CC0000',
  AAA_YELLOW: '#F4BA5D',
  AAA_DUSTY_GREY: '#979797',
  AAA_LOADER_GREY: '#E5E5E5',
  AAA_ELECTRIC_VIOLET: '#A000CF',
  AAA_JAFFA: '#EB7E30',
  AAA_SANGRIA: '#9D0208',
};

export default AppColors;

export type ButtonColors =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning';
