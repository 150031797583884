import { AWSCognitoDomains, AuthType } from './DomainConfig';
import { Amplify, Auth } from 'aws-amplify';
import { DomainAuthContext } from './DomainAuthContext';
import { LoggedUser } from './interfaces';
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { authSignIn } from 'auth/tools';
import {
  getCurrentAuthenticatedUser,
  getItemFromStorage,
  removeItemFromStorage,
  setItemToStorage,
} from './helpers';
import { useNavigate } from 'react-router-dom';
import { Role } from 'auth/permissions';

const AUTH_CODE = 'auth-code';

export const DomainAuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const [authCode, setAuthCode] = useState(getItemFromStorage<AuthType>(AUTH_CODE));
  const [user, setUser] = useState<LoggedUser | null>(null);
  const [isStarted, setStarted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const selectDomain = useCallback((authType: AuthType) => {
    setItemToStorage(AUTH_CODE, authType);
    const domain = AWSCognitoDomains.find((item) => item.authType === authType);
    Amplify.configure(domain?.config);
    setAuthCode(authType);
    return domain;
  }, []);

  const broadcastChannel = new BroadcastChannel('auth-channel');

  const signOut = useCallback(
    (ignoreCurrentPath?: boolean) => {
      if (authCode) {
        removeItemFromStorage(AUTH_CODE);
      }
      Auth.signOut()
        .then(() => {
          setUser(null);
          setAuthCode(null);
          setStarted(false);
          broadcastChannel.postMessage('logout');
          ignoreCurrentPath && navigate('/');
          window.location.reload();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authCode],
  );

  useEffect(() => {
    if (authCode) {
      setLoading(true);
      selectDomain(authCode);
      getCurrentAuthenticatedUser()
        .then((user) => {
          if (user?.groups.length === 0) {
            navigate('/no-permissions');
            Auth.signOut().then(() => {
              setUser(null);
              setAuthCode(null);
            });
          } else {
            setUser(user);
          }
        })
        .catch((err) => {
          console.error('DomainAuthProvider.getCurrentAuthenticatedUser', err);
          setUser(null);
          setAuthCode(null);
        })
        .finally(() => {
          setLoading(false);
          setStarted(true);
        });
    } else {
      setStarted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLogged = useMemo(() => !!authCode && !!user, [authCode, user]);

  const requestNonFederatedLogin = useCallback(
    (userEmail: string, userPass: string) => {
      setLoading(true);
      setErrorMessage('');
      selectDomain('NON-AD');
      authSignIn(userEmail, userPass)
        .then((responseUser) => {
          setIsDone(true);
          if (responseUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
            navigate('/reset-password', {
              state: {
                isFirstLogin: true,
                userEmail,
                userPass,
              },
            });
          } else if (responseUser.username) {
            // navigate to the path set to the url param 'from' or the default path
            const newLocation = new URLSearchParams(window.location.search).get('from') || '/';
            window.location.replace(newLocation);
          }
        })
        .catch((err) => {
          setErrorMessage(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [navigate, selectDomain],
  );

  const requestFederatedLogin = useCallback(async () => {
    setLoading(true);
    const selectedConfig = selectDomain('AD');
    if (selectedConfig) {
      try {
        await Auth.federatedSignIn({
          customProvider: selectedConfig.provider || '',
        });
      } catch (error) {
        console.error('Auth.federatedSignIn', error);
      }
    }
    setLoading(false);
  }, [selectDomain]);

  const userGroup = user?.groups.find((group) =>
    Object.values(Role).includes(group as Role),
  ) as Role;

  return (
    <DomainAuthContext.Provider
      value={{
        isStarted,
        isIdle: !!authCode,
        isLogged,
        isLoading,
        isDone,
        requestNonFederatedLogin,
        requestFederatedLogin,
        user,
        signOut,
        errorMessage,
        userGroup,
      }}
    >
      {isStarted ? children : <h3>Checking authentication...</h3>}
    </DomainAuthContext.Provider>
  );
};
