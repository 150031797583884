import { PricingRecordDto } from 'api/setup/manageServicesSetupApi';

/**
 * Extracts the startDateTime, endDateTime, and description properties from a PricingRecordDto object.
 *
 * @param generalInformation A PricingRecordDto object containing the general information to extract.
 * @returns An object containing startDateTime, endDateTime, and description properties.
 */
export const getUpdateInformationData = (
  generalInformation: PricingRecordDto,
): {
  startDateTime: string;
  endDateTime: string;
  description: string;
} => ({
  startDateTime: generalInformation.startDateTime,
  endDateTime: generalInformation.endDateTime,
  description: generalInformation.description,
});
