import { ReactComponent as PaymentIcon } from 'assets/payment.svg';
import { AuthComponent } from 'auth/permissions/authComponent';
import { ErrorResponseCard, LoaderCard } from 'components/Cards';
import { DynamicExpandablePanel } from 'components/Dynamic';
import SkeletonLoader from 'components/SkeletonLoader';
import { CaseDetailsContext } from 'features/Cases/contexts/CaseDetails';
import { useContext, useEffect, useState } from 'react';
import CaseFinancialsDetails from './CaseFinancialsDetails';
import { CASE_FINANCIAL_PERMISSION } from './config';
import useCaseFinancials from './useCaseFinancials';
import { RbpCaseStatus } from 'core/enums/RbpCaseStatus';

interface Props {
  caseUUID: string;
  clientId: number;
  rapCaseId: string;
  rbpCaseStatus: RbpCaseStatus;
}

function CaseFinancials({ caseUUID, clientId, rapCaseId, rbpCaseStatus }: Props) {
  const [expanded, setExpanded] = useState<boolean>(false);

  const {
    isLoading,
    data: caseFinancialsData,
    isSuccess,
    isError,
    errorCode,
  } = useCaseFinancials(caseUUID, expanded);

  const { allSectionsExpanded } = useContext(CaseDetailsContext);

  useEffect(() => {
    setExpanded(allSectionsExpanded);
  }, [allSectionsExpanded]);

  const onExpanded = (isExpanded: boolean) => () => {
    setExpanded(!isExpanded);
  };

  return (
    <DynamicExpandablePanel
      title="Case Financials"
      icon={<PaymentIcon />}
      onExpanded={onExpanded(expanded)}
      expanded={expanded}
      data-testid="CaseFinancialsPanel"
    >
      {isLoading && <LoaderCard label="Loading Financial Details" />}
      {isError && <ErrorResponseCard errorCode={errorCode} title="Financial Details" />}
      {isSuccess && caseFinancialsData && (
        <CaseFinancialsDetails
          caseFinancialsData={caseFinancialsData}
          caseUUID={caseUUID}
          clientId={clientId}
          rapCaseId={rapCaseId}
          rbpCaseStatus={rbpCaseStatus}
        />
      )}
    </DynamicExpandablePanel>
  );
}

export default AuthComponent(
  CASE_FINANCIAL_PERMISSION,
  CaseFinancials,
  <SkeletonLoader loading height={66} variant="rectangular" />,
);
