import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  BillingConfigurationClientDto,
  addClientBillingConfig,
  fetchClientBillingConfig,
  updateClientBillingConfig,
} from 'api/setup/manageClientApi';
import { BillTypes } from 'api/setup/manageBillingInfoApi';
import { ClientData, useClientContext } from './ClientProvider';

const QUERY_CLIENT_BILLING_INFO = 'QUERY_CLIENT_BILLING_INFO';

export interface BillingConfigurationClientData {
  uuid?: string;
  clientUuid?: string;
  billType: BillTypes;
  poRequired: boolean;
  poNumber: string;
  poEffectiveDates?: string[];
  holdingAccountNumberRevenue: string;
  holdingAccountNumberCost: string;
  addressAccountNumber: string;
  allowSplitPay: boolean;
  splitPayRapProgramCode: string;
  allowPrePay: boolean;
  outOfWarrantyProgramToRoute: string;
}

function mapDtoToData(
  dto: BillingConfigurationClientDto[],
): Partial<BillingConfigurationClientData> {
  const [firstDto] = dto;
  return {
    ...firstDto,
    poEffectiveDates: [firstDto.poEffectiveDate || '', firstDto.poExpirationDate || ''],
    poNumber: firstDto.poNumber || '',
  };
}

function extractDateFromTimestamp(timestamp: string) {
  if (!timestamp) {
    return null;
  }
  return new Date(timestamp).toISOString().split('T')[0];
}

function mapBillingDataToDto(data: BillingConfigurationClientData): BillingConfigurationClientDto {
  const [poEffectiveDate, poExpirationDate] = data.poEffectiveDates || [];
  const formattedPoEffectiveDate = extractDateFromTimestamp(poEffectiveDate);
  const formattedPoExpirationDate = extractDateFromTimestamp(poExpirationDate);

  return {
    billType: data.billType,
    poRequired: data.poRequired,
    poNumber: data.poNumber || null,
    poEffectiveDate: formattedPoEffectiveDate || null,
    poExpirationDate: formattedPoExpirationDate || null,
    clientUuid: data.clientUuid || '',
    holdingAccountNumberRevenue: data.holdingAccountNumberRevenue,
    holdingAccountNumberCost: data.holdingAccountNumberCost,
    addressAccountNumber: data.addressAccountNumber,
    splitPayRapProgramCode: data.splitPayRapProgramCode,
    allowPrePay: data.allowPrePay,
    outOfWarrantyProgramToRoute: data.outOfWarrantyProgramToRoute,
  };
}

export function useGetClientBillingConfig(clientUuid: string) {
  return useQuery<
    BillingConfigurationClientDto[],
    unknown,
    Partial<BillingConfigurationClientData>
  >([QUERY_CLIENT_BILLING_INFO, clientUuid], () => fetchClientBillingConfig(clientUuid), {
    select: mapDtoToData,
    enabled: !!clientUuid,
  });
}

export function useCreateClientBillingConfig(clientUuid: string) {
  const queryClient = useQueryClient();
  const {
    queries: { useUpdateByUUID, useFetchByUUID },
  } = useClientContext();
  const { data: clientData } = useFetchByUUID(clientUuid);
  const { mutateAsync: updateClientMutateAsync } = useUpdateByUUID(clientUuid);

  return useMutation(
    async (data: BillingConfigurationClientData) => {
      if (clientData?.allowSplitPay !== data.allowSplitPay) {
        await updateClientMutateAsync({
          ...clientData,
          allowSplitPay: data.allowSplitPay,
        } as ClientData);
      }

      return addClientBillingConfig(clientUuid, mapBillingDataToDto(data));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_CLIENT_BILLING_INFO]);
      },
    },
  );
}

export function useUpdateClientBillingConfig(clientUuid: string) {
  const queryClient = useQueryClient();
  const {
    queries: { useUpdateByUUID, useFetchByUUID },
  } = useClientContext();
  const { data: clientData } = useFetchByUUID(clientUuid);
  const { mutateAsync: updateClientMutateAsync } = useUpdateByUUID(clientUuid);

  return useMutation(
    async (data: BillingConfigurationClientData) => {
      if (clientData?.allowSplitPay !== data.allowSplitPay) {
        await updateClientMutateAsync({
          ...clientData,
          allowSplitPay: data.allowSplitPay,
        } as ClientData);
      }

      return updateClientBillingConfig(clientUuid, data?.uuid || '', mapBillingDataToDto(data));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_CLIENT_BILLING_INFO]);
      },
    },
  );
}

export const useGetDefaultClientBillingInfoData = (): BillingConfigurationClientData => ({
  billType: BillTypes.PER_EVENT,
  poRequired: false,
  poNumber: '',
  poEffectiveDates: [],
  holdingAccountNumberRevenue: '',
  holdingAccountNumberCost: '',
  addressAccountNumber: '',
  allowSplitPay: false,
  splitPayRapProgramCode: '',
  allowPrePay: false,
  outOfWarrantyProgramToRoute: '',
});
