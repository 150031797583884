import { CRUDNavigator } from 'components/CRUDNavigator';
import CasePageList from './CasePageList';
import CasePageView from './CasePageView';
import CaseProvider from './CaseProvider';

const CasePage = () => {
  return (
    <CaseProvider>
      <CRUDNavigator
        components={{
          Main: CasePageList,
          Individual: CasePageView,
        }}
      />
    </CaseProvider>
  );
};

export default CasePage;
