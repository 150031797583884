import { CardContent, Typography, styled } from '@mui/material';

export const SummaryCardContent = styled(CardContent)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const SummaryLabel = styled(Typography)(() => ({
  position: 'absolute',
  bottom: 0,
  minWidth: '172px',
  letterSpacing: '0.15px',
  fontSize: '1.25rem',
  lineHeight: '1.5rem',
}));

export const SummaryCount = styled(Typography)(() => ({
  letterSpacing: '0.15px',
  fontWeight: 800,
  fontSize: '2.5rem',
  lineHeight: '2.6rem',
}));
