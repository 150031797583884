import { CRUDComponent } from 'components/CRUDNavigator';
import { useVendorContext } from './VendorProvider';
import { VendorSearchFields, VendorDataTableConfig } from './VendorPageConfig';
import { CRUDPageableList } from 'components/CRUDPageable';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';

const VendorPageList: CRUDComponent = ({ navigators }) => {
  const context = useVendorContext();
  const { permissions } = useRapCoreFeaturePermissions('vendors');

  return (
    <CRUDPageableList
      name="Vendor"
      context={context}
      navigators={navigators}
      searchFields={VendorSearchFields}
      searchDefaultValues={{ type: '', rapVendorId: '', name: '', jdExternalId: '' }}
      tableColumns={VendorDataTableConfig}
      canCreate={permissions.create}
    />
  );
};

export default VendorPageList;
