import { useState } from 'react';
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Table,
  TableHead,
  TableBody,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { convertToCurrency } from 'utils/formatter';
import AppColors from 'AppColors';
import AttachedFinancialItemRow from './AttachedFinancialItemRow';
import { CaseData } from 'features/Cases/interfaces/CaseData';
import moment from 'moment';
import { RbpCaseStatus, RbpCaseStatusDescription } from 'core/enums/RbpCaseStatus';
import { StyledTextLink } from 'components/DataTable/DataTable.styled';
import { useNavigate } from 'react-router-dom';

export const FINANCIAL_ITEM_HEADERS = ['Service Code', 'Service Description', 'Amount'];

interface RowProps {
  row: CaseData;
  rowIndex: number;
}

function AttachedServicesRow({ row, rowIndex }: RowProps) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow key={rowIndex}>
        <TableCell
          sx={{
            borderRight: 0,
          }}
        >
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <StyledTextLink onClick={() => navigate(`/cases/${row.uuid}`)} variant="body2">
            {row.rapCaseId || 'Missing Case ID'}
          </StyledTextLink>
        </TableCell>
        <TableCell>{row.rapProgramCode}</TableCell>
        <TableCell>{row.clientName}</TableCell>
        <TableCell>{row.programName}</TableCell>
        <TableCell>{row.clubCode}</TableCell>
        <TableCell>
          {moment(new Date(row.rapCallCreatedDateTime)).isValid()
            ? moment(new Date(row.rapCallCreatedDateTime)).format('MM/DD/YYYY')
            : row.rapCallCreatedDateTime}
        </TableCell>
        <TableCell>
          {moment(new Date(row.rapCallClosedDateTime)).isValid()
            ? moment(new Date(row.rapCallClosedDateTime)).format('MM/DD/YYYY')
            : row.rapCallClosedDateTime}
        </TableCell>
        <TableCell>{row.callStatus}</TableCell>
        <TableCell>
          {row.rbpCaseStatus === RbpCaseStatus.CANCELLED ? (
            <Typography component={'span'} fontWeight={700} color={AppColors.AAA_RED}>
              {RbpCaseStatusDescription[row.rbpCaseStatus]}
            </Typography>
          ) : (
            RbpCaseStatusDescription[row.rbpCaseStatus]
          )}
        </TableCell>
        <TableCell>
          {convertToCurrency(row.financialItems?.reduce((sum, item) => sum + item.totalAmount, 0))}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: open ? 2 : 0, paddingTop: open ? 2 : 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box ml={6} mr={0}>
              <Table
                size="small"
                sx={{
                  '& .MuiTableHead-root .MuiTableCell-root': {
                    background: '#ECF4F8 !important',
                    borderTop: '1px solid #6080A0 !important',
                  },
                  '& .MuiTableHead-root .MuiTableCell-root:not(:last-child)': {
                    borderRight: '1px solid #FFFFFF !important',
                  },
                  '& .MuiTableHead-root .MuiTableCell-root:last-child': {
                    borderRight: '1px solid !important',
                  },
                  '& .MuiTableHead-root .MuiTableCell-root:first-child': {
                    borderLeft: '1px solid !important',
                  },
                  '& .MuiTableBody-root .MuiTableCell-body': {
                    background: 'transparent !important',
                    border: '1px solid #6080A0',
                  },
                  '& .MuiTableBody-root .MuiTableCell-body:not(:last-child)': {
                    background: 'transparent !important',
                    borderRight: '1px solid #E6EBF0',
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    {FINANCIAL_ITEM_HEADERS.map((header) => (
                      <TableCell key={header}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.financialItems &&
                    row.financialItems.map((finantialItem, idx) => (
                      <AttachedFinancialItemRow
                        key={`${finantialItem.itemCode}_${idx}`}
                        finantialItem={finantialItem}
                      />
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default AttachedServicesRow;
