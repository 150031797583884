import { StepConfig } from 'components/FormStepper/types';
import InfoIcon from '@mui/icons-material/Info';
import ServiceItemGeneralInfoStep from './ServiceItemGeneralInfoStep';

const GeneralInfoStepConfig: StepConfig = {
  key: 'GENERAL_INFO',
  label: 'General',
  actionBarLabel: 'General Info',
  icon: InfoIcon,
  component: ServiceItemGeneralInfoStep,
};

export const ServiceItemFormSteps = [GeneralInfoStepConfig];
