import { CreatePageableCRUDApi } from 'api/helper/PageableCRUDApi';
import { CreditCardTransactionDto } from './interfaces/CreditCardTransactionDto';
import { ENV_BACKOFFICE_BASE_API_URL } from 'lib/envConfig';
import { BaseAPI } from 'api/auth';
import { CaseGeneralInfoDto } from 'api/cases/interfaces/GeneralInfoDto';
import { TransactionStatus } from 'core/enums/CreditCards';

export const manageCreditCardsApi = CreatePageableCRUDApi<
  CreditCardTransactionDto | CaseGeneralInfoDto
>(`${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/credit-cards`, {
  fetchByUUID: (uuid: string) => {
    return BaseAPI.get<CaseGeneralInfoDto>(`${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${uuid}`);
  },
});

export function updateCreditCardStatus(
  uuid: string,
  creditCardUuid: string,
  status: TransactionStatus,
  refundReason?: string,
  refundAmount?: number,
) {
  return BaseAPI.patch(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${uuid}/credit-cards/${creditCardUuid}`,
    { status, refundReason, refundAmount },
  );
}
