import AppColors from 'AppColors';
import TableRow from '@mui/material/TableRow';
import { DataTableColumn } from './interfaces';
import { StyledTableCell } from './DataTable.styled';

export function generateFooters<DataType>(
  columns: DataTableColumn<DataType>[],
  rows: DataType[],
  hasSelectors?: boolean,
) {
  const hasFooters = columns.some((col) => !!col.Footer);

  if (!hasFooters) {
    return null;
  }

  return (
    <TableRow>
      {hasSelectors && (
        <StyledTableCell
          variant="body"
          sx={{
            borderBottom: `1px solid ${AppColors.AAA_LIGHT_NAVY}`,
            fontWeight: 700,
          }}
        />
      )}

      {columns.map((col) => {
        const hasFooter = !!col.Footer;

        return (
          <StyledTableCell
            variant="body"
            key={col.id as string}
            sx={{
              borderBottom: `1px solid ${AppColors.AAA_LIGHT_NAVY}`,
              fontWeight: 700,
              backgroundColor: hasFooter ? AppColors.AAA_GRAY : '',
            }}
          >
            {col.Footer?.(rows)}
          </StyledTableCell>
        );
      })}
    </TableRow>
  );
}
