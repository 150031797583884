import { updateCreditCardStatus } from 'api/creditCards/manageCreditCardsApi';
import { TransactionStatus } from 'core/enums/CreditCards';
import { QUERY_MANAGE_CASE_CREDIT_CARD_BY_UUID } from 'features/Cases/queries/manageCaseQueries';
import { useManagedMutation } from 'hooks/useReactQueryHelpers';
import { useQueryClient } from 'react-query';

const QUERY_CANCEL_PRE_AUTHORIZATION_CREDIT_CARD = 'QUERY_CANCEL_PRE_AUTHORIZATION_CREDIT_CARD';

interface CreditCardUpdatePayload {
  uuid: string;
  creditCardUuid: string;
  status: TransactionStatus;
}

type RefundCreditCard = CreditCardUpdatePayload & {
  refundReason: string;
  refundAmount: number;
};

export function useUpdateCreditCardStatus() {
  const queryClient = useQueryClient();
  return useManagedMutation({
    mutationFn: ({ uuid, creditCardUuid, status }: CreditCardUpdatePayload) =>
      updateCreditCardStatus(uuid, creditCardUuid, status),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_CANCEL_PRE_AUTHORIZATION_CREDIT_CARD]);
      queryClient.invalidateQueries([QUERY_MANAGE_CASE_CREDIT_CARD_BY_UUID]);
    },
  });
}

export function useRefundCreditCard() {
  const queryClient = useQueryClient();
  return useManagedMutation({
    mutationFn: ({ uuid, creditCardUuid, status, refundReason, refundAmount }: RefundCreditCard) =>
      updateCreditCardStatus(uuid, creditCardUuid, status, refundReason, refundAmount),
    onSuccess: () => queryClient.invalidateQueries([QUERY_CANCEL_PRE_AUTHORIZATION_CREDIT_CARD]),
  });
}
