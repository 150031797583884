import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

type SyncParamType = string;

export default function useSyncedSearchParams(
  key: string,
  initialValue = '',
): [string | undefined, React.Dispatch<SyncParamType>] {
  const [searchParams, setSearchParams] = useSearchParams();

  const [syncField, setSyncField] = useState<SyncParamType>(
    () => (initialValue || searchParams.get(key)) ?? '',
  );

  const onSetSyncField: React.Dispatch<SyncParamType> = (value: SyncParamType) => {
    if (value === syncField) return;
    if (!value) searchParams.delete(key);
    else searchParams.set(key, value);
    setSearchParams(searchParams);
    setSyncField(value);
  };

  // if search params change then update syncField with value from search params
  useEffect(() => {
    const value = searchParams.get(key);
    if (value === syncField) return;
    setSyncField(value || '');
  }, [key, searchParams, syncField]);

  return [syncField, onSetSyncField];
}
