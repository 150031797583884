import { useState } from 'react';
import Box from '@mui/material/Box';
import DataTable from 'components/DataTable';
import FormDivider from 'components/Form/FormDivider';
import { CaseComment } from '../../interfaces/CaseComments';
import AddComment from './AddComment';
import { FullCenteredCard } from 'components/Cards/FullCenteredCard';
import AppColors from 'AppColors';
import { useCaseCommentsConfig, useCaseSmsConfig } from './config';
import ErrorsTable from './ErrorsTable';
import { RbpCaseStatus } from 'core/enums/RbpCaseStatus';
import { CaseSms } from 'features/Cases/interfaces/CaseSms';
import { CaseErrorDto } from 'api/cases/interfaces/CaseErrorDto';

interface Props {
  errorsData?: CaseErrorDto[];
  commentsData?: CaseComment[];
  smsData?: CaseSms[];
  caseUuid: string;
  rbpCaseStatus: RbpCaseStatus;
}

const ErrorsAndCommentsEditor = ({
  errorsData,
  commentsData,
  smsData,
  caseUuid,
  rbpCaseStatus,
}: Props) => {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('');

  const createSortHandler = (_: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const { columns: commentsColumns, canCreateNew: canCreateComments } = useCaseCommentsConfig();
  const { columns: smsColumns } = useCaseSmsConfig();
  return (
    <>
      <ErrorsTable errors={errorsData} caseUUID={caseUuid} rbpCaseStatus={rbpCaseStatus} />

      {commentsColumns.length > 0 && (
        <>
          <FormDivider label="Comments" sx={{ mb: 3 }} />
          <Box sx={{ pb: 4, mx: -4, mb: 4, overflow: 'auto' }}>
            {commentsData?.length ? (
              <DataTable<CaseComment>
                columns={commentsColumns}
                rows={commentsData}
                sortOptions={{ hasSort: true, order, orderBy, createSortHandler }}
                tableContainerSx={{ maxHeight: '270px' }}
                stickyHeader
                stickyOffset={0}
              />
            ) : (
              <FullCenteredCard sx={{ fontSize: 20, boxShadow: 0, color: AppColors.AAA_QUARTZ }}>
                No Case Comments
              </FullCenteredCard>
            )}
          </Box>
          {canCreateComments && <AddComment caseUuid={caseUuid} />}
        </>
      )}

      {smsColumns.length > 0 && (
        <>
          <FormDivider label="SMS Messages" sx={{ mb: 3 }} />
          <Box sx={{ pb: 4, mx: -4, mb: 4, overflow: 'auto' }}>
            {smsData?.length ? (
              <DataTable<CaseSms>
                columns={smsColumns}
                rows={smsData}
                sortOptions={{ hasSort: true, order, orderBy, createSortHandler }}
                tableContainerSx={{ maxHeight: '270px' }}
                stickyHeader
                stickyOffset={0}
              />
            ) : (
              <FullCenteredCard sx={{ fontSize: 20, boxShadow: 0, color: AppColors.AAA_QUARTZ }}>
                No Case SMS Messages
              </FullCenteredCard>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default ErrorsAndCommentsEditor;
