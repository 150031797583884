import { Box, Paper, Step, Typography } from '@mui/material';
import { StyledSteppper, StyledStepConnector, StyledStepLabel } from './styles';
import { StepConfig } from '../types';

export interface StepperHeaderProps {
  steps: StepConfig[];
  activeStep: number;
  completed: { [k: number]: boolean };
  handleStepClick?: (stepIndex: number) => void;
}

export default function StepperHeader({
  steps,
  activeStep,
  completed,
  handleStepClick,
}: StepperHeaderProps) {
  const handleClick = (stepIndex: number) => () => {
    handleStepClick?.(stepIndex);
  };

  return (
    <Box component={Paper} sx={{ p: 2, width: '100%', mb: '10px' }}>
      <StyledSteppper
        nonLinear
        alternativeLabel
        activeStep={activeStep}
        connector={<StyledStepConnector />}
      >
        {steps.map((step, index) => (
          <Step key={step.label} completed={completed[index]}>
            <StyledStepLabel
              data-testid={`StepHeader-${step.key}`}
              StepIconComponent={step.icon}
              onClick={handleClick(index)}
            >
              <Typography
                data-testid={`StepperLabel-${step.key}`}
                sx={{ fontSize: '16px', fontWeight: 600 }}
              >
                {step.label}
              </Typography>
            </StyledStepLabel>
          </Step>
        ))}
      </StyledSteppper>
    </Box>
  );
}
