import { StepComponent } from 'components/FormStepper/types';
import { ServiceItemsData } from '../types';
import { useServiceItemsContext } from '../ServiceItemsProvider';
import { useEntityCreationStep } from 'components/EntityCreationFlow';
import { GeneralInfoValidationSchema } from '../ServiceItemDetails/ServiceItemDetailsConfig';
import ServiceItemForm from '../ServiceItemDetails/ServiceItemForm';

const ServiceItemGeneralInfoStep = ({
  submitted,
  completed,
  stepKey,
  resetSubmitted,
  setCompleted,
}: StepComponent) => {
  const { queries } = useServiceItemsContext();
  const defaultValues: Partial<ServiceItemsData> = {
    code: '',
    name: '',
    description: '',
    payable: true,
  };

  const GeneralInfoStep = useEntityCreationStep<ServiceItemsData>({
    initialData: defaultValues,
    validationSchema: GeneralInfoValidationSchema,
    form: ServiceItemForm,
    query: queries.useCreate,
  });

  return (
    <GeneralInfoStep
      stepKey={stepKey}
      submitted={submitted}
      completed={completed}
      resetSubmitted={resetSubmitted}
      setCompleted={setCompleted}
    />
  );
};

export default ServiceItemGeneralInfoStep;
