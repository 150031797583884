import { useCallback, useMemo } from 'react';
import { DataTableColumn } from 'components/DataTable';
import { usePermissionRoles } from './PermissionProvider';

interface Props<T> {
  table: string;
  fields: DataTableColumn<T>[];
}

export function useColumnPermission<T>({ table, fields }: Props<T>): {
  columns: DataTableColumn<T>[];
  canCreateNew: boolean;
} {
  const { permissions } = usePermissionRoles();

  const getColumnAttributes = useCallback(
    (fieldName: string) => {
      const permissionField = permissions[table]?.fields[fieldName];

      if (!permissionField) {
        return;
      }

      const canCreate = permissionField.create;
      const isReadOnly = permissionField.read;
      const isVisible = canCreate || isReadOnly;

      return { canCreate, isReadOnly, isVisible };
    },
    [permissions, table],
  );

  return useMemo(() => {
    let canCreateNew = false;
    const columns = fields.reduce<DataTableColumn<T>[]>((list, field) => {
      const attr = getColumnAttributes(field.id.toString());
      if (attr && attr.isVisible) {
        if (attr.canCreate === true) {
          canCreateNew = true;
        }
        list.push({ ...field });
      }
      return list;
    }, []);

    return { columns, canCreateNew };
  }, [fields, getColumnAttributes]);
}
