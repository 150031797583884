import DataRow from './DataRow';
import TableBody from '@mui/material/TableBody';
import { DataGridColumn } from './types';
import { DataObserver } from './DataObserver';

interface Props<DataType> {
  hasSelectors?: boolean;
  columns: DataGridColumn<DataType>[];
  observer: DataObserver<DataType>;
  isEditMode?: boolean;
  readonlyRow?: (row: DataType) => boolean;
}

export default function DataBody<DataType>({
  hasSelectors,
  columns,
  observer,
  isEditMode,
  readonlyRow,
}: Props<DataType>) {
  return (
    <TableBody>
      {observer.data.map((_, index) => (
        <DataRow
          key={index}
          index={index}
          columns={columns}
          hasSelectors={hasSelectors}
          observer={observer}
          isEditMode={isEditMode}
          readonlyRow={readonlyRow}
        />
      ))}
    </TableBody>
  );
}
