import { isValidElement } from 'react';
import { InputAdornment, Chip, Tooltip, useTheme, tooltipClasses, styled } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { TooltipCategories, getCategoryDetails } from './config';
import AppColors from '../../../AppColors';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';

interface Props {
  category: TooltipCategories;
  position: 'end' | 'start';
  children?: React.ReactNode;
}

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: AppColors.AAA_MEDIUM_GRAY,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: AppColors.AAA_MEDIUM_GRAY,
  },
}));

const FormInputTooltip: React.FC<Props> = ({ category, position, children }) => {
  const theme = useTheme();
  const categoryDetails = getCategoryDetails(theme);
  const { tooltipTitle, chipLabel, backgroundColor, placement, infoIcon } =
    categoryDetails[category];

  const chipElement = (
    <Chip
      label={chipLabel}
      size="small"
      sx={{
        backgroundColor: backgroundColor,
        color: theme.palette.common.white,
      }}
    />
  );

  return (
    <InputAdornment position={position}>
      {isValidElement(children) ? (
        <LightTooltip arrow title={tooltipTitle} placement={placement || 'bottom'}>
          {children}
        </LightTooltip>
      ) : (
        <LightTooltip arrow title={tooltipTitle} placement={placement || 'bottom'}>
          {infoIcon ? <InfoIcon sx={{ color: AppColors.AAA_LIGHT_BLUE }} /> : chipElement}
        </LightTooltip>
      )}
    </InputAdornment>
  );
};

export default FormInputTooltip;
