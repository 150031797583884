import { FunctionComponent } from 'react';
import { useFeaturePermission } from './useFeaturePermission';

export function AuthComponent<T extends object>(
  table: string,
  WrapperComponent: FunctionComponent<T>,
  loader?: JSX.Element,
) {
  return function HOCAuthComponent(props: T) {
    const permissions = useFeaturePermission({ table });

    if (permissions === undefined) {
      return loader ?? null;
    }

    if (permissions?.read) {
      return <WrapperComponent {...props} />;
    }
    return null;
  };
}
