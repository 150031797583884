import { BaseAPI } from 'api/auth';
import { PageableResponse } from 'api/interfaces/responses';

export interface GetAllParam {
  page: number;
  size: number;
  filter?: string;
  sort?: string;
}

export interface PageableCRUDApi<DtoType> {
  fetchAll: (params: GetAllParam) => Promise<PageableResponse<DtoType>>;
  create: (data: DtoType) => Promise<DtoType>;
  uploadLogo: (uuid: string, file: File) => Promise<DtoType>;
  fetchByUUID: (uuid: string) => Promise<DtoType>;
  updateByUUID: (uuid: string, data: DtoType) => Promise<DtoType>;
  deleteByUUID: (uuid: string) => Promise<void>;
}

export function CreatePageableCRUDApi<DtoType>(
  baseEndpoint: string,
  override?: Partial<PageableCRUDApi<DtoType>>,
): PageableCRUDApi<DtoType> {
  const methods = {
    fetchAll: (params: GetAllParam) => {
      return BaseAPI.get<PageableResponse<DtoType>>(`${baseEndpoint}`, {
        params,
      });
    },

    create: (data: DtoType) => {
      return BaseAPI.post<DtoType>(`${baseEndpoint}`, data);
    },

    uploadLogo: (uuid: string, file: File) => {
      const formData = new FormData();
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      formData.append('file', file);
      return BaseAPI.post<DtoType>(`${baseEndpoint}/${uuid}/logo`, formData, config);
    },

    fetchByUUID: (uuid: string) => {
      return BaseAPI.get<DtoType>(`${baseEndpoint}/${uuid}`);
    },

    updateByUUID: (uuid: string, data: DtoType) => {
      return BaseAPI.put<DtoType>(`${baseEndpoint}/${uuid}`, data);
    },

    deleteByUUID: (uuid: string) => {
      return BaseAPI.delete<void>(`${baseEndpoint}/${uuid}`);
    },
  };

  if (override) {
    Object.assign(methods, override);
  }

  return methods;
}
