import { Box, Typography } from '@mui/material';
import AppColors from 'AppColors';

interface Props {
  active?: boolean;
  label?: string;
}

export function ActiveFlagLabel({ active, label }: Props) {
  return (
    <Box>
      <Typography component={'span'} variant="h6">
        {label || 'Status'}:
      </Typography>{' '}
      <Typography component={'span'} variant="h6" fontWeight={600}>
        {active ? (
          <span style={{ color: AppColors.AAA_GREEN }}>Active</span>
        ) : (
          <span style={{ color: AppColors.AAA_LIGHT_GRAY }}>Inactive</span>
        )}
      </Typography>
    </Box>
  );
}
