import {
  FormControl as MuiFormControl,
  FormControlLabel as MuiFormControlLabel,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormHelperText,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { ControlFieldProps } from '../types';

import { ReactComponent as CheckBoxOutlineIcon } from 'assets/checkbox-outline.svg';
import { ReactComponent as CheckBoxCheckedIcon } from 'assets/checkbox-checked.svg';
import AppColors from 'AppColors';

export type ControlledCheckboxProps = ControlFieldProps &
  MuiCheckboxProps & {
    label: string;
    error?: boolean;
    helperText?: string;
    fullWidth?: boolean;
  };

const ControlledCheckbox = ({
  control,
  name,
  label,
  error,
  helperText,
  readOnly,
  fullWidth,
  ...checkboxProps
}: ControlledCheckboxProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, ...field } }) => (
        <MuiFormControl error={error} fullWidth={fullWidth}>
          <MuiFormControlLabel
            control={
              <MuiCheckbox
                {...field}
                {...checkboxProps}
                onChange={!readOnly ? onChange : undefined}
                checked={value || false}
                disabled={readOnly}
                icon={<CheckBoxOutlineIcon />}
                checkedIcon={<CheckBoxCheckedIcon />}
              />
            }
            label={label}
            sx={{
              '& .MuiFormControlLabel-label.Mui-disabled': {
                color: AppColors.AAA_BLUE,
              },
            }}
          />
          {error && <FormHelperText>{helperText}</FormHelperText>}
        </MuiFormControl>
      )}
    />
  );
};

export default ControlledCheckbox;
