import { VendorTypeOptions } from 'api/setup/interfaces/VendorDto';
import { DataTableColumn } from 'components/DataTable';
import { DynamicFieldType } from 'components/Dynamic';
import { DynamicQueryFieldType } from 'components/Dynamic/DynamicSearchBox/types';
import { VendorSearch, VendorData } from './types';
import { convertUTCDateToString } from 'utils/convertDate';

export const VendorSearchFields: DynamicQueryFieldType<VendorSearch>[] = [
  {
    name: 'type',
    label: 'Vendor Type',
    searchType: 'equal',
    type: DynamicFieldType.SELECT,
    select: {
      options: VendorTypeOptions.map((item) => ({ label: item, value: item })),
    },
  },
  {
    name: 'rapVendorId',
    label: 'Vendor Code',
    searchType: 'equal',
  },
  {
    name: 'name',
    label: 'Vendor Name',
    searchType: 'like',
  },
  {
    name: 'jdExternalId',
    label: 'JDEdwards Account Routing Number',
    searchType: 'equal',
  },
];

export const VendorDataTableConfig: DataTableColumn<VendorData>[] = [
  { label: 'Vendor Type', id: 'type', sortable: true },
  { label: 'Vendor Code', id: 'rapVendorId', sortable: true },
  { label: 'Vendor Name', id: 'name', sortable: true },
  { label: 'Backdoor Phone Number for Call Centers', id: 'callCenterPhoneNumber' },
  {
    label: 'Merge Date',
    id: 'mergeDate',
    accessor: (row) => convertUTCDateToString(row.mergeDate),
  },
];
