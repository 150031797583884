import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import theme from 'theme';

interface DeleteSavedSearchConfirmProps {
  open: boolean;
  title: string;
  message: string;
  onConfirm: () => void;
  onClose: () => void;
}

export const DeleteSavedSearchConfirm: React.FC<DeleteSavedSearchConfirmProps> = ({
  open,
  title,
  message,
  onConfirm,
  onClose,
}: DeleteSavedSearchConfirmProps) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ '& .MuiDialogContent-root': { width: 475, pl: 3, pr: 0 } }}
    >
      <DialogTitle id="alert-dialog-title" sx={{ pt: 3 }}>
        <Typography variant="body1">{title}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.text.primary,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pb: 3.5 }}>
        <Typography variant="body2" id="alert-dialog-description">
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button data-testid="CancelButton" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button data-testid="DeleteButton" variant="contained" onClick={onConfirm} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
