import { Box, Typography } from '@mui/material';

interface DataBoxProps {
  text?: string | string[];
  width: string;
  empty?: boolean;
  bold?: boolean;
  textAlign?: string;
  fontSize?: string;
  lineHeight?: string;
  hideBorder?: boolean;
  showBorderBottom?: boolean;
  showBorderRight?: boolean;
}

const DataBox = ({
  text,
  empty,
  width,
  bold,
  textAlign,
  fontSize,
  lineHeight,
  hideBorder,
  showBorderBottom,
  showBorderRight,
}: DataBoxProps) => {
  return (
    <>
      <Box
        component="div"
        sx={{
          width,
          borderLeft: !hideBorder ? 'solid 1px #000' : 'none',
          borderRight: showBorderRight ? 'solid 1px #000' : 'none',
          borderTop: !empty && !hideBorder ? 'solid 1px #000' : 'none',
          borderBottom: showBorderBottom ? 'solid 1px #000' : 'none',
          padding: '0 10px',
          background: !empty ? 'white' : 'none',
        }}
      >
        {typeof text === 'string' ? (
          <Typography
            variant="h6"
            sx={{
              textAlign: textAlign || 'center',
              color: '#000',
              fontSize: fontSize || 12,
              fontWeight: bold ? 'bold' : 'normal',
              width: '100%',
              lineHeight: lineHeight || '24px',
            }}
          >
            {text}
          </Typography>
        ) : (
          (text || []).map((item) => (
            <Typography
              key={item}
              variant="h6"
              sx={{
                textAlign: textAlign || 'center',
                color: '#000',
                fontSize: fontSize || 12,
                fontWeight: bold ? 'bold' : 'normal',
                width: '100%',
                lineHeight: lineHeight || '24px',
              }}
            >
              {item}
            </Typography>
          ))
        )}
      </Box>
    </>
  );
};

export default DataBox;
