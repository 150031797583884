import { createContext } from 'react';

interface CaseDetailsContextProps {
  allSectionsExpanded: boolean;
  isEditingSection: boolean;
  setIsEditingSection: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CaseDetailsContext = createContext<CaseDetailsContextProps>({
  allSectionsExpanded: false,
  isEditingSection: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsEditingSection: () => {},
});
