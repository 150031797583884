/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { convertRawData } from './convertRawData';
import { ErrorData, MappedQueryResult, ReactQueryOptions } from './types';

/**
 * Wrapps the useQuery to be used for generic requests.
 * @param key Unique Identification Key
 * @param requestor API caller
 * @param options useQuery options and mapper callback
 * @param args params to be passed to API caller
 */
export function useMappedReactQuery<SourceType, ResponseType = SourceType>(
  key: string,
  requestor: (...params: any[]) => Promise<SourceType>,
  options: ReactQueryOptions<SourceType, ResponseType, AxiosError>,
  ...args: any[]
): MappedQueryResult<SourceType, ResponseType> {
  const { mapper } = options;
  const {
    data: rawData,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    error,
    ...rest
  } = useQuery<SourceType, AxiosError, SourceType, (string | any[])[]>(
    [key, args],
    () => requestor(...args),
    options,
  );

  const isRunning = isLoading || isFetching;
  const isFinishedWithSuccess = !isRunning && isSuccess;
  const isEmpty = isFinishedWithSuccess && !rawData;
  const isDone = isFinishedWithSuccess && !!rawData;
  const data = convertRawData(rawData, mapper);

  const errorCode: ErrorData | undefined = useMemo(() => {
    const status = error?.response?.status;
    if (!status) {
      return;
    }
    switch (status) {
      case 400:
        return ErrorData.BadRequest;
      case 401:
        return ErrorData.Unauthorized;
      case 403:
        return ErrorData.Forbidden;
      case 404:
        return ErrorData.NotFound;
      default:
        return ErrorData.ServerError;
    }
  }, [error?.response?.status]);

  return {
    ...rest,
    data,
    rawData,
    isRunning,
    isEmpty,
    isDone,
    isLoading,
    isSuccess,
    isFetching,
    isError,
    error,
    errorCode,
  };
}
