import { BaseAPI } from 'api/auth';
import { PageableResponse } from 'api/interfaces/responses';
import { ENV_BACKOFFICE_BASE_API_URL } from 'lib/envConfig';
import { ServiceProvidedDto } from './interfaces';
import { QueryBuilder } from 'lib/querybuilder';
import { CaseServiceProvidedDto } from 'api/cases/interfaces/ServiceProvidedDto';

export function fetchServicesProvided() {
  return BaseAPI.get<PageableResponse<ServiceProvidedDto>>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/services-provided`,
    { params: { size: 9999 } },
  );
}

export const fetchServicesProvidedByCode = (codes: string[]) => {
  return BaseAPI.get<PageableResponse<CaseServiceProvidedDto>>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/services-provided?filter=${QueryBuilder.in(
      'code',
      ...codes,
    )}`,
    { params: { size: 999 } },
  );
};
