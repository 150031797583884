import { LoaderCard } from 'components/Cards';
import LoginHeader from './LoginHeader';
import LoginTypeNonFederated from './LoginTypeNonFederated';
import LoginTypeSelector from './LoginTypeSelector';
import LoginWrapper from './LoginWrapper';
import { AuthType } from 'auth';
import { useEffect, useState } from 'react';

interface LoginSelectorProps {
  onNonFederatedLogin?: (email: string, password: string) => void;
  onFederatedLogin?: () => void;
  errorMessage?: string;
  isLoading?: boolean;
  isDone?: boolean;
}

export default function LoginSelector({
  errorMessage,
  isLoading,
  onNonFederatedLogin,
  onFederatedLogin,
  isDone,
}: LoginSelectorProps) {
  const [authType, setAuthType] = useState<AuthType | null>(null);

  useEffect(() => {
    if (authType === 'AD' && onFederatedLogin) {
      onFederatedLogin();
    }
  }, [authType, onFederatedLogin]);

  if (isDone) {
    return (
      <LoginWrapper>
        <LoaderCard label="Redirecting user..." />
      </LoginWrapper>
    );
  }

  return (
    <LoginWrapper>
      <LoginHeader />

      {!authType && <LoginTypeSelector onSelect={(sel) => setAuthType(sel.value)} />}

      {authType === 'NON-AD' && (
        <LoginTypeNonFederated
          errorMessage={errorMessage}
          isLoading={isLoading}
          onSubmit={onNonFederatedLogin}
        />
      )}

      {isLoading && <LoaderCard label="Authenticating user..." />}
    </LoginWrapper>
  );
}
