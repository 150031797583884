import { BaseAPI } from 'api/auth';
import { BillingRequestDto, UpdateBillingRequestStatusDto } from './interfaces/BillingRequestDto';
import { BillingRequestStatus } from './interfaces';
import { CreatePageableCRUDApi, GetAllParam } from 'api/helper/PageableCRUDApi';
import { ENV_BACKOFFICE_BASE_API_URL } from 'lib/envConfig';
import { PageableResponse } from 'api/interfaces/responses';
import { QueryBuilder } from 'lib/querybuilder';
import { BillingRequestAdjustmentRequestDto } from 'features/Invoices/types';
import { PageableParams } from 'hooks/useReactQueryHelpers';
import { CaseData } from 'features/Cases/interfaces/CaseData';

const ENDPOINT_BILLING_REQUEST = `${ENV_BACKOFFICE_BASE_API_URL}/v1/billing-requests`;

export const manageBillingRequestApi = CreatePageableCRUDApi<BillingRequestDto>(
  ENDPOINT_BILLING_REQUEST,
  {
    fetchAll: (params: GetAllParam) => {
      return BaseAPI.get<PageableResponse<BillingRequestDto>>(`${ENDPOINT_BILLING_REQUEST}`, {
        params: {
          ...params,
          sort: params.sort ? params.sort : 'startPeriod,desc',
        },
      });
    },
  },
);

export const fetchBillingRequestTotalByStatus = (status: BillingRequestStatus[]) => {
  return BaseAPI.get<PageableResponse<BillingRequestDto>>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/billing-requests?filter=${QueryBuilder.in(
      'status',
      ...status,
    )}`,
    { params: { size: 999 } },
  );
};

export const updateBillingRequestStatus = (uuid: string, status: BillingRequestStatus) => {
  return BaseAPI.patch<UpdateBillingRequestStatusDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/billing-requests/${uuid}/status`,
    { status },
  );
};

export const addBillingRequestAdjustment = (
  uuid: string,
  data: BillingRequestAdjustmentRequestDto,
) => {
  return BaseAPI.post<BillingRequestAdjustmentRequestDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/billing-requests/${uuid}/adjustments`,
    data,
  );
};

export const addInvoiceLine = (uuid: string, data: Partial<BillingRequestAdjustmentRequestDto>) => {
  return BaseAPI.post<BillingRequestAdjustmentRequestDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/billing-requests/${uuid}/adjustments/line-items`,
    data,
  );
};

export const updateInvoiceLine = (
  uuid: string,
  lineUuid: string,
  data: Partial<BillingRequestAdjustmentRequestDto>,
) => {
  return BaseAPI.put<BillingRequestAdjustmentRequestDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/billing-requests/${uuid}/adjustments/${lineUuid}`,
    data,
  );
};

export const deleteInvoiceLine = (uuid: string, lineUuid: string) => {
  return BaseAPI.delete<BillingRequestAdjustmentRequestDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/billing-requests/${uuid}/adjustments/${lineUuid}`,
  );
};

export const fetchCasesBillingRequest = (
  uuid: string,
  billingLevel: string,
  params: PageableParams,
) => {
  return BaseAPI.get<{
    content: CaseData[];
    size: number;
    totalPages: number;
    totalElements: number;
    number: number;
    numberOfElements: number;
  }>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/billing-requests/${uuid}/cases?billingLevel=${billingLevel}&page=${params.page}&size=${params.size}`,
  );
};

export const addCaseToBillingRequest = (billingRequestUuid: string, caseUuid: string) => {
  return BaseAPI.post(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/billing-requests/${billingRequestUuid}/cases/${caseUuid}`,
    {},
  );
};
