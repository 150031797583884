import { BillingRequestItemData } from '../types';
import { DataGrid } from 'components/DataGrid';
import { DataGridColumn } from 'components/DataGrid/types';
import { DataGridReturn } from 'components/DataGrid/useDataGrid';

export const columns: DataGridColumn<BillingRequestItemData>[] = [
  { id: 'order', label: '#', isReadOnly: true, width: 100 },
  { id: 'description', label: 'Description', fieldType: 'TEXTFIELD', width: 450 },
  { id: 'itemNumber', label: 'Item Number', fieldType: 'TEXTFIELD', width: 150 },
  { id: 'quantity', label: 'Quantity', fieldType: 'TEXTFIELD', number: true, width: 150 },
  { id: 'unitPrice', label: 'Unit Price', fieldType: 'TEXTFIELD', number: true, width: 150 },
  { id: 'totalPrice', label: 'Total Price', isReadOnly: true, width: 150 },
];

export function BillingRequestItemsTable({
  hook,
  editingList,
}: {
  editingList: boolean;
  hook: DataGridReturn<BillingRequestItemData>;
}) {
  return (
    <DataGrid
      hook={hook}
      hasSelectors={editingList}
      isEditMode={editingList}
      readonlyRow={(row) => row.type !== 'ADJUSTMENT'}
    />
  );
}
