import { List, ListItemButton, ListItemText, Tooltip, Typography, styled } from '@mui/material';
import AppColors from 'AppColors';
import { DrawerItem } from './AppDrawer';
import DrawerMenuItem from './DrawerMenuItem';
import DrawerMenuItemWrapper from './DrawerMenuItemWrapper';

const DrawerListItemText = styled(ListItemText, {
  shouldForwardProp: (propName) => propName !== 'isCollapsed',
})<{ isCollapsed?: boolean }>(({ isCollapsed }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginLeft: !isCollapsed ? '55px' : '8px',
  opacity: !isCollapsed ? 1 : 0,
}));

type Props = {
  menuItem: DrawerItem;
  activeMenuItem: DrawerItem | undefined;
  isCollapsed?: boolean;
};

const DrawerMenuItemGroup = ({ menuItem, activeMenuItem, isCollapsed }: Props) => {
  return (
    <>
      <DrawerMenuItem key={menuItem.title} menuItem={menuItem} isCollapsed={isCollapsed} />
      {menuItem.children && (
        <List disablePadding>
          {menuItem.children.map((item) => (
            <DrawerMenuItemWrapper
              key={item.title}
              menuItem={item}
              isActive={activeMenuItem?.path === item.path}
            >
              <Tooltip title={item.title} placement="right">
                <ListItemButton
                  sx={{
                    '&:hover': {
                      color: AppColors.AAA_BLUE,
                    },
                  }}
                >
                  <DrawerListItemText
                    isCollapsed={isCollapsed}
                    primary={
                      <Typography
                        letterSpacing={0.5}
                        sx={{
                          fontSize: !isCollapsed ? 16 : 14,
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        {item.title}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </Tooltip>
            </DrawerMenuItemWrapper>
          ))}
        </List>
      )}
    </>
  );
};

export default DrawerMenuItemGroup;
