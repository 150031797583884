import { useCallback, useEffect, useState, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { ButtonGroup } from '@mui/material';
import { DynamicExpandablePanel, useExpandablePanelGroup } from 'components/Dynamic';
import { Context } from '../components/PricingSetupProvider';
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation';
import { TowSetupDto } from 'api/setup/manageTowSetupApi';
import { PricingRecordDto, DutyTypes } from 'api/setup/manageServicesSetupApi';
import { dutyOptions } from '../PricingSetupConfig';
import PricingTowMileageRatesEditor from './PricingTowMileageRatesEditor';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';

interface Props {
  toIndividualScreen: (uuid: string) => void;
  isCreationMode: boolean;
  isEditMode: boolean;
  lightTowsData?: TowSetupDto[];
  mediumTowsData?: TowSetupDto[];
  heavyTowsData?: TowSetupDto[];
  generalInfoHookForm: UseFormReturn<Partial<PricingRecordDto>>;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  setPricingServiceExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  hasT6Service: boolean;
}

const PricingTowMileageRates: React.FC<Props> = ({
  toIndividualScreen,
  isCreationMode,
  lightTowsData,
  mediumTowsData,
  heavyTowsData,
  generalInfoHookForm,
  isEditMode,
  setEditMode,
  expanded,
  setExpanded,
  setPricingServiceExpanded,
  hasT6Service,
}) => {
  const [nextTab, setNextTab] = useState<number | null>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { groupExpanded } = useExpandablePanelGroup();
  const { isTowMileageDirtyTabs, isEditingSection, setIsEditingSection, activeTab, setActiveTab } =
    useContext(Context);
  const { permissions } = useRapCoreFeaturePermissions('servicesSetup');

  useEffect(() => {
    setExpanded(groupExpanded);
  }, [groupExpanded]);

  const handleEdit = useCallback(() => {
    setEditMode(true);
    setIsEditingSection(true);
  }, [setIsEditingSection]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    event.stopPropagation();
    if (isTowMileageDirtyTabs?.[activeTab]) {
      setNextTab(newValue);
      setShowDialog(true);
      return;
    }
    setActiveTab(newValue);
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleConfirm = () => {
    if (nextTab !== null) {
      setActiveTab(nextTab);
      setNextTab(null);
    }
    setShowDialog(false);
  };

  const onSaveTowMileage = () => {
    setPricingServiceExpanded(true);
  };

  return (
    <>
      <DynamicExpandablePanel
        title="Tow Mileage Rates"
        icon={<DirectionsCarIcon />}
        onExpanded={() => setExpanded(!expanded)}
        expanded={expanded}
        actions={{
          isEditMode,
          onEdit: permissions.update ? handleEdit : undefined,
        }}
        leftPanel={
          <ButtonGroup size="small" disabled={!isEditMode && (isEditingSection || isCreationMode)}>
            {dutyOptions.map(({ label }, index) => (
              <Button
                key={label}
                variant={index === activeTab ? 'contained' : 'outlined'}
                onClick={(event) => handleChange(event, index)}
              >
                {label}
              </Button>
            ))}
          </ButtonGroup>
        }
        isEditingSection={isEditingSection || isCreationMode}
      >
        <Box sx={{ mx: -4 }}>
          {activeTab === 0 && (
            <PricingTowMileageRatesEditor
              dutyType={DutyTypes.LIGHT}
              data={lightTowsData}
              isEditMode={isEditMode}
              setEditMode={setEditMode}
              isCreationMode={isCreationMode}
              tabIndex={0}
              generalInfoHookForm={generalInfoHookForm}
              toIndividualScreen={toIndividualScreen}
              onSave={onSaveTowMileage}
              hasT6Service={hasT6Service}
            />
          )}
          {activeTab === 1 && (
            <PricingTowMileageRatesEditor
              dutyType={DutyTypes.MEDIUM}
              data={mediumTowsData}
              isEditMode={isEditMode}
              setEditMode={setEditMode}
              isCreationMode={isCreationMode}
              tabIndex={1}
              generalInfoHookForm={generalInfoHookForm}
              toIndividualScreen={toIndividualScreen}
              onSave={onSaveTowMileage}
              hasT6Service={hasT6Service}
            />
          )}
          {activeTab === 2 && (
            <PricingTowMileageRatesEditor
              dutyType={DutyTypes.HEAVY}
              data={heavyTowsData}
              isEditMode={isEditMode}
              setEditMode={setEditMode}
              isCreationMode={isCreationMode}
              tabIndex={2}
              generalInfoHookForm={generalInfoHookForm}
              toIndividualScreen={toIndividualScreen}
              onSave={onSaveTowMileage}
              hasT6Service={hasT6Service}
            />
          )}
        </Box>
      </DynamicExpandablePanel>
      <DialogConfirmation
        open={showDialog}
        title="You have unsaved changes"
        content="If you navigate to another pricing table, you will lose all the changes you have made."
        onClose={handleClose}
        onConfirm={handleConfirm}
        labelCancel="Go back"
        labelConfirm="Discard changes"
      />
    </>
  );
};

export default PricingTowMileageRates;
