import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, Paper, Typography } from '@mui/material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { ReactComponent as SearchEmpty } from 'assets/search-empty.svg';
import { LoaderCard } from 'components/Cards';
import AppColors from 'AppColors';
import DataTable from 'components/DataTable';
import { getAttachedCasesColumns } from '../helpers/attachedCasesConfig';
import { useGetBillRequestCasesByBRId } from '../BillingRequestQuery';
import { BillingRequestData } from '../types';
import { BillingRequestLevel } from 'api/invoices';
import AttachedServicesRow from './AttachedServicesRow';
import { CaseData } from 'features/Cases/interfaces/CaseData';

interface AttachedCasesTableProps {
  uuid: string;
  billingRequestData: BillingRequestData;
}

export const AttachedCasesTable = ({ uuid, billingRequestData }: AttachedCasesTableProps) => {
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { data, isLoading } = useGetBillRequestCasesByBRId(uuid, billingRequestData.billingLevel, {
    page: currentPage,
    size: rowsPerPage,
  });
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('');

  const createSortHandler = (_: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const paginationOptions = useMemo(() => {
    return {
      rowsCount: data?.totalElements ?? 25,
      currentPage,
      rowsPerPage,
      onPageChange: setCurrentPage,
      onRowsPerPageChange: setRowsPerPage,
      rowsPerPageOptions: [25, 50, 100],
    };
  }, [currentPage, data, rowsPerPage, setRowsPerPage]);

  const title = useMemo(() => {
    return billingRequestData.billingLevel === BillingRequestLevel.SERVICE_LEVEL
      ? 'Approved Services'
      : 'Cases';
  }, [billingRequestData]);

  return (
    <Box mt={8}>
      <Box component={Paper}>
        <Box display="flex" alignItems="center" p={2} gap={1}>
          <BusinessCenterIcon />
          <Typography variant="h6" sx={{ fontWeight: '700' }}>
            Attached {title}
          </Typography>
        </Box>
        <Divider sx={{ borderColor: AppColors.AAA_LIGHT_GRAY, mb: 2 }} />
        <Box pb={3}>
          {isLoading && <LoaderCard label={`Loading Attached ${title}...`} sx={{ p: 4 }} />}
          {!isLoading && data?.content?.length === 0 && <EmptyAttachedCasesCard />}

          {!isLoading && data?.content && data?.content?.length > 0 && (
            <DataTable<CaseData>
              onTextCellClick={(row, col) =>
                col.id == 'rapCaseId' ? navigate(`/cases/${row.uuid}`) : null
              }
              sortOptions={{ hasSort: true, order, orderBy, createSortHandler }}
              columns={getAttachedCasesColumns(billingRequestData)}
              rows={data?.content}
              paginationOptions={paginationOptions}
              {...(billingRequestData.billingLevel === BillingRequestLevel.SERVICE_LEVEL && {
                // eslint-disable-next-line react/display-name
                Row: (row, rowIndex) => () =>
                  <AttachedServicesRow row={row as CaseData} rowIndex={rowIndex} />,
              })}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

const EmptyAttachedCasesCard = () => (
  <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" my={8}>
    <SearchEmpty />
    <Typography variant="h5" sx={{ fontWeight: 500, pt: 3 }}>
      No attached cases found.
    </Typography>
  </Box>
);
