import { useState } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DataTable from 'components/DataTable/DataTable';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { DialogActions, useTheme } from '@mui/material';
import AppColors from 'AppColors';
import { ServiceProvidedDto } from 'api/setup/manageServicesSetupApi';

interface Props {
  title: string;
  open: boolean;
  handleClose: () => void;
  onApprove: (serviceCodes: string[]) => void;
  serviceItems?: ServiceProvidedDto[];
}

const ServiceItemsModal: React.FC<Props> = ({
  title,
  open,
  onApprove,
  handleClose,
  serviceItems,
}) => {
  const theme = useTheme();
  const [selectedServicesCodes, setSelectedServiceCodes] = useState<string[]>([]);

  const handleApprove = () => {
    onApprove?.(selectedServicesCodes);
    setSelectedServiceCodes([]);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      sx={{
        mt: theme.mixins.toolbar.minHeight,
        ml: theme.AppDrawer.width,
        overflowY: 'scroll',
      }}
    >
      <DialogTitle>
        <Box>
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="body2" sx={{ my: 3 }}>
            Select which additional services you would like to add. Note: Previously selected
            services will not appear in this list.
          </Typography>
        </Box>

        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: AppColors.AAA_BLUE,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent sx={{ px: 0 }}>
        <DataTable<Partial<ServiceProvidedDto>>
          rows={serviceItems || []}
          columns={[
            { id: 'code', label: 'Service Code' },
            { id: 'name', label: 'Service Name' },
          ]}
          stickyHeader
          stickyOffset={0}
          indexName="code"
          hasSelectors={true}
          onSelect={setSelectedServiceCodes}
        />
      </DialogContent>
      <DialogActions>
        <Stack
          borderTop="1px solid"
          borderColor={AppColors.AAA_LIGHT_NAVY}
          direction="row"
          justifyContent="end"
          spacing={1}
          width="100%"
          py={2}
        >
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="contained"
            color="success"
            startIcon={<CheckIcon />}
            onClick={handleApprove}
            disabled={selectedServicesCodes.length === 0}
          >
            Add Selected service items
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ServiceItemsModal;
