import { useEffect, useState } from 'react';
import { PricingGeneralInfo } from './PricingGeneralInfo';
import { EntityTypes, PricingRecordDto, ServiceSetupDto } from 'api/setup/manageServicesSetupApi';
import { PricingServiceRates } from './PricingServiceRates';
import PricingTowMileageRates from './PricingTowMileageRates';
import { TowSetupDto } from 'api/setup/manageTowSetupApi';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { generalInfoValidationSchema } from '../PricingSetupConfig';
import { useSearchParams } from 'react-router-dom';

interface Props {
  lightServicesData?: ServiceSetupDto[];
  mediumServicesData?: ServiceSetupDto[];
  heavyServicesData?: ServiceSetupDto[];
  lightTowsData?: TowSetupDto[];
  mediumTowsData?: TowSetupDto[];
  heavyTowsData?: TowSetupDto[];
  currentInformation?: PricingRecordDto;
  toIndividualScreen: (uuid: string, params?: Record<string, string>) => void;
  isCreationMode: boolean;
  entityType: EntityTypes;
}

const PricingDetailGroup: React.FC<Props> = ({
  lightServicesData,
  mediumServicesData,
  heavyServicesData,
  lightTowsData,
  mediumTowsData,
  heavyTowsData,
  toIndividualScreen,
  isCreationMode,
  currentInformation,
}) => {
  const [searchParams] = useSearchParams();
  const [isTowMileageEditMode, setTowMileageEditMode] = useState<boolean>(false);
  const [isTowMileageExpanded, setTowMileageExpanded] = useState<boolean>(false);
  const [isPricingServiceExpanded, setPricingServiceExpanded] = useState<boolean>(false);
  const [hasT6Service, setHasT6Service] = useState<boolean>(false);

  const generalInfoHookForm = useForm<Partial<PricingRecordDto>>({
    resolver: yupResolver(generalInfoValidationSchema),
  });

  useEffect(() => {
    // Check if the Pricing Service Rates has T6 service
    const find = lightServicesData?.find((item) => item.serviceProvided.code === 'T6');
    setHasT6Service(!!find);
  }, [lightServicesData]);

  useEffect(() => {
    const redirectTo = searchParams.get('redirectTo');
    if (redirectTo === 'TOW_MILEAGE_RATES') {
      setTowMileageExpanded(true);
      setTowMileageEditMode(true);
      setPricingServiceExpanded(false);
    }
  }, [searchParams, lightServicesData]);

  return (
    <>
      <PricingGeneralInfo
        data={currentInformation}
        isCreationMode={isCreationMode}
        hookForm={generalInfoHookForm}
        toIndividualScreen={toIndividualScreen}
      />

      <PricingServiceRates
        lightServicesData={lightServicesData}
        mediumServicesData={mediumServicesData}
        heavyServicesData={heavyServicesData}
        isCreationMode={isCreationMode}
        toIndividualScreen={toIndividualScreen}
        generalInfoHookForm={generalInfoHookForm}
        expanded={isPricingServiceExpanded}
        setExpanded={setPricingServiceExpanded}
        setTowMileageEditMode={setTowMileageEditMode}
        setTowMileageExpanded={setTowMileageExpanded}
        setHasT6Service={setHasT6Service}
      />

      <PricingTowMileageRates
        lightTowsData={lightTowsData}
        mediumTowsData={mediumTowsData}
        heavyTowsData={heavyTowsData}
        isCreationMode={isCreationMode}
        toIndividualScreen={toIndividualScreen}
        generalInfoHookForm={generalInfoHookForm}
        isEditMode={isTowMileageEditMode}
        setEditMode={setTowMileageEditMode}
        expanded={isTowMileageExpanded}
        setExpanded={setTowMileageExpanded}
        setPricingServiceExpanded={setPricingServiceExpanded}
        hasT6Service={hasT6Service}
      />
    </>
  );
};

export default PricingDetailGroup;
