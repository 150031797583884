import {
  ServiceProvidedDto,
  EntityTypes,
  PricingTypes,
  DutyTypes,
} from 'api/setup/manageServicesSetupApi';
import { ServiceSetupData, ServiceData } from '../PricingSetupQuery';
import { serviceCodeComparator } from 'utils/getComparator';
import { nanoid } from 'nanoid';
import { TooltipCategories } from 'components/Form/FormInputTooltip';

/**
 * Generates an object containing the data required for creating a new service setup.
 *
 * @param serviceItems - An array of ServiceProvidedDto objects to extract the service details from.
 * @param entityType - The type of entity the service is being provided for.
 * @param entityUuid - The UUID of the entity the service is being provided for.
 * @param duty - The type of duty (from DutyTypes) that the service configuration should adhere to.
 * @param serviceCodes - Optional list of the serviceCodes to be included in the service setup. If not provided, all services will be included.
 * @returns An object containing the create service setup data with default values and the provided entity and duty information.
 */

export const getCreateServiceSetupData = (
  serviceItems: ServiceProvidedDto[],
  entityType: EntityTypes,
  entityUuid: string,
  duty: DutyTypes,
  serviceCodes: string[] = [], // Default value is an empty array
): ServiceSetupData => {
  const services: ServiceData[] = serviceItems
    .filter(({ code }) => serviceCodes.length === 0 || serviceCodes.includes(code)) // Filter the services based on the serviceCodes
    .map(({ name, code, uuid }) => ({
      serviceName: name,
      serviceCode: code,
      serviceProvidedUuid: uuid,
      entityType: entityType,
      entityUuid: entityUuid,
      pricePerUnit: '0',
      priceCap: '',
      overridingPricePerUnit: '',
      freeUnits: '0',
      payable: entityType === EntityTypes.CLUB,
      billable: entityType !== EntityTypes.CLUB,
      pricingType: PricingTypes.AT_COST,
      uuid: `fakeUuid-${nanoid()}`,
      tooltip: code === 'T6' ? TooltipCategories.t6FieldOnPricingSetup : '',
    }))
    .sort((a, b) => serviceCodeComparator(a, b, 'serviceCode'));

  return {
    startDateTime: '',
    endDateTime: '',
    description: '',
    duty,
    services,
  };
};
