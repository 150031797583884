import { useMemo } from 'react';
import { CRUDPageableDetail } from 'components/CRUDPageable';
import { useParams, useNavigate } from 'react-router-dom';
import { useProgramContext } from './ProgramProvider';
import { CRUDComponent } from 'components/CRUDNavigator';
import ProgramDetails from './ProgramDetails';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';
import { useDeleteProgramByUUID } from './ProgramQuery';

const ProgramPageView: CRUDComponent = (props) => {
  const { clientUUID = '', uuid = '' } = useParams();
  const navigate = useNavigate();
  const context = useProgramContext();
  const { setNotifications } = context;
  const { mutateAsync } = useDeleteProgramByUUID();
  const { permissions } = useRapCoreFeaturePermissions('programs');

  const breadcrumbs = useMemo(() => {
    return [
      {
        url: '/setup/clients',
        label: 'Clients List',
      },
      {
        url: `/setup/clients/${clientUUID}`,
        label: 'Client Details',
      },
      {
        url: '/',
        label: 'Program Details',
      },
    ];
  }, [clientUUID]);

  const handleDeleteAction = async () => {
    try {
      await mutateAsync(uuid);
      navigate(`/setup/clients/${clientUUID}`, {
        state: {
          notifications: {
            type: 'success',
            name: 'Success',
            message: 'Program Successfully Deleted',
          },
        },
      });
    } catch (error: any) {
      setNotifications({
        type: 'error',
        name: 'Error',
        message: error.response.data.message,
      });
    }
  };

  return (
    <CRUDPageableDetail
      breadcrumbs={breadcrumbs}
      context={context}
      uuid={uuid}
      componentProps={props}
      details={({ data, isRunning }) => <ProgramDetails isLoading={isRunning} data={data} />}
      handleLabel={(data) => data.name || ''}
      visible={permissions.delete}
      subMenuActions={(data) => [
        {
          label: 'Delete Program',
          onClick: handleDeleteAction,
          title: `Delete ${data.name}?`,
          content: `Are you sure you want to delete ${data.name}?`,
          labelConfirm: 'Delete',
          labelCancel: 'Cancel',
        },
      ]}
    />
  );
};

export default ProgramPageView;
