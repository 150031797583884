export enum TransactionStatus {
  PRE_AUTHORIZED = 'PRE_AUTHORIZED',
  SETTLED = 'SETTLED',
  SETTLEMENT_FAILED = 'SETTLEMENT_FAILED',
  CREATED = 'CREATED',
  CAPTURE_PENDING = 'CAPTURE_PENDING',
  CAPTURE_FAILED = 'CAPTURE_FAILED',
  CAPTURE_SUCCESSFUL = 'CAPTURE_SUCCESSFUL',
  REFUND_PENDING = 'REFUND_PENDING',
  REFUND_FAILED = 'REFUND_FAILED',
  REFUND_SUCCESSFUL = 'REFUND_SUCCESSFUL',
  CANCELED = 'CANCELED',
  CANCEL_PRE_AUTH_PENDING = 'CANCEL_PRE_AUTH_PENDING',
  CANCEL_PRE_AUTH_FAILED = 'CANCEL_PRE_AUTH_FAILED',
  CANCEL_PRE_AUTH_SUCCESSFUL = 'CANCEL_PRE_AUTH_SUCCESSFUL',
  UNKNOWN = 'UNKNOWN',
}
