import { ENV_BACKOFFICE_BASE_API_URL } from 'lib/envConfig';
import { BaseAPI } from './authenticatedRequest';

export type UserPermissionsDto = TablePermission[];

interface TablePermission {
  name: string;
  attributes: Attribute[];
}

interface Attribute {
  [attrname: string]: string;
}

export function fetchUserPermissions() {
  return BaseAPI.get<UserPermissionsDto>(`${ENV_BACKOFFICE_BASE_API_URL}/v1/users/permissions`);
}
