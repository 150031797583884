import BreadcrumbHeader, { Breadcrumb } from 'components/BreadCrumbHeader';
import InvoiceSummary from './components/BillingRequestSummary';
import { routes } from 'routes';
import { useBillingRequestSummary } from './BillingRequestProvider';
import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { BillingRequestSearch, BillingRequestSearchInitialState } from './types';

interface Props {
  searchForm: UseFormReturn<BillingRequestSearch>;
  refetch: () => void;
}

export default function BillingRequestSummaryCardsHeader({ searchForm, refetch }: Props) {
  const breadcrumbs: Breadcrumb[] = useMemo(
    () => [{ label: 'Dashboard', url: routes.dashboard }, { label: 'Bill Requests List' }],
    [],
  );

  const { data, isFetched } = useBillingRequestSummary();

  const handleStatusClick = (status: string) => {
    if (status === 'Total') {
      searchForm.setValue('status', BillingRequestSearchInitialState.status);
    } else {
      searchForm.setValue('status', status);
    }
    searchForm.handleSubmit(() => null)();

    refetch();
  };

  const currentFilteredStatuses = searchForm.getValues('status');
  const isDefaultFiltered = BillingRequestSearchInitialState.status === currentFilteredStatuses;

  return (
    <BreadcrumbHeader breadcrumbs={breadcrumbs}>
      <InvoiceSummary
        data={data}
        isLoading={!isFetched}
        currentFilteredStatuses={currentFilteredStatuses}
        isDefaultFiltered={isDefaultFiltered}
        onStatusClick={handleStatusClick}
      />
    </BreadcrumbHeader>
  );
}
