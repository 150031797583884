import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { FullCenteredCard, FullCenteredCardProps } from 'components/Cards';

type CardLoaderProps = FullCenteredCardProps & {
  label?: string;
};

export function LoaderCard({ label, ...props }: CardLoaderProps) {
  return (
    <FullCenteredCard {...props}>
      <CircularProgress size={60} />
      <Box sx={{ p: 2 }} />
      <Typography>{label || 'Loading...'}</Typography>
    </FullCenteredCard>
  );
}
