import { CRUDPageableLayout } from 'components/CRUDPageable';
import React, { useCallback, useEffect, useMemo } from 'react';
import StepperActionBar from './components/StepperActionBar';
import StepperHeader from './components/StepperHeader';
import { StepConfig } from './types';

export interface FormStepperProps {
  steps: StepConfig[];
  onComplete?: () => void;
  onCancel?: () => void;
}

const FormStepper = ({ steps, onComplete, onCancel }: FormStepperProps) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [submitted, setSubmitted] = React.useState(false);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const StepComponent = useMemo(() => steps[activeStep]?.component, [activeStep, steps]);
  const allStepsCompleted = useCallback(
    () => Object.keys(completed).length === steps.length,
    [completed, steps],
  );

  useEffect(() => {
    if (allStepsCompleted()) {
      onComplete?.();
    }
  }, [activeStep, completed, allStepsCompleted, onComplete]);

  const handleNext = useCallback(() => {
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
  }, [activeStep]);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, [setActiveStep]);

  const handleCancel = useCallback(() => {
    setActiveStep(0);
    setCompleted({});
    onCancel?.();
  }, [onCancel]);

  const handleSubmit = useCallback(() => {
    if (!completed[activeStep]) {
      setSubmitted(true);
    } else {
      handleNext();
    }
  }, [completed, activeStep, setSubmitted, handleNext]);

  const resetSubmitted = useCallback(() => setSubmitted(false), [setSubmitted]);

  const setActiveStepCompleted = useCallback(() => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    resetSubmitted();
    handleNext();
  }, [completed, activeStep, setCompleted, handleNext, resetSubmitted]);

  return (
    <CRUDPageableLayout stackSpacing={0}>
      <StepperHeader steps={steps} activeStep={activeStep} completed={completed} />
      {steps[activeStep] && (
        <StepComponent
          stepKey={steps[activeStep].key}
          submitted={submitted}
          completed={completed[activeStep]}
          resetSubmitted={resetSubmitted}
          setCompleted={setActiveStepCompleted}
        />
      )}
      <StepperActionBar
        steps={steps}
        activeStep={activeStep}
        handleBack={handleBack}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
      />
    </CRUDPageableLayout>
  );
};

export default FormStepper;
