import Box from '@mui/material/Box';
import DataTable, { DataTableColumn } from 'components/DataTable';
import FormDivider from 'components/Form/FormDivider';
import { convertUTCToDateTimeString } from 'utils/convertDate';
import { useCaseFinancialsConfig } from './config';
import { BillingRequestStatusHistoryViewDto } from 'api/invoices';
import { useMemo } from 'react';

type Props = {
  billingStatus: BillingRequestStatusHistoryViewDto[];
  billingRequestNumber: number;
};

const columns: DataTableColumn<BillingRequestStatusHistoryViewDto & { number: number }>[] = [
  {
    id: 'status',
    label: 'Billing Status Description',
    testId: 'billing-status-description',
    accessor: (row) =>
      // eslint-disable-next-line no-nested-ternary
      row.status
        ? row.description
          ? `${row.status} - ${row.description}`
          : row.status
        : row.description
        ? row.description
        : '-',
  },
  {
    id: 'number',
    label: 'Bill Request Number',
    testId: 'billing-request-number',
  },
  {
    id: 'createdAtDateTime',
    label: 'Date & Time',
    accessor: (row) => convertUTCToDateTimeString(row.createdAtDateTime),
    testId: 'billing-date-time',
  },
  {
    id: 'createdByUserId',
    label: 'Created By',
    testId: 'billing-created-by',
  },
];

export default function BillingStatus({ billingStatus, billingRequestNumber }: Props) {
  const { caseFinancialViewTable } = useCaseFinancialsConfig();
  const canReadBillingStatus = caseFinancialViewTable?.fields?.['billingStatus']?.read;

  const billingData = useMemo(
    () =>
      billingStatus.map((item) => ({
        ...item,
        number: billingRequestNumber,
      })),
    [billingRequestNumber, billingStatus],
  );

  return (
    <>
      {canReadBillingStatus && (
        <>
          <FormDivider label="Processing Status" sx={{ mb: 2 }} />
          <Box mx={-4} mb={2}>
            <DataTable columns={columns} rows={billingData} />
          </Box>
        </>
      )}
    </>
  );
}
