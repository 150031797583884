import { useForm, useWatch } from 'react-hook-form';
import { useGetCaseGeneralInfoByUUID } from '../../GeneralInfo/GeneralInfoQuery';
import { DynamicField, DynamicValue } from 'components/Dynamic';
import { useEffect } from 'react';
import { GENERAL_INFO_PERMISSION } from '../../GeneralInfo/config';
import { useFieldPermission } from 'auth';

type Props = {
  caseUUID: string;
  isEditMode: boolean;
};

export function useMileagesForm({ caseUUID, isEditMode }: Props) {
  const { data: caseGeneralInfoData } = useGetCaseGeneralInfoByUUID(caseUUID, true);

  const mileagesHookForm = useForm({
    defaultValues: {
      mileageOverride: caseGeneralInfoData?.mileageOverride,
      totalMileage: caseGeneralInfoData?.totalMileage,
    },
  });

  useEffect(() => {
    if (caseGeneralInfoData) {
      mileagesHookForm.reset({
        mileageOverride: caseGeneralInfoData.mileageOverride,
        totalMileage: caseGeneralInfoData.totalMileage,
      });
    }
  }, [caseGeneralInfoData, mileagesHookForm]);

  const watchedMileage = useWatch({
    control: mileagesHookForm.control,
  });

  useEffect(() => {
    if (watchedMileage.mileageOverride !== caseGeneralInfoData?.mileageOverride) {
      mileagesHookForm.setValue('mileageOverride', watchedMileage.mileageOverride);
    }
  }, [watchedMileage]);

  const mileagesFields = useFieldPermission({
    fields: [
      {
        name: 'mileageOverride',
        label: 'Override/Overage Mileage',
        colspan: 3,
      },
      {
        label: 'Total Mileage',
        name: 'totalMileage',
        colspan: 3,
      },
    ],
    table: GENERAL_INFO_PERMISSION,
    isEditMode,
  });

  return {
    mileagesHookForm,
    mileagesFields,
  };
}
