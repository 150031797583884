import { useState, useCallback } from 'react';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation';

/**
 * Enumeration representing the types of confirmation dialogs.
 */
enum ConfirmationDialogType {
  DELETE,
}

/**
 * Interface defining the Props for the `DynamicFormDeleteAction` component.
 */
export interface Props {
  /** Optional prop to disable the delete action button. */
  disabled?: boolean;
  /** Optional prop to provide a custom label for the delete action button. */
  label?: string;
  /** Optional prop to provide a custom title for the confirmation dialog. */
  title?: string;
  /** Optional prop to provide custom content for the confirmation dialog. */
  content?: string;
  /** Optional prop to provide a callback function for when the delete action is confirmed. */
  onSubmit?: () => void;
}

/**
 * A reusable component that provides a delete action button and a confirmation dialog.
 */
const DynamicFormDeleteAction: React.FC<Props> = ({
  disabled,
  label,
  title,
  content,
  onSubmit,
}) => {
  const [confirm, setConfirm] = useState<ConfirmationDialogType | null>(null);

  const handleStartDelete = useCallback(() => {
    setConfirm(ConfirmationDialogType.DELETE);
  }, []);

  const handleDelete = useCallback(() => {
    setConfirm(null);
    onSubmit?.();
  }, [onSubmit]);

  const handleCloseDialog = useCallback(() => {
    setConfirm(null);
  }, []);

  return (
    <>
      <Button
        variant="outlined"
        size="large"
        startIcon={<DeleteIcon />}
        onClick={handleStartDelete}
        disabled={disabled}
      >
        {label || 'Delete Entry'}
      </Button>
      <DialogConfirmation
        open={confirm === ConfirmationDialogType.DELETE}
        title={title || 'Delete selected Entries?'}
        content={content || 'Are you sure you want to delete selected entries?'}
        onClose={handleCloseDialog}
        onConfirm={handleDelete}
        labelConfirm={'Save'}
      />
    </>
  );
};

export default DynamicFormDeleteAction;
