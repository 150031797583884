import { DynamicForm } from 'components/Dynamic';
import FormDivider from 'components/Form/FormDivider';
import { UseFormReturn } from 'react-hook-form';
import { contactInfoFields, generalInfoFields } from './fields';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hookForm: UseFormReturn<any>;
  isEditMode?: boolean;
  isCreationMode?: boolean;
}

export const VendorForm = ({ hookForm, isEditMode }: Props) => {
  return (
    <>
      <FormDivider label="Information" sx={{ mb: 3 }} />
      <DynamicForm hookForm={hookForm} isEditMode={isEditMode} fields={generalInfoFields} />
      <FormDivider label="Vendor Contact Information" sx={{ mt: 2, mb: 3 }} />
      <DynamicForm hookForm={hookForm} isEditMode={isEditMode} fields={contactInfoFields} />
    </>
  );
};
