import {
  Typography,
  Box,
  BoxProps,
  Container,
  ContainerProps,
  TypographyProps,
} from '@mui/material';
import styled from '@mui/system/styled';

interface BannerBoxProps extends BoxProps {
  imageUrl: string;
}

export const MainContainer = styled(Container)<ContainerProps>(() => ({
  margin: 0,
  padding: 0,
  width: 1440,
  height: '100%',
  display: 'flex',
}));

export const HeaderTypography = styled(Typography)<TypographyProps>(() => ({
  marginBottom: '1rem',
  fontWeight: 600,
  fontSize: '2.125rem',
  lineHeight: '2.25rem',
  letterSpacing: '0.15px',
  color: '#003366',
}));

export const BodyTypography = styled(Typography)<TypographyProps>(() => ({
  width: 397,
  marginBottom: '1.5rem',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  letterSpacing: '0.5px',
  color: '#003366',
}));

export const BannerBox = styled(Box)<BannerBoxProps>(({ imageUrl }) => ({
  width: 670,
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: 'cover',
  height: '100%',
  padding: '4.5rem 0 0 4.5rem',
}));
