import Typography from '@mui/material/Typography';
import { FullCenteredCard, FullCenteredCardProps } from 'components/Cards';
import ErrorIcon from '@mui/icons-material/Error';

export function ErrorSearchCard({ ...props }: FullCenteredCardProps) {
  return (
    <FullCenteredCard {...props}>
      <ErrorIcon sx={{ fontSize: '60px' }} />
      <Typography variant="h5" sx={{ fontWeight: 500, pt: 3 }}>
        An error has occurred
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: 500, pt: 2 }}>
        Please try again later
      </Typography>
    </FullCenteredCard>
  );
}
