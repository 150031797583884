import RecordDetailGroup from 'components/RecordDetailGroup';
import { EntityTypes, PricingRecordDto, ServiceSetupDto } from 'api/setup/manageServicesSetupApi';
import PricingDetailGroup from './PricingDetailGroup';
import { TowSetupDto } from 'api/setup/manageTowSetupApi';

interface Props {
  lightServicesData?: ServiceSetupDto[];
  mediumServicesData?: ServiceSetupDto[];
  heavyServicesData?: ServiceSetupDto[];
  lightTowsData?: TowSetupDto[];
  mediumTowsData?: TowSetupDto[];
  heavyTowsData?: TowSetupDto[];
  currentInformation?: PricingRecordDto;
  toIndividualScreen: (uuid: string, params?: Record<string, string>) => void;
  isCreationMode: boolean;
  entityType: EntityTypes;
}

const PricingDetail: React.FC<Props> = ({
  lightServicesData,
  mediumServicesData,
  heavyServicesData,
  lightTowsData,
  mediumTowsData,
  heavyTowsData,
  toIndividualScreen,
  isCreationMode,
  currentInformation,
  entityType,
}) => {
  return (
    <RecordDetailGroup
      loadingLabel="Loading Pricing Information"
      isLoading={false}
      showExpandAll={!isCreationMode}
      defaultExpanded={true}
    >
      <PricingDetailGroup
        lightServicesData={lightServicesData}
        mediumServicesData={mediumServicesData}
        heavyServicesData={heavyServicesData}
        lightTowsData={lightTowsData}
        mediumTowsData={mediumTowsData}
        heavyTowsData={heavyTowsData}
        currentInformation={currentInformation}
        toIndividualScreen={toIndividualScreen}
        isCreationMode={isCreationMode}
        entityType={entityType}
      />
    </RecordDetailGroup>
  );
};

export default PricingDetail;
