import { VendorTypeOptions } from 'api/setup/interfaces/VendorDto';
import { DynamicField, DynamicFieldType } from 'components/Dynamic';
import { PaymentRequestInformationData } from '../VendorQuery';
import * as yup from 'yup';
import { VendorData } from '../types';

export const generalInfoFields: DynamicField<VendorData>[] = [
  {
    label: 'Vendor Type',
    name: 'type',
    type: DynamicFieldType.SELECT,
    select: {
      options: VendorTypeOptions.map((item) => ({
        label: item,
        value: item,
      })),
    },
    required: true,
  },
  {
    label: 'Vendor Code',
    name: 'rapVendorId',
    isReadOnly: true,
  },
  {
    label: 'Vendor Name',
    name: 'name',
    required: true,
  },
  {
    label: 'Phone Number for Call Centers',
    name: 'callCenterPhoneNumber',
  },
  {
    label: 'Payment Reports Recipient Emails',
    name: 'paymentReportRecipientEmails',
    type: DynamicFieldType.CHIP_INPUT,
    colspan: 12,
    chipInput: {
      validation: {
        regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: 'Invalid email',
      },
    },
  },
];

export const contactInfoFields: DynamicField<VendorData>[] = [
  {
    label: 'Primary Vendor Contact First Name',
    name: 'primaryVendorContactFirstName',
  },
  {
    label: 'Primary Vendor Contact Last Name',
    name: 'primaryVendorContactLastName',
  },
  {
    label: 'Primary Contact Phone Number',
    name: 'primaryVendorContactPhoneNumber',
  },
  {
    label: 'Primary Email',
    name: 'primaryEmailAddress',
  },
  {
    label: 'Secondary Vendor Contact First Name',
    name: 'secondaryVendorContactFirstName',
  },
  {
    label: 'Secondary Vendor Contact Last Name',
    name: 'secondaryVendorContactLastName',
  },
  {
    label: 'Secondary Contact Phone Number',
    name: 'secondaryVendorContactPhoneNumber',
  },
  {
    label: 'Secondary Email',
    name: 'secondaryEmailAddress',
  },
];

export const paymentInfoFields: DynamicField<PaymentRequestInformationData>[] = [
  { label: 'Calls Account Number', name: 'callsAccountNumber' },
  { label: 'Revenue Share Account Number', name: 'revenueShareAccountNumber' },
  { label: 'JDEdwards Account Routing Number', name: 'jdeAccountRoutingNumber' },
];

export const generalInfoSchema = yup.object({
  type: yup.string().required().label('Vendor Type'),
  name: yup.string().required().label('Vendor Name'),
});
