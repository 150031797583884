import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import { FullCenteredCard, FullCenteredCardProps } from 'components/Cards';

type ErrorCardProps = FullCenteredCardProps & {
  label?: string;
};

const ErrorCard = ({ label, ...props }: ErrorCardProps) => (
  <FullCenteredCard {...props}>
    <ErrorIcon sx={{ fontSize: '60px' }} />
    <Box sx={{ p: 2 }} />

    <Typography>{label || 'An error occurred'}</Typography>
  </FullCenteredCard>
);

export default ErrorCard;
