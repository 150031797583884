import ClientDetailGroup from './ClientDetailGroup';
import RecordDetailGroup from 'components/RecordDetailGroup';
import { ClientData } from '../ClientProvider';

interface Props {
  data?: ClientData;
  isLoading?: boolean;
  toIndividualScreen?: (uuid: string) => void;
  isCreationMode?: boolean;
}

const ClientDetail = ({ isLoading, data, toIndividualScreen, isCreationMode }: Props) => {
  return (
    <RecordDetailGroup
      loadingLabel="Loading Client Information"
      isLoading={isLoading}
      showExpandAll={!isCreationMode}
      defaultExpanded={isCreationMode}
    >
      <ClientDetailGroup data={data} toIndividualScreen={toIndividualScreen} />
    </RecordDetailGroup>
  );
};

export default ClientDetail;
