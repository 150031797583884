import { BaseAPI } from 'api/auth';
import { CreatePageableCRUDApi } from 'api/helper/PageableCRUDApi';
import { ENV_BACKOFFICE_BASE_API_URL } from 'lib/envConfig';
import { FetchSavedSearchesResponse, SavedSearch } from './interfaces/SavedSearch';

export function fetchSavedSearches() {
  return BaseAPI.get<FetchSavedSearchesResponse>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/custom-filters`,
  );
}

export function deleteSavedSearch(customFilterId: string) {
  return BaseAPI.delete(`${ENV_BACKOFFICE_BASE_API_URL}/v1/custom-filters/${customFilterId}`);
}

export function getSavedSearchById(customFilterId: string) {
  return BaseAPI.get<SavedSearch>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/custom-filters/${customFilterId}`,
  );
}

export function editSearchQuery({
  name,
  filter,
  customFilterId,
  createdByUserId,
  createdDate,
  defaultFilter,
}: SavedSearch) {
  return BaseAPI.put<SavedSearch>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/custom-filters/${customFilterId}`,
    {
      name,
      filter,
      customFilterId,
      createdByUserId,
      createdDate,
      defaultFilter,
    },
  );
}

export const saveSearchApi = CreatePageableCRUDApi<SavedSearch>(
  `${ENV_BACKOFFICE_BASE_API_URL}/v1/custom-filters`,
);
