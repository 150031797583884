import { CRUDNavigators } from 'components/CRUDNavigator';
import FormStepper from 'components/FormStepper';
import { StepConfig } from 'components/FormStepper/types';
import { useCallback } from 'react';
import { useEntityCreationFlowContext } from './context/EntityCreationFlowContext';

export interface EntityCreationFlowProps {
  steps: StepConfig[];
  navigators: CRUDNavigators;
}

const EntityCreationFlow = ({ steps, navigators }: EntityCreationFlowProps) => {
  const { data } = useEntityCreationFlowContext();

  const onComplete = useCallback(() => {
    const uuid = data?.uuid as string;
    if (uuid) {
      navigators?.toIndividualScreen(uuid);
    } else {
      navigators?.toMainScreen();
    }
  }, [data, navigators]);

  const onCancel = useCallback(() => {
    navigators?.toMainScreen();
  }, [navigators]);

  return <FormStepper steps={steps} onComplete={onComplete} onCancel={onCancel} />;
};

export default EntityCreationFlow;
