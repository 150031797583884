import { Link, List, ListItem, Theme } from '@mui/material';
import { ReactElement } from 'react';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import AppColors from 'AppColors';

export enum TooltipCategories {
  costHoldingAcc = 'costHoldingAcc',
  billingRequestItemNumber = 'billingRequestItemNumber',
  revenueShareAccountNumber = 'revenueShareAccountNumber',
  costCenter = 'costCenter',
  manualAdjustmentRevenueExpense = 'manualAdjustmentRevenueExpense',
  manualAdjustmentContractorExpenses = 'manualAdjustmentContractorExpenses',
  prepaidEvents = 'prepaidEvents',
  JDEdwardsAccRoutingNumber = 'JDEdwardsAccRoutingNumber',
  poNumber = 'poNumber',
  t6FieldOnPricingSetup = 't6FieldOnPricingSetup',
  errorsSeparatedByCommas = 'errorsSeparatedByCommas',
  clubRate = 'clubRate',
}

interface CategoryDetail {
  tooltipTitle: string | ReactElement;
  chipLabel: string;
  backgroundColor: string;
  placement?: TooltipProps['placement'];
  infoIcon?: boolean;
}

type CategoryDetails = {
  [key in TooltipCategories]: CategoryDetail;
};

type TooltipContentProps = {
  name?: string;
  oldName?: string;
  target?: string;
  isTargetLink?: boolean;
  source?: string;
  isSourceLink?: boolean;
  description?: string;
  simpleText?: string;
};

type TooltipDataType = {
  [key in TooltipCategories]: TooltipContentProps;
};

const TooltipTitleData: TooltipDataType = {
  [TooltipCategories.costHoldingAcc]: {
    name: 'Cost Holding Account',
    target:
      'All Allocation Files Broken out into. Column Cost Center | Object account | Subsidiary',
    source:
      'https://aaanational.sharepoint.com/:x:/t/RBP/EfMrPJfLkY9BnpvhY-8qwH8BcpauNRVLiM56Qu1YGulrrg?e=enRH1D FIRST TAB OF DOC',
    isSourceLink: true,
    description: 'Cost Holding Account for this program',
  },
  [TooltipCategories.billingRequestItemNumber]: {
    name: 'Client Billing Requests',
    oldName: 'Client Billing Requests',
    description: 'Client Billing Requests',
    source:
      'https://aaanational.sharepoint.com/:x:/r/teams/RBP/_layouts/15/Doc.aspx?sourcedoc=%7BC3233667-015F-4DE2-8A82-3CC0722B70EF%7D&file=Program%20rev%20share%20accounting%20codes%20MASTER%20(February%202023).xlsx&action=default&mobileredirect=true&DefaultItemOpen=1',
    isSourceLink: true,
    target: 'Bill Request Line Item Code',
  },
  [TooltipCategories.revenueShareAccountNumber]: {
    name: 'Revenue Share Account Number',
    oldName: 'Revenue Share Account',
    description: 'Account Number',
    source:
      'https://aaanational.sharepoint.com/:x:/r/teams/RBP/_layouts/15/Doc.aspx?sourcedoc=%7BC3233667-015F-4DE2-8A82-3CC0722B70EF%7D&file=Program%20rev%20share%20accounting%20codes%20MASTER%20(February%202023).xlsx&action=default&mobileredirect=true&DefaultItemOpen=1',
    isSourceLink: true,
    target: 'Used in AP Allocation transmittals',
  },
  [TooltipCategories.costCenter]: {
    name: 'Cost Center Account Number',
    oldName: 'Cost Center',
    source:
      'https://aaanational.sharepoint.com/:x:/r/teams/RBP/_layouts/15/Doc.aspx?sourcedoc=%7BC3233667-015F-4DE2-8A82-3CC0722B70EF%7D&file=Program%20rev%20share%20accounting%20codes%20MASTER%20(February%202023).xlsx&action=default&mobileredirect=true&DefaultItemOpen=1',
    isSourceLink: true,
    target: 'Used in AP Allocation transmittals',
  },
  [TooltipCategories.manualAdjustmentRevenueExpense]: {
    name: 'Manual ADJ: Revenue Expense',
    oldName: 'Manual Adjustment: Revenue Expense',
  },
  [TooltipCategories.manualAdjustmentContractorExpenses]: {
    name: 'Manual ADJ: Contractor Expense',
    oldName: 'Manual Adjustment: Contractor Expense',
  },
  [TooltipCategories.prepaidEvents]: {
    name: 'prepaidEvents',
    oldName: 'Pre-Paid Events Item Number',
  },
  [TooltipCategories.JDEdwardsAccRoutingNumber]: {
    name: 'JDEdwards Account Routing Number',
    oldName: 'JDEdwards Account Routing Number',
    description:
      'This value is related to an account / address number in JDE and used to identify Clubs on payment requests',
  },
  [TooltipCategories.poNumber]: {
    name: 'PO Number',
    description:
      'PO Number is defined in Client Information and is assigned to the Bill Request Document sent to AAA Accounting',
    source: 'RAP CORE | Client | Information',
    target: 'Bill Request Detail | PO Number',
  },
  [TooltipCategories.t6FieldOnPricingSetup]: {
    name: '',
    simpleText: 'After configuring T6 - Tow service, you also need to configure Tow Mileage Rates.',
  },
  [TooltipCategories.clubRate]: {
    name: '',
    simpleText: 'This will override the set club rate. Leave empty to use set club rates',
  },
  [TooltipCategories.errorsSeparatedByCommas]: {
    name: '',
    simpleText: 'Separate multiple values with a comma',
  },
};

export const TooltipContentComponent = (props: TooltipContentProps) => (
  <>
    {props.name && <h3 style={{ marginBottom: 0 }}>{props.name}</h3>}
    {props.oldName && (
      <small style={{ fontSize: '10px' }}>
        formerly <i>{props.oldName}</i>
      </small>
    )}
    <List>
      {props.target && (
        <ListItem disablePadding>
          <p style={{ margin: 0 }}>
            {props.isTargetLink ? (
              <Link href={props.target}>Link</Link>
            ) : (
              <>
                <b style={{ marginRight: '5px' }}>Used in:</b> {props.target}
              </>
            )}
          </p>
        </ListItem>
      )}
      {props.source && (
        <ListItem disablePadding>
          <p style={{ margin: 0 }}>
            <b style={{ marginRight: '5px' }}>Source:</b>
            {props.isSourceLink ? <Link href={props.source}>Link</Link> : <>{props.source}</>}
          </p>
        </ListItem>
      )}
      {props.description && (
        <ListItem disablePadding>
          <p style={{ margin: 0 }}>
            <b style={{ marginRight: '5px' }}>Description:</b> {props.description}
          </p>
        </ListItem>
      )}
      {props.simpleText && (
        <ListItem disablePadding>
          <p style={{ margin: 0 }}>{props.simpleText}</p>
        </ListItem>
      )}
    </List>
  </>
);

export const getCategoryDetails = (theme: Theme): CategoryDetails => ({
  [TooltipCategories.costHoldingAcc]: {
    chipLabel: 'AR',
    tooltipTitle: (
      <TooltipContentComponent {...TooltipTitleData[TooltipCategories.costHoldingAcc]} />
    ),
    backgroundColor: theme.palette.text.primary,
    placement: 'bottom-end',
  },
  [TooltipCategories.billingRequestItemNumber]: {
    chipLabel: 'AR',
    tooltipTitle: (
      <TooltipContentComponent {...TooltipTitleData[TooltipCategories.billingRequestItemNumber]} />
    ),
    backgroundColor: theme.palette.text.primary,
  },
  [TooltipCategories.revenueShareAccountNumber]: {
    chipLabel: 'AR',
    tooltipTitle: (
      <TooltipContentComponent {...TooltipTitleData[TooltipCategories.revenueShareAccountNumber]} />
    ),
    backgroundColor: theme.palette.text.primary,
  },
  [TooltipCategories.costCenter]: {
    chipLabel: 'AP',
    tooltipTitle: <TooltipContentComponent {...TooltipTitleData[TooltipCategories.costCenter]} />,
    backgroundColor: AppColors.AAA_ELECTRIC_VIOLET,
  },
  [TooltipCategories.manualAdjustmentRevenueExpense]: {
    chipLabel: 'AR',
    tooltipTitle: (
      <TooltipContentComponent
        {...TooltipTitleData[TooltipCategories.manualAdjustmentRevenueExpense]}
      />
    ),
    backgroundColor: theme.palette.text.primary,
  },
  [TooltipCategories.manualAdjustmentContractorExpenses]: {
    chipLabel: 'AR',
    tooltipTitle: (
      <TooltipContentComponent
        {...TooltipTitleData[TooltipCategories.manualAdjustmentContractorExpenses]}
      />
    ),
    backgroundColor: theme.palette.text.primary,
  },
  [TooltipCategories.prepaidEvents]: {
    chipLabel: 'AR',
    tooltipTitle: (
      <TooltipContentComponent {...TooltipTitleData[TooltipCategories.prepaidEvents]} />
    ),
    backgroundColor: theme.palette.text.primary,
  },
  [TooltipCategories.JDEdwardsAccRoutingNumber]: {
    chipLabel: 'AP',
    tooltipTitle: (
      <TooltipContentComponent {...TooltipTitleData[TooltipCategories.JDEdwardsAccRoutingNumber]} />
    ),
    backgroundColor: AppColors.AAA_ELECTRIC_VIOLET,
  },
  [TooltipCategories.poNumber]: {
    chipLabel: 'AR',
    tooltipTitle: <TooltipContentComponent {...TooltipTitleData[TooltipCategories.poNumber]} />,
    backgroundColor: theme.palette.text.primary,
  },
  [TooltipCategories.t6FieldOnPricingSetup]: {
    chipLabel: 'T6',
    tooltipTitle: (
      <TooltipContentComponent {...TooltipTitleData[TooltipCategories.t6FieldOnPricingSetup]} />
    ),
    backgroundColor: theme.palette.primary.main,
    infoIcon: true,
  },
  [TooltipCategories.clubRate]: {
    chipLabel: 'T6',
    tooltipTitle: <TooltipContentComponent {...TooltipTitleData[TooltipCategories.clubRate]} />,
    backgroundColor: theme.palette.primary.main,
    infoIcon: true,
  },
  [TooltipCategories.errorsSeparatedByCommas]: {
    chipLabel: 'i',
    tooltipTitle: (
      <TooltipContentComponent {...TooltipTitleData[TooltipCategories.errorsSeparatedByCommas]} />
    ),
    backgroundColor: theme.palette.primary.main,
    infoIcon: true,
  },
});
