import { List, Box, Divider, Typography, styled } from '@mui/material';
import { DrawerItem, DrawerSection } from './AppDrawer';
import DrawerMenuItem from './DrawerMenuItem';
import AppColors from 'AppColors';
import DrawerMenuItemGroup from './DrawerMenuItemGroup';

interface DrawerMenuSectionProps {
  section: DrawerSection;
  open?: boolean;
  activeMenuItem?: DrawerItem;
}

const LabelWrapper = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'open',
})<{ open?: boolean }>`
  height: 30px;
  display: ${({ open }) => (open ? 'block' : 'none')};
`;

const IconWrapper = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'open',
})<{ open?: boolean }>`
  display: ${({ open }) => (open ? 'none' : 'block')};
`;

const DrawerMenuSection = ({ section, open, activeMenuItem }: DrawerMenuSectionProps) => {
  return (
    <Box key={section.label} data-testid={`AppDrawerSection-${section.label}`}>
      <Box sx={{ px: 3, pt: 1 }}>
        <LabelWrapper open={open}>
          <Typography
            sx={{
              fontSize: '13px',
              color: AppColors.AAA_LIGHT_GRAY,
            }}
          >
            {section.label}
          </Typography>
        </LabelWrapper>
        <IconWrapper open={open}>{section.icon}</IconWrapper>
      </Box>

      <Divider sx={{ borderColor: AppColors.AAA_LIGHT_GRAY }} />
      <List>
        {section.items?.map((menuItem) =>
          menuItem.children ? (
            <DrawerMenuItemGroup
              key={menuItem.title}
              menuItem={menuItem}
              isCollapsed={!open}
              activeMenuItem={activeMenuItem}
            />
          ) : (
            <DrawerMenuItem
              key={menuItem.title}
              menuItem={menuItem}
              isCollapsed={!open}
              isActive={activeMenuItem && activeMenuItem.path === menuItem.path}
            />
          ),
        )}
      </List>
    </Box>
  );
};

export default DrawerMenuSection;
