import { useMemo } from 'react';
import { usePermissionRoles } from './PermissionProvider';
import { CRUDPermissions, PermissionTable } from './types';

interface Props {
  table: string;
}

export function useFeaturePermissionDetails({ table }: Props): PermissionTable | undefined {
  const { permissions, isLoading } = usePermissionRoles();
  return useMemo(() => {
    return permissions[table] || (isLoading ? undefined : { permissions: {} });
  }, [permissions, isLoading, table]);
}

export function useFeaturePermission({ table }: Props): CRUDPermissions | undefined {
  return useFeaturePermissionDetails({ table })?.permissions;
}
