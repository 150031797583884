import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  SxProps,
  CircularProgress,
  Typography,
  Breakpoint,
} from '@mui/material';
import AppColors, { ButtonColors } from 'AppColors';
import CloseIcon from '@mui/icons-material/Close';
import { Theme } from '@mui/system';
import { PropsWithChildren, useEffect, useState } from 'react';

interface Props {
  open: boolean;
  title: string;
  content?: string;
  onConfirm?: () => void;
  onClose?: () => void;
  labelConfirm?: string;
  labelConfirmColor?: ButtonColors;
  labelCancel?: string;
  sx?: SxProps<Theme>;
  isLoading?: boolean;
  maxWidth?: Breakpoint;
  disableConfirmLoader?: boolean;
  isError?: boolean;
}

export default function DialogConfirmation({
  open,
  title,
  content,
  onConfirm,
  onClose,
  labelConfirm = 'Confirm',
  labelCancel = 'Cancel',
  labelConfirmColor = 'primary',
  children,
  sx,
  isLoading,
  maxWidth,
  disableConfirmLoader = false,
  isError = false,
}: PropsWithChildren<Props>) {
  const [isLoadingState, setIsLoadingState] = useState(false);
  useEffect(() => {
    setIsLoadingState(false);
  }, [open]);

  useEffect(() => {
    if (isError) setIsLoadingState(false);
  }, [isError]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      data-testid="DialogConfirmationModal"
      sx={sx}
      maxWidth={maxWidth}
    >
      <Box display="flex">
        <Box flexGrow={1}>
          <DialogTitle>
            <Typography variant="subtitle1" component={'span'}>
              {title}
            </Typography>
          </DialogTitle>
        </Box>
        <Box py="5px">
          <IconButton onClick={onClose}>
            <CloseIcon color="secondary" />
          </IconButton>
        </Box>
      </Box>
      <DialogContent>
        {content && (
          <DialogContentText>
            <Typography
              variant="body2"
              color={AppColors.AAA_BLUE}
              component={'span'}
              sx={{ whiteSpace: 'pre-line' }}
            >
              {content}
            </Typography>
          </DialogContentText>
        )}
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="DialogCancelButton"
          onClick={onClose}
          variant="outlined"
          disabled={isLoading}
        >
          {labelCancel}
        </Button>
        {onConfirm && (
          <Button
            data-testid="DialogConfirmButton"
            onClick={() => {
              !disableConfirmLoader && setIsLoadingState(true);
              onConfirm();
            }}
            autoFocus
            variant="contained"
            disabled={isLoading || isLoadingState}
            color={labelConfirmColor}
          >
            {labelConfirm}
            {(isLoading || isLoadingState) && <CircularProgress size={16} sx={{ ml: 1 }} />}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
