import CircularProgressSummarySection from './CircularProgressSummarySection';
import Stack from '@mui/material/Stack';
import { SummarySectionBox } from './SummaryComponents.styled';
import { useCallback } from 'react';

export type SummaryItem = {
  category: string;
  amount: number;
  color?: string;
  tag?: string;
  icon?: JSX.Element;
  name: string;
  value: string;
};

export interface SummaryData {
  summary: SummaryItem[];
  total: number;
}

export interface CircularProgressSummaryProps {
  data: SummaryData;
  currentFilteredStatuses: string;
  isDefaultFiltered: boolean;
  onCardClick: (status: string) => void;
}

export default function CircularProgressSummary({
  data,
  currentFilteredStatuses,
  isDefaultFiltered,
  onCardClick,
}: CircularProgressSummaryProps) {
  const handleStatusClick = useCallback(
    (status: string) => {
      return () => onCardClick(status);
    },
    [onCardClick],
  );

  return (
    <Stack height={85} flexDirection="row">
      {data.summary.map((item, index) => {
        const isActive =
          isDefaultFiltered ||
          currentFilteredStatuses.includes(item.name) ||
          // this condition is to cover late review tags, that belong to the same icon
          (item.name === 'LATE_REVIEW_SUBMISSION' &&
            currentFilteredStatuses === 'REVIEW_SUBMISSION');
        return (
          <SummarySectionBox
            key={`${item.category}-${index}`}
            onClick={handleStatusClick(item.value)}
            isActive={isActive}
            data-testid={`BillRequest-${item.name.charAt(0)}${item.name
              .toLowerCase()
              .slice(1)}Status`}
          >
            <CircularProgressSummarySection expectedMaxValue={data.total} item={item} />
          </SummarySectionBox>
        );
      })}
    </Stack>
  );
}
