import Stack from '@mui/material/Stack';
import { Box, Paper } from '@mui/material';
import FormDivider from 'components/Form/FormDivider';
import DataTable, { DataTableColumn } from 'components/DataTable';
import { CreditCardErrorMessage } from 'features/CreditCards/types';

export const ErrorCodesTable: DataTableColumn<any>[] = [
  {
    label: 'Return Code',
    id: 'code',
    sortable: false,
  },
  { label: 'Code Description', id: 'message', sortable: false },
];

interface Props {
  rows: CreditCardErrorMessage[];
}

const CreditCardErrorsTable = ({ rows = [] }: Props) => {
  return (
    <Stack spacing={0}>
      <FormDivider label="Cyber Source Errors" sx={{ mt: 2, mb: 3, padding: '0 32px' }} />
      <Box component={Paper}>
        <DataTable<any>
          data-testid={DataTable.name}
          indexName={'uuid'}
          onRowClick={() => null}
          columns={ErrorCodesTable}
          rows={rows}
          stickyHeader
        />
      </Box>
    </Stack>
  );
};

export default CreditCardErrorsTable;
