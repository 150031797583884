import { useState, useContext, useEffect, useMemo } from 'react';
import ServicesProvidedEditor from './ServicesProvidedEditor';
import { CaseDetailsContext } from 'features/Cases/contexts/CaseDetails';
import OfferIcon from '@mui/icons-material/LocalOffer';
import { LoaderCard } from 'components/Cards/LoaderCard';
import {
  DynamicExpandablePanel,
  ExpandablePanelEditOptions,
} from 'components/Dynamic/DynamicExpandablePanel';
import { useCaseServiceTroubleCodes, useCaseServicesProvided } from './ServicesProvidedQuery';
import { ErrorCard } from 'components/Cards/ErrorCard';
import { AuthComponent } from 'auth/permissions/authComponent';
import { CASE_SERVICES_PROVIDED_PERMISSION, useServiceProvidedConfig } from './config';
import SkeletonLoader from 'components/SkeletonLoader';

type Props = {
  caseUUID: string;
  editOptions?: ExpandablePanelEditOptions;
};

const ServicesProvided = ({ caseUUID, editOptions }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [isEditMode, setEditMode] = useState(false);
  const { allSectionsExpanded, isEditingSection, setIsEditingSection } =
    useContext(CaseDetailsContext);

  const {
    isLoading: isLoadingServicesProvided,
    isError: isServicesProvidedError,
    data: servicesProvided,
  } = useCaseServicesProvided(caseUUID, expanded);

  const {
    isLoading: isLoadingTroubleCodes,
    isError: isTroubleCodesError,
    data: troubleCodes = [],
  } = useCaseServiceTroubleCodes({
    caseId: caseUUID,
    enabled: expanded,
  });

  useEffect(() => {
    setExpanded(allSectionsExpanded);
  }, [allSectionsExpanded]);

  const onExpanded = (isExpanded: boolean) => () => {
    setExpanded(!isExpanded);
  };

  const handleEdit = () => {
    setEditMode(true), setIsEditingSection(true);
  };

  const isLoading = useMemo(() => {
    return isLoadingServicesProvided || isLoadingTroubleCodes;
  }, [isLoadingServicesProvided, isLoadingTroubleCodes]);

  const isError = useMemo(() => {
    return isServicesProvidedError || isTroubleCodesError;
  }, [isServicesProvidedError, isTroubleCodesError]);

  const disableEdit = useMemo(
    () => isLoading || isError || editOptions?.disabled,
    [isLoading, isError, editOptions?.disabled],
  );

  const { permissions } = useServiceProvidedConfig();

  return (
    <DynamicExpandablePanel
      title="Services Provided"
      data-testid="CaseServicesProvidedPanel"
      icon={<OfferIcon />}
      onExpanded={onExpanded(expanded)}
      expanded={expanded}
      actions={{
        onEdit: permissions?.update ? () => handleEdit() : undefined,
        isEditMode: isEditMode,
      }}
      editOptions={{
        ...editOptions,
        disabled: disableEdit,
      }}
      isEditingSection={isEditingSection}
    >
      {isLoading && <LoaderCard label="Loading Services Provided" />}

      {isError && <ErrorCard label="An Error Has Occurred While Retrieving Services Provided" />}

      {servicesProvided && troubleCodes && (
        <ServicesProvidedEditor
          caseUUID={caseUUID}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          setIsEditingSection={setIsEditingSection}
          servicesProvided={servicesProvided}
          troubleCodes={troubleCodes}
        />
      )}
    </DynamicExpandablePanel>
  );
};

export default AuthComponent(
  CASE_SERVICES_PROVIDED_PERMISSION,
  ServicesProvided,
  <SkeletonLoader loading height={66} variant="rectangular" />,
);
