import {
  fetchPaymentForecastsByFilter,
  fetchOnePaymentForecast,
  sendReportViaEmail,
  downloadReport,
  downloadOneReport,
  sendOneReportViaEmail,
  generateMonthlyReport,
  fetchPaymentForecastCasesByClub,
  regenerateReport,
} from 'api/paymentForecasts';
import { PageableParams, useMappedReactQuery } from 'hooks/useReactQueryHelpers';
import { PaymentForecastsData, PaymentForecastsInternalData } from './types';
import { PageableResponse } from 'api/interfaces/responses';
import {
  PaymentForecastsClubSummaryDto,
  PaymentForecastsItemDto,
} from 'api/paymentForecasts/interfaces';
import { useMutation } from 'react-query';
import { mapDtoToData as mapGeneralInfoDtoToData } from 'features/Cases/CaseProvider';

const QUERY_PAYMENT_FORECAST_FETCH_ALL = 'QUERY_PAYMENT_FORECAST_FETCH_ALL';
const QUERY_PAYMENT_FORECAST_FETCH_ONE = 'QUERY_PAYMENT_FORECAST_FETCH_ONE';
const QUERY_PAYMENT_FORECAST_FETCH_CASES = 'QUERY_PAYMENT_FORECAST_FETCH_CASES';

export const mapPaymentForecastsSummaryDtoToData = (
  dto: PageableResponse<PaymentForecastsClubSummaryDto>,
): PaymentForecastsInternalData[] => {
  const items: PaymentForecastsInternalData[] = [];
  const body: PaymentForecastsClubSummaryDto[] = JSON.parse(JSON.stringify(dto));

  body?.forEach((item) => {
    items.push({
      uuid: item.uuid,
      clubUuid: item.clubUuid,
      clubName: item.clubName,
      clubCode: item.clubCode,
      totalCases: item.totalCases,
      totalPayableAmount: item.totalPayableAmount,
      totalRevenueShareAmount: item.totalRevenueShareAmount,
      reports: item.reports,
    });
  });

  return items;
};

export const mapPaymentForecastsItemDtoToData = (
  dto: PageableResponse<PaymentForecastsItemDto>,
): PaymentForecastsItemDto[] => {
  const items: PaymentForecastsData[] = [];
  const body: PaymentForecastsItemDto[] = JSON.parse(JSON.stringify(dto));

  body?.forEach((item) => {
    items.push({
      paymentGroupNumber: item.paymentGroupNumber,
      startPeriod: item.startPeriod,
      endPeriod: item.endPeriod,
      totalCases: item.totalCases,
      totalClubPayments: item.totalClubPayments,
      reports: item.reports,
    });
  });

  return items;
};

export function usePaymentForecastsFetchAllQuery() {
  return useMappedReactQuery(
    QUERY_PAYMENT_FORECAST_FETCH_ALL,
    fetchPaymentForecastsByFilter,
    {
      mapper: mapPaymentForecastsItemDtoToData,
    },
    { page: 0, size: 9999, filter: '' },
  );
}

export function usePaymentForecastsFetchOneQuery(data: { startDate: string; endDate: string }) {
  return useMappedReactQuery(
    QUERY_PAYMENT_FORECAST_FETCH_ONE + JSON.stringify(data),
    (params) => fetchOnePaymentForecast({ ...data, params }),
    {
      mapper: mapPaymentForecastsSummaryDtoToData,
    },
    { page: 0, size: 9999, filter: '' },
  );
}

export function usePaymentForecastGetCasesByClub(
  paymentGroupNumber: string,
  clubUuid: string,
  params: PageableParams,
) {
  return useMappedReactQuery(
    QUERY_PAYMENT_FORECAST_FETCH_CASES + paymentGroupNumber + clubUuid + JSON.stringify(params),
    () => fetchPaymentForecastCasesByClub(paymentGroupNumber, clubUuid, params),
    {
      mapper: ({ content, totalElements }) => ({
        content: content?.map(mapGeneralInfoDtoToData),
        totalElements,
      }),
    },
    params,
  );
}

export function usePaymentForecastsSendReports() {
  return useMutation(sendReportViaEmail);
}

export function usePaymentForecastsSendOneReport() {
  return useMutation(sendOneReportViaEmail);
}

export function usePaymentForecastsDownloadManyReports() {
  return useMutation(downloadReport);
}

export function usePaymentForecastsDownloadOneReports() {
  return useMutation(downloadOneReport);
}

export function useRegenerateReports() {
  return useMutation(regenerateReport);
}

export function useGenerateMonthlyReport() {
  return useMutation(generateMonthlyReport);
}
