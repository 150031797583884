import { EntityTypes } from 'api/setup/manageServicesSetupApi';

type BreadcrumbItem = { url: string; label: string };

// Mapping of entity types to breadcrumb configuration objects
const breadcrumbConfigMap: Record<EntityTypes, BreadcrumbItem[]> = {
  PROGRAM: [
    { url: '/setup/clients', label: 'Clients List' },
    { url: '/setup/clients/:clientUuid', label: 'Client Details' },
    {
      url: '/setup/clients/:clientUuid/programs/:entityUuid',
      label: 'Program Details',
    },
    {
      url: '',
      label: 'Pricing Setup',
    },
  ],
  CLIENT: [
    {
      url: '/setup/clients',
      label: 'Client List',
    },
    {
      url: '/setup/clients/:entityUuid',
      label: 'Client Details',
    },
    {
      url: '',
      label: 'Pricing Setup',
    },
  ],
  CLUB: [
    {
      url: '/setup/vendors',
      label: 'Vendor List',
    },
    {
      url: '/setup/vendors/:entityUuid',
      label: 'Vendor Details',
    },
    {
      url: '',
      label: 'Pricing Setup',
    },
  ],
};

// Default breadcrumb configuration to use if entity type is not recognized
const defaultBreadcrumbConfig: BreadcrumbItem[] = [{ url: '', label: 'Empty' }];

/**
 * Returns an array of breadcrumb items for a given entity type and entity UUID.
 * @param entityType The type of entity to generate breadcrumbs for.
 * @param entityUuid The UUID of the entity to generate breadcrumbs for.
 * @param clientUuid (optional) The UUID of the client to generate breadcrumbs for, only used when entityType is 'PROGRAM'.
 * @returns An array of breadcrumb items containing a URL and label for each item in the breadcrumb trail.
 */
export const getBreadcrumbConfig = (
  entityType: EntityTypes,
  entityUuid?: string,
  clientUuid?: string,
): BreadcrumbItem[] => {
  // Get the breadcrumb configuration object for the given entity type
  const config = breadcrumbConfigMap[entityType] || defaultBreadcrumbConfig;

  // Replace any occurrences of :entityUuid or :clientUuid in the breadcrumb URLs with the actual UUIDs, if provided
  return config.map((item) => ({
    url:
      entityUuid || clientUuid
        ? item.url
            .replace(':entityUuid', entityUuid || ':entityUuid')
            .replace(
              ':clientUuid',
              entityType === 'PROGRAM' ? clientUuid || ':clientUuid' : ':clientUuid',
            )
        : item.url,
    label: item.label,
  }));
};
