export function formatDate(date: string): string {
  if (!date) return '';

  return new Date(date)?.toISOString()?.split('T')?.[0];
}

function addLeadingZero(value: number): string {
  return value.toString().padStart(2, '0');
}

export function formatDateTime(isoDate: string): string {
  const parsedDate = new Date(isoDate);
  const [date] = isoDate.split('T');
  return `${date} ${addLeadingZero(parsedDate.getUTCHours())}:${addLeadingZero(
    parsedDate.getUTCMinutes(),
  )}:${addLeadingZero(parsedDate.getSeconds())}`;
}
