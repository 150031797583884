import { BaseAPI } from 'api/auth';
import { ENV_BACKOFFICE_BASE_API_URL } from 'lib/envConfig';
import { ErrorCountResponse } from './types';

export function fetchTotalErrors() {
  return BaseAPI.get<ErrorCountResponse>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/dashboards/cards/credit-cards/errors`,
  );
}

export function fetchInQueue() {
  return BaseAPI.get<ErrorCountResponse>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/dashboards/cards/credit-cards/queue`,
  );
}

export function fetchSettledYesterday() {
  return BaseAPI.get<ErrorCountResponse>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/dashboards/cards/credit-cards/settled-yesterday`,
  );
}

export function fetchFailedToSettleYesterday() {
  return BaseAPI.get<ErrorCountResponse>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/dashboards/cards/credit-cards/failed-to-settle-yesterday`,
  );
}
