import { Auth } from 'aws-amplify';

export function authSignIn(email: string, password: string) {
  return Auth.signIn(email, password);
}

export function sendPasswordResetCode(emailAddress: string) {
  return Auth.forgotPassword(emailAddress);
}

export function completePasswordReset(emailAddress: string, code: string, newPassword: string) {
  return Auth.forgotPasswordSubmit(emailAddress, code, newPassword);
}

export function completeFirstLogin(cognitoUser: any, newPassword: string) {
  return Auth.completeNewPassword(cognitoUser, newPassword);
}
