import { PropsWithChildren } from 'react';
import { CRUDPageableProvider, usePageableContextData } from 'components/CRUDPageable';
import { CRUDPageableReactQuery } from 'hooks/useReactQueryHelpers';
import { SavedSearch } from 'api/savedSearches/interfaces/SavedSearch';
import { saveSearchApi } from 'api/savedSearches/savedSearchesApi';

const SavedSearchesQuery = CRUDPageableReactQuery<SavedSearch>(
  'QUERY_SAVED_SEARCHES',
  saveSearchApi,
);

export function useSavedSearchesContext() {
  return usePageableContextData<SavedSearch>();
}

export default function SavedSearchesProvider({ children }: PropsWithChildren) {
  return (
    <CRUDPageableProvider
      queries={SavedSearchesQuery}
      fetchAllOptions={{ refetchOnMount: 'always', enabled: true }}
    >
      {children}
    </CRUDPageableProvider>
  );
}
