import { ServiceItemsDto } from 'api/setup/manageServiceItemsApi';
import { DataTableColumn } from 'components/DataTable';
import { DynamicFieldType } from 'components/Dynamic';
import { DynamicQueryFieldType } from 'components/Dynamic/DynamicSearchBox/types';
import { ServiceItemsData } from './types';

export const ServiceItemsSearchFields: DynamicQueryFieldType<ServiceItemsDto>[] = [
  {
    name: 'code',
    label: 'Service Code',
    searchType: 'like',
  },
  {
    name: 'name',
    label: 'Service Name',
    searchType: 'like',
  },
  {
    name: 'payable',
    label: 'Payable',
    searchType: 'equal',
    type: DynamicFieldType.CHECKBOX,
  },
];

export const ServiceItemsTableConfig: DataTableColumn<ServiceItemsData>[] = [
  {
    label: 'Service Code',
    id: 'code',
    sortable: true,
  },
  {
    label: 'Service Name',
    id: 'name',
    sortable: true,
  },
  {
    label: 'Service Description',
    id: 'description',
  },
  { label: 'Payable', id: 'payable', accessor: (row) => (row.payable ? 'Yes' : 'No') },
];
