import { Typography } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  label: string;
  children?: ReactNode;
}

export function LabeledValue({ label, children }: Props) {
  return (
    <>
      <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
        {label}
      </Typography>
      <Typography variant="body2">{children}</Typography>
    </>
  );
}
