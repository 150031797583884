import { DataTableColumn } from 'components/DataTable';
import { DynamicFieldType } from 'components/Dynamic';
import { DynamicQueryFieldType } from 'components/Dynamic/DynamicSearchBox/types';
import { TransactionStatus } from 'core/enums/CreditCards';
import { RbpCaseStatusDescription } from 'core/enums/RbpCaseStatus';
import { CreditCardData, CreditCardSearch } from './types';
import sumBy from 'lodash.sumby';
import { precisionRoundMod } from 'utils/math';
import { convertUTCDateToString } from 'utils/convertDate';
import { HighlightedTypography } from './styles';
import { getStylesForCallDate } from './helpers/callDateStyles';
import { convertToCurrency } from 'utils/formatter';

const DateFilterValues = [
  { label: 'After', value: 'dateGreaterThan' },
  { label: 'Before', value: 'dateLessThan' },
  { label: 'Equal to', value: 'date' },
  { label: 'In between', value: 'dateRange' },
];

export const CreditCardSearchDefaultValues: CreditCardSearch = {
  'rbpCase/rapCaseId': '',
  'rbpCase/ccLastFourNumbers': '',
  'rbpCase/rapProgramCode': '',
  'rbpCase/clientName': '',
  'rbpCase/programName': '',
  'rbpCase/clubCode': '',
  dateFilter: 'date',
  'rbpCase/rapCallCreatedDateTime': '',
  'rbpCase/rbpCaseStatus': '',
  fourNumbers: '',
  cardType: '',
  services: [],
  cardChargeAmount: '',
  transactionStatus: '',
};

export const CreditCardSearchFields: DynamicQueryFieldType<CreditCardSearch>[] = [
  {
    name: 'rbpCase/rapCaseId',
    label: 'RAP Case Id',
    searchType: 'equal',
    colspan: 2.4,
  },
  {
    name: 'rbpCase/rapProgramCode',
    label: 'RAP Program Code',
    searchType: 'equal',
    colspan: 2.4,
  },
  {
    name: 'rbpCase/clientName',
    label: 'Client Name',
    searchType: 'like',
    colspan: 2.4,
  },
  {
    name: 'rbpCase/programName',
    label: 'Program Name',
    searchType: 'like',
    colspan: 2.4,
  },
  {
    name: 'rbpCase/clubCode',
    label: 'Club Code',
    searchType: 'equal',
    colspan: 2.4,
  },
  {
    name: 'dateFilter',
    label: 'Date Filter',
    type: DynamicFieldType.SELECT,
    searchType: 'operator',
    select: {
      options: DateFilterValues.map((item) => ({ label: item.label, value: item.value })),
    },
    colspan: 2.4,
  },
  {
    name: 'rbpCase/rapCallCreatedDateTime',
    label: 'Call Date',
    searchType: 'date',
    operatorField: 'dateFilter',
    type: DynamicFieldType.DATE,
    colspan: 2.4,
  },
  {
    name: 'rbpCase/rbpCaseStatus',
    label: 'RBP Case Status',
    searchType: 'equal',
    type: DynamicFieldType.SELECT,
    select: {
      options: Object.entries(RbpCaseStatusDescription).map(([value, label]) => ({
        label,
        value,
      })),
    },
    colspan: 2.4,
  },
  {
    name: 'rbpCase/ccLastFourNumbers',
    label: 'Last 4 of Card Number',
    searchType: 'like',
    colspan: 2.4,
  },
  {
    name: 'cardType',
    label: 'Card Type',
    searchType: 'like',
    colspan: 2.4,
  },
  {
    name: 'cardChargeAmount',
    label: 'Settled Amount',
    searchType: 'equal',
    colspan: 2.4,
  },
  {
    name: 'transactionStatus',
    label: 'Transaction Status',
    type: DynamicFieldType.SELECT,
    searchType: 'equal',
    select: {
      options: [
        { value: TransactionStatus.PRE_AUTHORIZED, label: 'Pre-Authorized' },
        { value: TransactionStatus.SETTLED, label: 'Settled' },
        { value: TransactionStatus.SETTLEMENT_FAILED, label: 'Settlement Failed' },
        { value: TransactionStatus.CREATED, label: 'Created' },
        { value: TransactionStatus.CAPTURE_PENDING, label: 'Capture Pending' },
        { value: TransactionStatus.CAPTURE_FAILED, label: 'Capture Failed' },
        { value: TransactionStatus.CAPTURE_SUCCESSFUL, label: 'Capture Successful' },
        { value: TransactionStatus.REFUND_PENDING, label: 'Refund Pending' },
        { value: TransactionStatus.REFUND_FAILED, label: 'Refund Failed' },
        { value: TransactionStatus.REFUND_SUCCESSFUL, label: 'Refund Successful' },
        { value: TransactionStatus.CANCELED, label: 'Canceled' },
        {
          value: TransactionStatus.CANCEL_PRE_AUTH_PENDING,
          label: 'Cancel Pre-Authorization Pending',
        },
        {
          value: TransactionStatus.CANCEL_PRE_AUTH_FAILED,
          label: 'Cancel Pre-Authorization Failed',
        },
        {
          value: TransactionStatus.CANCEL_PRE_AUTH_SUCCESSFUL,
          label: 'Cancel Pre-Authorization Successful',
        },
        { value: TransactionStatus.UNKNOWN, label: 'Unknown' },
      ],
    },
    colspan: 2.4,
  },
];

export const CreditCardDataTableConfig: DataTableColumn<CreditCardData>[] = [
  { id: 'rapCaseId', label: 'RAP Case Id', sortable: true, sortKey: 'rbpCase.rapCaseId' },
  {
    id: 'rapProgramCode',
    label: 'RAP Program Code',
    sortable: true,
    sortKey: 'rbpCase.rapProgramCode',
  },
  { id: 'clientName', label: 'Client Name', sortable: true, sortKey: 'rbpCase.clientName' },
  { id: 'programName', label: 'Program Name', sortable: true, sortKey: 'rbpCase.programName' },
  { id: 'clubCode', label: 'Club Code', sortable: true, sortKey: 'rbpCase.clubCode' },
  {
    id: 'rapCallCreatedDateTime',
    label: 'Call Date',
    sortable: true,
    sortKey: 'rbpCase.rapCallCreatedDateTime',
    accessor: (row) => (
      <HighlightedTypography
        variant="body2"
        sx={{
          ...getStylesForCallDate(row['rapCallCreatedDateTime']),
        }}
      >
        {convertUTCDateToString(row['rapCallCreatedDateTime'])}
      </HighlightedTypography>
    ),
  },
  {
    id: 'rbpCaseStatus',
    label: 'RBP Case Status',
    accessor: (row) => RbpCaseStatusDescription[row['rbpCaseStatus']],
    sortable: true,
    sortKey: 'rbpCase.rbpCaseStatus',
  },
  { id: 'fourNumbers', label: 'Last 4', sortable: true },
  { id: 'cardType', label: 'Card Type', sortable: true },
  {
    id: 'cardChargeAmount',
    label: 'Settled Amount',
    sortable: true,
    accessor: (row) => {
      const amount = parseFloat(row.cardChargeAmount);
      return isNaN(amount) ? '' : convertToCurrency(amount);
    },
    Footer: (rows) => {
      const total = sumBy(rows, (row) => {
        const value = parseFloat(row.cardChargeAmount || '0');
        return isNaN(value) ? 0 : value;
      });
      const roundedTotal = precisionRoundMod(total, 2);
      return convertToCurrency(roundedTotal);
    },
  },
  { id: 'transactionStatus', label: 'Transaction Status', sortable: true, Footer: () => '' },
];
