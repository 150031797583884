const currentFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const currentFormatter4Decimals = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 4,
  minimumFractionDigits: 4,
});

export function convertToCurrency4Decimals(value?: number) {
  return value === null || value === undefined ? '' : currentFormatter4Decimals.format(value);
}

export function convertToCurrency(value?: number) {
  if (value === null || value === undefined) {
    return '';
  }

  return currentFormatter.format(value);
}

export function convertToNumber(formattedValue: string): number {
  const numericValue = Number(formattedValue.replace(/[^\d.-]/g, ''));

  if (isNaN(numericValue)) {
    return 0;
  }

  return numericValue;
}
