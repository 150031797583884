import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { CircularProgressProps } from '@mui/material/CircularProgress';
import { CircularProgressWithContent } from 'components/CircularProgressWithContent';
import {
  SummaryCount,
  SummaryLabel,
  SummaryTagAlert,
  SummaryTitle,
} from './SummaryComponents.styled';
import { SummaryItem } from './CircularProgressSummary';
import DescriptionIcon from '@mui/icons-material/Description';

type Props = CircularProgressProps & {
  expectedMaxValue: number;
  item: SummaryItem;
};

const CircularProgressSummarySection = ({ expectedMaxValue, item, ...rest }: Props) => {
  const { amount, category, tag, color } = item;
  const icon = item.icon ?? <DescriptionIcon sx={{ color }} />;

  return (
    <Stack flexDirection="row" alignItems="center">
      <CircularProgressWithContent
        content={icon}
        value={amount}
        maxValue={expectedMaxValue}
        progressBarColor={color || 'primary'}
        displayValue={!icon}
        shade
        {...rest}
      />

      <Box height={90} paddingLeft={1.25}>
        <SummaryTitle>{category}</SummaryTitle>
        <Stack flexDirection="row" alignItems="center">
          <SummaryCount>{amount}</SummaryCount>
          <Box>
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
              }}
            >
              <SummaryLabel>
                <span>Bill</span>
                <span>Requests</span>
              </SummaryLabel>
              {tag && <SummaryTagAlert color={color}>{tag}</SummaryTagAlert>}
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};

export default CircularProgressSummarySection;
