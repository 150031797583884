/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { OptionalObjectSchema, TypeOfShape } from 'yup/lib/object';
import { AnyObject } from 'yup/lib/types';
import { useEntityCreationFlowContext } from '../context/EntityCreationFlowContext';

export interface UseEntityCreationHookFormProps<DataType> {
  stepKey: string;
  data: Partial<DataType> | undefined;
  validationSchema: OptionalObjectSchema<any, AnyObject, TypeOfShape<any>> | undefined;
}

export function useEntityCreationHookForm<DataType>({
  stepKey,
  data,
  validationSchema,
}: UseEntityCreationHookFormProps<DataType>) {
  const { hookFormMap, setHookFormMap } = useEntityCreationFlowContext();

  const newHookForm = useForm({
    defaultValues: data as any,
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
  });

  const hookForm = useMemo(
    () => hookFormMap.get(stepKey) || newHookForm,
    [hookFormMap, stepKey, newHookForm],
  );

  useEffect(() => {
    const hookForms = new Map(hookFormMap);
    hookForms.set(stepKey, hookForm);
    setHookFormMap(hookForms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hookForm, stepKey, setHookFormMap]);

  return hookForm;
}
