import AppColors from 'AppColors';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import getComparator from 'utils/getComparator';
import theme from 'theme';
import FormInputTooltip from 'components/Form/FormInputTooltip';
import { DataTableColumn, DataTableProps } from './interfaces';
import { StyledTableCell, StyledPaper } from './DataTable.styled';
import { convertToString } from 'utils/convertToString';
import { generateFooters } from './generateFooters';
import { makeHeaderGroups } from './utils';
import { useMemo, useState, useEffect } from 'react';
import { DataTableBodyRow } from './DataTableBodyRow';

export function ColumnHeader({ children }: { children: React.ReactNode }) {
  return (
    <Typography fontSize={14} fontWeight={700} width="100%" component={'span'}>
      {children}
    </Typography>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function DataTable<RowDataType extends Record<string, any>>({
  rows,
  columns,
  minWidth = 500,
  hasSelectors,
  indexName = '',
  onSelect,
  resetTrigger,
  paginationOptions,
  renderActions,
  onRowClick,
  onTextCellClick,
  className,
  sortOptions = { hasSort: true },
  stickyHeader,
  stickyOffset = theme.mixins.toolbar.minHeight,
  rowSx,
  tableContainerSx,
  relativeStickyHeader = false,
  Row,
  ...rest
}: DataTableProps<RowDataType>) {
  const [selected, setSelected] = useState<readonly string[]>([]);
  const getRowIndex = (row: RowDataType) => selected.indexOf(convertToString(row[indexName]));

  const [summaryHeaderGroup, mainHeaderGroup] = useMemo(() => makeHeaderGroups(columns), [columns]);

  const data = useMemo(() => {
    const { externalSorting, order, orderBy } = sortOptions;

    if (!externalSorting && order && orderBy) {
      return rows.slice().sort(getComparator(order, orderBy));
    }

    return rows;
  }, [rows, sortOptions]);

  const handleSelect = (row: RowDataType) => {
    if (hasSelectors) {
      const selectedIndex = getRowIndex(row);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, convertToString(row[indexName]));
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);

      if (onSelect) {
        onSelect(newSelected);
      }
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = event.target.checked
      ? rows.map((row) => convertToString(row[indexName]))
      : [];
    setSelected(newSelected);

    if (onSelect) {
      onSelect(newSelected);
    }
  };

  const isSelected = (row: RowDataType) => getRowIndex(row) !== -1;
  const numSelected = selected.length;
  const rowCount = rows.length;

  const handleChangePage = (_: unknown, newPage: number) => {
    paginationOptions?.onPageChange(newPage);
    onSelect?.([]);
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    paginationOptions?.onRowsPerPageChange(parseInt(event.target.value, 10));
    paginationOptions?.onPageChange(0);
    onSelect?.([]);
    setSelected([]);
  };

  const hasAnyAction = !!renderActions;

  const onFieldSort =
    (col: DataTableColumn<RowDataType>) => (event: React.MouseEvent<HTMLSpanElement>) => {
      if (sortOptions?.hasSort && col.sortable) {
        const sortKey = col.sortKey || col.id;
        sortOptions?.createSortHandler?.(event, sortKey.toString());
      }
    };

  useEffect(() => {
    setSelected([]);
  }, [resetTrigger]);

  return (
    <TableContainer
      data-testid="DataTable"
      component={StyledPaper}
      className={className}
      sx={{
        ...tableContainerSx,
        borderTop: `1px solid ${AppColors.AAA_LIGHT_NAVY}`,
        overflow: stickyHeader ? 'revert' : 'auto',
      }}
      {...rest}
    >
      <Table sx={{ minWidth }} stickyHeader={relativeStickyHeader}>
        <TableHead
          sx={{
            position: stickyHeader ? 'sticky' : 'static',
            top: stickyOffset,
          }}
        >
          {summaryHeaderGroup.headers.length > 0 && (
            <TableRow>
              {summaryHeaderGroup.headers.map((col, index) => (
                <StyledTableCell key={index} colSpan={col.colSpan}>
                  <ColumnHeader>{col.Header?.() ?? col.label}</ColumnHeader>
                </StyledTableCell>
              ))}
            </TableRow>
          )}

          <TableRow>
            {hasSelectors && (
              <TableCell
                padding="checkbox"
                sx={{
                  borderBottom: `1px solid ${AppColors.AAA_LIGHT_NAVY}`,
                }}
              >
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
            )}
            {mainHeaderGroup.headers.map((col, index) => {
              const sortable = sortOptions?.hasSort && col.sortable;
              const sorted = sortOptions?.orderBy === col.id;
              return (
                <StyledTableCell
                  key={index}
                  sx={{
                    minWidth: col.minWidth,
                    maxWidth: col.maxWidth,
                    width: col.width,
                    borderBottom: `1px solid ${AppColors.AAA_LIGHT_NAVY}`,
                    ...(sorted && { backgroundColor: `${AppColors.AAA_ALTO_GRAY} !important` }),
                  }}
                  sortDirection={sortable && sorted ? sortOptions?.order : false}
                  variant="head"
                >
                  <TableSortLabel
                    hideSortIcon={!sortable}
                    IconComponent={ArrowDropDownIcon}
                    active={sortable && sorted}
                    direction={sortable && sorted ? sortOptions?.order : undefined}
                    onClick={onFieldSort(col)}
                    sx={{
                      ...(!sortable && {
                        '&:hover': {
                          color: 'inherit',
                          cursor: 'default',
                        },
                      }),
                    }}
                  >
                    <Typography fontSize={14} fontWeight={700} width="100%" component={'span'}>
                      {col.Header?.() ?? col.label}
                    </Typography>
                    {col.headerTooltip && (
                      <Box ml={1}>
                        <FormInputTooltip category={col.headerTooltip} position="start" />
                      </Box>
                    )}
                  </TableSortLabel>
                </StyledTableCell>
              );
            })}
            {hasAnyAction && (
              <TableCell
                sx={{
                  borderBottom: `1px solid ${AppColors.AAA_LIGHT_NAVY}`,
                }}
              >
                <Typography fontSize={14} fontWeight={700}>
                  Actions
                </Typography>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <DataTableBodyRow
              key={row.id || rowIndex}
              hasAnyAction={hasAnyAction}
              hasSelectors={hasSelectors}
              renderActions={renderActions}
              headerGroup={mainHeaderGroup}
              isSelected={isSelected}
              onRowClick={onRowClick}
              onSelect={handleSelect}
              onTextCellClick={onTextCellClick}
              row={row}
              rowIndex={rowIndex}
              sx={rowSx}
              Row={Row}
            />
          ))}
        </TableBody>
        <TableFooter>{generateFooters(mainHeaderGroup.headers, rows, hasSelectors)}</TableFooter>
      </Table>
      {paginationOptions && (
        <TablePagination
          rowsPerPageOptions={paginationOptions.rowsPerPageOptions || [5, 10, 25]}
          component="div"
          count={paginationOptions.rowsCount}
          rowsPerPage={paginationOptions.rowsPerPage}
          page={paginationOptions.currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
          variant="footer"
        />
      )}
    </TableContainer>
  );
}
