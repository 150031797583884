import { ReactNode } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import SubMenuButton from 'components/SubMenuButton';
import EmailIcon from '@mui/icons-material/Email';
import { DynamicConfirmation, DynamicFormActions } from '../DynamicFormActions';
import { SubMenuItem } from 'components/SubMenuButton/SubMenuButton';

interface Props {
  isEditMode: boolean;
  leftPanel?: ReactNode;
  centralPanel?: ReactNode;
  save?: DynamicConfirmation;
  discard?: DynamicConfirmation;
  visible?: boolean;
  actions?: {
    onSendEmail?: () => void;
  };
  subMenuActions?: SubMenuItem[];
}

export function DynamicActions({
  isEditMode,
  leftPanel,
  centralPanel,
  save,
  discard,
  visible = true,
  actions = {},
  subMenuActions,
}: Props) {
  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          {leftPanel}
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-around" flexGrow={1}>
          {centralPanel}
        </Stack>
        <Stack direction="row" spacing={2}>
          {!!actions.onSendEmail && (
            <Button variant="outlined">
              <EmailIcon />
            </Button>
          )}
          {isEditMode && <DynamicFormActions save={save} discard={discard} />}
          {!isEditMode && visible && subMenuActions && (
            <SubMenuButton label="Actions" menu={subMenuActions || []} />
          )}
        </Stack>
      </Stack>
    </>
  );
}
