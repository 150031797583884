import { PropsWithChildren } from 'react';
import { CRUDPageableReactQuery } from 'hooks/useReactQueryHelpers';
import { ProgramDto, ManageProgramApi } from 'api/setup/manageProgramsApi';
import { CRUDPageableProvider, usePageableContextData } from 'components/CRUDPageable';
import { SurveyTypes } from 'api/setup/manageClientApi';
import { BillingConfigurationProgramData } from './ProgramQuery';

export type ProgramData = {
  poEffectiveDateTime: string;
  poExpirationDateTime: string;
  poRequired?: boolean;
  poNumber?: string;
  abbreviatedName?: string;
  name?: string;
  uuid: string;
  clientUUID?: string;
  code?: string;
  rapClientId?: number;
  clientName?: string;
  rapProgramId: number;
  programName?: string;
  rapProgramCode: number;
  survey?: boolean;
  surveyType?: SurveyTypes;
  surveyAdministrator?: string;
  contactCenterPartner?: string;
  customerCallNumber?: string;
  aaaAssignedDid?: string;
  clubCallInNumber?: string;
} & Partial<Pick<BillingConfigurationProgramData, 'allowSplitPay'>>;

function mapDtoToData(dto: ProgramDto): ProgramData {
  return {
    ...dto,
    uuid: dto.uuid,
    programName: dto.name,
    abbreviatedName: dto.abbreviatedName,
    code: dto.code,
    rapProgramCode: dto.rapProgramCode,
    clientName: dto.client?.name || '',
    clientUUID: dto.client?.uuid || '',
    rapClientId: dto.client?.rapClientId,
    rapProgramId: dto.rapProgramId,
    survey: dto.survey,
    surveyType: dto.surveyType,
    surveyAdministrator: dto.surveyAdministrator,
    poEffectiveDateTime: dto.poEffectiveDateTime,
    poExpirationDateTime: dto.poExpirationDateTime,
    contactCenterPartner: dto.contactCenterPartner,
    customerCallNumber: dto.customerCallNumber,
    clubCallInNumber: dto.clubCallInNumber,
    aaaAssignedDid: dto.aaaAssignedDid,
  };
}

function mapDataToDto(data: ProgramData): ProgramDto {
  return {
    ...data,
    uuid: data.uuid,
    name: data.programName,
    code: data.code,
    rapProgramCode: data.rapProgramCode,
    rapProgramId: data.rapProgramId,
    client: {
      uuid: data.clientUUID,
    },
    poEffectiveDateTime: data.poEffectiveDateTime,
    poExpirationDateTime: data.poExpirationDateTime,
    survey: data.survey,
    surveyType: data.surveyType,
    surveyAdministrator: data.surveyAdministrator,
  };
}

const ProgramsQuery = CRUDPageableReactQuery(
  'QUERY_PROGRAMS',
  ManageProgramApi,
  mapDtoToData,
  mapDataToDto,
);

export function useProgramContext() {
  return usePageableContextData<ProgramDto, ProgramData>();
}

export default function ProgramProvider({ children }: PropsWithChildren) {
  return <CRUDPageableProvider queries={ProgramsQuery}>{children}</CRUDPageableProvider>;
}
