import { CRUDComponent } from 'components/CRUDNavigator';
import { CRUDPageableList } from 'components/CRUDPageable';
import {
  CreditCardDataTableConfig,
  CreditCardSearchDefaultValues,
  CreditCardSearchFields,
} from './CreditCardPageConfig';
import { useCreditCardContext } from './CreditCardProvider';
import { useForm, useWatch } from 'react-hook-form';
import { CreditCardData, CreditCardSearch } from './types';
import { useMemo } from 'react';
import { DynamicFieldType } from 'components/Dynamic';
import { DynamicSearchType } from 'components/Dynamic/DynamicSearchBox/types';

const CreditCardPageList: CRUDComponent = ({ navigators }) => {
  const context = useCreditCardContext();
  const hookForm = useForm<CreditCardSearch>({ defaultValues: CreditCardSearchDefaultValues });
  const dateFilter = useWatch({
    control: hookForm.control,
    name: 'dateFilter',
  });

  const searchFields = useMemo(() => {
    return CreditCardSearchFields.map((field) => {
      if (field.name === 'rbpCase/rapCallCreatedDateTime') {
        return {
          ...field,
          type: dateFilter === 'dateRange' ? DynamicFieldType.RANGE_DATE : DynamicFieldType.DATE,
          searchType: dateFilter === 'dateRange' ? (dateFilter as DynamicSearchType) : 'date',
        };
      }
      return field;
    });
  }, [dateFilter]);

  return (
    <CRUDPageableList
      name="Credit Card"
      context={context}
      navigators={navigators}
      searchFields={searchFields}
      tableColumns={CreditCardDataTableConfig}
      searchDefaultValues={CreditCardSearchDefaultValues}
      canCreate={false}
      useFormReturn={hookForm}
      searchBoxTitle=""
      getUUID={(data: CreditCardData) => data.rbpCaseUuid}
    />
  );
};

export default CreditCardPageList;
