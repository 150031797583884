import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { PropsWithChildren } from 'react';

/**
 * Props for the PricingSetupPageableLayout component.
 */
export type Props = PropsWithChildren<{
  // Optional spacing between Stack children. Defaults to '10px'.
  stackSpacing?: string | number;
}>;

/**
 * PricingSetupPageableLayout component.
 *
 * A layout component that wraps its children in a Stack with customizable spacing,
 * within a Box component.
 *
 * @param props - Component properties.
 * @returns JSX.Element - Rendered component.
 */
const PricingSetupPageableLayout: React.FC<Props> = ({
  stackSpacing = '10px',
  children,
}: Props) => {
  return (
    <Box mt="50" flexGrow={1}>
      <Stack spacing={stackSpacing} data-testid={'pricing-setup-pageable-layout'}>
        {children}
      </Stack>
    </Box>
  );
};

export default PricingSetupPageableLayout;
