import { useFeaturePermission, useFieldPermission } from 'auth';
import { DataTableColumn } from 'components/DataTable';
import { DynamicField, DynamicFieldType } from 'components/Dynamic';
import { CaseEventDetails, EventsStatusHistory } from 'features/Cases/interfaces/CaseEventDetails';
import { convertUTCToDateTimeString } from 'utils/convertDate';
import { getEventStatusDescription } from './helper';
import * as Yup from 'yup';
import { Tooltip } from '@mui/material';

export const CASE_EVENT_STATUS_PERMISSION = 'EventDetailDto';

export enum EventStatusDescriptions {
  // Dispatch Status
  'AK' = 'Acknowledged',
  'AS' = 'Assigned',
  'CA' = 'Cancelled by Sponsor',
  'CL' = 'Cleared',
  'DI' = 'Dispatched',
  'ER' = 'Enroute',
  'ET' = 'Estimated Time of Arrival',
  'OL' = 'On Location',
  'RE' = 'Call Received',
  'TR' = 'Transmitted To Club',
  'TW' = 'Tow',
  // Payment Status
  'QMC' = 'QM Club',
  'QMN' = 'QM National',
  'PPD' = 'Prepaid',
  'PYP' = 'Pay Pending',
  'PYA' = 'Pay Accounting',
  'PYC' = 'Pay Created',
  'PAY' = 'Paid Club',
  'DCC' = 'Dispatch Center ID Changed',
  'WO' = 'Write-Off',
  'ERR' = 'Error',
  'NEW' = 'New Call',
  'CLS' = 'Closed',
  'RVW' = 'Review Pending',
  'SPD' = 'Sponsor Paid',
  'PDC' = 'Paid Club',
  'DSP' = 'Disputed',
  'JUS' = 'Justified',
  'COR' = 'Corrected',
  'EXP' = 'Expired',
  'BLP' = 'Bill Pending',
  'BLC' = 'Bill Created',
  'BLA' = 'Bill Accounting',
  'CAN' = 'Cancel',
  'STC' = 'Sponsor To Customer Paid Change',
  'STP' = 'Sponsor To Prepaid Change',
  'STL' = 'Settlement for GM',
  'CRW' = 'Corrected by Club',
  'JRW' = 'Justified by Club',
  'CPD' = 'Customer Paid',
  'NSP' = 'No Services Provided',
  'APP' = 'Approved For Billing',
  'BIL' = 'Billed to Sponsor',
  'HLD' = 'Hold',
  'CMS' = 'Close Message Sent',
  'RWE' = 'Researching With EDS',
  'HRW' = 'Hold Corrected by Club',
  'PTS' = 'Prepaid To Sponsor Paid Change',
  'MCL' = 'Manual Close',
  'MRV' = 'Manual Review',
  'GMG' = 'GM GOA Report Generated',
  'GMC' = 'GM / VW Misc Credit Report Generated',
  'RFS' = 'Receipt For Service Report Generated',
  'AMG' = 'AMEX GOA Report Generated',
  'AMC' = 'AMEX Credit Report Generated',
  'FCL' = 'Final Close Settlement',
  'TMP' = 'Temp Dispute',
  'MVN' = 'Mainframe VIN Disputes',
  'SDC' = 'Sprint Sponsor Paid Duplicate Call',
  'CDH' = 'Change Duty Indicator to Heavy Duty',
  'CBC' = 'Closed By Club',
  'TPC' = 'Transfer Payment To Club',
  'CCM' = 'Credit Card Number Modified',
  'CRG' = 'Credit Report Generated',
  'GRG' = 'GOA Report Generated',
  'SRG' = 'Service Report Generated',
  'RST' = 'Reset Call',
  'CPR' = 'Change Payment Responsibility',
  'CDM' = 'Change Duty Indicator to Medium Duty',
  'CDL' = 'Change Duty Indicator to Light Duty',
  'CLR' = 'Club Close Message Sent',
  'MNW' = 'Manual New',
  'PND' = 'Pending',
}

const eventDetailFields: DynamicField<CaseEventDetails>[] = [
  {
    label: 'Promised Time of Arrival',
    name: 'promisedTimeArrival',
    type: DynamicFieldType.TEXT,
  },
  {
    label: '150% Promised Time of Arrival',
    name: 'promisedTimeArrivalPlusFiftyPercent',
    type: DynamicFieldType.TEXT,
  },
  {
    label: 'Received to On Location (min)',
    name: 'receivedOnLocation',
    type: DynamicFieldType.TEXT,
  },
  {
    label: 'Dispatched to On Location (min)',
    name: 'dispatchedOnLocation',
    type: DynamicFieldType.TEXT,
  },
  {
    label: 'Exclusion Reason for Actual Time of Arrival',
    name: 'exclusionReason',
    type: DynamicFieldType.TEXT,
  },
  {
    label: 'Reunite',
    name: 'reunite',
    type: DynamicFieldType.CHECKBOX,
  },
  {
    label: 'Un-Associate',
    name: 'unAssociate',
    type: DynamicFieldType.CHECKBOX,
  },
];

export const eventDetailValidationSchema = Yup.object({
  promisedTimeArrival: Yup.string().matches(
    /^(0?[1-9]|1[0-2]):[0-5][0-9]([ ][aApP][mM])?$/,
    'Invalid time format HH:MM AM/PM',
  ),
});

export const eventStatusColumns: DataTableColumn<EventsStatusHistory>[] = [
  {
    id: 'rowIndex',
    accessor: (_, rowIndex) => rowIndex + 1,
    label: 'Row #',
    width: 200,
  },
  {
    label: 'Event Status Abbreviation / Description',
    id: 'eventStatus',
    accessor: (row) => getEventStatusDescription(row.eventStatus),
  },
  {
    id: 'createdAt',
    label: 'Date & Time',
    accessor: (row) => convertUTCToDateTimeString(row.createdAt),
    sortable: true,
  },
  {
    id: 'utcCreatedDateTime',
    label: 'UTC Date & Time',
    accessor: (row) => (
      <Tooltip
        title={`${convertUTCToDateTimeString(
          row.utcCreatedDateTime,
          true,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
        )} in your Time Zone`}
      >
        <span>{convertUTCToDateTimeString(row.utcCreatedDateTime)}</span>
      </Tooltip>
    ),
    sortable: true,
  },
  { label: 'Modified By', id: 'updatedBy' },
];

export function useEventStatusConfig(isEditMode?: boolean) {
  return {
    permissions: useFeaturePermission({ table: CASE_EVENT_STATUS_PERMISSION }),
    eventDetailFields: useFieldPermission({
      fields: eventDetailFields,
      table: CASE_EVENT_STATUS_PERMISSION,
      isEditMode,
    }),
  };
}
