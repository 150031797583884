import { useCallback, useMemo, useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import BreadcrumbHeader, { Breadcrumb } from 'components/BreadCrumbHeader';
import { CRUDComponent } from 'components/CRUDNavigator';
import { CRUDPageableLayout } from 'components/CRUDPageable';
import { ErrorCard, LoaderCard } from 'components/Cards';
import { convertToMMYYYY } from 'utils/convertDate';
import DataTable, { DataTableColumn } from 'components/DataTable';
import {
  usePaymentForecastGetCasesByClub,
  usePaymentForecastsFetchOneQuery,
} from './PaymentForecastsQuery';
import { RbpCaseStatus, RbpCaseStatusDescription } from 'core/enums/RbpCaseStatus';
import AppColors from 'AppColors';
import { CaseData } from 'features/Cases/interfaces/CaseData';
import { convertToCurrency } from 'utils/formatter';

const PaymentForecastsClubDetail: CRUDComponent = () => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('');

  const location = useLocation();
  const params = useParams();
  const search = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const paymentForecastUrl = useMemo(() => {
    const [path] = location.pathname.split('/club/');
    const searchParams = location.search;
    return path + searchParams;
  }, [location.pathname, location.search]);

  const { data: paymentForecastData } = usePaymentForecastsFetchOneQuery({
    startDate: search.get('startPeriod') ?? '',
    endDate: search.get('endPeriod') ?? '',
  });

  const clubName = useMemo(() => {
    const paymentForecast = paymentForecastData?.find(
      (paymentForecast) => paymentForecast.clubUuid === params?.id,
    );

    return `${paymentForecast?.clubCode} - ${paymentForecast?.clubName}`;
  }, [params, paymentForecastData]);

  const { isLoading, data, isError } = usePaymentForecastGetCasesByClub(
    params.uuid || '',
    params.id || '',
    {
      page: currentPage,
      size: rowsPerPage,
    },
  );

  const getPaymentForecastCasesTableColumns = useCallback((): DataTableColumn<CaseData>[] => {
    return [
      {
        label: 'RAP Case Id',
        id: 'rapCaseId',
        sortable: true,
      },
      { id: 'rapProgramCode', label: 'RAP Program Code', sortable: true },
      { id: 'clientName', label: 'Client Name', sortable: true },
      { id: 'programName', label: 'Program Name', sortable: true },
      { id: 'clubCode', label: 'Club Code', sortable: true },
      { id: 'rapCallCreatedDateTime', label: 'Call Date', sortable: true },
      { id: 'rapCallClosedDateTime', label: 'Call Close Date', sortable: true },
      {
        id: 'callStatus',
        label: 'RAP Call Status',
        sortable: true,
      },
      {
        id: 'rbpCaseStatus',
        label: 'RBP Case Status',
        accessor: (row) =>
          row.rbpCaseStatus === RbpCaseStatus.CANCELLED ? (
            <Typography component={'span'} fontWeight={700} color={AppColors.AAA_RED}>
              {RbpCaseStatusDescription[row.rbpCaseStatus]}
            </Typography>
          ) : (
            RbpCaseStatusDescription[row.rbpCaseStatus]
          ),
        sortable: true,
      },
      {
        id: 'totalAmount',
        label: 'Total Amount',
        accessor: (row) => convertToCurrency(row.totalAmount || 0),
      },
    ];
  }, []);

  const createSortHandler = (_: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const paginationOptions = useMemo(() => {
    return {
      rowsCount: data?.totalElements ?? 25,
      currentPage,
      rowsPerPage,
      onPageChange: setCurrentPage,
      onRowsPerPageChange: setRowsPerPage,
      rowsPerPageOptions: [25, 50, 100],
    };
  }, [currentPage, data, rowsPerPage, setRowsPerPage]);

  const breadcrumbs: Breadcrumb[] = [
    {
      url: '..',
      label: 'Payment Forecasts',
    },
    {
      url: paymentForecastUrl,
      label: `Payment Forecast ID ${params.uuid} - ${convertToMMYYYY(
        search.get('startPeriod') ?? '',
      )}`,
    },
    {
      url: '',
      label: clubName,
    },
  ];

  return (
    <CRUDPageableLayout>
      {isLoading && <LoaderCard label="Loading Payment Forecast Club Detail..." sx={{ p: 4 }} />}
      {!isLoading && (
        <Box component={Paper}>
          <BreadcrumbHeader breadcrumbs={breadcrumbs} />
          {isError && (
            <ErrorCard label="An Error Has Occurred While Retrieving Payment Forecast Cases" />
          )}
          {!isError && (
            <DataTable<CaseData>
              columns={getPaymentForecastCasesTableColumns()}
              rows={data?.content ?? []}
              onRowClick={() => null}
              stickyHeader
              stickyOffset={152}
              sortOptions={{ hasSort: true, order, orderBy, createSortHandler }}
              paginationOptions={paginationOptions}
            />
          )}
        </Box>
      )}
    </CRUDPageableLayout>
  );
};

export default PaymentForecastsClubDetail;
