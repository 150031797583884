import React from 'react';
import { LoggedUser } from './interfaces';
import { Role } from 'auth/permissions';

export interface DomainAuthState {
  isStarted?: boolean;
  isIdle?: boolean;
  isLogged?: boolean;
  isLoading?: boolean;
  isDone?: boolean;

  /**
   * Login for external users
   */
  requestNonFederatedLogin: (userEmail: string, userPass: string) => void;

  /**
   * Login for AD users
   */
  requestFederatedLogin: () => void;
  signOut: (ignoreCurrentPath?: boolean) => void;
  user: LoggedUser | null;
  errorMessage: string;

  /**
   * User Group
   */
  userGroup: Role | undefined;
}

export const DomainAuthContext = React.createContext<DomainAuthState>({
  requestNonFederatedLogin: () => null,
  requestFederatedLogin: () => null,
  signOut: () => null,
  errorMessage: '',
  user: null,
  userGroup: undefined,
});
