import { nanoid } from 'nanoid';
import { type DataTableColumn } from './interfaces';

const isParentColumn = (column?: DataTableColumn<any>) => !!column?.columns?.length;

const createGroup = (): { headers: Array<DataTableColumn<any>> } => ({
  headers: [],
});

const BASE_GROUP_INDEX = 0;
const PARENT_GROUP_INDEX = 1;

export type HeaderGroup = {
  headers: DataTableColumn<any>[];
};

export type HeaderGroupList = Array<HeaderGroup>;

export function makeHeaderGroups(columns: DataTableColumn<any>[]): HeaderGroupList {
  const headerGroups = [createGroup(), createGroup()];
  const processQueue = [...columns];
  let nextDefaultParentIndex = 0;

  const hasParentGroups = processQueue.some(isParentColumn);

  while (processQueue.length > 0) {
    const col = processQueue.shift();

    if (!col) break;

    const isParent = isParentColumn(col);

    if (!isParent) {
      headerGroups[BASE_GROUP_INDEX].headers.push(col);

      if (hasParentGroups) {
        let currentHeader = headerGroups[PARENT_GROUP_INDEX].headers[nextDefaultParentIndex];
        currentHeader = currentHeader
          ? { ...currentHeader, colSpan: (currentHeader.colSpan || 0) + 1 }
          : {
              id: `${nanoid()}-default-group`,
              colSpan: 1,
            };

        headerGroups[PARENT_GROUP_INDEX].headers[nextDefaultParentIndex] = currentHeader;
      }
    } else {
      headerGroups[BASE_GROUP_INDEX].headers.push(...col.columns!);
      headerGroups[PARENT_GROUP_INDEX].headers.push({
        ...col,
        columns: undefined,
        colSpan: col.columns?.length ?? 1,
      });
      nextDefaultParentIndex = headerGroups[PARENT_GROUP_INDEX].headers.length;
    }
  }

  return headerGroups.reverse();
}
