/**
 * Extracts the phone extension and phone number from a given full phone number string.
 * @param {string | undefined} fullPhoneNumber - The full phone number string to extract information from.
 * @returns {{ phoneExtension: string | undefined; phoneNumber: string | undefined }} An object containing the phone extension and phone number.
 */
export function extractPhoneParts(fullPhoneNumber: string | undefined): {
  phoneExtension: string | undefined;
  phoneNumber: string | undefined;
} {
  if (!fullPhoneNumber) return { phoneExtension: undefined, phoneNumber: undefined };

  const phonePattern = /^(\d{3}\s)?\d{7}$/;
  const phoneMatch = fullPhoneNumber.match(phonePattern);

  if (phoneMatch) {
    const parts = fullPhoneNumber.split(' ');
    return {
      phoneExtension: parts.length === 2 ? parts[0] : undefined,
      phoneNumber: parts.length === 2 ? parts[1] : parts[0],
    };
  }

  return { phoneExtension: undefined, phoneNumber: undefined };
}
