import InfoIcon from '@mui/icons-material/Info';
import { ReactComponent as BillingIcon } from 'assets/payment.svg';
import { StepConfig } from 'components/FormStepper/types';
import ProgramBillingInfoStep from './ProgramBillingInfoStep';
import ProgramGeneralInfoStep from './ProgramGeneralInfoStep';

const GeneralInfoStepConfig: StepConfig = {
  key: 'GENERAL_INFO',
  label: 'General',
  actionBarLabel: 'General Info',
  icon: InfoIcon,
  component: ProgramGeneralInfoStep,
};

const BillingInfoStepConfig: StepConfig = {
  key: 'BILLING_CONFIG',
  label: 'Billing',
  icon: BillingIcon,
  component: ProgramBillingInfoStep,
};

export const ProgramCreationFormSteps = [GeneralInfoStepConfig, BillingInfoStepConfig];
