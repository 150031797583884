import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';

export enum CaseSearchType {
  CaseId = 'CaseId',
  CallKey = 'CallKey',
}

type CaseSearchTypeDetailsType = {
  [key in CaseSearchType]: {
    label: string;
    filterField: string;
  };
};

export const CaseSearchTypeDetails: CaseSearchTypeDetailsType = {
  [CaseSearchType.CaseId]: {
    label: 'RAP Case Id',
    filterField: 'rapCaseId',
  },
  [CaseSearchType.CallKey]: {
    label: 'Call Key',
    filterField: 'rapCallId',
  },
};

type CaseSearchTypeSelectorProps = {
  value: CaseSearchType;
  onChange: (value: CaseSearchType) => void;
};

export const CaseSearchTypeSelector = ({ value, onChange }: CaseSearchTypeSelectorProps) => {
  return (
    <Box sx={{ mt: 0 }}>
      <RadioGroup
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={(e) => onChange(e.target.value as CaseSearchType)}
      >
        <FormControlLabel
          value={CaseSearchType.CaseId}
          control={<Radio />}
          label={CaseSearchTypeDetails[CaseSearchType.CaseId].label}
        />
        <FormControlLabel
          value={CaseSearchType.CallKey}
          control={<Radio />}
          label={CaseSearchTypeDetails[CaseSearchType.CallKey].label}
        />
      </RadioGroup>
    </Box>
  );
};
