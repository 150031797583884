import { useEffect, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { servicesProvidedValidationSchema } from '../config';
import { CaseServiceProvidedData, TroubleCodeData, TroubleCodes } from '../ServicesProvidedQuery';

export interface ServicesProvidedFormInput {
  servicesProvided: CaseServiceProvidedData[];
}

interface TroubleCodesFormInput {
  troubleCodes: TroubleCodeData[];
}

export function useServicesProvidedForm(
  servicesProvided: CaseServiceProvidedData[],
  troubleCodes: TroubleCodeData[],
) {
  const [totalCost, setTotalCost] = useState<number>(0);

  // Trouble Codes Form
  const troubleCodesHookForm = useForm<TroubleCodesFormInput>({
    defaultValues: { troubleCodes },
  });

  const {
    setValue: troubleCodesSetValue,
    control: troubleCodesControl,
    handleSubmit: troubleCodesHandleSubmit,
    formState: { isDirty: troubleCodesIsDirty },
    reset: troubleCodesReset,
  } = troubleCodesHookForm;

  const { fields: troubleCodesFields } = useFieldArray({
    control: troubleCodesControl,
    name: 'troubleCodes',
  });

  const watchedTroubleCodes = useWatch({
    control: troubleCodesControl,
    name: 'troubleCodes',
  });

  useEffect(() => {
    watchedTroubleCodes.forEach((item, index) => {
      const newDescription =
        TroubleCodes[item.code as keyof typeof TroubleCodes] ||
        troubleCodes.find((troubleCode) => troubleCode.code === item.code)?.description ||
        '';

      if (item.description !== newDescription) {
        troubleCodesSetValue(`troubleCodes.${index}.description`, newDescription);
      }
    });
  }, [troubleCodes, troubleCodesSetValue, watchedTroubleCodes]);

  useEffect(() => {
    troubleCodesReset({ troubleCodes });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [troubleCodesReset]);

  // Services Provided Form
  const servicesProvidedHookForm = useForm<ServicesProvidedFormInput>({
    defaultValues: { servicesProvided },
    resolver: yupResolver(servicesProvidedValidationSchema),
  });

  const {
    setValue: servicesProvidedSetValue,
    control: servicesProvidedControl,
    handleSubmit: servicesProvidedHandleSubmit,
    formState: { isDirty: servicesProvidedIsDirty, dirtyFields: servicesProvidedDirtyFields },
    reset: servicesProvidedReset,
  } = servicesProvidedHookForm;

  const {
    fields: servicesProvidedFields,
    append: servicesProvidedAppend,
    remove: servicesProvidedRemove,
  } = useFieldArray({
    control: servicesProvidedControl,
    name: 'servicesProvided',
  });

  const watchedServicesProvided = useWatch({
    control: servicesProvidedControl,
    name: 'servicesProvided',
    defaultValue: servicesProvided,
  });

  useEffect(() => {
    let sum = 0;

    watchedServicesProvided.forEach((item, index) => {
      if (item.code) {
        const newValue = item.code === 'T6' ? 'ML' : 'EA';
        if (item.unitOfMeasure !== newValue) {
          servicesProvidedSetValue(`servicesProvided.${index}.unitOfMeasure`, newValue);
        }
      }

      const costNumber = Number(item.cost);
      if (!isNaN(costNumber)) {
        sum += costNumber;
      }
    });

    setTotalCost(sum);
  }, [watchedServicesProvided, servicesProvidedSetValue]);

  useEffect(() => {
    servicesProvidedReset({ servicesProvided });
  }, [servicesProvidedReset, servicesProvided]);

  const resetForms = () => {
    troubleCodesReset();
    servicesProvidedReset();
  };

  return {
    totalCost,
    troubleCodesHookForm,
    troubleCodesHandleSubmit,
    troubleCodesIsDirty,
    troubleCodesFields,
    servicesProvidedHookForm,
    servicesProvidedHandleSubmit,
    servicesProvidedIsDirty,
    servicesProvidedDirtyFields,
    servicesProvidedFields,
    servicesProvidedAppend,
    servicesProvidedRemove,
    resetForms,
  };
}
