import { useContext, useEffect, useMemo, useState } from 'react';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Box } from '@mui/material';
import { DynamicExpandablePanel } from 'components/Dynamic';
import { LoaderCard } from 'components/Cards';
import {
  useGetBreakdownLocationByUUID,
  useGetCaseCreditCardByUUID,
  useGetCaseCreditCardReceiptByUUID,
} from '../../queries/manageCaseQueries';
import { CaseDetailsContext } from '../../contexts/CaseDetails';
import { ErrorCard } from 'components/Cards/ErrorCard';
import { AuthComponent } from 'auth/permissions/authComponent';
import { CASE_CREDIT_CARD_PERMISSION } from './config';
import SkeletonLoader from 'components/SkeletonLoader';
import CreditCardErrorsTable from './CreditCardErrorsTable';
import CreditCardView from './CreditCardView';
import CreditCardHeader from './CreditCardHeader';
import { useLocation } from 'react-router-dom';
import { useGetCaseGeneralInfoByUUID } from '../GeneralInfo/GeneralInfoQuery';
import { useCaseServicesProvided } from '../ServicesProvided/ServicesProvidedQuery';
import RefundCreditCardTable from './RefundCreditCardTable';

interface Props {
  caseUUID: string;
}

const CreditCard = ({ caseUUID }: Props) => {
  const { pathname } = useLocation();
  const openDefaultCreditCardPanel = useMemo(() => pathname.includes('credit-cards'), [pathname]);
  const [expanded, setExpanded] = useState<boolean>(Boolean(openDefaultCreditCardPanel));
  const { data, isLoading, isFetching, isError } = useGetCaseCreditCardByUUID(caseUUID, expanded);
  const { allSectionsExpanded } = useContext(CaseDetailsContext);
  const { data: receiptData } = useGetCaseCreditCardReceiptByUUID(caseUUID, expanded);
  const { data: generalInfoData } = useGetCaseGeneralInfoByUUID(caseUUID, expanded);

  const { breakdownLocationDto, caseFinancialItems } = receiptData || {};

  useEffect(() => {
    setExpanded(allSectionsExpanded || openDefaultCreditCardPanel);
  }, [allSectionsExpanded]);

  const onExpanded = (isExpanded: boolean) => () => {
    setExpanded(!isExpanded);
  };

  const isRunning = isFetching || isLoading;

  return (
    <DynamicExpandablePanel
      containerSX={{ p: '16px 0' }}
      title="Credit Card"
      icon={<CreditCardIcon />}
      onExpanded={onExpanded(expanded)}
      expanded={expanded}
    >
      <Box sx={{ padding: '8px 32px' }}>
        {isRunning && <LoaderCard label="Loading Credit Card Information" />}

        {isError && (
          <ErrorCard label="An Error Has Occurred While Retrieving Credit Card Information" />
        )}
        {!data && !isRunning && <ErrorCard label="No data found" />}
      </Box>

      {!isRunning && data && (
        <>
          <CreditCardHeader
            statusCode={data.transactionStatus}
            data={data}
            breakdownLocation={breakdownLocationDto}
            generalInfo={generalInfoData}
            caseFinancialItems={caseFinancialItems}
            receiptData={receiptData}
          />
          <CreditCardView data={data} />
          {!!data?.creditCardErrorMessage?.length && (
            <CreditCardErrorsTable rows={data.creditCardErrorMessage} />
          )}
          <RefundCreditCardTable data={data} />
        </>
      )}
    </DynamicExpandablePanel>
  );
};

export default AuthComponent(
  CASE_CREDIT_CARD_PERMISSION,
  CreditCard,
  <SkeletonLoader loading height={66} variant="rectangular" />,
);
