import { ReactNode, SyntheticEvent, useMemo } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { SxProps, Theme } from '@mui/material';
import {
  StyledContainerAccordion,
  StyledEditButton,
  StyledHorizRow,
  StyledRightPanel,
  StyledAccordionDetails,
  StyledSummaryIconBox,
} from './DynamicExpandablePanel.styles';

interface ExpandablePanelProps {
  title: string;
  icon?: ReactNode;
  children?: ReactNode;
  defaultExpanded?: boolean;
  onExpanded?: () => void;
  expanded?: boolean;
  actions?: Partial<{
    onEdit?: () => void;
    isEditMode?: boolean;
  }>;
  leftPanel?: ReactNode;
  editOptions?: Partial<ExpandablePanelEditOptions>;
  isEditingSection?: boolean;
  containerSX?: SxProps<Theme>;
}

export interface ExpandablePanelEditOptions {
  disabled: boolean;
  tooltip: string;
}

export function DynamicExpandablePanel({
  title,
  icon,
  children,
  onExpanded,
  expanded,
  actions = {},
  leftPanel,
  editOptions = {},
  isEditingSection = false,
  containerSX,
  ...otherProps
}: ExpandablePanelProps) {
  const { onEdit, isEditMode = false } = actions;
  const { disabled, tooltip } = editOptions;
  const isEditable = !!onEdit;

  const isSectionDisabled = useMemo(
    () => disabled || isEditingSection,
    [disabled, isEditingSection],
  );

  const handleEdit = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onEdit?.();
    if (!expanded) {
      onExpanded?.();
    }
  };

  return (
    <StyledContainerAccordion
      onChange={onExpanded}
      expanded={expanded}
      isEditMode={isEditMode}
      {...otherProps}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ alignItems: 'center', height: 66 }}>
        <Box display="flex" alignItems="center">
          {icon && <StyledSummaryIconBox>{icon}</StyledSummaryIconBox>}
          <Typography variant="h6" sx={{ fontWeight: '700' }}>
            {title}
          </Typography>
          <Box sx={{ ml: 1 }}>{leftPanel}</Box>
        </Box>

        <StyledRightPanel>
          {isEditable && expanded && (
            <Tooltip title={tooltip || ''}>
              <div>
                <StyledEditButton
                  aria-label="Edit"
                  onClick={handleEdit}
                  isEditMode={isEditMode}
                  data-testid="EditButton"
                  disabled={isSectionDisabled}
                >
                  <ModeEditIcon />
                </StyledEditButton>
              </div>
            </Tooltip>
          )}
        </StyledRightPanel>
      </AccordionSummary>

      <StyledHorizRow />
      <StyledAccordionDetails sx={containerSX}>{children}</StyledAccordionDetails>
    </StyledContainerAccordion>
  );
}
