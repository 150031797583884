import { CRUDPermissions } from '../types';

export function revalidatedPermissions(
  fromTable: CRUDPermissions,
  fromFields: CRUDPermissions,
): CRUDPermissions {
  return Object.assign(fromTable, {
    create: fromFields.create || fromTable.create,
    read: fromFields.read || fromTable.read,
    update: fromFields.update || fromTable.update,
    delete: fromFields.delete || fromTable.delete,
  });
}
