import { SetupNavigator } from '../components';
import ProgramPageView from './ProgramPageView';
import ProgramPageCreator from './ProgramPageCreator';
import ProgramProvider from './ProgramProvider';
import ProgramPagePricing from './ProgramPagePricing';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { routes } from 'routes';

const ProgramsPage: React.FC = () => {
  const { clientUUID } = useParams();
  const mainRoute = useMemo(() => `${routes.setup.clients}/${clientUUID}`, [clientUUID]);
  return (
    <ProgramProvider>
      <SetupNavigator
        components={{
          Individual: ProgramPageView,
          Creator: ProgramPageCreator,
          Pricing: ProgramPagePricing,
        }}
        routes={{ MAIN_SCREEN: `.${mainRoute}` }}
      />
    </ProgramProvider>
  );
};

export default ProgramsPage;
