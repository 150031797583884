import Typography from '@mui/material/Typography';
import { FullCenteredCard, FullCenteredCardProps } from 'components/Cards';
import { ReactComponent as SearchEmpty } from 'assets/search-empty.svg';

export function EmptySearchCard({ ...props }: FullCenteredCardProps) {
  return (
    <FullCenteredCard {...props}>
      <SearchEmpty />
      <Typography variant="h5" sx={{ fontWeight: 500, pt: 3 }}>
        No Results Found
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: 500, pt: 2 }}>
        Try adjusting your search filters to see case results
      </Typography>
    </FullCenteredCard>
  );
}
