import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import DialogConfirmation from 'components/DialogConfirmation';
import { DynamicForm } from 'components/Dynamic/DynamicForm';
import { useDomainAuth } from 'auth/domain-auth/useDomainAuth';

import { useAddAdjustmentConfig, AddAdjustmentForm } from './helpers';
import { useAddAdjustmentCases } from 'features/Cases/queries';

interface CaseAddAdjustmentDialogProps {
  caseDataUuid: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  defaultAdjustmentValues: Partial<AddAdjustmentForm>;
  onConfirmAddInvoice: () => void;
}

export default function CaseAddAdjustmentDialog({
  visible,
  setVisible,
  caseDataUuid,
  defaultAdjustmentValues,
  onConfirmAddInvoice,
}: CaseAddAdjustmentDialogProps) {
  const { isError, mutateAsync, isLoading } = useAddAdjustmentCases(caseDataUuid);
  const { user } = useDomainAuth();

  const { addAdjustmentFields, addAdjustmentValidationSchema } = useAddAdjustmentConfig();

  const hookForm = useForm<AddAdjustmentForm>({
    resolver: yupResolver(addAdjustmentValidationSchema),
    defaultValues: defaultAdjustmentValues,
    mode: 'onChange',
  });

  const handleSubmit = async () => {
    await hookForm.trigger();
    if (hookForm.formState.isValid && user) {
      mutateAsync(hookForm.getValues())
        .then(() => {
          setVisible(false);
          onConfirmAddInvoice();
        })
        .catch();
    }
  };

  const onClose = () => {
    setVisible(false);
    hookForm.reset(defaultAdjustmentValues);
  };

  return (
    <DialogConfirmation
      open={visible}
      maxWidth="xl"
      title="Add Adjustment"
      onClose={onClose}
      onConfirm={handleSubmit}
      labelConfirm="Add Adjustment"
      labelCancel="Cancel"
      isLoading={isLoading}
      sx={{
        '& .MuiDialog-paper': {
          width: 'fit-content',
          maxWidth: 980,
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <DynamicForm hookForm={hookForm} isEditMode fields={addAdjustmentFields} rowSpacing={3} />
        {isError && <Typography>An Error Has Occurred While Adding Adjustment</Typography>}
      </Box>
    </DialogConfirmation>
  );
}
