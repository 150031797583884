import BillingInformationSection from 'features/Setup/BillingInformation';
import { useProgramContext } from '../ProgramProvider';
import { ProgramBillingInfoForm } from './ProgramBillingInfoForm';
import {
  useGetProgramBillingConfig,
  useCreateProgramBillingConfig,
  useUpdateProgramBillingConfig,
  useGetDefaultProgramBillingInfoData,
  BillingConfigurationProgramData,
} from '../ProgramQuery';
import { BillingConfigurationProgramDto } from 'api/setup/manageProgramsApi';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';
import { ProgramBillingInfoValidationSchema } from './fields';

interface Props {
  entityUUID: string;
}

export function ProgramBillingInfo({ entityUUID }: Props) {
  const { isEditingSection, setIsEditingSection, queries } = useProgramContext();
  const { permissions } = useRapCoreFeaturePermissions('programs');
  const { data: entityData } = queries.useFetchByUUID(entityUUID || '');

  return (
    <BillingInformationSection<BillingConfigurationProgramData, BillingConfigurationProgramDto>
      entityUUID={entityUUID}
      title="Billing Information"
      formContent={(hookForm, isEditMode) => (
        <ProgramBillingInfoForm hookForm={hookForm} isEditMode={isEditMode} />
      )}
      validationSchema={ProgramBillingInfoValidationSchema}
      isEditingSection={isEditingSection}
      setIsEditingSection={setIsEditingSection}
      useGetBillingConfig={useGetProgramBillingConfig}
      useCreateBillingConfig={useCreateProgramBillingConfig}
      useUpdateBillingConfig={useUpdateProgramBillingConfig}
      useGetDefaultBillingInfoData={useGetDefaultProgramBillingInfoData}
      readOnly={!permissions.update}
      entityData={entityData}
      fieldsToUpdateInEntity={['allowSplitPay']}
    />
  );
}
