import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { NotificationType } from './types';
import { forwardRef } from 'react';
import { Typography } from '@mui/material';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface Props {
  autoHideDuration?: number;
  notification?: NotificationType;
  clearNotification: () => void;
}

export function NotificationSnackbar({
  autoHideDuration = 10000,
  notification,
  clearNotification,
}: Props) {
  const open = !!notification;

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    clearNotification();
  };

  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity={notification?.type}
        sx={{ width: '100%', height: 100, overflow: 'hidden' }}
      >
        <Typography variant={'body1'}>{notification?.name}</Typography>
        <Typography variant={'body2'}>{notification?.message}</Typography>
      </Alert>
    </Snackbar>
  );
}
