import { Typography } from '@mui/material';
import moment from 'moment';
import AppColors from 'AppColors';
import { DataTableColumn } from 'components/DataTable';
import { StyledTextLink } from 'components/DataTable/DataTable.styled';
import { RbpCaseStatus, RbpCaseStatusDescription } from 'core/enums/RbpCaseStatus';
import { CaseData } from 'features/Cases/interfaces/CaseData';
import { BillingRequestData } from '../types';
import { BillingRequestLevel } from 'api/invoices';
import { convertToCurrency } from 'utils/formatter';

export const getAttachedCasesColumns = (
  billingRequestData: BillingRequestData,
): DataTableColumn<CaseData>[] => {
  return [
    ...(billingRequestData.billingLevel === BillingRequestLevel.SERVICE_LEVEL
      ? [
          {
            id: 'expandable' as keyof CaseData,
            label: '',
          },
        ]
      : []),
    {
      label: 'RAP Case Id',
      id: 'rapCaseId',
      testId: 'rapCaseId',
      accessor: (row: CaseData) => {
        return (
          <StyledTextLink variant="body2">{row.rapCaseId || 'Missing Case ID'}</StyledTextLink>
        );
      },
      sortable: true,
    },
    { id: 'rapProgramCode', label: 'RAP Program Code', sortable: true, testId: 'rapProgramCode' },
    { id: 'clientName', label: 'Client Name', sortable: true },
    { id: 'programName', label: 'Program Name', sortable: true },
    { id: 'clubCode', label: 'Club Code', sortable: true },
    {
      id: 'rapCallCreatedDateTime',
      label: 'Call Date',
      sortable: true,
      accessor: (row) =>
        moment(new Date(row.rapCallCreatedDateTime)).isValid()
          ? moment(new Date(row.rapCallCreatedDateTime)).format('MM/DD/YYYY')
          : row.rapCallCreatedDateTime,
    },
    {
      id: 'rapCallClosedDateTime',
      label: 'Call Close Date',
      accessor: (row) =>
        moment(new Date(row.rapCallClosedDateTime)).isValid()
          ? moment(new Date(row.rapCallClosedDateTime)).format('MM/DD/YYYY')
          : row.rapCallClosedDateTime,
    },
    {
      id: 'callStatus',
      label: 'RAP Call Status',
    },
    {
      id: 'rbpCaseStatus',
      label: 'RBP Case Status',
      testId: 'rbpCaseStatus',
      accessor: (row: CaseData) =>
        row.rbpCaseStatus === RbpCaseStatus.CANCELLED ? (
          <Typography component={'span'} fontWeight={700} color={AppColors.AAA_RED}>
            {RbpCaseStatusDescription[row.rbpCaseStatus]}
          </Typography>
        ) : (
          RbpCaseStatusDescription[row.rbpCaseStatus]
        ),
    },
    ...(billingRequestData.billingLevel === BillingRequestLevel.SERVICE_LEVEL
      ? [
          {
            id: 'totalAmount' as keyof CaseData,
            label: 'Total Amount',
            accessor: (row: CaseData) => {
              const sum = row.financialItems?.reduce((sum, item) => sum + item.totalAmount, 0);
              return convertToCurrency(sum);
            },
          },
        ]
      : []),
  ];
};
