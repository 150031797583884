import { fetchCaseRejects } from 'api/cases/manageCasesApi';
import { useMappedReactQuery } from 'hooks/useReactQueryHelpers';
import { CaseRejectData } from './types';
import { CaseRejectDto } from 'api/cases/interfaces/CaseRejectDto';

function mapper(dto: CaseRejectDto): CaseRejectData {
  return dto;
}

export default function useCaseRejects(uuid: string, enabled = true) {
  return useMappedReactQuery('QUERY_CASE_REJECTS', fetchCaseRejects, { enabled, mapper }, uuid);
}
