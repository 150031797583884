import { Controller } from 'react-hook-form';
import { ControlFieldProps } from '../types';
import ChipInput, { ChipInputProps } from 'components/ChipInput/ChipInput';
import { FormControl, InputLabel, styled } from '@mui/material';
import AppColors from 'AppColors';

export type ControlledChipInputProps = ControlFieldProps &
  ChipInputProps<string> & {
    error?: boolean;
    helperText?: string;
    label: string;
    required?: boolean;
  };

const CustomInputLabel = styled(InputLabel)`
  font-weight: 600;
  color: ${AppColors.AAA_BLUE};
  background-color: white;
  padding-left: 4px;
  padding-right: 4px;
`;

const ControlledChipInput = ({
  control,
  name,
  rules,
  label,
  readOnly,
  options,
  required,
}: ControlledChipInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, ...field } }) => {
        return (
          <FormControl fullWidth>
            <CustomInputLabel id={`${name}-${label}`} size="small" shrink>
              {required && !readOnly ? `${label} *` : label}
            </CustomInputLabel>
            <ChipInput {...field} id={name} readOnly={readOnly} options={options} />
          </FormControl>
        );
      }}
    />
  );
};

export default ControlledChipInput;
