import { Skeleton, SkeletonProps, SxProps } from '@mui/material';
import AppColors from 'AppColors';
import { PropsWithChildren } from 'react';

const defaultStyles: SxProps = { bgcolor: AppColors.AAA_LOADER_GREY, borderRadius: 1 };

export type SkeletonLoaderProps = PropsWithChildren<{
  loading?: boolean;
  className?: string;
  animate?: boolean;
}> &
  Omit<SkeletonProps, 'loading' | 'children' | 'className' | 'animation'>;

export default function SkeletonLoader(props: SkeletonLoaderProps) {
  const { loading = false, children, animate = true, className, ...restProps } = props;

  if (!loading) {
    return <>{children}</>;
  }

  return (
    <Skeleton
      animation={animate ? 'pulse' : false}
      className={className}
      sx={defaultStyles}
      {...restProps}
    />
  );
}
