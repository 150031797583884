import moment from 'moment';
import { Box, Grid } from '@mui/material';
import { ControlledCheckbox } from 'components/ControlledForm/ControlledCheckbox';
import { ControlledSelect } from 'components/ControlledForm/ControlledSelect';
import { ControlledTextField } from 'components/ControlledForm/ControlledTextField';
import { ControlledSingleDatePicker } from 'components/ControlledForm/ControlledSingleDatePicker';
import { ControlledRangeDatePicker } from 'components/ControlledForm/ControlledRangeDatePicker';
import { UseFormReturn } from 'react-hook-form';
import React from 'react';
import { ControlledImageUpload } from 'components/ControlledForm/ControlledImageUpload';
import { FileUploadInputElement } from 'components/ControlledForm/ControlledImageUpload/types';
import { ControlledChipInput } from 'components/ControlledForm/ControlledChipInput';
import FormInputTooltip, { TooltipCategories } from 'components/Form/FormInputTooltip';
import { ControlledCurrencyInput } from 'components/ControlledForm/ControlledCurrencyInput';
import { OpenDirectionShape } from 'react-dates';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DynamicValue = Record<string, any>;

export enum DynamicFieldType {
  TEXT = 'TEXT',
  CHECKBOX = 'CHECKBOX',
  SELECT = 'SELECT',
  DATE = 'DATE',
  RANGE_DATE = 'RANGE_DATE',
  HIDDEN = 'HIDDEN',
  IMAGE_UPLOAD = 'IMAGE_UPLOAD',
  CHIP_INPUT = 'CHIP_INPUT',
  CURRENCY = 'CURRENCY',
}

export interface DynamicField<T extends DynamicValue> {
  label: string;
  /**
   * Required for all field types but IMAGE_UPLOAD.
   */
  name: keyof T;
  type?: DynamicFieldType;
  getType?: (operatorValue: string) => DynamicFieldType;
  /**
   * Field length in columns. (Max. 12 columns)
   */
  colspan?: number;
  /**
   * property for select types
   */
  select?: {
    options: {
      label: string;
      value: string | number | boolean;
    }[];
    multiple?: boolean;
    type?: 'chip';
  };
  /**
   * property for chipInput types
   */
  chipInput?: {
    maxLength?: {
      value: number;
      message: string;
    };
    validation?: {
      regex: RegExp;
      message: string;
    };
  };
  fileInput?: { icon?: JSX.Element; options?: FileUploadInputElement };
  /**
   * Declare field as readonly and can't be edited
   * at EditMode.
   */
  isReadOnly?: boolean;
  startNewRow?: boolean;
  /**
   * Add a chip with hover tooltip at the end of the input field
   */
  tooltip?: TooltipCategories;
  /**
   * Add props for multiline text field
   */
  multiline?: boolean;
  rows?: number;
  /**
   * Add props for DatePickers
   */
  openDirection?: OpenDirectionShape;
  required?: boolean;
  /**
   * Add props for Text fields
   */
  endAdornment?: JSX.Element;
  isOutsideRange?: (day: moment.Moment) => boolean;
  /**
   * Add a testID for testing purposes
   */
  testID?: string;
}

interface DynamicFormProps<T extends DynamicValue> {
  fields: DynamicField<T>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hookForm: UseFormReturn<any>;
  isEditMode?: boolean;
  // define general column span for items or set to true for auto layout
  colspan?: number | boolean;
  rowSpacing?: number;
  className?: string;
}

export function DynamicForm<T extends DynamicValue>({
  fields,
  hookForm,
  isEditMode = false,
  colspan = 3,
  rowSpacing = 2,
  className,
}: DynamicFormProps<T>) {
  const {
    control,
    formState: { errors },
  } = hookForm;

  return (
    <Grid container rowSpacing={rowSpacing} columnSpacing={2} className={className}>
      {fields.map((item, index) => {
        const name = item.name.toString();
        const key = `${String(name)}-${index}`;
        const readOnly = !isEditMode || item.isReadOnly;
        const error = !!errors[name];
        const errorMessage = errors[name]?.message?.toString();
        const fileInputProps = item.fileInput;
        const tooltipCategory = item.tooltip as TooltipCategories;

        return (
          <React.Fragment key={key}>
            {item.startNewRow && <Box width="100%" />}
            <Grid xs={item.colspan || colspan} item>
              {
                {
                  [DynamicFieldType.TEXT]: (
                    <ControlledTextField
                      control={control}
                      id={name}
                      name={name}
                      label={item.label}
                      readOnly={readOnly}
                      isEditMode={isEditMode}
                      fullWidth
                      error={error}
                      helperText={errorMessage}
                      Tooltip={
                        tooltipCategory && (
                          <FormInputTooltip category={tooltipCategory} position="end" />
                        )
                      }
                      multiline={item.multiline}
                      rows={item.rows}
                      required={item.required}
                      endAdornment={item.endAdornment}
                      data-testid={item.testID}
                    />
                  ),
                  [DynamicFieldType.CHECKBOX]: (
                    <ControlledCheckbox
                      control={control}
                      id={name}
                      name={name}
                      label={item.label}
                      readOnly={readOnly}
                      fullWidth
                      error={error}
                      helperText={errorMessage}
                    />
                  ),
                  [DynamicFieldType.SELECT]: (
                    <ControlledSelect
                      control={control}
                      id={name}
                      name={name}
                      label={item.label}
                      readOnly={readOnly}
                      isEditMode={isEditMode}
                      fullWidth
                      options={item.select?.options}
                      error={error}
                      helperText={errorMessage}
                      required={item.required}
                      multiple={item.select?.multiple}
                      type={item.select?.type}
                      Tooltip={
                        tooltipCategory && (
                          <FormInputTooltip category={tooltipCategory} position="start" />
                        )
                      }
                    />
                  ),
                  [DynamicFieldType.DATE]: (
                    <ControlledSingleDatePicker
                      control={control}
                      id={name}
                      name={name}
                      label={item.label}
                      readOnly={readOnly}
                      error={error}
                      helperText={errorMessage}
                      openDirection={item.openDirection}
                      required={item.required}
                      isOutsideRange={item.isOutsideRange}
                    />
                  ),
                  [DynamicFieldType.RANGE_DATE]: (
                    <ControlledRangeDatePicker
                      control={control}
                      id={name}
                      name={name}
                      label={item.label}
                      readOnly={readOnly}
                      error={error}
                      helperText={errorMessage}
                      required={item.required}
                    />
                  ),
                  [DynamicFieldType.IMAGE_UPLOAD]: (
                    <ControlledImageUpload
                      control={control}
                      id={name}
                      name={name}
                      label={item.label}
                      readOnly={readOnly}
                      icon={fileInputProps?.icon}
                      {...fileInputProps?.options}
                    />
                  ),
                  [DynamicFieldType.CHIP_INPUT]: (
                    <ControlledChipInput
                      control={control}
                      id={name}
                      name={name}
                      label={item.label}
                      readOnly={readOnly}
                      error={error}
                      helperText={errorMessage}
                      options={item.chipInput}
                      required={item.required}
                    />
                  ),
                  [DynamicFieldType.CURRENCY]: (
                    <ControlledCurrencyInput
                      control={control}
                      id={name}
                      name={name}
                      label={item.label}
                      readOnly={readOnly}
                      error={error}
                      helperText={errorMessage}
                      required={item.required}
                    />
                  ),
                  [DynamicFieldType.HIDDEN]: null,
                }[item.type || DynamicFieldType.TEXT]
              }
            </Grid>
          </React.Fragment>
        );
      })}
    </Grid>
  );
}
