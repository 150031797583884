import { PropsWithChildren } from 'react';
import { CRUDPageableProvider, usePageableContextData } from 'components/CRUDPageable';
import { CRUDPageableReactQuery } from 'hooks/useReactQueryHelpers';
import { ManageServiceItemsApi, ServiceItemsDto } from 'api/setup/manageServiceItemsApi';
import { ServiceItemsData } from './types';

const ServiceItemsQuery = CRUDPageableReactQuery('QUERY_SERVICE_ITEMS', ManageServiceItemsApi);

export function useServiceItemsContext() {
  return usePageableContextData<ServiceItemsDto, ServiceItemsData>();
}

export default function ServiceItemsProvider({ children }: PropsWithChildren) {
  return <CRUDPageableProvider queries={ServiceItemsQuery}>{children}</CRUDPageableProvider>;
}
