import { styled, Theme, Box } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { PropsWithChildren } from 'react';
import DrawerMenuSection from './DrawerMenuSection';

import { CSSObject } from '@mui/system';
import { useActiveItem } from 'layouts/AppLayout/AppDrawerItems';
import DrawerMenuItem from './DrawerMenuItem';

export type DrawerItem = {
  path?: string;
  title: string;
  icon?: JSX.Element;
  action?: () => void;
  redirect?: boolean;
  children?: Omit<DrawerItem, 'children'>[];
  notAllowedRoles?: string[];
};

export type DrawerSection = {
  label: string;
  icon?: JSX.Element;
  items: DrawerItem[];
};

export type AppDrawerProps = PropsWithChildren<{
  segments?: Array<DrawerSection | DrawerItem>;
  open?: boolean;
}>;

function isSection(segment: DrawerSection | DrawerItem): segment is DrawerSection {
  return !!(segment as DrawerSection).items;
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: theme.AppDrawer.width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: theme.AppDrawer.collapsedWidth,
});

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: theme.AppDrawer.width,
  flexShrink: 0,
  whiteSpace: 'nowrap',

  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  }),
}));

const DrawerHeader = styled(Box)(({ theme }) => ({
  marginBottom: '14px',
  display: 'flex',
  alignItems: 'center',
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function AppDrawer({ open, segments }: AppDrawerProps) {
  const activeMenuItem = useActiveItem();
  return (
    <Drawer
      data-testid={`AppDrawer${open ? 'Expanded' : 'Collapsed'}`}
      open={open}
      variant="permanent"
      anchor="left"
    >
      <DrawerHeader />

      {segments?.map((segment) => {
        return isSection(segment) ? (
          <DrawerMenuSection
            section={segment}
            open={open}
            activeMenuItem={activeMenuItem}
            key={segment.label}
          />
        ) : (
          <DrawerMenuItem
            key={segment.title}
            menuItem={segment}
            isCollapsed={!open}
            isActive={activeMenuItem && activeMenuItem.path === segment.path}
          />
        );
      })}
    </Drawer>
  );
}
