import VendorPageCreator from './VendorPageCreator';
import VendorPageList from './VendorPageList';
import VendorPageView from './VendorPageView';
import VendorProvider from './VendorProvider';
import VendorPagePricing from './VendorPagePricing';
import { SetupNavigator } from '../components';

const VendorPage = () => {
  return (
    <VendorProvider>
      <SetupNavigator
        components={{
          Main: VendorPageList,
          Individual: VendorPageView,
          Creator: VendorPageCreator,
          Pricing: VendorPagePricing,
        }}
      />
    </VendorProvider>
  );
};

export default VendorPage;
