import { DynamicForm } from 'components/Dynamic';
import FormDivider from 'components/Form/FormDivider';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  InboundContactFields,
  ClientContactFields,
  LogoFields,
  useSurveyInfoFields,
  useClientGeneralInfoFields,
} from './fields';
import { useEffect } from 'react';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hookForm: UseFormReturn<any>;
  isEditMode?: boolean;
  isCreationMode?: boolean;
}

export const ClientForm = ({ hookForm, isEditMode, isCreationMode = false }: Props) => {
  const { control, setValue } = hookForm;
  const watchSurvey = useWatch({ control, name: 'survey' });
  const SurveyInfoFields = useSurveyInfoFields({ isSurvey: watchSurvey });
  const clientGeneralInfoFields = useClientGeneralInfoFields(isCreationMode);

  useEffect(() => {
    if (!watchSurvey) {
      setValue('surveyType', null);
      setValue('surveyAdministrator', '');
    }
  }, [watchSurvey, setValue]);

  return (
    <>
      <FormDivider label="Information" sx={{ mt: 1, mb: 3 }} />
      <DynamicForm
        hookForm={hookForm}
        fields={clientGeneralInfoFields}
        colspan={true}
        isEditMode={isEditMode}
      />
      <FormDivider label="Logo" sx={{ mt: 1, mb: 3 }} />
      <DynamicForm hookForm={hookForm} fields={LogoFields} colspan={true} isEditMode={isEditMode} />
      <FormDivider label="Survey Info" sx={{ mt: 1, mb: 3 }} />
      <DynamicForm
        hookForm={hookForm}
        fields={SurveyInfoFields}
        colspan={3}
        isEditMode={isEditMode}
      />
      <FormDivider label="Inbound Contact Center Details" sx={{ mt: 1, mb: 3 }} />
      <DynamicForm hookForm={hookForm} fields={InboundContactFields} isEditMode={isEditMode} />
      <FormDivider label="Client Contact Information" sx={{ mt: 3, mb: 3 }} />
      <DynamicForm hookForm={hookForm} fields={ClientContactFields} isEditMode={isEditMode} />
    </>
  );
};
