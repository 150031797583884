import { PageableResponse } from 'api/interfaces/responses';
import { EntityTypes } from 'api/setup/manageServicesSetupApi';
import { UseMutationResult, UseQueryOptions, UseQueryResult } from 'react-query';

export interface PageableParams {
  page: number;
  size: number;
  entityType?: EntityTypes;
  entityUuid?: string;
  startDateTime?: string;
  endDateTime?: string;
  status?: string;
  filter?: string;
  sort?: string;
}

export interface ReactQueryOptions<
    SourceType,
    ResponseType,
    ErrorType = unknown,
    MapperContentType = SourceType,
  >
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extends Omit<UseQueryOptions<SourceType, ErrorType, SourceType, any[]>, 'queryKey' | 'queryFn'> {
  mapper?: (content: MapperContentType) => ResponseType;
}

interface BaseMappedQueryResult<SourceType, ResponseType> {
  data?: ResponseType;
  rawData?: SourceType;
  isRunning: boolean;
  isEmpty: boolean;
  isDone: boolean;
  errorCode?: ErrorData;
}

export enum ErrorData {
  // request errors
  BadRequest,
  Unauthorized,
  Forbidden,
  NotFound,
  // server errors
  ServerError,
  // undefined
  Undefined,
}

export type MappedQueryResult<SourceType, ResponseType> = Omit<UseQueryResult<SourceType>, 'data'> &
  BaseMappedQueryResult<SourceType, ResponseType>;

export type PageableReactQueryResult<SourceType, ResponseType> = Omit<
  UseQueryResult<SourceType>,
  'data'
> &
  BaseMappedQueryResult<SourceType, ResponseType> & { rowsCount: number; data: ResponseType };

export type FetchOptions = {
  enabled?: boolean;
  refetchOnMount?: boolean | 'always';
};

export type FetchAllMethod<DtoType, DataType> = (
  params: PageableParams,
  options?: FetchOptions,
) => PageableReactQueryResult<PageableResponse<DtoType>, DataType[]>;
export type FetchByUUIDMethod<DtoType, DataType> = (
  uuid?: string,
) => MappedQueryResult<DtoType, DataType>;
export type CreateMethod<DtoType, DataType> = () => UseMutationResult<
  DtoType,
  unknown,
  DataType,
  unknown
>;
export type UploadMethod<DtoType, DataType> = () => UseMutationResult<
  DtoType | undefined,
  unknown,
  DataType,
  unknown
>;

export type UpdateByUUIDMethod<DtoType, DataType> = (
  uuid: string,
) => UseMutationResult<DtoType, unknown, DataType, unknown>;
export type DeleteByUUIDMethod = () => UseMutationResult<void, unknown, string, unknown>;
export type InvalidateAllMethod = () => () => void;
export type ClearCacheMethod = () => () => void;

export interface CRUDPageableReactQueryType<DtoType, DataType> {
  useFetchAll: FetchAllMethod<DtoType, DataType>;
  useFetchByUUID: FetchByUUIDMethod<DtoType, DataType>;
  useCreate: CreateMethod<DtoType, DataType>;
  useUpdateByUUID: UpdateByUUIDMethod<DtoType, DataType>;
  useDeleteByUUID: DeleteByUUIDMethod;
  useInvalidateAll: InvalidateAllMethod;
  useClearCache: ClearCacheMethod;
  baseKey: string;
}
