import { Box, CircularProgress, IconButton, Paper, Stack, Tooltip } from '@mui/material';
import DataTable, { DataTableColumn } from 'components/DataTable';
import { NotificationSnackbar, NotificationType } from 'components/Notifications';
import { useMemo, useState } from 'react';
import { ReportData } from './types';
import DownloadIcon from '@mui/icons-material/Download';
import CachedIcon from '@mui/icons-material/Cached';
import { useGetReportsByNumber } from './ReportsQuery';
import { ErrorResponseCard, LoaderCard } from 'components/Cards';
import {
  usePaymentForecastsDownloadOneReports,
  useRegenerateReports,
} from 'features/PaymentForecasts/PaymentForecastsQuery';
import { downloadReportFile } from './utils';
import BreadcrumbHeader, { Breadcrumb } from 'components/BreadCrumbHeader';
import { useParams } from 'react-router-dom';
import { CRUDPageableLayout } from 'components/CRUDPageable';
import { convertToMMYYYY, convertUTCToDateTimeString } from 'utils/convertDate';

export default function ReportsDetail() {
  const params = useParams();
  const search = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [notifications, setNotifications] = useState<NotificationType>();
  const [selectedReport, setSelectedReport] = useState<string>();
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('');

  const [rowsPerPage, setRowsPerPage] = useState<number>(300);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const reportsUrl = useMemo(() => {
    const [path] = location.pathname.split('/report/');
    const searchParams = location.search;
    return path + searchParams;
  }, [location.pathname, location.search]);

  const { data, isLoading, isError, errorCode, refetch } = useGetReportsByNumber(
    {
      reportNumber: params.id as string,
      startPeriod: search.get('startPeriod') ?? '',
      endPeriod: search.get('endPeriod') ?? '',
    },
    {
      page: currentPage,
      size: rowsPerPage,
    },
  );

  const { mutateAsync: downloadReport, isLoading: isLoadingDownloadReport } =
    usePaymentForecastsDownloadOneReports();

  const { mutateAsync: regenerateReport, isLoading: isLoadingRegenerateReport } =
    useRegenerateReports();

  const createSortHandler = (_: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const paginationOptions = useMemo(() => {
    return {
      rowsCount: data?.length ?? 25,
      currentPage,
      rowsPerPage,
      onPageChange: setCurrentPage,
      onRowsPerPageChange: setRowsPerPage,
      rowsPerPageOptions: [25, 50, 100, 150, 200, 250, 300],
    };
  }, [currentPage, data, rowsPerPage, setRowsPerPage]);

  const handleDownloadReport = (report: ReportData) => {
    downloadReport(report.reportUuid)
      .then((res: any) => {
        const fileName = report.s3Path.split('/').pop() ?? '';

        const downloadBody = {
          res,
          reportName: report.reportName,
          setNotifications,
          fileType: 'text/csv',
          fileName: fileName,
        };
        downloadReportFile(downloadBody);
      })
      .catch(() => {
        setNotifications({
          name: `Download ${report.reportName} Report`,
          type: 'error',
          message: `The ${report.reportName} report has not been downloaded, try again later`,
        });
      });
  };

  const handleRegenerateReport = (reportNumber: string, s3Path: string) => {
    regenerateReport({ number: reportNumber, s3Path })
      .then((res: any) => {
        setNotifications({
          name: `Regenerated Report ${reportNumber}`,
          type: 'success',
          message: `The Report ${reportNumber} has been regenerated successfully. Attention: It may take a few minutes to generate the updated report.`,
        });
        refetch();
      })
      .catch(() => {
        setNotifications({
          name: `Regenerated Report ${reportNumber}`,
          type: 'error',
          message: `The Report ${reportNumber} has not been regenerated, try again later`,
        });
      });
  };

  const reportsColumns: DataTableColumn<ReportData>[] = [
    {
      id: 'clientName',
      label: 'Billing Request Name',
      sortable: true,
    },
    {
      id: 'billingNumber',
      sortable: true,
      label: 'Bill Request Number',
    },
    {
      id: 'monthYear',
      label: 'Month/Year',
      sortable: true,
      accessor: (row) => convertToMMYYYY(row.monthYear),
    },
    {
      id: 'reportRunDate',
      label: 'Report Run Date',
      sortable: true,
      accessor: (row) => convertUTCToDateTimeString(row.reportRunDate),
    },
    {
      id: 'reports' as keyof ReportData,
      label: 'Actions',
      accessor: (row) => {
        return (isLoadingDownloadReport || isLoadingRegenerateReport) &&
          selectedReport === row.reportUuid ? (
          <Stack spacing={1}>
            <CircularProgress size={24} />
          </Stack>
        ) : (
          <Stack
            spacing={1}
            direction={'row'}
            sx={{
              minHeight: '24px',
            }}
          >
            <Tooltip title="Download Report" placement="top">
              <IconButton
                sx={{
                  padding: 0,
                  top: '2px',
                  cursor: 'pointer',
                  color: 'primary.dark',
                  '&:hover': { color: 'primary.main' },
                }}
                onClick={(e) => {
                  setSelectedReport(row.reportUuid);
                  e.stopPropagation();
                  handleDownloadReport(row);
                }}
              >
                <DownloadIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Regenerate Report" placement="top">
              <IconButton
                sx={{
                  padding: 0,
                  top: '2px',
                  cursor: 'pointer',
                  color: 'primary.dark',
                  '&:hover': { color: 'primary.main' },
                }}
                onClick={(e) => {
                  setSelectedReport(row.reportUuid);
                  e.stopPropagation();
                  handleRegenerateReport(row.billingNumber, row.s3Path);
                }}
              >
                <CachedIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  const breadcrumbs: Breadcrumb[] = [
    {
      url: '..',
      label: 'Reports List',
    },
    {
      url: reportsUrl,
      label: `Reports List - ${params.uuid?.split('-').reverse().join('/')}`,
    },
    {
      url: '',
      label: `Report ${params.id}`,
    },
  ];

  return (
    <CRUDPageableLayout>
      <NotificationSnackbar
        clearNotification={() => setNotifications(undefined)}
        notification={notifications}
      />
      <Box component={Paper}>
        <BreadcrumbHeader breadcrumbs={breadcrumbs} />

        {isLoading && <LoaderCard label="Loading Reports..." sx={{ p: 4 }} />}

        {isError && <ErrorResponseCard errorCode={errorCode} title="Reports" />}

        {!isLoading && data && data?.length > 0 && (
          <DataTable
            columns={reportsColumns}
            rows={data ?? []}
            sortOptions={{ hasSort: true, order, orderBy, createSortHandler }}
            paginationOptions={paginationOptions}
            stickyHeader
            stickyOffset={152}
          />
        )}
      </Box>
    </CRUDPageableLayout>
  );
}
