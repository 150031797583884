import { DynamicForm } from 'components/Dynamic';
import FormDivider from 'components/Form/FormDivider';
import { UseFormReturn } from 'react-hook-form';
import { GeneralInfoFields } from './ServiceItemDetailsConfig';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hookForm: UseFormReturn<any>;
  isEditMode?: boolean;
  isCreationMode?: boolean;
}

const ServiceItemForm = ({ hookForm, isEditMode }: Props) => {
  return (
    <>
      <FormDivider label="Information" sx={{ mt: 1, mb: 3 }} />
      <DynamicForm
        hookForm={hookForm}
        fields={GeneralInfoFields}
        colspan={4}
        isEditMode={isEditMode}
      />
    </>
  );
};

export default ServiceItemForm;
