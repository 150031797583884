import { useContext, useEffect, useMemo, useState } from 'react';
import EventDetailsEditor from './EventDetailsEditor';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { LoaderCard } from 'components/Cards/LoaderCard';
import {
  DynamicExpandablePanel,
  ExpandablePanelEditOptions,
} from 'components/Dynamic/DynamicExpandablePanel';
import { useGetCaseEventDetailsByUUID } from 'features/Cases/queries/manageCaseQueries';
import { CaseDetailsContext } from 'features/Cases/contexts/CaseDetails';
import { ErrorCard } from 'components/Cards/ErrorCard';
import { AuthComponent } from 'auth/permissions/authComponent';
import { CASE_EVENT_STATUS_PERMISSION, useEventStatusConfig } from './config';
import SkeletonLoader from 'components/SkeletonLoader';

type Props = {
  caseUUID: string;
  editOptions?: ExpandablePanelEditOptions;
};

function EventDetails({ caseUUID, editOptions }: Props) {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [isEditMode, setEditMode] = useState(false);
  const { isLoading, data, isSuccess, isError } = useGetCaseEventDetailsByUUID(caseUUID, expanded);
  const { allSectionsExpanded, isEditingSection, setIsEditingSection } =
    useContext(CaseDetailsContext);

  useEffect(() => {
    setExpanded(allSectionsExpanded);
  }, [allSectionsExpanded]);

  const onExpanded = (isExpanded: boolean) => () => {
    setExpanded(!isExpanded);
  };

  const handleEdit = () => {
    setEditMode(true);
    setIsEditingSection(true);
  };

  const disableEdit = useMemo(
    () => isLoading || isError || editOptions?.disabled,
    [isLoading, isError, editOptions?.disabled],
  );

  const { permissions } = useEventStatusConfig();

  return (
    <DynamicExpandablePanel
      title="Event Details"
      icon={<EventNoteIcon />}
      onExpanded={onExpanded(expanded)}
      expanded={expanded}
      actions={{
        onEdit: permissions?.update ? () => handleEdit() : undefined,
        isEditMode: isEditMode,
      }}
      editOptions={{
        ...editOptions,
        disabled: disableEdit,
      }}
      isEditingSection={isEditingSection}
    >
      {isLoading && <LoaderCard label="Loading Event Details Information" />}

      {isError && (
        <ErrorCard label="An Error Has Occurred While Retrieving Event Details Information" />
      )}

      {isSuccess && data && (
        <EventDetailsEditor
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          setIsEditingSection={setIsEditingSection}
          data={data}
          caseUuid={caseUUID}
        />
      )}
    </DynamicExpandablePanel>
  );
}

export default AuthComponent(
  CASE_EVENT_STATUS_PERMISSION,
  EventDetails,
  <SkeletonLoader loading height={66} variant="rectangular" />,
);
