/* eslint-disable @typescript-eslint/no-explicit-any */
import AppColors from 'AppColors';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { DataGridColumn } from './types';
import { DataInput } from './DataInput';
import { DataObserver } from './DataObserver';
import { SelectChangeEvent } from '@mui/material/Select';
import { StyledTableCell } from './styled';
import { useDataRow } from './useDataRow';

interface DynamicDataRowProps<DataType> {
  columns: DataGridColumn<DataType>[];
  hasSelectors?: boolean;
  observer: DataObserver<DataType>;
  index: number;
  isEditMode?: boolean;
  readonlyRow?: (row: DataType) => boolean;
}

export default function DataRow<DataType>({
  columns,
  hasSelectors,
  observer,
  index,
  isEditMode,
  readonlyRow,
}: DynamicDataRowProps<DataType>) {
  const { row, selected, updateByField, selectItem: select } = useDataRow(observer, index);
  const readOnly = useMemo(() => {
    return readonlyRow && readonlyRow(row);
  }, [readonlyRow, row]);

  const onChangeSelect = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      select(e.target.checked);
    },
    [select],
  );

  const onChangeInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const column = columns.find((i) => i.id === e.target.name);
      const parsedValue = column?.number
        ? e.target.value.replace(/[^0-9,.]+/g, '')
        : e.target.value;
      updateByField(e.target.name, parsedValue);
    },
    [updateByField],
  );

  const onChangeInputSelect = useCallback(
    (e: SelectChangeEvent<any>) => {
      updateByField(e.target.name, e.target.value);
    },
    [updateByField],
  );

  return (
    <TableRow data-testid="DataRow">
      {hasSelectors && (
        <TableCell
          padding="checkbox"
          sx={{
            borderBottom: `1px solid ${AppColors.AAA_LIGHT_NAVY}`,
          }}
        >
          {isEditMode && !readOnly && (
            <Checkbox color="primary" checked={selected} onChange={onChangeSelect} />
          )}
        </TableCell>
      )}
      {columns.map((column, columnIndex) => {
        const value: any = row[column.id];
        return (
          <StyledTableCell
            focusHighlight
            key={index + '-' + columnIndex}
            sx={{
              borderBottom: `1px solid ${AppColors.AAA_LIGHT_NAVY}`,
              minWidth: column.minWidth,
              maxWidth: column.maxWidth,
              width: column.width,
            }}
          >
            <Box sx={{ width: '100%', color: 'inherit' }}>
              <DataInput
                column={column}
                onChangeInput={onChangeInput}
                onChangeInputSelect={onChangeInputSelect}
                value={value}
                isEditMode={isEditMode && !readOnly}
              />
            </Box>
          </StyledTableCell>
        );
      })}
    </TableRow>
  );
}
