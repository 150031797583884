import { PropsWithChildren, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { EntityCreationFlowContext } from './EntityCreationFlowContext';

const EntityCreationFlowProvider = ({ children }: PropsWithChildren) => {
  const [data, setData] = useState<Record<string, unknown> | undefined>();
  const [hookFormMap, setHookFormMap] = useState<Map<string, UseFormReturn<FieldValues>>>(
    new Map(),
  );
  return (
    <EntityCreationFlowContext.Provider value={{ data, hookFormMap, setData, setHookFormMap }}>
      {children}
    </EntityCreationFlowContext.Provider>
  );
};

export default EntityCreationFlowProvider;
