import { SxProps, Theme } from '@mui/material';
import { EnumStrType } from 'lib/EnumStrType';
import { SchemaRecord } from 'lib/Schema';
import { StatusChip } from './StatusChip';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

export interface StatusElement {
  color?: string;
  label: string;
  key: string;
  outlined?: boolean;
  hint?: string;
}

export type StatusSchema<T extends EnumStrType> = SchemaRecord<StatusElement, T>;

interface Props<T extends EnumStrType> {
  status: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  schemelist: StatusSchema<T>;
  sx?: SxProps<Theme>;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  dataTestId?: string;
}

export function SchemaStatusChip<T extends EnumStrType>({
  status,
  schemelist,
  endIcon,
  dataTestId,
  ...rest
}: Props<T>) {
  const scheme = schemelist[status];
  if (!scheme) {
    return null;
  }

  const { color, label, outlined, hint } = schemelist[status];
  const bgcolor = outlined ? 'none' : `${color}4C`; // add opacity=0.3 to background
  const borderColor = outlined ? `${color}4C` : '';
  return (
    <StatusChip
      bgcolor={bgcolor}
      color={color}
      label={label}
      borderColor={borderColor}
      endIcon={
        hint ? (
          <Tooltip title={hint}>
            <InfoIcon />
          </Tooltip>
        ) : (
          endIcon
        )
      }
      dataTestId={dataTestId ?? 'chip'}
      {...rest}
    />
  );
}
