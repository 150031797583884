import { BaseAPI } from 'api/auth';
import { ENV_BACKOFFICE_BASE_API_URL } from 'lib/envConfig';
import { PageableParams } from 'hooks/useReactQueryHelpers';
import { PageableResponse } from 'api/interfaces/responses';
import { PaymentRequestGroupedDto, PaymentRequestDto } from './interfaces';
import { CaseGeneralInfoDto } from 'api/cases/interfaces/GeneralInfoDto';

export async function fetchPaymentRequestSummary(params: PageableParams) {
  return BaseAPI.get<PageableResponse<PaymentRequestGroupedDto>>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/payment-requests/summary?page=${params.page}&size=${params.size}&filter=${params.filter}`,
  );
}

export async function fetchPaymentRequestByFilter(params: PageableParams) {
  return BaseAPI.get<PageableResponse<PaymentRequestDto>>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/payment-requests?page=${params.page}&size=${params.size}&filter=${params.filter}`,
  );
}

export async function fetchPaymentRequestCasesByClub(
  uuid: string,
  clubUuid: string,
  params: PageableParams,
) {
  return BaseAPI.get<PageableResponse<CaseGeneralInfoDto>>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/payment-requests/${uuid}/clubs/${clubUuid}/cases?page=${params.page}&size=${params.size}`,
  );
}

export async function sendManyUUIDReportViaEmail(reportIDs: string[]) {
  const reportUuid = reportIDs.join('&reportUuid=');
  return BaseAPI.patch(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/reports/email?reportEntityType=PAYMENT_SUMMARY&reportUuid=${reportUuid}`,
  );
}

export async function downloadManyUUIDReport(reportIDs: string[]) {
  const reportUuid = reportIDs.join('&reportUuid=');
  return BaseAPI.get(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/reports/download?reportEntityType=PAYMENT_SUMMARY&reportUuid=${reportUuid}`,
    {
      responseType: 'blob',
    },
  );
}

export async function generateMonthlyReport() {
  return BaseAPI.post(`${ENV_BACKOFFICE_BASE_API_URL}/v1/payment-requests/creation`);
}
