import { Box, Breadcrumbs, Link, Paper, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import { EditQueryForm } from './EditQueryForm';
import SavedSearchesProvider from 'features/Cases/SavedSearchesBar/SavedSearchesBarProvider';

const EditQuery = () => {
  const navigate = useNavigate();

  const navigateBackToCases = () => {
    navigate(routes.casePage);
  };

  return (
    <SavedSearchesProvider>
      <Box component={Paper} sx={{ height: '100%', p: '20px 0 20px 20px' }}>
        <Breadcrumbs
          color="text.primary"
          aria-label="breadcrumb"
          separator={<NavigateNextIcon />}
          sx={{ mb: 3 }}
        >
          <Link
            underline="hover"
            color="inherit"
            href="/cases"
            sx={{ fontSize: '14px', opacity: 0.5 }}
          >
            Case View
          </Link>
          <Typography color="text.primary" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
            Edit Query
          </Typography>
        </Breadcrumbs>
        <EditQueryForm onSubmit={navigateBackToCases} onCancel={navigateBackToCases} />
      </Box>
    </SavedSearchesProvider>
  );
};

export default EditQuery;
