import { ServiceItemsData } from '../types';
import ServiceItemGeneralInfo from './ServiceItemGeneralInfo';

type Props = {
  data?: ServiceItemsData;
  toIndividualScreen?: (uuid: string) => void;
  isCreationMode?: boolean;
};

export default function ServiceItemDetailGroup({
  data,
  toIndividualScreen,
  isCreationMode,
}: Props) {
  return (
    <ServiceItemGeneralInfo
      isCreationMode={isCreationMode}
      onAfterCreate={toIndividualScreen}
      initialData={data}
    />
  );
}
