import { Box, Card, Typography } from '@mui/material';
import DataTable, { DataTableColumn } from 'components/DataTable';
import { NotificationSnackbar, NotificationType } from 'components/Notifications';
import { useMemo, useState } from 'react';
import { ErrorResponseCard, LoaderCard } from 'components/Cards';
import { CRUDComponent } from 'components/CRUDNavigator';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import { useGetReportMonthlySummary } from 'features/Reports/ReportsQuery';

const ReportsMonthsList: CRUDComponent = ({ navigators }) => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState<NotificationType>();
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('');

  const [rowsPerPage, setRowsPerPage] = useState<number>(300);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const { data, isLoading, isError, errorCode } = useGetReportMonthlySummary({
    page: currentPage,
    size: rowsPerPage,
  });

  const createSortHandler = (_: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const paginationOptions = useMemo(() => {
    return {
      rowsCount: data?.totalElements ?? 25,
      currentPage,
      rowsPerPage,
      onPageChange: setCurrentPage,
      onRowsPerPageChange: setRowsPerPage,
      rowsPerPageOptions: [25, 50, 100, 150, 200, 250, 300],
    };
  }, [currentPage, data, rowsPerPage, setRowsPerPage]);

  const reportsColumns: DataTableColumn<{ monthYear: string }>[] = [
    {
      id: 'monthYear',
      label: 'Month/Year',
      sortable: true,
    },
    // to be added when we implement actions (download all is coming)
    // {
    //   id: 'reports' as keyof { monthYear: string },
    //   label: 'Actions',
    //   accessor: (row) => {
    //     return '-';
    //   },
    // },
  ];

  const handleRowClick = (row: { monthYear: string }) => {
    navigators.toIndividualScreen(row.monthYear.split('/').reverse().join('-'));
  };

  if (isLoading) {
    return <LoaderCard label="Loading Reports List ...." sx={{ p: 2 }} />;
  }

  if (data) {
    return (
      <Card>
        <NotificationSnackbar
          clearNotification={() => setNotifications(undefined)}
          notification={notifications}
        />
        <Box sx={{ p: 2 }} justifyContent="space-between" alignItems="center" display="flex">
          <Typography fontWeight="bold" component="div">
            Reports List
          </Typography>
        </Box>
        {isLoading && <LoaderCard label="Loading Reports..." sx={{ p: 4 }} />}

        {isError && <ErrorResponseCard errorCode={errorCode} title="Reports" />}

        {!isLoading && data && data?.content.length > 0 && (
          <DataTable
            columns={reportsColumns}
            rows={data.content.map((row) => ({ monthYear: row }))}
            sortOptions={{ hasSort: true, order, orderBy, createSortHandler }}
            paginationOptions={paginationOptions}
            onRowClick={handleRowClick}
          />
        )}
      </Card>
    );
  }

  return null;
};

export default ReportsMonthsList;
