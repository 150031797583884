import { useState } from 'react';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import DialogConfirmation from 'components/DialogConfirmation';

export interface DynamicConfirmation {
  onSubmit: () => void;
  label?: string;
  disabled?: boolean;
  dialog?: {
    label?: string;
    title?: string;
    content?: string;
  };
}

enum ConfirmationDialogType {
  SAVE,
  DISCARD,
}

interface Props {
  save?: DynamicConfirmation;
  discard?: DynamicConfirmation;
}

export function DynamicFormActions({ save, discard }: Props) {
  const [confirm, setConfirm] = useState<ConfirmationDialogType | null>(null);

  const handleStartSave = () => {
    setConfirm(ConfirmationDialogType.SAVE);
  };

  const handleStartDiscard = () => {
    setConfirm(ConfirmationDialogType.DISCARD);
  };

  const handleSave = () => {
    setConfirm(null);
    save?.onSubmit();
  };
  const handleDiscard = () => {
    setConfirm(null);
    discard?.onSubmit();
  };

  const handleCloseDialog = () => {
    setConfirm(null);
  };

  return (
    <>
      {discard && (
        <>
          <Button
            variant="outlined"
            startIcon={<ClearIcon />}
            color="error"
            onClick={handleStartDiscard}
            data-testid="DiscardButton"
            disabled={discard.disabled}
          >
            {discard.label || 'Cancel'}
          </Button>
          <DialogConfirmation
            open={confirm === ConfirmationDialogType.DISCARD}
            title={discard.dialog?.title || 'Discard changes?'}
            content={discard.dialog?.content || 'Are you sure you want to discard changes made?'}
            onClose={handleCloseDialog}
            onConfirm={handleDiscard}
            labelConfirm={discard.dialog?.label || 'Discard'}
          />
        </>
      )}

      {save && (
        <>
          <Button
            variant="outlined"
            startIcon={<CheckIcon />}
            color="success"
            onClick={handleStartSave}
            data-testid="SaveButton"
            disabled={save.disabled}
          >
            {save.label || 'Save'}
          </Button>
          <DialogConfirmation
            open={confirm === ConfirmationDialogType.SAVE}
            title={save.dialog?.title || 'Save changes?'}
            content={save.dialog?.content || 'Are you sure you want to save changes?'}
            onClose={handleCloseDialog}
            onConfirm={handleSave}
            labelConfirm={save.dialog?.label || 'Save'}
          />
        </>
      )}
    </>
  );
}
