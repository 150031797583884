import { useMemo } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { BaseRoutes, CRUDComponent, CRUDComponentProps } from './types';

interface Props {
  /**
   * List of route paths. Example of default route (if not provided):
   * @example
   * const defaultRoutes = {
   * MAIN_SCREEN: '/',
   * CREATE_SCREEN: '/create',
   * INDIVIDUAL_SCREEN: '/view/:uuid',
   * };
   */
  routes?: Partial<BaseRoutes>;

  /**
   * Define components to be loaded inside of routes
   */
  components: Partial<{
    Main: CRUDComponent;
    Creator: CRUDComponent;
    Individual: CRUDComponent;
    Detail: CRUDComponent;
  }>;
}

const defaultRoutes: BaseRoutes = {
  MAIN_SCREEN: '/',
  CREATE_SCREEN: '/create',
  INDIVIDUAL_SCREEN: '/:uuid',
  DETAIL_SCREEN: '/:uuid/:subsection/:id',
};

export function CRUDNavigator({ components, routes }: Props) {
  const navigate = useNavigate();
  const baseRoutes: BaseRoutes = useMemo(() => ({ ...defaultRoutes, ...routes }), [routes]);
  const componentProps: CRUDComponentProps = useMemo(
    () => ({
      navigators: {
        toIndividualScreen: (uuid: string, params?: Record<string, string>, state?: any) => {
          const filters = new URLSearchParams(location.search).get('filters') ?? '';
          if (filters) {
            params = { ...params, filters };
          }
          const queryParams = params ? `?${new URLSearchParams(params).toString()}` : '';
          navigate(`.${baseRoutes.INDIVIDUAL_SCREEN.replace(':uuid', uuid)}${queryParams}`, {
            state,
          });
        },
        toCreateScreen: () => {
          navigate(`.${baseRoutes.CREATE_SCREEN}`);
        },
        toMainScreen: () => {
          navigate(`.${baseRoutes.MAIN_SCREEN}`);
        },
        toDetailScreen: (
          uuid: string,
          subsection: string,
          id: string,
          params?: Record<string, string>,
        ) => {
          const filters = new URLSearchParams(location.search).get('filters') ?? '';
          if (filters) {
            params = { ...params, filters };
          }
          const queryParams = params ? `?${new URLSearchParams(params).toString()}` : '';
          navigate(
            `.${baseRoutes.DETAIL_SCREEN?.replace(':uuid', uuid)
              .replace(':subsection', subsection)
              .replace(':id', id)}${queryParams}`,
          );
        },
      },
      routes: baseRoutes,
    }),
    [baseRoutes, navigate],
  );

  const { Main, Creator, Individual, Detail } = components;

  return (
    <Routes>
      {Main && <Route path={baseRoutes.MAIN_SCREEN} element={<Main {...componentProps} />} />}
      {Creator && (
        <Route path={baseRoutes.CREATE_SCREEN} element={<Creator {...componentProps} />} />
      )}
      {Individual && (
        <Route path={baseRoutes.INDIVIDUAL_SCREEN} element={<Individual {...componentProps} />} />
      )}
      {Detail && <Route path={baseRoutes.DETAIL_SCREEN} element={<Detail {...componentProps} />} />}
    </Routes>
  );
}
