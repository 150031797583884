import Box from '@mui/material/Box';
import DataTable from 'components/DataTable';
import FormDivider from 'components/Form/FormDivider';
import { useCaseFinancialsConfig } from './config';
import { ErrorResponseCard, LoaderCard } from 'components/Cards';
import { ErrorData } from 'hooks/useReactQueryHelpers';
import useCaseRejects from './useCaseRejects';
import { hmaGmaColumns, tmnaColumns } from './CaseRejectDetailsConfig';
import { useMemo } from 'react';
import { CaseRejectProvider } from 'features/Cases/contexts/CaseReject';
import { RbpCaseStatus } from 'core/enums/RbpCaseStatus';

type Props = {
  caseUUID: string;
  rapCaseId: string;
  clientId: number;
  rbpCaseStatus: RbpCaseStatus;
};

export default function CaseRejectDetails({ caseUUID, rapCaseId, clientId, rbpCaseStatus }: Props) {
  const { isLoading, data, isSuccess, isError, errorCode } = useCaseRejects(caseUUID);

  const htmasData = useMemo(() => (data?.hmas || []).map((r) => ({ ...r, actions: null })), [data]);
  const tmnasData = useMemo(
    () => (data?.tmnas || []).map((r) => ({ ...r, actions: null })),
    [data],
  );
  const { caseFinancialDetailsTable } = useCaseFinancialsConfig();
  const canReadBillableClaim = caseFinancialDetailsTable?.fields?.['billableClaim']?.read;

  if (!canReadBillableClaim) return null;

  const isHmaGma = data?.hmas?.length || 0 > 0;
  const hasData = isHmaGma ? data?.hmas?.length || 0 > 0 : data?.tmnas?.length || 0 > 0;

  return (
    <>
      <FormDivider label="Client Reject Details" sx={{ mb: 2 }} />
      {isLoading && <LoaderCard label="Loading Client Reject Details" />}
      {isError && <ErrorResponseCard errorCode={errorCode} title="Client Reject Details" />}
      {isSuccess && data && hasData && (
        <Box mx={-4}>
          <CaseRejectProvider caseUuid={caseUUID} rapCaseId={rapCaseId} clientId={clientId}>
            {isHmaGma ? (
              <DataTable columns={hmaGmaColumns(rbpCaseStatus)} rows={htmasData} />
            ) : (
              <DataTable columns={tmnaColumns(rbpCaseStatus)} rows={tmnasData} />
            )}
          </CaseRejectProvider>
        </Box>
      )}
      {isSuccess && !hasData && (
        <ErrorResponseCard errorCode={ErrorData.NotFound} title="Client Reject Details" />
      )}
    </>
  );
}
