import { useState } from 'react';
import { FormControl, FormHelperText, InputLabel, FormControlProps } from '@mui/material';
import { Controller } from 'react-hook-form';
import moment from 'moment';
import { SingleDatePicker, SingleDatePickerInputShape } from 'react-dates';
import EventIcon from '@mui/icons-material/Event';
import StyledDatePickerWrapper from './ControlledSingleDatePicker.styled';
import { ControlFieldProps } from '../types';
import AppColors from 'AppColors';

type ControlledSingleDatePickerProps = SingleDatePickerInputShape &
  FormControlProps &
  ControlFieldProps & {
    helperText?: string;
    label: string;
    isOutsideRange?: (day: moment.Moment) => boolean;
  };
const ControlledSingleDatePicker = ({
  control,
  name,
  rules,
  readOnly,
  id,
  error,
  helperText,
  label,
  openDirection,
  required,
  isOutsideRange,
}: ControlledSingleDatePickerProps) => {
  const [focused, setFocused] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <FormControl error={error} fullWidth>
          <InputLabel
            id={`${name}-${label}`}
            size="small"
            shrink
            sx={{
              fontWeight: 600,
              ...(!readOnly && { background: '#ffffff' }),
              padding: '0 5px',
              textFillColor: focused ? AppColors.AAA_LIGHT_BLUE : AppColors.AAA_BLUE,
            }}
          >
            {required && !readOnly ? `${label} *` : label}
          </InputLabel>
          <StyledDatePickerWrapper disabled={readOnly} focusedInput={focused}>
            <SingleDatePicker
              ariaLabel={name}
              date={field.value ? moment.utc(field.value) : null}
              id={id}
              onDateChange={(date) => {
                field.onChange(date ? date.format() : '');
              }}
              focused={focused}
              onFocusChange={({ focused }) => setFocused(focused)}
              onClose={field.onBlur}
              readOnly={readOnly}
              disabled={readOnly}
              numberOfMonths={1}
              displayFormat="MM/DD/YYYY"
              firstDayOfWeek={1}
              isOutsideRange={(day) => isOutsideRange?.(day) ?? false}
              noBorder
              customInputIcon={<EventIcon fontSize="small" />}
              inputIconPosition="after"
              openDirection={openDirection}
              isDayHighlighted={(momentDate: moment.Moment) => {
                const format = 'YYYY-MM-DD';
                return !field.value && momentDate.format(format) === moment().format(format);
              }}
            />
          </StyledDatePickerWrapper>
          {error && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default ControlledSingleDatePicker;
