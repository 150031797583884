import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import DialogConfirmation from 'components/DialogConfirmation';
import { DynamicForm } from 'components/Dynamic/DynamicForm';
import { AdjustmentLineItemDefaultParams, BillingRequestAdjustmentRequestDto } from './types';
import { useAddInvoiceLine } from './BillingRequestQuery';
import FormDivider from 'components/Form/FormDivider';
import {
  addInvoiceLineFields,
  addInvoiceLineValidationSchema,
  addInvoiceLineFieldsDefault,
} from './helpers/billingAddInvoiceLineConfig';

interface BillingRequestAddInvoiceLineProps {
  billingRequestUuid: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  defaultAdjustmentValues?: Partial<AdjustmentLineItemDefaultParams>;
  onConfirmAddInvoice: () => void;
}

export default function BillingRequestAddInvoiceLine({
  visible,
  setVisible,
  billingRequestUuid,
  defaultAdjustmentValues,
  onConfirmAddInvoice,
}: BillingRequestAddInvoiceLineProps) {
  const { isError, mutateAsync, isLoading } = useAddInvoiceLine(billingRequestUuid);

  const hookFormDefault = useForm<AdjustmentLineItemDefaultParams>({
    defaultValues: defaultAdjustmentValues,
  });

  const hookForm = useForm<BillingRequestAdjustmentRequestDto>({
    resolver: yupResolver(addInvoiceLineValidationSchema),
    mode: 'onChange',
  });

  const onSubmit = async (data: BillingRequestAdjustmentRequestDto) => {
    try {
      const { totalAmount, ...adjustmentData } = data;
      await mutateAsync(adjustmentData);
      setVisible(false);
      onConfirmAddInvoice();
    } catch (error) {
      console.log(error);
    }
  };

  const unitPrice = hookForm.watch('amount');
  const quantity = hookForm.watch('quantity');

  // Calculate Total Price
  useEffect(() => {
    if (Number(unitPrice) && Number(quantity)) {
      const totalAmount = (Number(unitPrice) * Number(quantity)).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'USD',
      });
      hookForm.setValue('totalAmount', totalAmount);
    } else {
      hookForm.setValue('totalAmount', '$0.00');
    }
  }, [unitPrice, quantity, hookForm]);

  const onClose = () => {
    setVisible(false);
    hookForm.reset();
  };

  return (
    <DialogConfirmation
      open={visible}
      maxWidth="xl"
      title="Add Invoice Line Item"
      onClose={onClose}
      onConfirm={hookForm.handleSubmit(onSubmit)}
      labelConfirm="ADD INVOICE LINE ITEM"
      labelCancel="Cancel"
      isLoading={isLoading}
      sx={{
        '& .MuiDialog-paper': {
          width: 'fit-content',
          maxWidth: 980,
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormDivider label="Bill Request Information" sx={{ mb: 3 }} />
        <DynamicForm
          hookForm={hookFormDefault}
          isEditMode
          fields={addInvoiceLineFieldsDefault}
          rowSpacing={3}
        />
        <FormDivider label="Invoice Line Item" sx={{ mt: 3, mb: 3 }} />
        <DynamicForm hookForm={hookForm} isEditMode fields={addInvoiceLineFields} rowSpacing={3} />
        {isError && <Typography>An Error Has Occurred While Adding Adjustment</Typography>}
      </Box>
    </DialogConfirmation>
  );
}
