import { useCallback, useMemo } from 'react';
import { usePermissionRoles } from './PermissionProvider';
import { ControlledFormTableColumn } from 'components/ControlledForm/ControlledFormTable/ControlledFormTable';
import { DynamicValue } from 'components/Dynamic';

interface Props<T extends DynamicValue> {
  table: string;
  columns: ControlledFormTableColumn<T>[];
  isCreationMode?: boolean;
  isEditMode?: boolean;
}

// To use when certain column IDs don't correspond to a permission field
const permissionFieldIdMapping: { [key: string]: string } = {
  fullPhoneNumber: 'phoneNumber',
  cityStateZip: 'city',
};

export function useTableFieldPermission<T extends DynamicValue>({
  table,
  isCreationMode,
  isEditMode,
  columns,
}: Props<T>) {
  const { permissions } = usePermissionRoles();

  const getTableColumnAttributes = useCallback(
    (columnId: string) => {
      const permissionFieldId = permissionFieldIdMapping[columnId] || columnId;
      const permissionField = permissions[table]?.fields[permissionFieldId];

      if (!permissionField) {
        return;
      }

      const canCreate = permissionField.create && isCreationMode;
      const canUpdate = permissionField.update && isEditMode;
      const isReadOnly = permissionField.read && !canCreate && !canUpdate;
      const isVisible = canCreate || canUpdate || isReadOnly;
      return { canCreate, canUpdate, isReadOnly, isVisible };
    },
    [isCreationMode, isEditMode, permissions, table],
  );

  return useMemo(
    () =>
      columns.reduce<ControlledFormTableColumn<T>[]>((list, column) => {
        const attr = getTableColumnAttributes(column.id.toString());
        if (attr && attr.isVisible) {
          list.push({ ...column, isReadOnly: attr.isReadOnly || column.isReadOnly });
        }
        return list;
      }, []),
    [columns, getTableColumnAttributes],
  );
}
