import { CRUDNavigator } from 'components/CRUDNavigator';
import CreditCardPageList from './CreditCardPageList';
import CreditCardProvider from './CreditCardProvider';
import CreditCardPageView from './CreditCardPageView';

const CreditCardPage = () => {
  return (
    <CreditCardProvider>
      <CRUDNavigator
        components={{
          Main: CreditCardPageList,
          Individual: CreditCardPageView,
        }}
      />
    </CreditCardProvider>
  );
};

export default CreditCardPage;
