type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function serviceCodeComparator<T>(a: T, b: T, orderBy: keyof T) {
  const value1 = a[orderBy] as unknown as string;
  const value2 = b[orderBy] as unknown as string;
  const startsWithT = (value: string) => value.startsWith('T');

  if (startsWithT(value1) && !startsWithT(value2)) {
    return -1;
  }
  if (!startsWithT(value1) && startsWithT(value2)) {
    return 1;
  }
  return value1.localeCompare(value2);
}

/**
 * Returns a comparator function for sorting an array of objects based on a specific key.
 * The comparator function takes into account the sorting order ('asc' or 'desc')
 *
 * @param order - The sorting order, either 'asc' or 'desc'.
 * @param orderBy - The key by which the array of objects will be sorted.
 * @returns A comparator function that can be used in the Array.prototype.sort() method.
 * @example
 * const order: Order = 'asc';
 * const orderBy = 'serviceCode';
 * const comparator = getComparator(order, orderBy);
 * const sortedArray = myArray.sort(comparator);
 */
export default function getComparator<Key extends string>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
