/* eslint-disable @typescript-eslint/no-explicit-any */
import { CaseSearchType } from '../CaseSearchTypeSelector';
import SavedSearchesBarContainer from './SavedSearchesBarContainer';
import SavedSearchesProvider from './SavedSearchesBarProvider';

export interface SavedSearchesBarProps {
  caseSearchType: CaseSearchType;
  currentSearchData?: Record<string, any>;
  onSelectedSavedSearchChange: (searchData: Record<string, any> | null) => void;
}

const SavedSearchesBar: React.FC<SavedSearchesBarProps> = ({
  caseSearchType,
  currentSearchData,
  onSelectedSavedSearchChange,
}: SavedSearchesBarProps) => {
  return (
    <SavedSearchesProvider>
      <SavedSearchesBarContainer
        caseSearchType={caseSearchType}
        currentSearchData={currentSearchData}
        onSelectedSavedSearchChange={onSelectedSavedSearchChange}
      />
    </SavedSearchesProvider>
  );
};

export default SavedSearchesBar;
