import ClientPageCreator from './ClientPageCreator';
import ClientPageList from './ClientPageList';
import ClientPageView from './ClientPageView';
import ClientProvider from './ClientProvider';
import ClientPagePricing from './ClientPagePricing';
import { SetupNavigator } from '../components';

const ClientPage = () => {
  return (
    <ClientProvider>
      <SetupNavigator
        components={{
          Main: ClientPageList,
          Individual: ClientPageView,
          Creator: ClientPageCreator,
          Pricing: ClientPagePricing,
        }}
      />
    </ClientProvider>
  );
};

export default ClientPage;
