import { Box, styled } from '@mui/material';
import AppColors from 'AppColors';

export const StyledHorizRow = styled('hr')(() => ({
  height: 1,
  border: 0,
  borderTop: `1px solid ${AppColors.AAA_BLUE}`,
}));

export const StyledSummaryIconBox = styled(Box)(() => ({
  display: 'inline-flex',
  paddingRight: 12,
  alignItems: 'center',
}));
