import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import theme from 'theme';
import { ChangeEvent, useEffect, useState } from 'react';
import { BasicSavedSearch, SavedSearch } from 'api/savedSearches/interfaces/SavedSearch';

export interface SaveSearchPopupProps {
  open: boolean;
  appliedFilter: string;
  onClose: () => void;
  onSubmit: (createdSearch: BasicSavedSearch) => void;
  savedSearches: SavedSearch[];
  isLoading: boolean;
}

const SaveSearchPopup = ({
  open,
  appliedFilter,
  onClose,
  onSubmit,
  savedSearches,
  isLoading,
}: SaveSearchPopupProps) => {
  const [searchQueryName, setSearchQueryName] = useState<string>('');
  const [defaultSearchQuery, setDefaultSearchQuery] = useState<boolean>(false);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onDefaultSearchQueryChecked = () => setDefaultSearchQuery(!defaultSearchQuery);

  const onSaveSearchButtonClicked = async () => {
    onSubmit({
      name: searchQueryName,
      filter: appliedFilter,
      defaultFilter: defaultSearchQuery,
      createdByUserId: '',
      systemFilter: false,
    });
  };

  const onSearchQueryNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const nameExists = !!savedSearches.find((savedSearch) => savedSearch.name === value);
    const valid = value.trim().length >= 3 && !nameExists;
    setErrorMessage(nameExists ? 'Name already exists.' : '');
    setFormValid(valid);
    setSearchQueryName(value);
  };

  useEffect(() => {
    if (open) {
      setSearchQueryName('');
      setDefaultSearchQuery(false);
    }
  }, [open]);

  return (
    <Dialog open={open} sx={{ '& .MuiDialogContent-root': { maxWidth: 485, pl: 3, pr: 0 } }}>
      <DialogTitle sx={{ pt: '23px' }}>
        <Typography variant="body1">Do you want to save this query?</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.text.primary,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControl variant="outlined" sx={{ mb: 2, width: 444, pt: 1 }}>
          <TextField
            id="search-query-name"
            type="text"
            placeholder="Search Query Name"
            value={searchQueryName}
            error={!!searchQueryName && !formValid}
            helperText={errorMessage}
            autoFocus
            onChange={onSearchQueryNameChanged}
          />
        </FormControl>
        <FormControlLabel
          id="default-search-query-checkbox"
          control={<Checkbox onChange={onDefaultSearchQueryChecked} />}
          label="Default Search Query"
          sx={{ height: '20px' }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onSaveSearchButtonClicked}
          disabled={isLoading || !formValid}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveSearchPopup;
