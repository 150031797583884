import { Button, Box, Container } from '@mui/material';
import { MainContainer, BannerBox, HeaderTypography, BodyTypography } from './NoPermissions.styles';
import { useNavigate } from 'react-router-dom';

import Logo from './../../assets/logo-blue-m.svg';
import LoginBanner from './../../assets/login-banner.png';

const NoPermissions = () => {
  const navigate = useNavigate();

  return (
    <MainContainer disableGutters>
      <Container disableGutters>
        <BannerBox imageUrl={LoginBanner}>
          <Box component="img" src={Logo} sx={{ mb: 5 }} />
          <HeaderTypography variant="h5">
            {'RAP Billing'}
            <br />
            {'& Payment Portal'}
          </HeaderTypography>
        </BannerBox>
      </Container>
      <Container disableGutters sx={{ m: 'auto', pl: 19.25 }}>
        <HeaderTypography variant="h5">Insufficient Permissions</HeaderTypography>
        <BodyTypography variant="body2" paragraph>
          Your current role does not allow access to this page.
        </BodyTypography>

        <Button
          variant="contained"
          sx={{ width: 444, letterSpacing: '1.25px' }}
          onClick={() => {
            navigate('/dashboard', { replace: true });
          }}
        >
          Go to Dashboard
        </Button>
      </Container>
    </MainContainer>
  );
};

export default NoPermissions;
