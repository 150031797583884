import { CRUDComponent } from 'components/CRUDNavigator';
import CasePageView from 'features/Cases/CasePageView';

const CreditCardPageView: CRUDComponent = ({ navigators, routes }) => {
  return (
    <CasePageView
      navigators={navigators}
      routes={routes}
      previousTitle="Credit Cards"
      previousRoute="/credit-cards"
    />
  );
};

export default CreditCardPageView;
