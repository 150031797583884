import { BaseAPI } from 'api/auth';
import { CreatePageableCRUDApi, GetAllParam } from 'api/helper/PageableCRUDApi';
import { ENV_BACKOFFICE_BASE_API_URL } from 'lib/envConfig';
import { ClientDto } from './manageClientApi';
import { SurveyTypes } from 'api/setup/manageClientApi';
import { BillTypes } from './manageBillingInfoApi';
import { PageableResponse } from 'api/interfaces/responses';
import { QueryBuilder } from 'lib/querybuilder';

export interface ProgramDto {
  uuid: string;
  rapProgramCode: number;
  poEffectiveDateTime: string;
  poExpirationDateTime: string;
  code?: string;
  name?: string;
  abbreviatedName?: string;
  merchantId?: string;
  rapProgramId: number;
  oem?: boolean;
  warrantyVinRequired?: boolean;
  poRequired?: boolean;
  poNumber?: string;
  survey?: boolean;
  surveyType?: SurveyTypes;
  surveyAdministrator?: string;
  client?: Partial<ClientDto>;
  contactCenterPartner?: string;
  customerCallNumber?: string;
  clubCallInNumber?: string;
  aaaAssignedDid?: string;
  billType?: BillTypes;
}

export interface CreateProgramRequestDto {
  rapClientId: number;
  clientName: string;
  rapProgramCode: string;
  uuid: string;
  rapProgramId: number;
  name: string;
  abbreviatedName?: string;
  survey: boolean;
  surveyType: SurveyTypes;
  surveyAdministrator: string;
  clientUuid: string;
}

export interface BillingConfigurationProgramDto {
  uuid?: string;
  createdAtDateTime?: string;
  updatedAtDateTime?: string;
  createdByUserId?: string;
  updatedByUserId?: string;
  programUuid: string;
  billType: BillTypes;
  poRequired: boolean;
  poNumber: string | null;
  poEffectiveDate: string | null;
  poExpirationDate: string | null;
  holdingAccountNumberRevenue: string;
  holdingAccountNumberCost: string;
  addressAccountNumber: string;
  revenueShareAccountNumber: string;
  costCenterAccountNumber: string;
  billingRequestItemNumber: string | null;
  rapAccountingItemNumber: number | null;
  manualAdjustmentRevenueExpenseItemNumber: string;
  manualAdjustmentContractorExpenseItemNumber: string;
  prePaidEventItemNumber: string | null;
  splitPayRapProgramCode: string;
  allowPrePay: boolean;
  outOfWarrantyProgramToRoute: string;
}

const findProgramById = async (id: number) => {
  return BaseAPI.get<PageableResponse<ClientDto>>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/programs?filter=${QueryBuilder.equal('rapProgramId', id)}`,
    { params: { size: 999 } },
  );
};

export const ManageProgramApi = CreatePageableCRUDApi<ProgramDto>(
  `${ENV_BACKOFFICE_BASE_API_URL}/v1/programs`,
  {
    fetchAll: async (params: GetAllParam) => {
      const programs = await BaseAPI.get<PageableResponse<ProgramDto>>(
        `${ENV_BACKOFFICE_BASE_API_URL}/v1/programs`,
        {
          params,
        },
      );
      try {
        const programIds = programs.content.map((i) => i.uuid);
        const billingsConfigs = await Promise.allSettled(
          programIds.map((i) => fetchProgramBillingConfig(i)),
        ).then((res) => res.map((item) => (item.status === 'fulfilled' ? item.value : [])));
        const parsedPrograms = {
          ...programs,
          content: programs.content.map((i, index) => ({
            ...i,
            billType: billingsConfigs[index][0] ? billingsConfigs[index][0].billType : undefined,
          })),
        };
        return parsedPrograms;
      } catch (e) {
        return programs;
      }
    },
    create: async (data: ProgramDto) => {
      const { rapProgramId } = data;

      const { content } = await findProgramById(rapProgramId);

      if (content && content.length > 0) {
        throw new Error('The entered Program ID is already in use, please try another ID');
      }

      return BaseAPI.post<ProgramDto>(`${ENV_BACKOFFICE_BASE_API_URL}/v1/programs`, data);
    },
  },
);

export function fetchPrograms(params: GetAllParam) {
  return BaseAPI.get<PageableResponse<ProgramDto>>(`${ENV_BACKOFFICE_BASE_API_URL}/v1/programs`, {
    params,
  });
}

export function addProgram(programData: BillingConfigurationProgramDto) {
  return BaseAPI.post<BillingConfigurationProgramDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/programs`,
    programData,
  );
}

export function deleteProgramByUUID(programUUID: string) {
  return BaseAPI.delete<string>(`${ENV_BACKOFFICE_BASE_API_URL}/v1/programs/${programUUID}`);
}

export function fetchProgramBillingConfig(programUuid: string) {
  return BaseAPI.get<BillingConfigurationProgramDto[]>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/programs/${programUuid}/billing-configurations`,
  );
}

export function addProgramBillingConfig(
  programUuid: string,
  billingConfig: BillingConfigurationProgramDto,
) {
  return BaseAPI.post<BillingConfigurationProgramDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/programs/${programUuid}/billing-configurations`,
    billingConfig,
  );
}

export function updateProgramBillingConfig(
  programUuid: string,
  uuid: string,
  billingConfig: Partial<BillingConfigurationProgramDto>,
) {
  return BaseAPI.put<Partial<BillingConfigurationProgramDto>>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/programs/${programUuid}/billing-configurations/${uuid}`,
    billingConfig,
  );
}
