import { CRUDNavigator } from 'components/CRUDNavigator';
import ServiceItemPageCreator from './ServiceItemPageCreator';
import ServiceItemsPageList from './ServiceItemsPageList';
import ServiceItemsPageView from './ServiceItemsPageView';
import ServiceItemsProvider from './ServiceItemsProvider';

const ServiceItemsPage = () => {
  return (
    <ServiceItemsProvider>
      <CRUDNavigator
        components={{
          Main: ServiceItemsPageList,
          Individual: ServiceItemsPageView,
          Creator: ServiceItemPageCreator,
        }}
      />
    </ServiceItemsProvider>
  );
};

export default ServiceItemsPage;
