import { useContext } from 'react';
import { CRUDPageableContext } from './CRUDPageableContext';
import { PageableProviderContextType } from './types';

export function usePageableContextData<DtoType, DataType = DtoType>(): PageableProviderContextType<
  DtoType,
  DataType
> {
  return useContext(CRUDPageableContext);
}
