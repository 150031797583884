import { ReportDto } from 'features/Reports/types';

export interface PaymentForecastsData {
  paymentGroupNumber: number;
  startPeriod: string;
  endPeriod: string;
  totalCases: number;
  totalClubPayments: number;
  files?: string;
  recipient?: string;
  sentAtDateTime?: string;
  reports?: ReportDto[];
}

export interface PaymentForecastsInternalData {
  uuid: string;
  clubUuid: string;
  clubName: string;
  clubCode: number;
  totalCases: number;
  totalRevenueShareAmount: number;
  totalPayableAmount: number;
  files?: string;
  recipient?: string;
  sentAtDateTime?: string;
  reports?: ReportDto[];
  status?: string;
}

export enum CallbackType {
  DOWNLOAD,
  SEND,
}
