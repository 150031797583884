import { Box, Typography } from '@mui/material';
import AppColors from 'AppColors';
import { RbpCaseStatus, RbpCaseStatusDescription } from 'core/enums/RbpCaseStatus';

interface Props {
  status: string;
  rbpCaseStatus: RbpCaseStatus;
}

export function CaseViewStatus({ status, rbpCaseStatus }: Props) {
  return (
    <Box>
      <Typography component={'span'} variant="body1">
        RAP Call Status:
        <Typography component={'span'} fontWeight={600}>
          {' '}
          {status}
        </Typography>
      </Typography>
      {' / '}
      <Typography component={'span'} variant="body1">
        RBP Case Status:
        <Typography
          component={'span'}
          fontWeight={600}
          color={rbpCaseStatus === RbpCaseStatus.CANCELLED ? AppColors.AAA_RED : AppColors.AAA_BLUE}
        >
          {' '}
          {RbpCaseStatusDescription[rbpCaseStatus]}
        </Typography>
      </Typography>
    </Box>
  );
}
