import { useContext } from 'react';
import { Context } from './components/PricingSetupProvider';
import { PricingSetupComponent } from './components/types';
import PricingSetupPageableLayout from './components/PricingSetupPageableLayout';
import PricingDetail from './PricingDetail';

/**
 * A component that renders a page for creating a new pricing setup.
 *
 * @param navigators - An object containing functions for navigating to other screens.
 * @param navigators.toIndividualScreen - A function that navigates to the individual pricing screen.
 */
const PricingSetupCreator: PricingSetupComponent = ({ navigators }) => {
  const { entityType } = useContext(Context);

  return (
    <PricingSetupPageableLayout>
      <PricingDetail
        toIndividualScreen={navigators.toIndividualScreen}
        isCreationMode={true}
        entityType={entityType}
      />
    </PricingSetupPageableLayout>
  );
};

export default PricingSetupCreator;
