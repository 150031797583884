import { TableRow, TableCell } from '@mui/material';
import { GMRevisionDto } from 'api/cases/interfaces/CaseFinancialDto';
import { formatDateTime } from '../helpers';
import { convertToCurrency } from 'utils/formatter';

export const REVISION_ITEM_HEADERS = [
  'No.',
  'Document ID',
  'Status',
  'OEM Claim Number',
  'Created date',
  'Codes',
  'Descriptions',
  'Total amount',
];
interface Props {
  revision: GMRevisionDto;
  idx: number;
}

function RevisionItemRow({ revision, idx }: Props) {
  return (
    <TableRow
      sx={{
        background: 'white',
      }}
    >
      <TableCell>{idx}</TableCell>
      <TableCell>{revision.documentId}</TableCell>
      <TableCell>{revision.status}</TableCell>
      <TableCell>{revision.oemClaimNumber}</TableCell>
      <TableCell>{formatDateTime(revision.createdAtDateTime)}</TableCell>
      <TableCell>{revision.codes?.join(', ') || '-'}</TableCell>
      <TableCell
        sx={{
          maxWidth: '400px',
        }}
      >
        {revision.descriptions?.join(', ') || '-'}
      </TableCell>
      <TableCell>{convertToCurrency(revision.totalAmount)}</TableCell>
    </TableRow>
  );
}

export default RevisionItemRow;
