import { DynamicField, DynamicFieldType, DynamicValue } from 'components/Dynamic/DynamicForm';
import * as Yup from 'yup';

export function useAddAdjustmentConfig() {
  const addAdjustmentFields: DynamicField<DynamicValue>[] = [
    {
      name: 'rapCaseId',
      label: 'RAP Case Id',
      colspan: 4,
      isReadOnly: true,
    },
    {
      label: 'Client Name - ID',
      name: 'clientName',
      colspan: 4,
      isReadOnly: true,
    },
    {
      label: 'Service Type',
      name: 'payable',
      isReadOnly: true,
      colspan: 4,
    },
    {
      name: 'subType',
      label: 'Sub Type',
      colspan: 4,
      type: DynamicFieldType.SELECT,
      select: {
        options: [{ value: 'REIMBURSEMENT', label: 'Reimbursement' }],
      },
    },
    {
      name: 'statusReason',
      label: 'Status Reason',
      colspan: 4,
      type: DynamicFieldType.SELECT,
      select: {
        options: [{ value: 'CLS', label: 'Closed' }],
      },
      required: true,
    },
    {
      name: 'compensationAmount',
      label: 'Compensation Amount',
      colspan: 4,
      type: DynamicFieldType.TEXT,
      required: true,
    },
    {
      name: 'rootCause',
      label: 'Root Cause',
      colspan: 4,
      type: DynamicFieldType.SELECT,
      select: {
        options: [{ value: 'FAILURE_TO_NOTIFY_OF_GOA', label: 'Failure To Notify Of Goa' }],
      },
      required: true,
    },
    {
      name: 'resolutionNote',
      label: 'Resolution Note',
      colspan: 12,
      type: DynamicFieldType.TEXT,
      multiline: true,
      rows: 4,
      required: true,
    },
  ];

  const addAdjustmentValidationSchema = Yup.object().shape({
    statusReason: Yup.string().required().label('Status Reason'),
    compensationAmount: Yup.string().required().label('Compensation Amount'),
    rootCause: Yup.string().required().label('Root Cause'),
    resolutionNote: Yup.string().required().label('Resolution Note'),
  });

  return {
    addAdjustmentFields,
    addAdjustmentValidationSchema,
  };
}

export interface AddAdjustmentForm {
  resolutionNote: string;
  statusReason: string;
  compensationAmount: string;
  rootCause: string;
  rapCaseId: string;
  clientName: string;
  payable?: string;
}

export function useAddtoBRConfig(options: any) {
  const addCaseToBRFields: DynamicField<DynamicValue>[] = [
    {
      label: '',
      name: 'billingRequest',
      type: DynamicFieldType.SELECT,
      select: {
        options,
      },
      required: true,
    },
  ];

  const addCaseToBRValidationSchema = Yup.object().shape({
    billingRequest: Yup.string().required().label('Billing Request'),
  });

  return {
    addCaseToBRFields,
    addCaseToBRValidationSchema,
  };
}

export interface AddCaseToBRForm {
  billingRequest: string;
}
