import { useState } from 'react';
import { nanoid } from 'nanoid';
import {
  CaseServiceProvidedData,
  useUpdateServicesProvided,
  useUpdateTroubleCode,
} from '../ServicesProvidedQuery';
import { FieldArrayWithId, UseFieldArrayAppend, UseFieldArrayRemove } from 'react-hook-form';
import { ServicesProvidedFormInput } from './useServicesProvidedForm';

export const useServicesProvided = (caseUUID: string) => {
  const [selectedServicesProvided, setSelectedServicesProvided] = useState<string[]>([]);
  const [deletedServicesProvided, setDeletedServicesProvided] = useState<string[]>([]);
  const [resetTrigger, setResetTrigger] = useState<boolean>(false);

  const {
    mutateAsync: updateServicesProvidedMutateAsync,
    isError: isAddServiceProvidedError,
    errorMessage: addServiceProvidedErrorMessage,
    reset: resetAddServicesProvided,
  } = useUpdateServicesProvided(caseUUID);

  const {
    mutateAsync: updateTroubleCodeMutateAsync,
    isError: isUpdateTroubleCodeError,
    errorMessage: updateTroubleCodeErrorMessage,
    reset: resetUpdateTroubleCode,
  } = useUpdateTroubleCode(caseUUID);

  const onAddServiceProvided = (
    appendServiceProvided: UseFieldArrayAppend<ServicesProvidedFormInput>,
  ) => {
    appendServiceProvided({
      unitCount: 0,
      unitOfMeasure: '',
      code: '',
      cost: 0,
    });
  };

  const onDeleteServiceProvided = (
    servicesProvidedFields: FieldArrayWithId<ServicesProvidedFormInput, 'servicesProvided', 'id'>[],
    removeServiceProvided: UseFieldArrayRemove,
  ) => {
    const itemsToRemove = selectedServicesProvided.filter(
      (x) => !deletedServicesProvided.includes(x),
    );

    const indexes = itemsToRemove.map((service) => {
      return servicesProvidedFields.findIndex(
        (field: CaseServiceProvidedData) => field.uuid === service,
      );
    });

    removeServiceProvided(indexes);
    setDeletedServicesProvided((current) => [...current, ...itemsToRemove]);
    setSelectedServicesProvided([]);
    setResetTrigger((current) => !current);
  };

  const errorMapping = [
    {
      condition: isUpdateTroubleCodeError,
      message: `An Error Has Occurred While Updating Trouble Codes: ${updateTroubleCodeErrorMessage}`,
    },
    {
      condition: isAddServiceProvidedError,
      message: `An Error Has Occurred While Adding Service Provided: ${addServiceProvidedErrorMessage}`,
    },
  ];

  const resetMutations = (): void => {
    resetAddServicesProvided();
    resetUpdateTroubleCode();
  };

  return {
    selectedServicesProvided,
    setSelectedServicesProvided,
    deletedServicesProvided,
    setDeletedServicesProvided,
    resetTrigger,
    updateServicesProvidedMutateAsync,
    updateTroubleCodeMutateAsync,
    onAddServiceProvided,
    onDeleteServiceProvided,
    errorMapping,
    resetMutations,
  };
};
