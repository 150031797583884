import { Box, Typography } from '@mui/material';
import AppColors from 'AppColors';

interface Props {
  label: string;
  icon: JSX.Element;
}

const DashboardSectionHeader = ({ label, icon }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        borderBottom: `1px solid ${AppColors.AAA_LIGHT_GRAY}`,
        color: AppColors.AAA_WHITE,
        svg: {
          width: '24px',
          height: '24px',
          color: AppColors.AAA_WHITE,
        },
      }}
    >
      {icon}
      <Typography variant="h6" sx={{ marginLeft: '11px' }}>
        {label}
      </Typography>
    </Box>
  );
};

export default DashboardSectionHeader;
