import { styled } from '@mui/material/styles';
import {
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  Typography,
} from '@mui/material';
import AppColors from 'AppColors';
import { Controller } from 'react-hook-form';
import { ControlFieldProps } from '../types';

export type ControlledTextFieldProps = MuiTextFieldProps &
  ControlFieldProps & {
    inlineEditing?: boolean;
    Tooltip?: React.ReactNode;
    startAdornment?: string;
    isEditMode?: boolean;
    endAdornment?: JSX.Element;
    'data-testid'?: string;
  };

const CustomTextField = styled(MuiTextField, {
  shouldForwardProp: (propName) => propName !== 'inlineEditing',
})<MuiTextFieldProps & { inlineEditing?: boolean; Tooltip?: React.ReactNode }>(
  ({ inlineEditing, InputProps }) => {
    const disabled = InputProps?.disabled || false;

    return {
      ...(inlineEditing && {
        '.MuiInput-root': {
          '&:before': { borderBottom: 'none' },
          '&:after': { borderBottom: 'none' },
          '&:hover:not(.Mui-disabled):before ': { borderBottom: 'none' },
        },
      }),

      '& legend': {
        fontWeight: 600,
      },
      '& label': {
        fontWeight: 600,
        color: AppColors.AAA_BLUE,
      },
      ...(disabled && {
        '.MuiInputBase-root': {
          '& input': { textFillColor: AppColors.AAA_BLUE },
          '&.Mui-disabled:before': { borderBottomStyle: 'inherit' },
        },
      }),
      '& fieldset': {
        // Overwrite the MUI default color
        borderColor: `${disabled ? AppColors.AAA_GRAY : AppColors.AAA_BLUE} !important`,
      },
    };
  },
);

const ControlledTextField = ({
  control,
  name,
  rules,
  size = 'small',
  readOnly,
  InputProps,
  InputLabelProps,
  inlineEditing,
  startAdornment,
  Tooltip,
  label,
  required,
  isEditMode,
  endAdornment,
  'data-testid': dataTestId,
  ...rest
}: ControlledTextFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <>
          <CustomTextField
            {...field}
            {...rest}
            label={required && !readOnly ? `${label} * ` : label}
            size={size}
            inputProps={{
              'data-testid': dataTestId,
            }}
            InputProps={{
              ...InputProps,
              disabled: readOnly,
              startAdornment: startAdornment && (
                <InputAdornment disableTypography={true} position="end">
                  <Typography variant="body2" sx={{ color: AppColors.AAA_BLUE, pb: '4px' }}>
                    {startAdornment}
                  </Typography>
                </InputAdornment>
              ),
              style: {
                background: isEditMode && readOnly ? AppColors.AAA_GRAY : 'inherit',
              },
              endAdornment: Tooltip || endAdornment,
            }}
            InputLabelProps={{
              ...InputLabelProps,
              shrink: true,
            }}
            inlineEditing={inlineEditing}
          />
        </>
      )}
    />
  );
};

export default ControlledTextField;
