import { DynamicForm } from 'components/Dynamic';
import FormDivider from 'components/Form/FormDivider';
import { UseFormReturn } from 'react-hook-form';
import { paymentInfoFields } from './fields';
interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hookForm: UseFormReturn<any>;
  isEditMode?: boolean;
  isCreationMode?: boolean;
}

export const VendorPaymentInfoForm = ({ hookForm, isEditMode, isCreationMode }: Props) => {
  return (
    <>
      {isCreationMode && <FormDivider label={'Information'} sx={{ mb: 3 }} />}
      <DynamicForm
        hookForm={hookForm}
        isEditMode={isEditMode}
        fields={paymentInfoFields}
        rowSpacing={3}
      />
    </>
  );
};
