import ReportsDetail from './ReportsDetail';
import ReportsList from './ReportsList';
import { CRUDNavigator } from 'components/CRUDNavigator';
import ReportsMonthsList from './ReportsMonthsList';

export default function PaymentRequestPage() {
  return (
    <CRUDNavigator
      components={{
        Main: ReportsMonthsList,
        Individual: ReportsList,
        Detail: ReportsDetail,
      }}
    />
  );
}
