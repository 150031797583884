import { PaymentRequestDetailsData, PaymentRequestSummaryData } from './types';
import { PaymentRequestDto, PaymentRequestGroupedDto } from 'api/paymentRequests/interfaces';

import {
  downloadManyUUIDReport,
  fetchPaymentRequestByFilter,
  fetchPaymentRequestCasesByClub,
  fetchPaymentRequestSummary,
  generateMonthlyReport,
  sendManyUUIDReportViaEmail,
} from 'api/paymentRequests';
import { PageableParams, useMappedReactQuery } from 'hooks/useReactQueryHelpers';
import { PageableResponse } from 'api/interfaces/responses';
import { convertDateToUTCFormat } from 'utils/convertDate';
import { useMutation } from 'react-query';
import { ReportDto, ReportStatus } from 'features/Reports/types';
import { mapDtoToData as mapGeneralInfoDtoToData } from 'features/Cases/CaseProvider';

/**
 * Filters the payment request reports and separates the club report from the rest.
 * If a club report is found, it is renamed to "Payment Summary Files" and added to the filtered reports.
 * @param reports - An array of payment request reports.
 * @returns An array of payment request reports with the club report separated and renamed if found.
 */
export const filterClubFiles = (reports?: ReportDto[]): ReportDto[] => {
  const filteredReports: ReportDto[] = [];
  let clubReport: ReportDto | undefined;

  reports?.forEach((report) => {
    if (report.recipient === 'CLUB') {
      if (!clubReport) {
        clubReport = {
          ...report, // Create a copy to avoid mutating the original
          uuids: report.uuid ? [report.uuid] : [],
          reportStatus: reports.some(
            (report) =>
              report.recipient === 'CLUB' &&
              ![ReportStatus.READY, ReportStatus.SENT].includes(
                report.reportStatus as ReportStatus,
              ),
          )
            ? undefined
            : report.reportStatus,
        };
      } else if (report.uuid) {
        clubReport.uuids = [...(clubReport.uuids ?? []), report.uuid];
      }
    } else {
      filteredReports.push(report);
    }
  });

  if (clubReport) {
    // Optionally handle merging logic here if needed
    clubReport.name = 'Payment Summary Files';
    filteredReports.push(clubReport);
  }
  return filteredReports;
};

export const mapPaymentRequestGroupedDtoToData = (
  dto: PageableResponse<PaymentRequestGroupedDto>,
): PaymentRequestGroupedDto[] => {
  const items: PaymentRequestSummaryData[] = [];
  const body: PaymentRequestGroupedDto[] = JSON.parse(JSON.stringify(dto));

  body?.forEach((item) => {
    const filteredReports = filterClubFiles(item.reports);
    items.push({
      paymentGroupNumber: item.paymentGroupNumber,
      startPeriod: item.startPeriod,
      endPeriod: item.endPeriod,
      totalCases: item.totalCases,
      totalClubPayments: item.totalClubPayments,
      reports: filteredReports,
    });
  });
  return items;
};

export const mapPaymentRequestItemDataToData = (
  dto: PageableResponse<PaymentRequestDto>,
): PaymentRequestDetailsData[] => {
  const items: PaymentRequestDetailsData[] = [];
  const body: PaymentRequestDto[] = dto.content;

  body?.forEach((item) => {
    const filteredReports = filterClubFiles(item.reports);
    items.push({
      paymentRequestUuid: item.uuid,
      clubUuid: item.clubUuid,
      clubName: item.clubName,
      totalCases: item.caseCount,
      casesClosedAmount: item.casesClosedAmount,
      revenueShare: item.revenueShare,
      reports: filteredReports,
      rapVendorId: item.rapVendorId,
    });
  });
  return items;
};

const QUERY_PAYMENT_REQUEST_FETCH_SUMMARY = 'QUERY_PAYMENT_REQUEST_FETCH_SUMMARY';
const QUERY_PAYMENT_REQUEST_BY_FILTER = 'QUERY_PAYMENT_REQUEST_BY_FILTER';

export function usePaymentRequestFetchSummary() {
  return useMappedReactQuery(
    QUERY_PAYMENT_REQUEST_FETCH_SUMMARY,
    fetchPaymentRequestSummary,
    {
      mapper: mapPaymentRequestGroupedDtoToData,
    },
    { page: 0, size: 9999, filter: '' },
  );
}

export function usePaymentRequestFetchByMonthYearQuery(data: {
  startDate: string;
  endDate: string;
}) {
  return useMappedReactQuery(
    QUERY_PAYMENT_REQUEST_BY_FILTER + JSON.stringify(data),
    fetchPaymentRequestByFilter,
    {
      mapper: mapPaymentRequestItemDataToData,
    },
    {
      page: 0,
      size: 9999,
      filter: `startPeriod:'${convertDateToUTCFormat(
        data.startDate,
      )}' AND endPeriod:'${convertDateToUTCFormat(data.endDate)}'`,
    },
  );
}

const QUERY_PAYMENT_REQUEST_FETCH_CASES = 'QUERY_PAYMENT_REQUEST_FETCH_CASES';

export function usePaymentRequestGetCasesByClub(
  uuid: string,
  clubUuid: string,
  params: PageableParams,
) {
  return useMappedReactQuery(
    QUERY_PAYMENT_REQUEST_FETCH_CASES + uuid + clubUuid + JSON.stringify(params),
    () => fetchPaymentRequestCasesByClub(uuid, clubUuid, params),
    {
      mapper: ({ content, totalElements }) => ({
        content: content?.map(mapGeneralInfoDtoToData),
        totalElements,
      }),
    },
    params,
  );
}

export function useSendManyUUIDReportViaEmail() {
  return useMutation(sendManyUUIDReportViaEmail);
}

export function useDownloadManyUUIDReport() {
  return useMutation(downloadManyUUIDReport);
}

export function useGenerateMonthlyReport() {
  return useMutation(generateMonthlyReport);
}
