import { fetchReportMonthlySummary, fetchReports, fetchReportSummary } from 'api/reports';
import { PageableParams, useMappedReactQuery } from 'hooks/useReactQueryHelpers';
import {
  ReportDto,
  ReportData,
  ReportSummaryData,
  ReportSummaryDto,
  ReportQueryProps,
} from './types';
import { getMonthStartEnd } from './utils';

const QUERY_REPORTS = 'QUERY_REPORTS';

function mapReportDtoToData(reportDtos: ReportDto[]): ReportData[] {
  return reportDtos?.map((reportDto) => ({
    s3Path: reportDto.s3Path,
    reportUuid: reportDto.uuid,
    reportName: reportDto.name,
    reportNumber: reportDto.number || '',
    monthYear: reportDto.endPeriod || '',
    clientName: reportDto.addOns?.billingRequestName || '',
    billingNumber: reportDto.addOns?.billingRequestNumber || '',
    reportRunDate: reportDto.generatedAtDateTime || '',
    addOns: reportDto.addOns || {},
    generatedAtDateTime: reportDto.generatedAtDateTime,
  }));
}

function mapReportSummaryDtoToData(reportSummaryDto: ReportSummaryDto): ReportSummaryData {
  return {
    number: reportSummaryDto.number,
    name: reportSummaryDto.name,
    reports: mapReportDtoToData(reportSummaryDto.reports),
    monthYear: reportSummaryDto.monthYear,
    startPeriod: getMonthStartEnd(reportSummaryDto.monthYear).startDate || '',
    endPeriod: getMonthStartEnd(reportSummaryDto.monthYear).endDate || '',
  };
}

export function useGetReportsByNumber(
  { reportNumber, startPeriod, endPeriod }: ReportQueryProps,
  params: PageableParams,
) {
  return useMappedReactQuery(
    QUERY_REPORTS + reportNumber + startPeriod + endPeriod,
    () => fetchReports({ reportNumber, startPeriod, endPeriod }, params).then((res) => res.content),
    { mapper: mapReportDtoToData },
    params,
  );
}

export function useGetReportSummary(params: PageableParams) {
  return useMappedReactQuery(
    QUERY_REPORTS + JSON.stringify(params),
    () => fetchReportSummary(params),
    {
      mapper: ({ content, totalElements }) => ({
        content: content?.map(mapReportSummaryDtoToData),
        totalElements,
      }),
    },
    params,
  );
}

export function useGetReportMonthlySummary(params: PageableParams) {
  return useMappedReactQuery(
    QUERY_REPORTS + JSON.stringify(params),
    () => fetchReportMonthlySummary(params),
    {
      mapper: ({ content, totalElements }) => ({
        content,
        totalElements,
      }),
    },
    params,
  );
}
