import { ReactNode } from 'react';
import { Box, styled } from '@mui/material';
import AppColors from 'AppColors';

const PrimaryContainerWrapper = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'focused',
})<{ focused: boolean; disabled: boolean }>(({ focused, disabled }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '10px 14px',
  gap: '8px',
  borderRadius: '4px',
  border: focused
    ? `2px solid ${AppColors.AAA_LIGHT_BLUE}`
    : `1px solid ${disabled ? AppColors.AAA_GRAY : AppColors.AAA_BLUE}`,
  backgroundColor: AppColors.AAA_WHITE,
  height: '80px',
  flexWrap: 'wrap',
  overflowY: 'scroll',
}));

export const PrimaryContainer = ({
  focused,
  children,
  disabled,
}: {
  focused: boolean;
  children: ReactNode;
  disabled: boolean;
}) => (
  <PrimaryContainerWrapper focused={focused} disabled={disabled}>
    {children}
  </PrimaryContainerWrapper>
);
